import { Col, Modal, Row } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  generateGuardianInformationArray,
  generatePatientInformationArray
} from './customHooks';
import PatientPersonalInfoForm from '../BookingInfoForms/PatientPersonalInfoForm';
import BookingDetailTab from './bookingDetailTab';
import { useMediaQuery } from 'react-responsive';
export const ReviewPersonalInfo = () => {
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const bookingData = useSelector(state => state.booking);
  const patientInfomation = generatePatientInformationArray(bookingData);
  const guardianInfomation = generateGuardianInformationArray(bookingData);
  const [isModal, setIsModal] = useState(false);
  return (
    <div className='reviewPersonalInfoWrap'>
      <div className='customReviewComponentHeading'>
        <p>Personal info</p>
        {/* <button onClick={() => setIsModal(true)} className='noBgBtn'>
          Change
        </button> */}
      </div>
      <Row gutter={[20, 20]}>
        {patientInfomation?.map((item, index) =>
          item?.question ? (
            <Col key={index} span={mobileResponsive ? 24 : 12}>
              <BookingDetailTab
                key={index}
                question={item?.question}
                answer={item?.answer}
              />
            </Col>
          ) : (
            ''
          )
        )}
      </Row>
      {bookingData?.bookingFlowAs === 'guardian' ? (
        <div className='customReviewComponentHeading'>
          <p>Guardian info</p>
        </div>
      ) : null}
      <Row gutter={[20, 20]}>
        {bookingData?.bookingFlowAs === 'guardian' && (
          <>
            {guardianInfomation?.map((item, index) =>
              item?.question ? (
                <Col key={index} span={mobileResponsive ? 24 : 12}>
                  <BookingDetailTab
                    key={index}
                    question={item?.question}
                    answer={item?.answer}
                  />
                </Col>
              ) : (
                ''
              )
            )}
          </>
        )}
      </Row>
      {isModal ? (
        <Modal
        centered
          title='Update personal info'
          width={mobileResponsive ? '90%' : '60%'}
          open={isModal}
          onCancel={() => setIsModal(false)}
          footer={false}
        >
          <PatientPersonalInfoForm setIsModal={setIsModal} />
        </Modal>
      ) : null}
    </div>
  );
};
