import classes from "./StepsLayout.module.scss";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { arrowDown, logo } from "../../assets";
import { setBookingFlowInformationSteps, setBookingFlowMedialInfoSteps } from "../../Redux/Reducers/bookingSlice";

const StepsLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bookingFlowInformationSteps = useSelector((state) => state.booking.bookingFlowInformationSteps);
  const bookingFlowMedialInfoSteps = useSelector((state) => state.booking.bookingFlowMedialInfoSteps);


  
  const routesHanler = () => {
    if(window.location.pathname.includes("/infoSteps")){
      if (bookingFlowInformationSteps > 0) {
        dispatch(setBookingFlowInformationSteps(bookingFlowInformationSteps - 1));
      } else {
        navigate("/");
      }
    }else if(window.location.pathname.includes("/medical")){
      if (bookingFlowMedialInfoSteps > 0) {
        dispatch(setBookingFlowMedialInfoSteps(bookingFlowMedialInfoSteps - 1));
      } else {
        navigate("/consultations");
      }
    }else if(window.location.pathname.includes("/booking-review")){
      dispatch(setBookingFlowMedialInfoSteps(2));
      navigate("/medical")

    }
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.logo}>
          <ReactSVG src={logo} onClick={() => navigate("/")} />
        </div>
        <div className={classes.backBtn}>
          <ReactSVG src={arrowDown} onClick={() => routesHanler()} />
          <span className={classes.back} onClick={() => routesHanler()}>
            Back
          </span>
        </div>
      </div>
      <main>{children}</main>
    </>
  );
};

export default StepsLayout;
