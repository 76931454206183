import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOut } from '../../Redux/Reducers/authSlice';
import {
  setCompleteUser,
  setInfoSteps
} from '../../Redux/Reducers/gernalSlice';
import SecondaryButton from '../UI/SecondaryButton/SecondaryButton';
import { useMediaQuery } from 'react-responsive';

const LogoutModal = ({ handleOk }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const SignOut = () => {
    dispatch(logOut());
    dispatch(setCompleteUser({}));
    dispatch(setInfoSteps({}));
    navigate('/');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginTop: '50px'
      }}
    >
      <h2
        style={{
          fontSize: mobileResponsive ? '20px' : '30px',
          textAlign: 'center'
        }}
      >
        Are you sure you want to logout?
      </h2>
      <div style={{ display: 'flex' }}>
        <button onClick={() => handleOk()} className='noBgBtn'>
          Cancel
        </button>
        <SecondaryButton onClick={() => SignOut()} className='primaryButton'>
          Logout
        </SecondaryButton>
      </div>
    </div>
  );
};

export default LogoutModal;
