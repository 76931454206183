import React from "react";
import { Skeleton } from "antd";

const SkeletonInput = ({ block, size }) => {
  return (
    <Skeleton.Input
      active
      size={size || "large"}
      block={block}
      shape="square"
    />
  );
};

export default SkeletonInput;
