import React from "react";
import "./pharmacy&Rx.scss";
import { useMediaQuery } from "react-responsive";
import { RxPrescriptions } from "./RxPrescriptions";

const PharmacyAndRx = ({ booking }) => {
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  return (
    <div className="pharmacyAndRx">
      <div className="pharmacybox">
        <h3>Pharmacy</h3>
        <p>
          {`${booking?.pharmacy?.StoreName || "-"} | ${
            booking?.pharmacy?.Address1 || "-"
          } ${booking?.pharmacy?.City || "-"} ${
            booking?.pharmacy?.State || "-"
          } ${booking?.pharmacy?.ZipCode || "-"}` || "--"}
        </p>
      </div>

      <div className="heading">
        <h2>Prescription</h2>
        <div className="prescriptionTable">
          <RxPrescriptions
            mobileResponsive={mobileResponsive}
            booking={booking}
          />
        </div>
      </div>
    </div>
  );
};

export default PharmacyAndRx;
