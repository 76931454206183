import React, { useEffect, useState } from "react";
import "./PatientBookingReview.scss";
import { ReviewPersonalInfo } from "./BookingReview/ReviewPersonalInfo";
import { ReviewAllergies } from "./BookingReview/ReviewAllergies";
import { ReviewPharmacyAndLab } from "./BookingReview/ReviewPharmacyAndLab";
// import { ReviewSocialHistory } from './BookingReview/ReviewSocialHistory';
import { ReviewMedicalHistory } from "./BookingReview/ReviewMedicalHistory";
import { ReviewSocialHistory } from "./BookingReview/ReviewSocialHistory";
import { CloseOutlined } from "@ant-design/icons";
import { getRequest } from "../../../service/ApisFunctions";

export const PatientBookingReview = ({
  isMedicalHistoryQuestions,
  setIsMedicalHistoryQuestions,
  setSocialHistoryQuestions,
  isFilledMedicalHistory,
}) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [medicalHistorylist, setMedicalHistorylist] = useState([]);
  const [socialHistorylist, setSocialHistorylist] = useState([]);

  const getmedicalHistoryList = async () => {
    setModalLoading(true);
    const onSuccess = (res) => {
      setModalLoading(false);
      const filterMedicalHistory = res?.data?.[0]?.questions?.filter(
        (item) => !item?.isSocialHistory
      );
      const filterSocialHistory = res?.data?.[0]?.questions?.filter(
        (item) => item?.isSocialHistory
      );
      setMedicalHistorylist(filterMedicalHistory);
      setSocialHistorylist(filterSocialHistory);
    };
    const onError = () => {
      setModalLoading(false);
    };

    await getRequest(
      "",
      `medicalHistory/get-medical-history-questions`,
      true,
      onSuccess,
      onError
    );
    // await getRequest(
    //   "",
    //   bookingData?.bookingFlowType === "Treatment programs"
    //     ? `speciality/program_medical_history_question_listing/${bookingData?.bookableFlowProgram?._id}`
    //     : `speciality/category_medical_history_question_listing/${bookingData?.bookableFlowCondition?._id}`,
    //   true,
    //   onSuccess,
    //   onError
    // );
  };

  useEffect(() => {
    getmedicalHistoryList();
  }, []);

  return (
    <div className="patientBookingReviewWrap">
      <div className="reviewHeading">
        <h3>Review info</h3>
        <div onClick={() => isFilledMedicalHistory()} className="closeIcon">
          <CloseOutlined />
        </div>
      </div>
      <ReviewPersonalInfo />
      <ReviewMedicalHistory
        // isMedicalHistoryQuestions={isMedicalHistoryQuestions}
        // medicalHistorylist={medicalHistorylist}
        setIsMedicalHistoryQuestions={setIsMedicalHistoryQuestions}
      />
      <ReviewSocialHistory
        socialHistorylist={socialHistorylist}
        setSocialHistoryQuestions={setSocialHistoryQuestions}
      />
      <ReviewAllergies />
      {/* <ReviewSocialHistory /> */}
      <ReviewPharmacyAndLab />
    </div>
  );
};
