import React, { useEffect, useState } from "react";
import {
  disabledFutureDate,
  formatHeight,
  formatPhoneNumber,
  validateHeight,
  ValidateMaxLength,
  ValidateMaxLengthAndContent,
  validatePhoneNumber,
  validateWeight,
} from "../../Utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { useMediaQuery } from "react-responsive";
import { useGeolocated } from "react-geolocated";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {
  AllLanguages,
  EthnicityList,
  HeightUnit,
  PhoneTypes,
  RaceList,
  WeightUnit,
  birthGendersList,
  gendersList,
  pronounList,
  sexualOrientation,
} from "../../Utils/constant";
import dayjs from "dayjs";
import {
  setIsConsentForm,
  setIsProfileFilled,
  setToken,
  setUser,
} from "../../Redux/Reducers/authSlice";
import { setCompleteUser } from "../../Redux/Reducers/gernalSlice";
import { getRequest, postRequest } from "../../service/ApisFunctions";
import { ReactSVG } from "react-svg";
import { calendarSimple } from "../../assets";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";
import CheckBoxFields from "../UI/CheckBoxFields/CheckFields";
import { useNavigate } from "react-router-dom";
import { DEPLOYMENT_MODE } from "../../Utils/baseUrl";
import { setBookingFlowLogin } from "../../Redux/Reducers/bookingSlice";
import BackButton from "../UI/BackButton/BackButton";
import CustomDesignedDatePicker from "../UI/CustomDesignedDatePicker/CustomDesignedDatePicker";

const RefillsGuestSignup = ({ setRefillBookingProgressStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [informationStep, setInformationStep] = useState("general");
  const [isGuest, setIsGuest] = useState(false);
  const [consentCheck, setConsentCheck] = useState(false);

  const [guardianHeightUnit, setGuardianHeightUnit] = useState(1);
  const [guardianWeightUnit, setGuardianWeightUnit] = useState(1);
  const [allState, setAllState] = useState([]);
  const [fpHash, setFpHash] = useState("");
  const [userData, setUserData] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const patientToken = useSelector((state) => state.authReducer.paitentToken);
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const consentForms = useSelector(
    (state) => state.generalReducer.consentForms
  );

  const getAllSatates = async () => {
    setLoading(true);
    const onSuccess = (res) => {
      setAllState(res?.data);
      setLoading(false);
    };

    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    await getRequest(
      "",
      "state/active_state_listing",
      true,
      onSuccess,
      onError
    );
  };
  const handleSubmit = (values) => {
    const age = dayjs().diff(dayjs(values?.date_of_birth), "year");
    if (age < 18) {
      message.warning("Under 18 you are unable to book consultation");
      return;
    }
    const filterPhoneTypeByLable = PhoneTypes.find(
      (item) => item?.value === values?.PrimaryPhoneType
    );
    setUserData({
      first_name: values?.firstName,
      last_name: values?.lastname,
      middle_name: values?.middle_name,
      email: values?.email,
      phone_number: values?.phone_number,
      address: values?.address,
      dob: values?.date_of_birth?.$d,
      height: { value: values?.height, unit: "3" },
      weight: { value: values?.weight, unit: "1" },
      language: values?.language || [],
      state: values?.state,
      ZipCode: values?.ZipCode,
      City: values?.City,
      PrimaryPhoneType: filterPhoneTypeByLable?.id,
      gender: values?.gender,
      suffix: values?.suffix,
        prefix: values?.prefix,
    });
    if (!loading) {
      setInformationStep("demographic");
    }
  };
  const handleCensusSubmit = async (values) => {
    if (!patientToken) {
      setLoading(true);
      const dataToSend = {
        email: userData?.email,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        suffix: userData?.suffix,
        prefix: userData?.prefix,
        middle_name: userData?.middle_name,
        deviceId: fpHash,
        gender: values?.gender,
        birthGender: values?.birthGender,
        sexualOrientation: values?.sexualOrientation,
        pronoun: values?.pronoun,
        race: values?.race,
        ethnicity: values?.ethnicity,
        height: userData?.height,
        weight: userData?.weight,
        dob: userData?.dob,
        phone_number: userData?.phone_number,
        state: userData?.state,
        location: userLocation,
        language: userData?.language || [],
        ZipCode: userData?.ZipCode,
        City: userData?.City,
        PrimaryPhoneType: userData?.PrimaryPhoneType,
        allowConsent: consentCheck,
        consentHistory: consentForms,
      };
      const onSuccess = (res) => {
        localStorage.setItem("patientToken", res?.token);
        dispatch(setToken(res?.token));
        dispatch(setCompleteUser(res.data));
        setLoading(false);
        message.success(res?.message);
        setRefillBookingProgressStep("medications");
        dispatch(setIsProfileFilled({ personalInfo: true }));
      };
      const onError = (err) => {
        if (err?.message?.Message) {
          const modelState = err.message.ModelState;
          for (const key in modelState) {
            message.error(`${key}: ${modelState[key]}`);
          }
        } else {
          message.error(err.message);
        }
        setLoading(false);
      };
      await postRequest(
        dataToSend,
        "customer/guest_signup",
        true,
        onSuccess,
        onError
      );
    }
  };
  const me = async () => {
    const onSuccess = (res) => {
      dispatch(setCompleteUser(res.data));
    };

    const onError = (err) => {};

    await getRequest(
      "",
      "auth/auth_me?role=customer",
      true,
      onSuccess,
      onError
    );
  };
  const handleChangePhoneNumberPattren = (e) => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);

    form.setFieldsValue({
      phone_number: formatted,
    });
  };
  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFpHash(visitorId);
    };
    setFp();
    getAllSatates();
    if (patientToken) {
      me();
      dispatch(setUser({}));
    }
  }, [patientToken]);

  useEffect(() => {
    form.setFieldsValue({
      weightUnit: 1,
      heightUnit: 3,
    });
  }, []);

  const handleChangeHeight = (e) => {
    const input = e.target.value;
    const formatted = formatHeight(input);
    form.setFieldsValue({
      height: formatted,
    });
  };
  const height = Form.useWatch("height", form);
  const weight = Form.useWatch("weight", form);

  const calculateBMI = (height, weight) => {
    if (!height || !weight || height.length < 3) {
      form.setFieldsValue({
        bmi: "",
      });
      return;
    }
    const split_height = height.split("'");
    const feet = parseInt(split_height[0], 10);
    const inches = parseInt(split_height[1], 10);
    const heightInInches = feet * 12 + inches;
    const heightInMeters = heightInInches * 0.0254;
    const weightInKg = weight * 0.453592;
    const bmi = weightInKg / (heightInMeters * heightInMeters);
    form.setFieldsValue({
      bmi: bmi.toFixed(1),
    });
  };

  useEffect(() => {
    calculateBMI(height, weight);
  }, [height, weight]);

  return (
    <Spin spinning={loading}>
      <div className="information" style={{ width: "65%", margin: "0 auto" }}>
        <>
          <CheckBoxFields
            label=""
            placeholder="Already have an account?"
            button={
              <button
                className="customLoginButton"
                onClick={() => {
                  dispatch(setBookingFlowLogin("refill"));
                  navigate("/login");
                }}
              >
                Log in
              </button>
            }
          />
          {!isGuest && DEPLOYMENT_MODE === "dev" ? (
            <CheckBoxFields
              label=""
              placeholder="Don't have an account?"
              button={
                <button
                  className="customLoginButton"
                  onClick={() => setIsGuest(true)}
                >
                  Continue As Guest
                </button>
              }
            />
          ) : null}
        </>
        {isGuest ? (
          <>
            {" "}
            {informationStep === "general" ? (
              <>
                <h3>Patient Info</h3>

                <Form
                  layout="vertical"
                  form={form}
                  className={"fields"}
                  onFinish={handleSubmit}
                  style={{ width: "100%" }}
                >
                  <Row gutter={[35, 10]}>
                    <Col span={3}>
                      <Form.Item
                        name="prefix"
                        label="Prefix"
                        rules={[
                          { required: false },
                          ValidateMaxLength(10, "Prefix"),
                        ]}
                      >
                        <Input
                          className="ant-input-affix-wrapper"
                          placeholder="Enter your prefix"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 6}>
                      <Form.Item
                        name="firstName"
                        label="First name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter first name",
                          },
                          ValidateMaxLengthAndContent(35, "First Name"),
                        ]}
                      >
                        <Input
                          className="ant-input-affix-wrapper"
                          placeholder="Enter your First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 6}>
                      <Form.Item
                        name="middle_name"
                        label="Middle name"
                        rules={[
                          { required: false },
                          ValidateMaxLengthAndContent(35, "Middle Name"),
                        ]}
                      >
                        <Input
                          className="ant-input-affix-wrapper"
                          placeholder="Enter your Middle Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 6}>
                      <Form.Item
                        name="lastname"
                        label="Last name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name",
                          },
                          ValidateMaxLengthAndContent(35, "Last name"),
                        ]}
                      >
                        <Input
                          className="ant-input-affix-wrapper"
                          placeholder="Enter your Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        name="suffix"
                        label="Suffix"
                        rules={[
                          { required: false },
                          ValidateMaxLength(10, "Suffix"),
                        ]}
                      >
                        <Input
                          className="ant-input-affix-wrapper"
                          placeholder="Enter your suffix"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="email"
                        label="Email Address"
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "Please enter your valid email",
                          },
                          ValidateMaxLength(80, "Email"),
                        ]}
                      >
                        <Input
                          className="ant-input-affix-wrapper"
                          placeholder="Email address"
                          type="email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="date_of_birth"
                        label="Date Of Birth"
                        rules={[
                          {
                            required: true,
                            message: "Please select your date of birth",
                          },
                        ]}
                      >
                        <CustomDesignedDatePicker
                          field={"date_of_birth"}
                          form={form}
                          defaultValue={completeUser?.dob}
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="date_of_birth"
                        label="Date Of Birth"
                        rules={[
                          {
                            required: true,
                            message: "Please select your date of birth",
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={disabledFutureDate}
                          className="ant-input-affix-wrapper"
                          placeholder="Date of birth"
                          suffixIcon={
                            <ReactSVG width="20px" src={calendarSimple} />
                          }
                          format="MM-DD-YYYY"
                          picker="date"
                        />
                      </Form.Item>
                    </Col> */}
                    {/* <Col span={mobileResponsive ? 24 : 12}>
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          name="height"
                          label="Height"
                          style={{ width: "80%" }}
                          rules={[
                            { required: true },
                            { validator: validateHeight(guardianHeightUnit) },
                          ]}
                        >
                          <Input
                            type="number"
                            className="ant-input-affix-wrapper"
                            placeholder="Height"
                          />
                        </Form.Item>

                        <Form.Item
                          name="heightUnit"
                          label="    "
                          style={{ width: "20%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select your unit",
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) => {
                              setGuardianHeightUnit(e);
                            }}
                            className="ant-select-selector"
                            placeholder="Unit"
                          >
                            {HeightUnit?.map((item, index) => (
                              <Select.Option key={index} value={item?.id}>
                                {item?.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Space.Compact>
                    </Col> */}

                    <Col span={mobileResponsive ? 12 : 6}>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Form.Item
                          name="height"
                          label="Height"
                          rules={[
                            { required: true },
                            { validator: validateHeight(guardianHeightUnit) },
                          ]}
                        >
                          <Input
                            addonBefore="Ft'In"
                            placeholder="Height"
                            onChange={handleChangeHeight}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    {/* <Col span={mobileResponsive ? 24 : 12}>
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          style={{ width: "80%" }}
                          name="weight"
                          label="Weight"
                          rules={[
                            { required: true },
                            { validator: validateWeight(guardianWeightUnit) },
                          ]}
                        >
                          <Input
                            min={1}
                            type="number"
                            className="ant-input-affix-wrapper"
                            placeholder="Weight"
                          />
                        </Form.Item>

                        <Form.Item
                          style={{ width: "20%" }}
                          name="weightUnit"
                          label="   "
                          rules={[
                            {
                              required: true,
                              message: "Please select your unit",
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) => {
                              setGuardianWeightUnit(e);
                            }}
                            className="ant-select-selector"
                            placeholder="Unit"
                            disabled
                          >
                            {WeightUnit?.map((item, index) => (
                              <Select.Option key={index} value={item?.id}>
                                {item?.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Space.Compact>
                    </Col> */}

                    <Col span={mobileResponsive ? 12 : 6}>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Form.Item
                          name="weight"
                          label="Weight"
                          rules={[
                            { required: true },
                            { validator: validateWeight(guardianWeightUnit) },
                          ]}
                        >
                          <Input
                            addonBefore="Lbs"
                            type="number"
                            placeholder="Enter your weight"
                          />
                        </Form.Item>
                      </div>
                    </Col>

                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="bmi"
                        label="BMI"
                        rules={[
                          {
                            required: false,
                            message: "Please select phone type",
                          },
                        ]}
                      >
                        <Input placeholder="BMI" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="language"
                        label="Language"
                        rules={[
                          {
                            required: true,
                            message: "Please select your language",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          mode="multiple"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          className="ant-select-selector"
                          placeholder="language"
                        >
                          {AllLanguages?.map((item, index) => (
                            <Select.Option key={index} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={mobileResponsive ? 24 : 12}>
                      <Space.Compact style={{ width: "100%" }}>
                        <Form.Item
                          name="phone_number"
                          label="Phone Number"
                          style={{ width: "75%" }}
                          rules={[
                            {
                              validator: validatePhoneNumber,
                            },
                          ]}
                        >
                          <Input
                            onChange={handleChangePhoneNumberPattren}
                            className="ant-input-affix-wrapper"
                            placeholder="Phone number"
                            min={1}
                          />
                        </Form.Item>
                        <Form.Item
                          name="PrimaryPhoneType"
                          label="     "
                          style={{ width: "25%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select phone type",
                            },
                          ]}
                        >
                          <Select
                            className="ant-select-selector"
                            placeholder="Select phone type"
                          >
                            {PhoneTypes?.map((item, index) => (
                              <Select.Option key={index} value={item?.value}>
                                {item?.label || "-"}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Space.Compact>
                    </Col>

                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="address"
                        label="Street"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Street",
                          },
                          ValidateMaxLength(35, "Street"),
                        ]}
                      >
                        <Input
                          className="ant-input-affix-wrapper"
                          placeholder="Reachable Street"
                          onChange={(e) =>
                            setUserLocation({
                              address: e.target.value,
                              coordinates: [
                                coords?.longitude ? coords?.longitude : 0,
                                coords?.latitude ? coords?.latitude : 0,
                              ],
                            })
                          }
                          // suffix={
                          //   <ReactSVG
                          //     style={{ cursor: "pointer" }}
                          //     // onClick={getLocation}
                          //     src={locationIcon}
                          //   />
                          // }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={mobileResponsive ? 24 : 12}>
                      {" "}
                      <Form.Item
                        name="City"
                        label="City"
                        rules={[
                          { required: true, message: "Please enter city" },
                          {
                            pattern: /^[A-Za-z\s]+$/,
                            message: "City must contain only alphabets",
                          },
                          ValidateMaxLength(35, "City"),
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          className="ant-input-affix-wrapper"
                          placeholder="Please enter city"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="ZipCode"
                        label="Zip Code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter only digits",
                          },
                          {
                            pattern: /^[0-9]{5}$/,
                            message: "Zip Code must be exactly 5 digits",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          maxLength={5}
                          autoComplete="off"
                          className="ant-input-affix-wrapper"
                          placeholder="Please enter zip code"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "please select any state",
                          },
                          ValidateMaxLength(10, "State"),
                        ]}
                      >
                        <Select
                          className="ant-select-selector"
                          placeholder="Select your state"
                        >
                          {allState?.map((item, index) => (
                            <Select.Option key={index} value={item?.state}>
                              {item?.state}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: "200px", marginBottom: "20px" }}>
                        <SecondaryButton type="submit" width="100%">
                          Next
                        </SecondaryButton>
                      </div>
                    </div>
                  </Row>
                </Form>
              </>
            ) : informationStep === "demographic" ? (
              <>
                <h3>Health Equity</h3>
                <Form
                  layout="vertical"
                  form={form}
                  className={"fields"}
                  onFinish={handleCensusSubmit}
                >
                  <Row gutter={[35, 10]}>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="birthGender"
                        label="Birth Gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select your birth gender",
                          },
                        ]}
                      >
                        <Select
                          className="ant-select-selector"
                          placeholder="Birth Gender"
                        >
                          {birthGendersList.map((item, index) => (
                            <Select.Option key={index} value={item?.name}>
                              {item?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="gender"
                        label="Current Gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select your gender",
                          },
                        ]}
                      >
                        <Select
                          className="ant-select-selector"
                          placeholder="Select gender"
                        >
                          {gendersList?.map((item, index) => (
                            <Select.Option key={index} value={item?.name}>
                              {item?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="sexualOrientation"
                        label="Sexual orientation"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your sexual orientation",
                          },
                        ]}
                      >
                        <Select
                          className="ant-select-selector"
                          placeholder="Please select sexual orientation"
                        >
                          {sexualOrientation.map((item, index) => (
                            <Select.Option key={index} value={item?.name}>
                              {item?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="pronoun"
                        label="Pronouns"
                        rules={[
                          {
                            required: true,
                            message: "Please select your pronoun",
                          },
                        ]}
                      >
                        <Select
                          className="ant-select-selector"
                          placeholder="Select pronoun"
                        >
                          {pronounList?.map((item, index) => (
                            <Select.Option key={index} value={item?.name}>
                              {item?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="ethnicity"
                        label="Ethnicity"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your ethnicity",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          className="ant-select-selector"
                          placeholder="Please select ethnicity"
                        >
                          {EthnicityList.map((item, index) => (
                            <Select.Option key={index} value={item?.name}>
                              {item?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <Form.Item
                        name="race"
                        label="Race"
                        rules={[
                          { required: true, message: "Please enter your race" },
                        ]}
                      >
                        <Select
                          className="ant-select-selector"
                          placeholder="Please select race"
                        >
                          {RaceList.map((item, index) => (
                            <Select.Option key={index} value={item?.name}>
                              {item?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="signUpConsent" span={24}>
                      <Form.Item
                        name="consent"
                        rules={[
                          {
                            required: consentCheck ? false : true,
                            message: "Please agree to the terms and conditions",
                          },
                        ]}
                      >
                        <Checkbox
                          checked={consentCheck}
                          onChange={(e) => setConsentCheck(e.target.checked)}
                        >
                          I have read and agree to
                          <a href="/terms-of-use" target="_blank">
                            <span className="title">Terms of Use</span>
                          </a>
                          ,
                          <a href="/responsibility-and-billing" target="_blank">
                            <span className="title">
                              Financial Responsibility and Billing
                            </span>
                          </a>{" "}
                          and the
                          <a href="/privacy-policy" target="_blank">
                            <span className="title">Privacy policy</span>
                          </a>
                          .
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "25px",
                      }}
                    >
                      <div style={{ width: "200px" }}>
                        <BackButton
                          onClick={() => setInformationStep("general")}
                          type="button"
                          width="100%"
                        >
                          Back
                        </BackButton>
                      </div>
                      <div style={{ width: "200px" }}>
                        <SecondaryButton type="submit" width="100%">
                          Next
                        </SecondaryButton>
                      </div>
                    </div>
                  </Row>
                </Form>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </Spin>
  );
};

export default RefillsGuestSignup;
