import {
  Col,
  DatePicker,
  Divider,
  Modal,
  Row,
  Spin,
  Table,
  message
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { getRequest } from '../../service/ApisFunctions';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';

import SecondaryButton from '../UI/SecondaryButton/SecondaryButton';
import PrimaryButton from '../UI/PrimaryButton/PrimaryButton';
import {
  setRefillPrescriptions,
  setServiceMedications
} from '../../Redux/Reducers/refillsSlice';
import { ReloadOutlined } from '@ant-design/icons';
import { TrashIcon } from '../../assets';
import Swal from 'sweetalert2';
import RefillServiceMedications from './RefillServiceMedications';
const { RangePicker } = DatePicker;

const RefillMedications = ({ setRefillBookingProgressStep }) => {
  const dispatch = useDispatch();

  const refillServiceMedications = useSelector(
    state => state.refills.refillServiceMedications
  );
  const refillSpeciality = useSelector(state => state.refills.refillSpeciality);

  const completeUser = useSelector(state => state.generalReducer.completeUser);
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });
  const [refillServiceMedModal, setRefillServiceMedModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [medicationHistory, setMedicationHistory] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedMedicationsState, setSelectedMedicationsState] = useState([
    dayjs().subtract(6, 'month'),
    dayjs()
  ]);
  const getMedicationsList = async pageNumber => {
    setLoading(true);
    const onSuccess = res => {
      setLoading(false);
      setMedicationHistory(res?.data?.Items);
      if (res?.data?.Result?.ResultDescription) {
        message.warning(res?.data?.Result?.ResultDescription);
        setDisableSubmit(true);
      } else if (res?.data?.message) {
        message?.warning(res?.data?.message);
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
        matchSelectedMedWithMedHistory(res?.data?.Items);
      }
      setTotalPages(res?.data?.PageResult?.TotalPages);
    };
    const onError = err => {
      setLoading(false);
      message.error(err?.message);
    };
    await getRequest(
      '',
      `medication/get-medication-history?patientId=${
        completeUser?.doseSpotId
      }&start=${dayjs(selectedMedicationsState[0]).format(
        'YYYY-MM-DD'
      )}&end=${dayjs(selectedMedicationsState[1]).format(
        'YYYY-MM-DD'
      )}&pageNumber=${pageNumber}`,
      true,
      onSuccess,
      onError
    );
  };
  const handleSubmitMedications = () => {
    const restricted = refillServiceMedications?.filter(
      elem => !elem?.isApproved
    );

    if (restricted) {
      const approvedMedications = refillServiceMedications.filter(
        med => med.isApproved
      );
      const otherMedications = refillServiceMedications.filter(
        med => !med.isApproved
      );
      let htmlContent =
        '<div style="text-align: left;"><h2 style="text-align: center;">Medications</h2>';
      htmlContent += '<h4>Approved Medications</h4><ul>';
      approvedMedications.forEach(med => {
        htmlContent += `<li style="font-size: 13px;">${med.label}</li>`;
      });
      if (otherMedications?.length) {
        htmlContent += '</ul><h4>Rejected Medications</h4><ul>';
        otherMedications.forEach(med => {
          htmlContent += `<li style="font-size: 13px;">${med.label}</li>`;
        });

        htmlContent +=
          '</ul><h5 style="text-align: center;margin-top:20px">The following medications have been rejected because they are either not present in your medication history or were last refilled over six months ago. Do you want to continue with just the approved medications?</h5></div>';
      }
      Swal.fire({
        html: htmlContent,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      }).then(result => {
        if (result.isConfirmed) {
          if (approvedMedications?.length) {
            dispatch(setRefillPrescriptions(approvedMedications));
            setRefillBookingProgressStep('refillPayment');
          } else {
            message.error('No Approved Medications.');
          }
        }
      });
    }
  };
  const matchSelectedMedWithMedHistory = medicationHistory => {
    const sixMonthsAgo = dayjs().subtract(6, 'month');
    const result = refillServiceMedications?.map(item => {
      const existsInArray2 = medicationHistory?.find(
        obj =>
          obj?.DispensableDrugId === item?.value &&
          dayjs(obj?.WrittenDate).isAfter(sixMonthsAgo)
      );
      if (!existsInArray2) {
        return { ...item, isApproved: false };
      }
      return { ...item, isApproved: true, ...existsInArray2 };
    });
    dispatch(setServiceMedications(result));
  };
  const deleteServiceMedications = async item => {
    const filtered = refillServiceMedications?.filter(
      data => data?.value !== item?.value
    );
    matchSelectedMedWithMedHistory(filtered);
    dispatch(setServiceMedications(filtered));
  };
  useEffect(() => {
    getMedicationsList(1);
  }, [selectedMedicationsState, refillServiceMedModal]);

  useMemo(() => {
    if (!refillServiceMedications?.length) {
      setRefillServiceMedModal(true);
    }
  }, [refillServiceMedications]);
  return (
    <>
      <Spin spinning={loading}>
        <div className='refillServicesMedicationsWrapper'>
          <Row gutter={[10, 10]}>
            {refillServiceMedications?.map(item => (
              <Col span={12}>
                <div
                  style={{ borderColor: item?.isApproved ? '#25d366' : 'red' }}
                  className='singleServiceMed'
                >
                  <p style={{ color: item?.isApproved ? '#25d366' : 'red' }}>
                    {item?.label}
                  </p>
                  <img
                    src={TrashIcon}
                    alt=''
                    onClick={() => {
                      deleteServiceMedications(item);
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className='refillMedicationsWrapper'>
          <div className='refillMedicationsHeader'>
            <h2>Medications</h2>
            <div className='refillMedicationsActions'>
              <ReloadOutlined onClick={() => getMedicationsList(1)} />
              <RangePicker
                disabledDate={current =>
                  current && current > dayjs().endOf('day')
                }
                disabled
                onChange={value => setSelectedMedicationsState(value)}
                defaultValue={[dayjs().subtract(6, 'month'), dayjs()]}
                allowClear={false}
                allowEmpty={false}
                style={{ height: '25px' }}
              />
            </div>
          </div>
          <Divider />
          <Table
            scroll={mobileResponsive ? { x: 1200, y: 260 } : {}}
            className='antd-custom-table'
            columns={columns}
            dataSource={medicationHistory}
            // rowKey={record => record.PatientMedicationHistoryId}
            pagination={{
              current: 1,
              pageSize: 20,
              total: totalPages
            }}
            // rowSelection={{
            //   selectedRowKeys: selectedMedicationsKeys,
            //   onChange: newselectedMedicationsKeys => {
            //     if (newselectedMedicationsKeys?.length <= 5) {
            //       setSelectedMedicationsKeys(newselectedMedicationsKeys);
            //     } else {
            //       message.error('Max allowed 5');
            //     }
            //   },
            //   hideSelectAll: true
            // }}
            size='small'
            // onChange={pagination => {
            //   const { current } = pagination;
            //   getMedicationsList(current);
            // }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              marginTop: '30px'
            }}
          >
            <PrimaryButton
              onClick={() => setRefillBookingProgressStep('services')}
              className='primaryActive'
            >
              Previous
            </PrimaryButton>
            <SecondaryButton
              disabled={disableSubmit}
              style={{
                opacity: disableSubmit ? '0.6' : '1',
                pointerEvent: disableSubmit ? 'none' : ''
              }}
              onClick={handleSubmitMedications}
              className='primaryActive'
            >
              Next
            </SecondaryButton>
          </div>
        </div>
      </Spin>
      {refillServiceMedModal ? (
        <Modal
          title={'Medications'}
          width={mobileResponsive ? '90%' : '50%'}
          footer={false}
          open={refillServiceMedModal}
          closable={false}
          centered
          // onCancel={() => setRefillServiceMedModal(false)}
          // className='ehr-action-modal'
        >
          <RefillServiceMedications
            activeSpeciality={{ _id: refillSpeciality }}
            setRefillBookingProgressStep={setRefillBookingProgressStep}
            setRefillServiceMedModal={setRefillServiceMedModal}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default RefillMedications;
const columns = [
  {
    key: '1',
    title: 'Drug name',
    render: (_, object) => object?.DisplayName || '-'
  },
  {
    key: '4',
    title: 'Strength',
    render: (_, object) => (object?.Strength ? object?.Strength : '-')
  },
  {
    key: '3',
    title: 'Days Supply',
    render: (_, object) => object?.DaysSupply || '-'
  },
  {
    key: '5',
    title: 'Last Fill Date',
    render: (_, object) =>
      object?.LastFillDate
        ? dayjs(object?.LastFillDate).format('YYYY-MM-DD')
        : '-'
  }
];
