import { Checkbox, Col, Form, message, Row, Spin } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import { putRequest } from "../../service/ApisFunctions";
import { setCompleteUser } from "../../Redux/Reducers/gernalSlice";

const UpdateConsentForm = () => {
  const dispatch = useDispatch();
  const [consentCheck, setConsentCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const consentForms = useSelector(
    (state) => state.generalReducer.consentForms
  );

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    const onSuccess = (res) => {
      setLoading(false);
      message.success(res?.message);
      dispatch(setCompleteUser(res?.data));
    };
    const onError = (err) => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setLoading(false);
    };

    const formdata = {
      consentHistory: consentForms,
      allowConsent: true,
    };
    await putRequest(
      formdata,
      "customer/update_customer_consent",
      true,
      onSuccess,
      onError
    );
  };

  return (
    <Spin spinning={loading}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
            Ohana Doc Terms of Use and Consent Update
          </h1>

          <span>
            We’ve made important updates to our Terms of Use and Consent to
            ensure your privacy and security while using Ohana Doc. These
            changes include enhanced protocols for the collection, use, and
            sharing of your personal and health information. By using our
            services, you consent to receiving electronic communications, the
            secure storage of your data, and its sharing with authorized
            healthcare providers to improve your care.
          </span>
          <div style={{ marginTop: "20px" }}>
            <span>
              We’ve also clarified your rights regarding data access, updates,
              and deletion. Please review the full Terms of Use and Privacy
              Policy on our website for detailed information. Continuing to use
              our platform indicates your acceptance of these terms and our
              commitment to safeguarding your health information.
            </span>
          </div>
          <Form onFinish={handleSubmit} style={{ marginTop: "20px" }}>
            <Row>
              <Col className="signUpConsent" span={24}>
                <Form.Item
                  name="consent"
                  rules={[
                    {
                      required: consentCheck ? false : true,
                      message: "Please agree to the terms and conditions",
                    },
                  ]}
                >
                  <Checkbox
                    checked={consentCheck}
                    onChange={(e) => setConsentCheck(e.target.checked)}
                  >
                    I have read and agree to
                    <a href="/terms-of-use" target="_blank">
                      <span className="title">Terms of Use</span>
                    </a>
                    ,
                    <a href="/responsibility-and-billing" target="_blank">
                      <span className="title">
                        Financial Responsibility and Billing
                      </span>
                    </a>{" "}
                    and the
                    <a href="/privacy-policy" target="_blank">
                      <span className="title">Privacy policy</span>
                    </a>
                    .
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col style={{ marginTop: "20px", textAlign: "center" }} span={24}>
                <SecondaryButton type="submit" width="50%">
                  Update
                </SecondaryButton>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default UpdateConsentForm;
