import {
  Col,
  DatePicker,
  Empty,
  Form,
  Row,
  Select,
  Spin,
  message,

} from "antd";
import React, { useState } from "react";
import debounce from "lodash.debounce";
import { getRequest, postRequest } from "../../../service/ApisFunctions";
import { Reaction, ReactionType, StatusType } from "../../../Utils/constant";
import SecondaryButton from "../../UI/SecondaryButton/SecondaryButton";
import { disabledFutureDate } from "../../../Utils/helper";
import { useMediaQuery } from "react-responsive";

const SearchAllergies = ({ form, handleOk, getAllAllergies,setAddAllergieType }) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [allAllergies, setAllAllergies] = useState({});

  const [fetching, setFetching] = useState(false);

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });


  const debounceFetcher = debounce(async (value) => {
    let searchValue = value?.replace(/\s/g, "");
    if (searchValue?.length >= 3) {
      setFetching(true);

      const onSuccess = (res) => {
        setAllAllergies(res?.data);
        setFetching(false);
      };
      const onError = (err) => {
        message.error(err?.message);
        setFetching(false);
      };

      await getRequest(
        "",
        `allergy/seacrh-allergy?name=${searchValue}`,
        true,
        onSuccess,
        onError
      );
    }

    // await getAllPharmacy(value);
  }, 800);

  const handlesubmit = async (value) => {
    setModalLoading(true);
    const onSuccess = (res) => {
      handleOk();
      setModalLoading(false);
      message.success(res?.message);
      getAllAllergies();
      setAllAllergies(null);
    };
    const onError = (err) => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setModalLoading(false);
    };
    const formData = {
      AllergenId: value?.AllergenId?.value,
      ...(value?.Reaction && { Reaction: value?.Reaction }),
      ReactionType: value?.ReactionType,
      StatusType: value?.StatusType,
      ...(value?.OnsetDate && { OnsetDate: value?.OnsetDate }),
    };

    const route = `allergy/addcoded-allergy-by-customer`;
    await postRequest(formData, route, true, onSuccess, onError);
  };

  return (
    <Spin spinning={modalLoading}>
      <Form form={form} onFinish={handlesubmit} layout="vertical">
        <Row gutter={[10, 0]}>
        <Col span={24}>
      <Form.Item
        name="AllergenId"
        label="Search allergy"
        rules={[{ required: true, message: "Please select allergy" }]}
      >
        <Select
          labelInValue
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          className="ant-select-selector"
          placeholder="Search allergy"
          onSearch={debounceFetcher}
          style={{ width: "100%" }}
          notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <button
              className="noBgBtn"
                type="link"
                onClick={() => 
                  setAddAllergieType("Freetext")
                }
              >
                + Add Free Text Allergy
              </button>
            </div>
          )}
        >
          {allAllergies?.Items?.map((item) => (
            <Select.Option key={item.AllergenId} value={item.AllergenId}>
              {`${item.Name} - ${item.AllergenType} - ${item.BrandName}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
    <Col span={mobileResponsive ? 24 :12}>
            <Form.Item
              name="StatusType"
              label="Status Type"
              rules={[{ required: true, message: "Please select status type" }]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Please Select Status Type"
              >
                {StatusType?.map((item, index) => (
                  <Select.Option key={index} value={item?.value}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 :12}>
            <Form.Item name="Reaction" label="Severity of Reaction">
              <Select
                className="ant-select-selector"
                placeholder="mild, moderate, and severe"
              >
                {Reaction?.map((item, index) => (
                  <Select.Option key={index} value={item?.label}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 :12}>
            <Form.Item
              name="ReactionType"
              label="Reaction Type"
              rules={[
                { required: true, message: "Please select reaction type" },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Please Select Reaction Type"
              >
                {ReactionType?.map((item, index) => (
                  <Select.Option key={index} value={item?.label}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 :12}>
            <Form.Item name="OnsetDate" label="On Set Date">
              <DatePicker
                disabledDate={disabledFutureDate}
                className="ant-input-affix-wrapper"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                onClick={() => handleOk()}
                type="button"
                className="noBgBtn"
                style={{ padding: "0px 30px" }}
              >
                Cancel
              </button>
              <SecondaryButton
                style={{ padding: "0px 30px" }}
                type="submit"
                className="primaryButton"
              >
                Add Allergy
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default SearchAllergies;
