import "./otherPatientCard.scss";
import { DetailIcons, DomyP, EditIcons, TrashIcon } from "../../assets";

const OtherPatientCard = ({ key, full_name,editpatient,deletePatient,detailPatient }) => {
  return (
    <div key={key} className="otherPatientsCard">
      <div className="ViewotherPatientscardBody">
        <div>
          <div className="content">
            <div className="image">
              <img width="100%" height="100%" src={DomyP} alt="$$$" />
            </div>
            <div>
              <h3>{full_name || "--"}</h3>
              <p className="description">View your consultations</p>
            </div>
          </div>
        </div>
        <div className="content-right-side">
          <div onClick={deletePatient} style={{ background: "#FEE9D9" }} className="actionBtn">
            <img src={TrashIcon} alt="$$$" />
          </div>
          <div onClick={editpatient} style={{ background: "#D9F0FA" }} className="actionBtn">
            <img src={EditIcons} alt="$$$" />
          </div>
          <div onClick={detailPatient} style={{ background: "#DFDBD9" }} className="actionBtn">
            <img src={DetailIcons} alt="$$$" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherPatientCard;
