import { DatePicker, Spin, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getRequest } from '../../../../service/ApisFunctions';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

export const RxPrescriptions = ({ mobileResponsive, booking }) => {
  const completeUser = useSelector(state => state.generalReducer.completeUser);
  const [loading, setLoading] = useState(false);
  const [prescriptions, setPrescriptions] = useState([]);
  const [filteredPrescriptions, setFilteredPrescriptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [dateRange, setDateRange] = useState([]);
  const [pageResult, setPageResult] = useState(null);
  console.log(pageResult);
  const getPrescriptions = async () => {
    setLoading(true);
    const onSuccess = res => {
      setLoading(false);
      const matchableId = booking?._id;
      const prescriptions = res?.data?.Items?.filter(
        elem => elem?.Encounter === matchableId
      );
      console.log(res);
      setPageResult(res?.data?.PageResult);
      setPrescriptions(prescriptions);
      setFilteredPrescriptions(prescriptions);
    };
    const onError = err => {
      message.error(err?.message);
      setLoading(false);
    };
    if (booking?.doseSpotId) {
      await getRequest(
        '',
        `prescription/get-all-prescriptions?doseSpotId=${booking?.doseSpotId}${
          dateRange?.length
            ? `&startDate=${dayjs(dateRange[0]).format('YYYY-MM-DD')}`
            : `&startDate=${dayjs(booking?.createdAt).format('YYYY-MM-DD')}`
        }${
          dateRange?.length
            ? `&endDate=${dayjs(dateRange[1]).format('YYYY-MM-DD')}`
            : ''
        }&pageNumber=${currentPage || 1}`,

        true,
        onSuccess,
        onError
      );
    }
  };
  const columns = [
    {
      key: '1',
      title: 'Drug name',
      render: (_, object) => object?.DisplayName
    },
    {
      key: '1',
      title: 'Quantity',
      render: (_, object) => object?.Quantity
    },
    {
      key: '1',
      title: 'Refills',
      render: (_, object) => object?.Refills
    },

    {
      key: '3',
      title: 'Status',
      render: (_, object) => object?.MedicationStatus
    }
  ];
  useEffect(() => {
    getPrescriptions();
  }, [booking, dateRange, currentPage]);

  return (
    <Spin spinning={loading}>
      <div className='prescriptionFilters'>
        <RangePicker
          disabledDate={current =>
            current && current <= dayjs(booking?.createdAt).startOf('day')
          }
          onChange={value => setDateRange(value)}
          allowClear={true}
          allowEmpty={true}
          style={{ height: '25px' }}
          className='prescriptionsDateRange'
        />
      </div>
      <Table
        scroll={mobileResponsive ? { x: 1200, y: 260 } : { x: 0, y: 210 }}
        className='antd-custom-table'
        pagination={{
          showSizeChanger: false,
          pageSize: pageResult?.PageSize,
          total: pageResult?.TotalCount,
          onChange(page) {
            setCurrentPage(page);
          }
        }}
        columns={columns}
        dataSource={filteredPrescriptions}
        size='small'
      />
    </Spin>
  );
};
