import TestingPlans from "../../Components/VirtualConsultation/LabTesting/TestingPlans/TestingPlans";
import classes from "../WeightLoss/WeightLoss.module.scss";
import { lab } from "../../assets";
import Layouts from "../../Components/Layouts/Layout/Layouts";
const LabTest = () => {
  const Tests = [
    {
      title: "Complete Blood Count (CBC)",
      description:
        "With OhanaDoc, you can receive an online diagnosis and prescription 7 days a week from the convenience of your home.",
      img: lab,
      title1: "Overview",
      title2: "Why It's Done",
      description1:
        "A complete blood count (CBC) is a blood test. It's used to look at overall health and find a wide range of conditions, including anemia, infection and leukemia.",
      description2: "A complete blood count test measures the following:",
      description3:
        "A complete blood count can show unusual increases or decreases in cell counts. Those changes might point to a medical condition that calls for more testing.",
    },
  ];
  const lists = [
    {
      list: "Red blood cells, which carry oxygen",
    },
    {
      list: "White blood cells, which fight infection",
    },
    {
      list: "Hemoglobin, the oxygen-carrying protein in red blood cells",
    },
    {
      list: "Hematocrit, the amount of red blood cells in the blood",
    },
    {
      list: "Platelets, which help blood to clot",
    },
  ];
  const labDescription = [
    {
      heading: "To look at overall health:",
      description:
        "A complete blood count can be part of a medical exam to check general health and to look for conditions, such as anemia or leukemia.",
    },
    {
      heading: "To diagnose a medical condition:",
      description:
        "A complete blood count can help find the cause of symptoms such as weakness, fatigue and fever. It also can help find the cause of swelling and pain, bruising, or bleeding.",
    },
    {
      heading: "To check on a medical condition:",
      description:
        "A complete blood count can help keep an eye on conditions that affect blood cell counts.",
    },
    {
      heading: "To check on medical treatment:",
      description:
        "A complete blood count may be used to keep an eye on treatment with medicines that affect blood cell counts and radiation.",
    },
  ];
  return (
    <Layouts>
      <div className={classes.width}>
        <TestingPlans
          background={true}
          data={Tests}
          test={labDescription}
          lists={lists}
          bgColor={false}
        />
      </div>
    </Layouts>
  );
};

export default LabTest;
