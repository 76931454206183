import React, { useEffect, useState } from 'react';
import Layouts from '../../Layouts/Layout/Layouts';
import { Button, Col, Row, Spin, message } from 'antd';
import classes from '../../../Pages/Consultation/Consultations.module.scss';
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton';
import { useMediaQuery } from 'react-responsive';
import { setRefillSpeciality } from '../../../Redux/Reducers/refillsSlice';
import { getRequest } from '../../../service/ApisFunctions';
import { useDispatch, useSelector } from 'react-redux';

const RefillDetailPage = () => {
  const [loading, setloading] = useState(false);
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 1000px)'
  });
  const dispatch = useDispatch();

  const refillSpeciality = useSelector(state => state.refills.refillSpeciality);
  const [isLoading, setisLoading] = useState(false);
  const [specialityLoading, setSpecialityLoading] = useState(false);
  const [conditionsFlows, setConditionsFlows] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [activeSpeciality, setActiveSpeciality] = useState(null);
  const [activeConditionFlow, setActiveConditionFlow] = useState(null);
  const [refillServiceMedModal, setRefillServiceMedModal] = useState(false);
  const getAllFlows = async () => {
    setisLoading(true);
    const onSuccess = res => {
      const filteredData = res?.data?.filter(
        item => item?.flow === 'Medical consultation'
      );
      setConditionsFlows(filteredData);
      setActiveConditionFlow(filteredData?.length ? filteredData[0] : null);
      setisLoading(false);
    };
    const onError = err => {
      message.error(err?.message);
      setisLoading(false);
    };

    await getRequest(
      '',
      'booking_type/active_booking_type_listing',
      true,
      onSuccess,
      onError
    );
  };
  const getSpecialization = async () => {
    setSpecialityLoading(true);
    const onSuccess = res => {
      setSpecialities(res?.data);
      dispatch(setRefillSpeciality(res?.data[0]?._id));
      setActiveSpeciality(res?.data[0]);
      setSpecialityLoading(false);
    };
    const onError = err => {
      if (err?.message) {
        message.error(err?.message);
        setSpecialityLoading(false);
      } else {
        message.error('Something Went Wrong');
      }
    };
    if (activeConditionFlow?._id) {
      const route = `speciality/active_speciality_by_booking_type/${activeConditionFlow?._id}`;
      await getRequest('', route, true, onSuccess, onError);
    }
  };
  useEffect(() => {
    getAllFlows();
  }, []);
  useEffect(() => {
    getSpecialization();
  }, [activeConditionFlow]);
  return (
    <Layouts>
      <Spin spinning={loading}>
        <div className={classes.consult}>
          <div className={classes.head}>
            <div>
              <h3>{'Refill Request'}</h3>
              <SecondaryButton
                className={classes.button}
                children='Get Started'
              />
            </div>
          </div>

          <div className={classes.body}>
            <Row style={{ width: '100%' }} gutter={[15, 15]}>
              <Col span={mobileResponsive ? 24 : 12}>
                <div className={classes.bodyCont}>
                  <h5>Specialities</h5>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                      marginTop: '10px'
                    }}
                  >
                    {specialities?.map(elem => (
                      <Button>{elem?.speciality_name}</Button>
                    ))}
                  </div>
                </div>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <div className={classes.bodyCont}>
                  <h5>Conditions</h5>
                  {/* <p>{specialityDetail?.overview || '--'}</p> */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </Layouts>
  );
};

export default RefillDetailPage;
