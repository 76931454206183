import React, { useState, useEffect} from "react";
import "./consultationVideoCall.scss";
import { useSelector } from "react-redux";

const ConsultationParticipant = ({
  participant,
  isAudio = true,
  isVideo = true,
  localUser,
  videoRef,
  audioRef,
  booking
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [videoDisable, setVideoDisable] = useState(false);

  const isAudioGlobal = useSelector(
    (state) => state?.generalReducer?.isAudioGlobal
  );

  console.log(videoRef,"user side side")

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

      const trackSubscribed = (track) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => [...videoTracks, track]);
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => [...audioTracks, track]);
        }
      };
  
      const trackUnsubscribed = (track) => {
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
        } else if (track.kind === "audio") {
          setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
          console.log("Audio Track Unpublished");
        }
      };
  useEffect(() => {

    setVideoTracks(trackpubsToTracks(participant?.videoTracks));
    setAudioTracks(trackpubsToTracks(participant?.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant,videoRef]);

  useEffect(() => {
    const videoTrack = videoTracks[0];

    if (videoTrack) {
      videoTrack.on("disabled", () => {
        setVideoDisable(true);
      });
      videoTrack.on("enabled", () => {
        setVideoDisable(false);
        videoTrack?.attach(videoRef.current);
      });
      const isEnabled = videoTrack?.isEnabled;
      if (isVideo) {
        if (!isEnabled) {
          try {
            videoTrack?.enable();
          } catch (error) {
            console.log(error);
          }
        }
        videoTrack.attach(videoRef?.current);
      } else {
        videoTrack.disable();
      }
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks, isVideo,videoRef?.current]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      // Handling the "enabled" and "disabled" events
      audioTrack.on("disabled", () => {
        audioTrack.detach();
      });
      audioTrack.on("enabled", () => {
        audioTrack.attach(audioRef.current);
      });
  
      // Check if audio should be enabled or disabled
      if (isAudio && isAudioGlobal) {
        // Enable and attach the audio track if both flags are true
        if (!audioTrack.enabled) {
          audioTrack.enabled = true; // Enable the audio track
        }
        audioTrack.attach(audioRef?.current);
      } else {
        // Disable and detach the audio track if either flag is false
        if (audioTrack.enabled) {
          audioTrack.enabled = false; // Disable the audio track
        }
        audioTrack.detach();
      }
  
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, isAudio, isAudioGlobal, audioRef]);
  
  
  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      try {
        videoTrack.disable();
        videoTrack.detach();
      } catch (error) {
        console.log(error);
      }
    }
    return () => {};
  }, []);
  return (
    <div className="fullScreenParticipant">
      <div className="video-screen">
        {localUser ? (
          <>
            {isVideo ? (
              <video
                style={{ borderRadius: "7px" }}
                ref={videoRef}
                autoPlay={true}
              />
            ) : (
              <div className="camOff"> You </div>
            )}
            <audio ref={audioRef} autoPlay={true} muted={!isAudio} />
          </>
        ) : (
          <>
            {videoDisable ? (
              <div
                style={{
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  borderRadius: '0px 0px 10px 10px',
                  fontSize: '13px'
                }}
                className="videoOff"
              >
                {`${booking?.provider_id?.first_name || "-"} ${
                booking?.provider_id?.last_name || "-"
              } has turned off their Cam`}
              </div>
            ) : (
              <video
                style={{ display: videoDisable ? "none" : "block" }}
                ref={videoRef}
                autoPlay={true}
                playsInline
              />
            )}
            <audio ref={audioRef} autoPlay={true} muted={false} />
          </>
        )}
      </div>
    </div>
  );
};
export default ConsultationParticipant;
