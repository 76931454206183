import { useEffect, useState } from "react";
import classes from "./Notifications.module.scss";
import Notification from "../../Components/Notifications/Notification";
import { getRequest, putRequest } from "../../service/ApisFunctions";
import { Empty, Spin, message } from "antd";
import { useDispatch } from "react-redux";
import { setnotificationCount } from "../../Redux/Reducers/gernalSlice";
import Header from "../../Components/Layouts/Header/Header";

const Notifications = () => {
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState([]);
  const getNotifications = async () => {
    setLoading(true);
    const onSuccess = (res) => {
      const updatedNotificatins = categorizeNotifications(res.data);
      setNotifications(updatedNotificatins);
      markReadNotifications();
    };
    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };
    await getRequest(
      "",
      "notification/get-user-notifications",
      true,
      onSuccess,
      onError
    );
  };
  const markReadNotifications = async () => {
    const onSuccess = () => {
      setLoading(false);
      dispatch(setnotificationCount(0));
    };
    const onError = () => {
      setLoading(false);
    };
    await putRequest(
      "",
      "notification/read-user-notifications",
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const [showMega, setShowMega] = useState(false);

  function categorizeNotifications(notifications) {
    const categorized = [];
    notifications.forEach((notification) => {
      const createdAt = new Date(notification.createdAt);
      const dateKey = createdAt.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const existingCategoryIndex = categorized.findIndex(
        (item) => item.date === dateKey
      );
      if (existingCategoryIndex !== -1) {
        categorized[existingCategoryIndex].notifications.push(notification);
      } else {
        categorized.push({
          date: dateKey,
          notifications: [notification],
        });
      }
    });
    categorized.sort((a, b) => new Date(b.date) - new Date(a.date));
    return categorized;
  }
  return (
    <Spin spinning={loading}>
      <Header showMega={showMega} setShowMega={setShowMega} />
      <div className={classes.container} onClick={() => setShowMega(false)}>
        <h1 style={{marginTop:"20px"}} >Notifications</h1>
        <div className={classes.scrol}>
          {notifications?.length > 0 ? (
            notifications.map((el, index) => (
              <div key={index} className={classes.border}>
                <h5>{el.date}</h5>
                <Notification arrayData={el.notifications} />
              </div>
            ))
          ) : (
            <div
              style={{
                minHeight: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Empty />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default Notifications;
