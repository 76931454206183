import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { ScheduleConsultationDoctors } from './BookingSchedule/ScheduleConsultationDoctors';
import { ScheduleConsultationTimeSlots } from './BookingSchedule/ScheduleConsultationTimeSlots';
import { SingleDoctorView } from './BookingSchedule/SingleDoctorView';
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton';
import {
  setBookingFlowAccessType,
  setBookingFlowDoctor,
  setBookingFlowInformationSteps,
  setBookingFlowSchedule
} from '../../../Redux/Reducers/bookingSlice';
import { MessageChatIcon, UrgentCallIcon } from '../../../assets';
import './PatientBookingSchedule.scss';
import { useMediaQuery } from 'react-responsive';

export const PatientBookingSchedule = () => {
  const dispatch = useDispatch();
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [activeInfoTab, setActiveInfoTab] = useState('');
  const [value, setValue] = useState('schedule');
  const [isNext, setIsNext] = useState(false);

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const bookingData = useSelector(state => state.booking);

  const selectConsultationType = val => {
    if (bookingData?.bookingFlowIsUrgent) {
      dispatch(setBookingFlowDoctor(null));
      dispatch(setBookingFlowSchedule(null));
      dispatch(setBookingFlowInformationSteps(2));
      setValue('urgent');
    } else {
      setIsNext(true);
      setValue('schedule');
    }
  };

  const renderConsultationTypeSelection = () => (
    <div className='consultationTypeSelectWrap'>
      <h2 className='heading'>Consultation Type</h2>
      <Row gutter={[20, 20]} justify='center'>
        {bookingData?.bookingFlowIsUrgent ? (
          <Col span={mobileResponsive ? 24 : 8}>
            <div className='consultationTypeBox'>
              <img src={UrgentCallIcon} alt='$$$' />
              <h2>Messaging Visit</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur. Luctus orci est est
                porttitor morbi a dui. Sagittis tellus enim lectus auctor
                condimentum nunc nec pharetra cum.
              </p>
              <SecondaryButton
                onClick={() => {
                  dispatch(setBookingFlowAccessType('message'));
                  selectConsultationType();
                }}
              >
                Continue
              </SecondaryButton>
            </div>
          </Col>
        ) : (
          <>
            <Col span={mobileResponsive ? 24 : 8}>
              <div className='consultationTypeBox'>
                <img src={UrgentCallIcon} alt='$$$' />
                <h2>Video Visit</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Luctus orci est est
                  porttitor morbi a dui. Sagittis tellus enim lectus auctor
                  condimentum nunc nec pharetra cum.
                </p>
                <SecondaryButton
                  onClick={() => {
                    dispatch(setBookingFlowAccessType('video'));
                    selectConsultationType();
                  }}
                >
                  Continue
                </SecondaryButton>
              </div>
            </Col>
            <Col span={mobileResponsive ? 24 : 8}>
              <div className='consultationTypeBox'>
                <img src={MessageChatIcon} alt='$$$' />
                <h2>Phone call Visit</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Luctus orci est est
                  porttitor morbi a dui. Sagittis tellus enim lectus auctor
                  condimentum nunc nec pharetra cum.
                </p>
                <SecondaryButton
                  onClick={() => {
                    dispatch(setBookingFlowAccessType('audio'));
                    selectConsultationType();
                  }}
                >
                  Continue
                </SecondaryButton>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );

  const renderDoctorSelection = () => (
    <div className='customDoctorsView'>
      <ScheduleConsultationDoctors
        setSelectedDoctor={setSelectedDoctor}
        setActiveInfoTab={setActiveInfoTab}
        setIsNext={setIsNext}
        type='video'
      />
    </div>
  );

  const renderTimeSlots = () => (
    <ScheduleConsultationTimeSlots selectedDoctor={selectedDoctor} />
  );

  const renderSingleDoctorView = () => (
    <SingleDoctorView
      singleDoctor={selectedDoctor}
      setSingleDoctor={setSelectedDoctor}
      setActiveInfoTab={setActiveInfoTab}
    />
  );
  let content;
  if (bookingData?.bookingFlowType === 'Medical consultation') {
    if (!isNext) {
      content = renderConsultationTypeSelection();
    } else if (value === 'schedule') {
      if (activeInfoTab === 'detail') {
        content = renderSingleDoctorView();
      } else if (selectedDoctor?._id) {
        content = renderTimeSlots();
      } else {
        content = renderDoctorSelection();
      }
    }
  } else if (bookingData?.bookingFlowType === 'Treatment programs') {
    if (!selectedDoctor?._id) {
      content = (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              paddingBottom: '20px'
            }}
          >
            {/* <h2 className="heading">Available Providers</h2> */}
          </div>
          <ScheduleConsultationDoctors
            setSelectedDoctor={setSelectedDoctor}
            setActiveInfoTab={setActiveInfoTab}
            type='program'
          />
        </>
      );
    } else if (!activeInfoTab) {
      content = renderTimeSlots();
    } else if (activeInfoTab === 'detail') {
      content = renderSingleDoctorView();
    }
  }
  return <>{content}</>;
};
