import { Col, DatePicker, Form, Input, Row, Select, message } from 'antd';
import React, { useState } from 'react';
import {
  disabledFutureDate,
  validateHeight,
  validateWeight
} from '../../../../Utils/helper';
import { ReactSVG } from 'react-svg';

import {
  postRequest,
  postRequestFormData
} from '../../../../service/ApisFunctions';
import SecondaryButton from '../../../UI/SecondaryButton/SecondaryButton';
import { useMediaQuery } from 'react-responsive';
import {
  AllLanguages,
  EthnicityList,
  HeightUnit,
  RaceList,
  WeightUnit,
  birthGendersList,
  gendersList,
  pronounList,
  sexualOrientation
} from '../../../../Utils/constant';
import { calendarSimple, paperclip } from '../../../../assets';

export const InformationChildForm = ({ patientToken, isModal, setModal }) => {
  const [form] = Form.useForm();
  // Define states
  const [loading, setLoading] = useState(false);
  const [guardianFile, setGuardianFile] = useState(null);
  const [childHeightUnit, setChildHeightUnit] = useState('1');
  const [childWeightUnit, setChildWeightUnit] = useState('1');

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  // Custom Functions

  const handleInputChange = async e => {
    const file = e.target.files[0];
    UploadS3(file);
  };
  const UploadS3 = async file => {
    setLoading(true);
    const onSuccess = res => {
      setLoading(false);
      setGuardianFile(res?.data);
    };

    const onError = err => {
      setLoading(false);
    };

    const formData = {
      image: file
    };

    await postRequestFormData(
      formData,
      'auth/upload_image_s3',
      true,
      onSuccess,
      onError
    );
  };

  const handleSubmit = async values => {
    const onSuccess = res => {
      setLoading(false);
    };

    const onError = err => {
      message.error(err?.message);
      setLoading(false);
    };
    const dataToSend = {
      patient: {
        first_name: values?.firstName,
        last_name: values?.lastname,
        middle_name: values?.middle_name,
        dob: values?.date_of_birth_patient?.$d,
        height: {
          value: values?.heightPatient,
          unit: values?.heightUnitPatient
        },
        weight: {
          value: values?.weightPatient,
          unit: values?.weightUnitPatient
        },
        attachment: guardianFile,
        language: values?.languagePatient,
        relation: values?.relation,
        gender: values?.gender,
        birthGender: values?.birthGenderPatient,
        sexualOrientation: values?.sexualOrientationPatient,
        race: values?.racePatient,
        ethnicity: values?.ethnicityPatient,
        pronoun: values?.pronounPatient
      }
    };
    await postRequest(
      dataToSend,
      'patient/add-patient',
      true,
      onSuccess,
      onError
    );
    // if (!guardianFile) {
    //   message.warning('Please attach file.');
    //   return;
    // }
    // dispatch(
    //   setBookingFlowPatientData({
    //     first_name: values?.firstName,
    //     last_name: values?.lastname,
    //     middle_name: values?.middle_name,
    //     email: completeUser?.user_id?.email,
    //     address: completeUser?.location?.address,
    //     dob: values?.date_of_birth_patient?.$d,
    //     height: {
    //       value: values?.heightPatient,
    //       unit: values?.heightUnitPatient
    //     },
    //     weight: {
    //       value: values?.weightPatient,
    //       unit: values?.weightUnitPatient
    //     },
    //     file: guardianFile,
    //     language: values?.languagePatient,
    //     relation: values?.relation,
    //     pronoun: completeUser?.pronoun

    //     guardianFirstName: completeUser?.first_name,
    //     guardianLastName: completeUser?.last_name,
    //     guardian_middle_name: completeUser?.middle_name,
    //     guardianDob: completeUser?.dob ? dayjs(completeUser?.dob) : null,
    //     phone_number: completeUser?.phone_number,
    //     state: completeUser?.state,
    //   })
    // );
    // if (!loading && patientToken) {
    //   setInformationStep('demographic');
    // }
  };

  return (
    <div>
      <div className='information'>
        <>
          <h1>Patient Info</h1>
          <Form
            layout='vertical'
            form={form}
            className='fields'
            onFinish={handleSubmit}
          >
            <div style={{ display: 'flex', gap: '20px' }}>
              <Form.Item
                style={{ width: '33.33%' }}
                name='firstName'
                label='Patient First Name'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name'
                  }
                ]}
              >
                <Input
                  className='ant-input-affix-wrapper'
                  placeholder='Patient First name'
                />
              </Form.Item>
              <Form.Item
                style={{ width: '33.33%' }}
                name='middle_name'
                label='Patient Middle Name'
              >
                <Input
                  className='ant-input-affix-wrapper'
                  placeholder='Patient Middle name'
                />
              </Form.Item>

              <Form.Item
                style={{ width: '33.33%' }}
                name='lastname'
                label='Patient Last Name'
                rules={[
                  { required: true, message: 'Please enter your last name' }
                ]}
              >
                <Input
                  className='ant-input-affix-wrapper'
                  placeholder='Patient Last name'
                />
              </Form.Item>
            </div>
            <Form.Item
              name='date_of_birth_patient'
              label='Patient Date Of Birth'
              rules={[
                {
                  required: true,
                  message: 'Please select your date of birth'
                }
              ]}
            >
              <DatePicker
                disabledDate={disabledFutureDate}
                className='ant-input-affix-wrapper'
                placeholder='Date of birth'
                suffixIcon={<ReactSVG width='20px' src={calendarSimple} />}
                format='MM-DD-YYYY'
              />
            </Form.Item>
            <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
              <div
                style={
                  mobileResponsive
                    ? { width: '100%', display: 'flex', gap: '20px' }
                    : { width: '50%', display: 'flex', gap: '20px' }
                }
              >
                <Form.Item
                  style={{ width: '70%' }}
                  name='heightPatient'
                  label='Patient Height'
                  rules={[
                    { required: true },
                    { validator: validateHeight(childHeightUnit) }
                  ]}
                >
                  <Input
                    min={1}
                    type='number'
                    className='ant-input-affix-wrapper'
                    placeholder='Patient Height'
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '30%' }}
                  name='heightUnitPatient'
                  label='Unit'
                  rules={[{ required: true, message: 'Please select unit' }]}
                >
                  <Select
                    onChange={e => {
                      setChildHeightUnit(e);
                    }}
                    className='ant-select-selector'
                    placeholder='Unit'
                  >
                    {HeightUnit?.map((item, index) => (
                      <Select.Option key={index} value={item?.id}>
                        {item?.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div
                style={
                  mobileResponsive
                    ? { width: '100%', display: 'flex', gap: '20px' }
                    : { width: '50%', display: 'flex', gap: '20px' }
                }
              >
                <Form.Item
                  style={{ width: '70%' }}
                  name='weightPatient'
                  label='Patient Weight'
                  rules={[
                    { required: true },
                    { validator: validateWeight(childWeightUnit) }
                  ]}
                >
                  <Input
                    min={1}
                    type='number'
                    className='ant-input-affix-wrapper'
                    placeholder='Patient Weight'
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '30%' }}
                  name='weightUnitPatient'
                  label='Unit'
                  rules={[{ required: true, message: 'Please select unit' }]}
                >
                  <Select
                    onChange={e => {
                      setChildWeightUnit(e);
                    }}
                    className='ant-select-selector'
                    placeholder='Unit'
                    disabled
                  >
                    {WeightUnit?.map((item, index) => (
                      <Select.Option key={index} value={item?.id}>
                        {item?.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <Form.Item
              name='languagePatient'
              label='Patient Language'
              rules={[
                { required: true, message: 'Please select your language' }
              ]}
            >
              <Select
                showSearch
                mode='multiple'
                className='ant-select-selector'
                placeholder='language'
              >
                {AllLanguages?.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='relation'
              label='Relationship with Patient'
              rules={[
                {
                  required: true,
                  message: 'Please enter your relationship'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                className='ant-select-selector'
                placeholder='Relationship'
              >
                <Select.Option value='parent'>Parent</Select.Option>
                <Select.Option value='guardian'>Guardian</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label='Attachment'>
              <div className='formInputs'>
                <div className={`${'fileField'} ${!guardianFile && 'error'}`}>
                  <input
                    className='field'
                    id='guardianID'
                    name='guardianID'
                    type='file'
                    placeholder='Guardian’s ID'
                    // value={guardianFile}
                    onChange={handleInputChange}
                    style={{ boxShadow: 'none', display: 'none' }}
                  />
                  <label
                    className='fileLabel'
                    type='file'
                    htmlFor='guardianID'
                    // style={{
                    //   minHeight: '45px',
                    //   width: '100%',
                    //   alignItems: 'center',
                    //   display: 'flex',
                    //   justifyContent: 'space-between',
                    //   padding: '0px 10px'
                    // }}
                  >
                    <p
                      style={
                        guardianFile
                          ? { color: 'gray' }
                          : { color: 'lightgray' }
                      }
                    >
                      {guardianFile ? guardianFile : 'Attachment'}
                    </p>
                    <ReactSVG src={paperclip} />
                  </label>
                </div>
                {!guardianFile && (
                  <p className='errorMessage'>Please select file</p>
                )}
              </div>
            </Form.Item>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name='birthGenderPatient'
                  label='Patient Birth Gender'
                  rules={[
                    {
                      required: true,
                      message: 'Please select your birth gender'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Patient BirthGender'
                  >
                    {birthGendersList.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='gender'
                  label='Patient Current Gender'
                  rules={[
                    { required: true, message: 'Please select your gender' }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Patient gender'
                  >
                    {gendersList.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </Col>

              <Col span={12}>
                <Form.Item
                  name='sexualOrientationPatient'
                  label='Patient Sexual orientation'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter patient sexual orientation'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Please select patient sexual orientation'
                  >
                    {sexualOrientation.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </Col>

              <Col span={12}>
                <Form.Item
                  name='pronounPatient'
                  label='Patient Pronoun'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter patient pronoun'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Please select patient pronoun'
                  >
                    {pronounList.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </Col>

              <Col span={12}>
                <Form.Item
                  name='ethnicityPatient'
                  label='Patient Ethnicity'
                  rules={[
                    { required: true, message: 'Please enter your ethnicity' }
                  ]}
                >
                  <Select
                    mode='multiple'
                    className='ant-select-selector'
                    placeholder='Please select ethnicity'
                  >
                    {EthnicityList.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </Col>

              <Col span={12}>
                <Form.Item
                  name='racePatient'
                  label='Patient Race '
                  rules={[
                    { required: true, message: 'Please enter your race' }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Please select patient race'
                  >
                    {RaceList.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px'
              }}
            >
              <div style={{ width: '200px', marginBottom: '20px' }}>
                <SecondaryButton type='submit' width='100%'>
                  Add Patient
                </SecondaryButton>
              </div>
            </div>
          </Form>
        </>
      </div>
    </div>
  );
};
