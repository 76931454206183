import { Table, message } from 'antd';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { DeleteIcon, DetailIcons, EditIcons } from '../../../../assets';
import { putRequest } from '../../../../service/ApisFunctions';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
// General Css

const AllergiesTable = ({
  getAllergieById,
  data,
  getAllAllergies,
  setAllergiesLoading
}) => {
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });
  const completeUser = useSelector(state => state.generalReducer.completeUser);
  const columns = [
    {
      key: '1',
      title: 'Reactant',
      render: (_, object) => object?.DisplayName || '-',
      width: '16.66%'
    },
    {
      key: '3',
      title: 'Status',
      width: '16.66%',
      render: (_, object) =>
        object?.StatusType === 'Active' ? 'Current' : 'Past' || '-'
    },
    {
      key: '2',
      title: 'Severity of Reaction',
      render: (_, object) => object?.Reaction || '-',
      width: '16.66%'
    },
    {
      key: '4',
      title: 'Reaction Type',
      render: (_, object) => object?.ReactionType || '-',
      width: '16.66%'
    },
    {
      key: '5',
      title: 'On Set Date',
      render: (_, object) => moment(object?.OnsetDate).format('DD-MM-YYYY'),
      width: '16.66%'
    }
  ];
  const deleteAllergy = async data => {
    setAllergiesLoading(true);
    const onSuccess = res => {
      getAllAllergies();
      message.success(res?.message);
    };
    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }

      setAllergiesLoading(false);
    };
    const formData = {
      ReactionType: data?.ReactionType,
      StatusType: 'Deleted'
    };

    const route = `allergy/edit-patient-allergy/${completeUser?.user_id?._id || completeUser?.user_id}?patientAllergyId=${data?.PatientAllergyId}`;
    await putRequest(formData, route, true, onSuccess, onError);
  };
  // Check if the pathname includes "/consultation-detail"
  if (!window?.location?.pathname?.includes('/consultation-detail')) {
    columns.push({
      key: '6',
      title: 'Action',
      align: 'center',
      render: (_, object) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            justifyContent: 'center'
          }}
        >
          <img
            style={{ cursor: 'pointer' }}
            src={EditIcons}
            alt='$$$'
            onClick={() => getAllergieById(object)}
          />
          <img
            style={{ cursor: 'pointer' }}
            src={DeleteIcon}
            alt='$$$'
            onClick={() =>
              Swal.fire({
                title: 'Are you sure you want to delete?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
              }).then(result => {
                if (result.isConfirmed) {
                  deleteAllergy(object);
                }
              })
            }
          />
        </div>
      ),
      width: '16.66%'
    });
  }
  return (
    <div className='MainTable'>
      <Table
        className='antd-custom-table'
        pagination={{ pageSize: 3 }}
        columns={columns}
        scroll={mobileResponsive ? { x: 1300, y: 660 } : {}}
        dataSource={data}
        size='small'
      />
    </div>
  );
};

export default AllergiesTable;
