import { Col, Form, Input, Row, message } from 'antd';
import React, { useState } from 'react';
import { postRequest } from '../../../../service/ApisFunctions';
import { useMediaQuery } from 'react-responsive';
import { setBookingFlowCouponDetail } from '../../../../Redux/Reducers/bookingSlice';
import { useDispatch } from 'react-redux';

export const BookingPaymentCoupon = ({
  setLoading,
  setCouponDetails,
  bookingType,
  category,
  speciality,
  program
}) => {
  const dispatch = useDispatch();

  const [coupon, setCoupon] = useState('');
  const [form] = Form.useForm();
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });
  const formHandler = async () => {
    if (coupon.length >= 8) {
      setLoading(true);
      const onSuccess = res => {
        setLoading(false);
        if (res?.data?._id) {
          setCouponDetails(res?.data);
          dispatch(setBookingFlowCouponDetail(res?.data));
          setCoupon('');
          message.success('Applied successfully');
        } else {
          message.error(`Coupon is not for ${bookingType}`);
        }
      };
      const onError = res => {
        setLoading(false);
        message.error(res?.message);
      };
      const formData = {
        code: coupon.trim(),

        ...(bookingType === 'Medical consultation' && { category: category }),

        ...(bookingType === 'Medical consultation' && {
          speciality: speciality
        }),

        ...(bookingType === 'Treatment programs' && { program: program }),

        booking_type:
          (bookingType === 'Medical consultation' && 'video') ||
          (bookingType === 'Treatment programs' && 'program') ||
          ''
      };
      await postRequest(
        formData,
        'coupon/coupon_exists',
        true,
        onSuccess,
        onError
      );
    } else if (coupon.length && coupon.length < 8) {
      message.warning('Coupon minimum 8 character');
    } else {
      message.warning('Please enter coupon code');
    }
  };

  return (
    <Form form={form} layout='vertical'>
      <Row gutter={20}>
        <Col span={mobileResponsive ? 16 : 19}>
          <Form.Item
            label='Coupon Code'
            name='code'
            rules={[{ required: true, message: 'Please enter coupon code' }]}
          >
            <Input
              min={8}
              max={8}
              onChange={e => setCoupon(e.target.value)}
              placeholder='Enter coupon code'
              className='ant-input-affix-wrapper'
            />
          </Form.Item>
        </Col>
        <Col
          span={mobileResponsive ? 8 : 5}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <button
            onClick={() => formHandler()}
            type='button'
            style={{
              marginTop: '21px',
              height: '36px',
              width: '100%',
              padding: '0 !important'
            }}
            className={coupon?.length < 8 ? 'disableBtn' : 'secondaryBtn'}
          >
            Apply Coupon
          </button>
        </Col>
      </Row>
    </Form>
  );
};
