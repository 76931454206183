import { Col, Form, Row, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import BlueButton from "../../../../Components/UI/BlueButton/BlueButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../../../service/ApisFunctions";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Login, OtpVerify } from "../constant";
import { setToken } from "../../../../Redux/Reducers/authSlice";
import { setCompleteUser } from "../../../../Redux/Reducers/gernalSlice";
import OTPInput from "../../../../Components/UI/OtpInputs";
import { setBookingFlowLogin } from "../../../../Redux/Reducers/bookingSlice";

const OtpStep = () => {
  const user = useSelector((state) => state.authReducer.user);
  const bookingFlowLogin = useSelector(
    (state) => state.booking.bookingFlowLogin
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [fpHash, setFpHash] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (otp.length === 6) {
      setLoading(true);
      const onSuccess = (res) => {
        localStorage.setItem("patientToken", res?.token);
        message.success(res?.message);
        dispatch(setToken(res?.token));
        dispatch(setCompleteUser(res.data));
        setLoading(false);
        if (bookingFlowLogin === "booking") {
          navigate("/consultation-booking");
        } else if (bookingFlowLogin === "refill") {
          navigate("/refill-request");
        } else {
          navigate("/");
        }
        dispatch(setBookingFlowLogin(""));
      };
      const onError = (err) => {
        message.error(err?.message);
        setLoading(false);
      };

      const formdataa = {
        email: user?.email,
        verification_code: otp,
        deviceId: fpHash,
        deviceType: "website",
        role: "customer",
      };

      await postRequest(formdataa, OtpVerify, true, onSuccess, onError);
    } else {
      message.warning("Please enter a 6-digit OTP");
    }
  };

  // Resend Otp

  const resendOtp = async () => {
    setLoading(true);

    const onSuccess = (res) => {
      message.success(res?.message);
      setLoading(false);
    };
    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    const formdataOtp = {
      email: user?.email,
      password: user?.password,
      role: "customer",
    };

    await postRequest(formdataOtp, Login, true, onSuccess, onError);
  };

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFpHash(visitorId);
    };

    setFp();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="login-right-side">
        <div>
          <h2>OTP Verification</h2>
          <p>
            We have sent an OTP code to your
            <br />
            <span
              style={{ fontWeight: "700", color: "#f76d00", fontSize: "15px" }}
            >
              {" "}
              {user.email}{" "}
            </span>
            Please verify.
          </p>
        </div>
        <div className="formArea">
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  name="otp"
                  // label="One-Time Passcode"
                  rules={[
                    {
                      required: true,
                      message: "Please enter OTP",
                    },
                  ]}
                >
                  <OTPInput value={otp} onChange={setOtp} className="field" />
                </Form.Item>
              </Col>

              <Col style={{ marginTop: "20px" }} span={24}>
                <BlueButton width="100%">Verify</BlueButton>
              </Col>
            </Row>
          </Form>
        </div>
        <p className="alreadyAccount">
          Didn’t received OTP?
          <span onClick={() => resendOtp()}> Resend</span>
        </p>
      </div>
    </Spin>
  );
};

export default OtpStep;
