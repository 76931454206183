import moment from "moment";
import { PhoneTypes } from "../../../../Utils/constant";
import {
  FormatFullName,
  getHeightUnit,
  getWeightUnit,
} from "../../../../Utils/helper";

const generatePatientInformationArray = (bookingData) => {

  console.log(bookingData?.bookingFlowPatientData?.prefix,"bookingData?.bookingFlowPatientData?.prefix")

  const phoneType = PhoneTypes?.find(
    (item) =>
      item?.id?.toString() ===
      bookingData?.bookingFlowPatientData?.PrimaryPhoneType?.toString()
  );
  const patientInformation = [
    {
      question: "Full name",
      answer: FormatFullName(
        bookingData?.bookingFlowPatientData?.prefix,
        bookingData?.bookingFlowPatientData?.first_name,
        bookingData?.bookingFlowPatientData?.middle_name,
        bookingData?.bookingFlowPatientData?.last_name,
        bookingData?.bookingFlowPatientData?.suffix
      ),
    },
    {
      question: "Date of birth",
      answer:
        moment(bookingData?.bookingFlowPatientData?.dob?.$d).format(
          "MM/DD/YYYY"
        ) || "-",
    },
    {
      question: "Height",
      answer: `${bookingData?.bookingFlowPatientData?.height?.value || "-"} ${
        getHeightUnit(bookingData?.bookingFlowPatientData?.height?.unit) || "-"
      }`,
    },
    {
      question: "Weight",
      answer: `${bookingData?.bookingFlowPatientData?.weight?.value || "-"} ${
        getWeightUnit(bookingData?.bookingFlowPatientData?.weight?.unit) || "-"
      }`,
    },

    ...(bookingData?.bookingFlowAs === "patient"
      ? [
          {
            question: "Street",
            answer: bookingData?.bookingFlowPatientData?.address,
          },
        ]
      : []),
    ...(bookingData?.bookingFlowAs === "patient"
      ? [
          {
            question: "City",
            answer: bookingData?.bookingFlowPatientData?.city,
          },
        ]
      : []),
    ...(bookingData?.bookingFlowAs === "patient"
      ? [
          {
            question: "State",
            answer: bookingData?.bookingFlowPatientData?.state,
          },
        ]
      : []),
    ...(bookingData?.bookingFlowAs === "patient"
      ? [
          {
            question: "Zip Code",
            answer: bookingData?.bookingFlowPatientData?.zipCode,
          },
        ]
      : []),
    ...(bookingData?.bookingFlowAs === "patient"
      ? [
          {
            question: "Phone Type",
            answer: phoneType?.label,
          },
        ]
      : []),
    ...(bookingData?.bookingFlowAs === "patient"
      ? [
          {
            question: "Phone Number",
            answer: bookingData?.bookingFlowPatientData?.phone_number,
          },
        ]
      : []),
    {
      question: "Birth Gender",
      answer: bookingData?.bookingFlowPatientData?.birthGender,
    },
    {
      question: "Current Gender",
      answer: bookingData?.bookingFlowPatientData?.gender,
    },
    {
      question: "Pronoun",
      answer: bookingData?.bookingFlowPatientData?.pronoun,
    },

    {
      question: "Sexual orientation",
      answer: bookingData?.bookingFlowPatientData?.sexualOrientation,
    },

    {
      question: "Ethnicity",
      answer: bookingData?.bookingFlowPatientData?.ethnicity?.length
        ? bookingData?.bookingFlowPatientData?.ethnicity?.map((item) =>
            bookingData?.bookingFlowPatientData?.ethnicity?.[
              bookingData?.bookingFlowPatientData?.ethnicity?.length - 1
            ] === item
              ? item
              : item + " | "
          )
        : "-",
    },
    {
      question: "Race",
      answer: bookingData?.bookingFlowPatientData?.race,
    },
    {
      question: "Language",
      answer:
        bookingData?.bookingFlowPatientData?.language?.map(
          (item) => item + ", "
        ) || "-",
    },
  ].filter(Boolean);

  return patientInformation;
};
const generateGuardianInformationArray = (bookingData) => {
  const guardianInformation = [
    {
      question: "Guardian name",
      answer: `${
        bookingData?.bookingFlowPatientData?.guardianFirstName || "-"
      } `,
    },
    {
      question: "Guardian date of birth",
      answer:
        moment(bookingData?.bookingFlowPatientData?.guardianDob?.$d).format(
          "MM/DD/YYYY"
        ) || "-",
    },
    {
      question: "Guardian email address",
      answer: bookingData?.bookingFlowPatientData?.email || "-",
    },
    {
      question: "Guardian phone number",
      answer: bookingData?.bookingFlowPatientData?.phone_number || "-",
    },
    {
      question: "Guardian reachable address",
      answer: bookingData?.bookingFlowPatientData?.address || "-",
    },
  ];

  return guardianInformation;
};
const generateAllergiesArray = () => {
  const allergyInformation = [
    {
      question: "Allergy 1",
      answer: "Allergy 1",
    },
    {
      question: "Allergy 2",
      answer: "Allergy 2",
    },
    {
      question: "Allergy 3",
      answer: "Allergy 3",
    },
  ];

  return allergyInformation;
};
const generateSocialHistoryArray = () => {
  const socialHistoryInformation = [
    {
      question: "Do you smoke / use tobacco?",
      answer: "No",
    },
    {
      question: "Do you drink alcohol?",
      answer: "No",
    },
    {
      question: "Traveled overseas in the past 2 months?",
      answer: "Yes",
    },
  ];

  return socialHistoryInformation;
};
const generatePharmacyAndLabArray = (completeUser) => {
  const PharmacyAndLabInformation = [
    {
      question: "Pharmacy",
      answer: completeUser?.pharmacy?.StoreName || "-",
    },
    // {
    //   question: 'Lab',
    //   answer: 'Lab'
    // }
  ];

  return PharmacyAndLabInformation;
};

export {
  generateGuardianInformationArray,
  generatePatientInformationArray,
  generateAllergiesArray,
  generateSocialHistoryArray,
  generatePharmacyAndLabArray,
};
