import { Col, Form, Input, Row, Spin, message } from 'antd';
import React, { useState } from 'react';
import BlueButton from '../../../../Components/UI/BlueButton/BlueButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../../../service/ApisFunctions';
import { setUser } from '../../../../Redux/Reducers/authSlice';
import BackButton from '../../../../Components/UI/BackButton/BackButton';
import { EmailVerification } from '../constant';

const ForgotPasswordStep = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async e => {
    if (e?.email) {
      setLoading(true);
      const onSuccess = res => {
        message.success(res?.message);
        dispatch(setUser({ email: e?.email }));

        navigate('/forgot-otp');
        setLoading(false);
      };
      const onError = err => {
        message.error(err?.message);
        setLoading(false);
      };

      const formData = {
        email: e?.email,
        role: 'customer'
      };

      await putRequest(formData, EmailVerification, true, onSuccess, onError);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className='login-right-side'>
        <div>
          <h2>Forget Password</h2>
        </div>

        <div className='formArea'>
          <Form form={form} onFinish={handleSubmit} layout='vertical'>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  name='email'
                  label='Email Address'
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter valid email'
                    }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Enter your Email'
                  />
                </Form.Item>
              </Col>

              <Col style={{ marginTop: '20px' }} span={6}>
                <BackButton
                  onClick={() => navigate('/login')}
                  type='button'
                  width='100%'
                >
                  Back
                </BackButton>
              </Col>
              <Col style={{ marginTop: '20px' }} span={18}>
                <BlueButton type='submit' width='100%'>
                  Next
                </BlueButton>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default ForgotPasswordStep;
