import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../../service/ApisFunctions";
import { Col, Modal, Rate, Row, Spin, message } from "antd";
import "./consultationDetail.scss";
import ConsultationHeader from "../../Components/ConsultationDetail/consultationHeader";
import ConsultationEHR from "../../Components/ConsultationDetail/EHR";
import ConsultationVideoCall from "../../Components/ConsultationDetail/consultationVideoCall";
import ConsultationMessage from "../../Components/ConsultationDetail/consultationMessage";
import ConsultationFollow from "../../Components/ConsultationDetail/consultationFollow";
import ConsultationProviderDetails from "../../Components/ConsultationDetail/ProviderPersonalDetails/consultationProviderDetails";
import { useSelector } from "react-redux";
import Review from "../Review/Review";

const ConsultationDetail = () => {
  const [loading, setLoading] = useState(false);
  const [booking, setBookingDetails] = useState({});
  const [newVideoToken, setNewVideoToken] = useState("");
  const [unpaidFollowups, setUnpaidFollowUps] = useState([]);
  const [isRestricted, setisRestricted] = useState(null);
  const [isReviewModal, setIsReviewModal] = useState(false);
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const fullScreen = useSelector((state) => state?.generalReducer?.fullScreen);
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const notificationCount = useSelector(
    (state) => state?.generalReducer?.notificationCount
  );
  const bookingId = useParams();
  const isRestrictedHandler = (booking) => {
    if (booking?.urgent_care && !booking?.provider_id) {
      setisRestricted({
        restricted: true,
        reason: "Your consultation request is not accepted yet",
      });
      return;
    } else if (booking?.booking_status === "missed") {
      setisRestricted({
        restricted: true,
        reason: "You have missed the consultation",
      });
      return;
    } else if (booking?.booking_status === "completed") {
      setisRestricted({
        restricted: true,
        reason: "Your consultation has been completed",
      });
      return;
    } else if (booking?.booking_status === "leave") {
      setisRestricted({
        restricted: true,
        reason: "Provider is on leave",
      });
      return;
    }
  };
  const getConsultationById = async () => {
    setLoading(true);

    const onSuccess = (res) => {
      isRestrictedHandler(res?.data);
      setBookingDetails(res?.data);
      if (!res?.data?.isPatientView) {
        handleSubmit(res?.data);
      }

      setLoading(false);
    };
    const onError = (err) => {
      setLoading(false);
    };

    const route = `booking/booking_detail/${bookingId?.id}`;
    await getRequest("", route, true, onSuccess, onError);
  };

  const handleSubmit = async (data) => {
    const onSuccess = (res) => {};

    const onError = (res) => {
      message.error(res?.message);
      setLoading(false);
    };

    const formData = {
      isPatientView: true,
    };

    if (data?.booking_type === "video") {
      await putRequest(
        formData,
        `booking/update_video_booking/${data?._id}`,
        true,
        onSuccess,
        onError
      );
    } else if (data?.booking_type === "program") {
      await putRequest(
        formData,
        `booking/update_program_booking/${data?._id}`,
        true,
        onSuccess,
        onError
      );
    }
  };

  useEffect(() => {
    if (bookingId?.id || notificationCount) {
      getConsultationById();
    }
  }, [bookingId?.id, notificationCount]);
  const getToken = async () => {
    setLoading(true);

    const onSuccess = (res) => {
      setLoading(false);
      setNewVideoToken(res?.token);
    };

    const onError = (err) => {
      setLoading(false);
      message.error(err?.message);
    };

    const formData = {
      name: booking?.user_id,
      booking_id: booking?._id,
    };

    await postRequest(formData, "auth/video_token", true, onSuccess, onError);
  };
  useEffect(() => {
    if (booking?._id && booking?.payment_status) {
      getToken();
    }
  }, [booking?._id]);

  return (
    <Spin spinning={loading}>
      <div
        style={fullScreen ? { padding: "0px" } : {}}
        className="newConsultation"
      >
        <Row gutter={fullScreen ? 0 : 20}>
          {!fullScreen && (
            <ConsultationHeader
              booking={booking}
              getConsultationById={getConsultationById}
            />
          )}
          {/* Left Side */}
          <Col span={mobileResponsive || fullScreen ? 24 : 7}>
            <Row gutter={[0, 10]}>
              {booking?.booking_access_type === "audio" ||
              booking?.booking_access_type === "video" ? (
                <Col span={24}>
                  <ConsultationVideoCall
                    newVideoToken={newVideoToken}
                    booking={booking}
                    completeUser={completeUser}
                    isRestricted={isRestricted}
                  />
                </Col>
              ) : null}

              {!fullScreen && (
                <>
                  {/* Messages */}
                  {
                    // !isRestricted?.restricted &&
                    booking?.chat_id && (
                      <Col span={24}>
                        <ConsultationMessage
                          setLoading={setLoading}
                          booking={booking}
                        />
                      </Col>
                    )
                  }

                  {(booking?.consultation_status === "completed" ||
                    booking?.consultation_status === "review pending") && (
                    <Col span={24}>
                      <div className="DetailsMessage">
                        <div className="DetailsMessageLeft">
                          {!booking?.review ? (
                            <h2
                              onClick={() => {
                                setIsReviewModal(true);
                              }}
                            >
                              Pendiing
                            </h2>
                          ) : (
                            <h2>Review</h2>
                          )}
                        </div>

                        <Rate
                          style={{ color: "#1890ff" }}
                          allowHalf
                          disabled
                          value={booking?.rating || 0}
                        />
                      </div>
                    </Col>
                  )}

                  {/* Follow Up */}
                  {booking?.booking_type === "video" && (
                    <Col span={24}>
                      <ConsultationFollow
                        setLoading={setLoading}
                        booking={booking}
                        setUnpaidFollowUps={setUnpaidFollowUps}
                        unpaidFollowups={unpaidFollowups}
                      />
                    </Col>
                  )}

                  {/* Provider Personal Info */}
                  <Col span={24}>
                    <ConsultationProviderDetails booking={booking} />
                  </Col>
                </>
              )}
            </Row>
          </Col>

          {!fullScreen && (
            <Col span={mobileResponsive ? 24 : 17}>
              <div className="right-side">
                <ConsultationEHR
                  booking={booking}
                  getConsultationById={getConsultationById}
                  isRestricted={isRestricted}
                />
              </div>
            </Col>
          )}

          {isReviewModal ? (
            <Modal
              title={""}
              width={mobileResponsive ? "90%" : "60%"}
              footer={false}
              open={isReviewModal}
              onCancel={() => setIsReviewModal(false)}
              className="ehr-action-modal"
              centered
            >
              <Review
                bookingId={booking?._id}
                setIsReviewModal={setIsReviewModal}
                getAllBookings={getConsultationById}
                isTable={true}
              />
            </Modal>
          ) : null}
        </Row>
      </div>
    </Spin>
  );
};

export default ConsultationDetail;
