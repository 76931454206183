import { Route, Routes, Navigate } from 'react-router-dom';
import ContactUs from '../Pages/ContactUs/ContactUs';
import Aging from '../Pages/Aging/Aging';
import SexualHealth from '../Pages/SexualHealth/SexualHealth';
import WeightLoss from '../Pages/WeightLoss/WeightLoss';
import LabTest from '../Pages/LabTest/LabTest';
import Consultations from '../Pages/Consultation/Consultations';
import { ConsultationBooking } from '../Pages/Booking/ConsultationBooking';
import { LandingPage } from '../Pages/LandingPage/LandingPage';
import ComingSoon from '../Pages/CommingSoon';
import VirtualConsultation from '../Components/VirtualConsultation/VirtualConsultation';
import TreatmentConsultations from '../Pages/Consultation/treatmentConsultations';
import { JoinOhana } from '../Components/LandingPage2/JoinOhana';
import Refills from '../Pages/Refills';
import SignUpFrom from '../Pages/Auth/SignUp';
import LoginFrom from '../Pages/Auth/LogIn';
import SpecialityDetails from '../Components/Booking/DetailPages/SpecialityDetails';
import ConsentForm from '../Components/ConsentForm/ConsentForm';

const Auth = () => {
  return (
    <>
      <Routes>
        {/* <Route path={'*'} element={<Navigate to='/' replace />} /> */}

        {/* <Route path='/' element={<ComingSoon />} /> */}
        <Route path='/' element={<LandingPage />} />

        {/* Login */}
        <Route path='/login' element={<LoginFrom />} />
        <Route path='/forgot' element={<LoginFrom />} />
        <Route path='/reset' element={<LoginFrom />} />
        <Route path='/otp' element={<LoginFrom />} />
        <Route path='/forgot-otp' element={<LoginFrom />} />

        <Route path='/consultation-booking' element={<ConsultationBooking />} />
        <Route path='/signUp' element={<SignUpFrom />} />
        <Route
          path='/speciality-booking-details/:id'
          element={<SpecialityDetails />}
        />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/aging' element={<Aging />} />
        <Route path='/sexualHealth' element={<SexualHealth />} />
        <Route path='/weightLoss' element={<WeightLoss />} />
        <Route path='/labTest' element={<LabTest />} />
        <Route path='/booking-details' element={<Consultations />} />
        {/* <Route path='/virtualConsultation' element={<VirtualConsultation />} /> */}
        <Route path='/join-as-a-provider' element={<JoinOhana />} />
        <Route
          path='/treatment-consultations-details'
          element={<TreatmentConsultations />}
        />

        <Route path='/refill-request' element={<Refills />} />

        <Route path="/terms-of-use" element={<ConsentForm />} />
        <Route path="/responsibility-and-billing" element={<ConsentForm />} />
        <Route path="/privacy-policy" element={<ConsentForm />} />
        
      </Routes>
    </>
  );
};

export default Auth;
