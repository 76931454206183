import React from "react";
import "./ed&Ex.scss";
import { ReactSVG } from "react-svg";
import { paperclip } from "../../../assets";
import moment from "moment";

const EdAndEx = ({ title, edAndEx }) => {
  return (
    <div className="edAndEx">
      <h2>{title}</h2>


      {edAndEx?.map((item, index) => (
        <div key={index} className="edAndExOptions">
          <p>{`${item?.title? item?.title + " -" : ""} ${item?.institute || "-"} - ${moment(
            item?.year
          ).format("YYYY")}`}</p>
          <a href={item?.file} target="_blank" className="attachment" rel="noreferrer">
            <ReactSVG src={paperclip} />
            <p>Attachment</p>
          </a>
        </div>
      ))}
    </div>
  );
};

export default EdAndEx;
