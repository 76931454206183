import moment from "moment";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import SecondaryButton from "../../../UI/SecondaryButton/SecondaryButton";
import { Col, Empty, Row, message } from "antd";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import { getRequest } from "../../../../service/ApisFunctions";
import { setBookingFlowInformationSteps } from "../../../../Redux/Reducers/bookingSlice";
import { setBookingFlowSchedule } from "../../../../Redux/Reducers/bookingSlice";
import SkeletonInput from "../../../UI/Skeleton/skeletonInput";
import { SingleDoctorView } from "./SingleDoctorView";
import { useMediaQuery } from "react-responsive";

export const ScheduleConsultationTimeSlots = ({ selectedDoctor }) => {
  const dispatch = useDispatch();
  const [slots, setslots] = useState([]);
  const [selected, setSelected] = useState(null);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const getTimeSlots = async (id) => {
    if (date && selectedDoctor?._id) {
      setLoading(true);
      const onSuccess = (res) => {
        setLoading(false);
        setslots(res.data);
      };

      const onError = (err) => {
        setLoading(false);
      };
      const route = `provider/get_timeslots_by_provider_id/${
        selectedDoctor?._id
      }&${moment(date).format("YYYY-MM-DD")}`;

      await getRequest("", route, true, onSuccess, onError);
    }
  };
  useEffect(() => {
    getTimeSlots();
  }, [date]);
  return (
    <div className="container">
      <Row gutter={[20, 20]}>
        <Col span={mobileResponsive ? 24 : 14}>
          <SingleDoctorView />
        </Col>
        <Col
          style={{
            border: "1px solid lightGray",
            borderRadius: "7px",
            padding: "20px",
          }}
          span={mobileResponsive ? 24 : 10}
        >
          <div>
            <Calendar
              onChange={(e) => {
                setDate(e);
              }}
              minDate={new Date()}
              value={date}
              maxDate={null}
              className="calendar"
            />
          </div>
          <hr />
          <span className="bold">Available time slots</span>
          {loading ? (
            <Row style={{ marginTop: "10px" }} gutter={[10, 10]}>
              {[1, 2, 3, 4, 5, 6, 7, 8]?.map((item, index) => (
                <Col key={index} span={12}>
                  <SkeletonInput block={true} />
                </Col>
              ))}
            </Row>
          ) : (
            <Col span={24}>
              <div className="calendarButtons">
                <Row style={{ marginTop: "10px",width:"100%" }} gutter={[10, 10]}>
                  {slots?.length ? (
                    slots?.map((button, index) => {
                      return (
                        <Col key={index} span={12}>
                          <PrimaryButton
                            key={index}
                            className={
                              button?._id === selected?._id
                                ? `button activeBtn`
                                : "button"
                            }
                            style={{ width: "100%" }}
                            onClick={() => {
                              setSelected(button);
                              dispatch(setBookingFlowSchedule(button));
                            }}
                          >
                            {`${moment(button?.start).format(
                              "hh:mm A"
                            )} - ${moment(button?.end).format("hh:mm A")}`}
                          </PrimaryButton>
                        </Col>
                      );
                    })
                  ) : (
                    <Col span={24}>
                      {/* <p style={{ textAlign: "center" }}>Slots not available</p> */}
                      <Empty description={<p>Slots not available</p>} />
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <div style={{ width: "200px", marginTop: "10px" }}>
              <SecondaryButton
                type="submit"
                width="100%"
                onClick={() => {
                  selected?._id
                    ? dispatch(setBookingFlowInformationSteps(2))
                    : message.warning(
                        "Please select a time slot."
                      );
                }}
              >
                Next
              </SecondaryButton>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
