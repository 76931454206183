import { Badge, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactSVG } from "react-svg";
import { arrowRight, messageLines } from "../../assets";
import classes from "../DashboardTable/DashboardTable.module.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CapitalizeFirstLetter } from "../../Utils/helper";

const AllRefillsTable = ({ refills }) => {
  const navigate = useNavigate();
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const expandedRowRender = (record) => {
    console.log(record, "record");
    const columns = [
      {
        key: "1",
        title: "Provider",
        render: (_, object) =>
          object?.provider_id
            ? `${object?.provider_id?.first_name || "-"} ${
                object?.provider_id?.middle_name || ""
              } ${object?.provider_id?.last_name || "-"}`
            : "--",
        width: "20%",
      },
      {
        key: "2",
        title: "Refill For",
        render: (_, object) =>
          object?.category?.category_name ||
          object?.speciality?.speciality_name ||
          "-",
        width: "20%",
      },
      {
        key: "3",
        title: "Payment",
        render: (_, object) => "$" + object?.payment,
        width: "20%",
      },
      {
        title: "Status",
        key: "4",
        render: (_, object) => (
          <Badge
            status={
              object?.status === "pending"
                ? "warning"
                : object?.status === "accepted"
                ? "processing"
                : "success"
            }
            text={CapitalizeFirstLetter(object?.status)}
          />
        ),
        width: "20%",
      },
      {
        key: "5",
        title: "Action",
        align: "center",
        width: "20%",
        render: (_, object) => {
          return (
            <Space
              size="middle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {object?.chat_id && (
                <Tooltip placement="left" title="Message">
                  <div>
                    <ReactSVG
                      onClick={() => {
                        navigate(`/messages?messageId=${object?.chat_id}`);
                      }}
                      src={messageLines}
                      className={classes.messageBtn}
                    />
                  </div>
                </Tooltip>
              )}
              {object?.status !== "pending" ? (
                // record?.isPreQuestionAttempted ? (
                <Tooltip placement="left" title="Refill Detail">
                  <div>
                    <ReactSVG
                      onClick={() => {
                        navigate(`/single-refill/${record?._id}`, {
                          state: {
                            data: {
                              provider_id: object?.provider_id?.user_id,
                              speciality_id: object?.speciality?._id,
                            },
                          },
                        });
                      }}
                      src={arrowRight}
                      className={classes.bgGreen}
                    />
                  </div>
                </Tooltip>
              ) : (
                // ) : (
                //   <Tooltip placement="left" title="Fill Pre Questions">
                //     <div>
                //       <ReactSVG
                //         onClick={() => {
                //           navigate(
                //             `/refill-request?refill_id=${object?._id}&step=refillPreQuestions`
                //           );
                //         }}
                //         src={arrowRight}
                //         className={classes.bgBlue}
                //       />
                //     </div>
                //   </Tooltip>
                // )
                "Request Pending"
              )}
            </Space>
          );
        },
      },
    ];

    return (
      <Table columns={columns} dataSource={record.refills} pagination={false} />
    );
  };

  const columns = [
    {
      title: "Status",
      key: "status",
      render: (_, object) => (
        <Badge
          status={
            object?.status === "pending"
              ? "warning"
              : object?.status === "accepted"
              ? "processing"
              : "success"
          }
          text={CapitalizeFirstLetter(object?.status)}
        />
      ),
      width: "50%",
    },
    {
      key: "3",
      title: "Payment",
      render: (_, object) => "$" + object?.payment,
      width: "25%",
    },
    {
      key: "4",
      title: "Created At",
      render: (_, object) =>
        moment(object?.createdAt)?.format("YYYY-MM-DD hh:mm A"),
      width: "25%",
    },
  ];

  const handleExpand = (expanded, record) => {
    const keys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(keys);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={refills.map((item, index) => ({ ...item, key: index }))}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) =>
            record.refills && record.refills.length > 0,
          expandedRowKeys,
          onExpand: handleExpand,
        }}
        className={classes}
        scroll={mobileResponsive ? { x: 1300, y: 660 } : {}}
      />
    </div>
  );
};

export default AllRefillsTable;
