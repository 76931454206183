import React from 'react';
import './BlueButton.scss';

const BlueButton = ({
  onClick,
  className,
  children,
  type,
  width,
  disabled,
  btnRef,
  style
}) => {
  return (
    <button
      style={style ?style : width ? { width: width } : {}}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`blue-button ${className} `}
      ref={btnRef}
    >
      {children}
    </button>
  );
};

export default BlueButton;
