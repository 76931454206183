import {
  Alert,
  Card,
  Col,
  Empty,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin
} from 'antd';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../Utils/baseUrl';
import { DebounceSelect } from '../UI/DebounceSelect';
import './newRefillMedications.scss';
import { useMediaQuery } from 'react-responsive';
import SecondaryButton from '../UI/SecondaryButton/SecondaryButton';
import { getRequest } from '../../service/ApisFunctions';
import { CloseOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRefillMedications,
  setRefillMedicationsPrice
} from '../../Redux/Reducers/refillsSlice';
import {
  setIsConsentForm,
  setIsProfileFilled
} from '../../Redux/Reducers/authSlice';
import RefillPreQuestions from './RefillPreQuestions';

const RefillNewMedication = ({ setRefillBookingProgressStep }) => {
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const dispatch = useDispatch();

  const refillMedications = useSelector(
    state => state?.refills?.refillMedications
  );

  const completeUser = useSelector(state => state.generalReducer.completeUser);
  const [loading, setLoading] = useState(false);
  const [medicationWithSpeciality, setMedicationWithSpeciality] = useState(
    refillMedications?.length > 0 ? refillMedications : []
  );
  const [allSpecialities, setAllSpecialities] = useState(null);
  const [specialities, setSpecialities] = useState([]);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [selectedMedicationValue, setSelectedMedicationValue] = useState(null);
  const [isAddMedModalOpen, setIsAddMedModalOpen] = useState(false);
  const [medicationQuestionsModal, setMedicationQuestionsModal] =
    useState(false);

  const getRefillSpecialities = async id => {
    setLoading(true);
    const onSuccess = res => {
      const transformedServices = res?.data?.map(item => ({
        label: item?.speciality?.speciality_name,
        value: item?.speciality?._id
      }));
      setSpecialities(transformedServices);
      setAllSpecialities(res?.data);
      setLoading(false);
    };
    const onError = err => {
      message.error(err?.message);
      setLoading(false);
    };
    const route = `refillMedication/get-refill-specialities-price`;
    await getRequest('', route, true, onSuccess, onError);
  };

  async function fetchDrugList(drug) {
    const token = await localStorage.getItem('patientToken');
    if (!token) {
      message.error('No token found');
      return;
    }

    const headers = {
      'x-sh-auth': token,
      'Content-Type': 'application/json'
    };

    if (drug) {
      try {
        const response = await fetch(
          `${BASE_URL}medication/drug/search?name=${drug}`,
          {
            method: 'GET',
            headers
          }
        );

        if (!response.ok) {
          message.error('There is some issue please try again!');
          return;
        }

        const body = await response.json();
        const medSet = new Set(
          medicationWithSpeciality?.map(med => med?.medication?.value)
        );

        const userList = body?.data?.Items.map(drug => ({
          label: `${drug?.NameWithRouteDoseForm} ${
            drug?.Strength ? drug?.Strength : ''
          }`,
          value: drug?.DispensableDrugId,
          disabled: medSet?.has(drug?.DispensableDrugId)
        }));

        return userList;
      } catch (error) {
        message.error('There was an error fetching the drug list.');
        console.error(error);
        return;
      }
    }
  }
  const handleMedications = async value => {
    setSelectedMedicationValue(value);

    setLoading(true);
    const onSuccess = res => {
      console.log(res);
      setLoading(false);
      if (!res?.data?.Item?.Schedule) {
        setSelectedMedication({ medication: res?.data?.Item });
      } else {
        message.error('No controlled medication is allowed');
      }
    };
    const onError = err => {
      message.error(err?.message);
      setLoading(false);
    };

    await getRequest(
      '',
      `medication/single-drug/search?DispensableDrugId=${value}`,
      true,
      onSuccess,
      onError
    );
  };
  // const handleMedications = value => {

  //   setSelectedMedication({ medication: value });
  // };
  const handleSingleMedication = (value, medication) => {
    const findSpeciality = allSpecialities?.find(
      item => item?.speciality?._id === value
    );
    if (selectedMedication?.medication.value === medication.value) {
      setSelectedMedication({
        medication: medication,
        speciality: findSpeciality
      });
      setMedicationQuestionsModal(true);
    }
  };
  const handleMedicationsWithSpeciality = (value, medication) => {
    const findSpeciality = allSpecialities?.find(
      item => item?.speciality?._id === value
    );

    setMedicationWithSpeciality(prevMedicationWithSpeciality => {
      // Check if the medication already exists in the array
      const medicationExists = prevMedicationWithSpeciality.some(
        item => item.medication?.value === medication.value
      );

      if (medicationExists) {
        // Update the speciality of the existing medication
        return prevMedicationWithSpeciality.map(item =>
          item.medication?.value === medication.value
            ? { ...item, speciality: findSpeciality }
            : item
        );
      } else {
        // Add new medication with speciality
        return [
          ...prevMedicationWithSpeciality,
          { medication: medication, speciality: findSpeciality }
        ];
      }
    });
  };
  const deleteMedicationUsingValue = medicationValue => {
    console.log(medicationValue);
    const filterMedications = medicationWithSpeciality.filter(
      item => item.medication?.DispensableDrugId !== medicationValue
    );
    setMedicationWithSpeciality(filterMedications);
    dispatch(setRefillMedications(filterMedications));
  };
  const handleSubmit = () => {
    const price = Number(
      medicationWithSpeciality.reduce((total, item) => {
        const price = item?.speciality?.price;
        return parseInt(total) + parseInt(price);
      }, 0)
    ).toFixed(2);

    dispatch(setRefillMedications(medicationWithSpeciality));
    dispatch(setRefillMedicationsPrice(price));
    setRefillBookingProgressStep('refillPayment');
  };
  useEffect(() => {
    getRefillSpecialities();
  }, []);
  return (
    <Row className='newRefillMedications' gutter={[10, 10]}>
      <Col span={14}>
        {medicationWithSpeciality?.length > 0 ? (
          <div className='newRefillMedicationHeader'>
            <h2>Refill Medications</h2>
          </div>
        ) : (
          <>
            <h3>Search Medication</h3>
            <Input
              style={{ marginTop: '10px' }}
              className='ant-input-affix-wrapper'
              placeholder='Search Drug'
              onClick={() => {
                if (completeUser?._id && !completeUser?.isProfileFilled) {
                  dispatch(setIsProfileFilled({ personalInfo: true }));
                } else if (completeUser?._id && !completeUser?.allowConsent) {
                  dispatch(setIsConsentForm(true));
                } else {
                  setIsAddMedModalOpen(true);
                }
              }}
            />
          </>
        )}
      </Col>
      <Col span={14}>
        <div>
          {medicationWithSpeciality?.map((item, index) => {
            return (
              <Card
                key={index}
                style={{ marginTop: '10px', position: 'relative' }}
              >
                <div
                  style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                  <CloseOutlined
                    onClick={() =>
                      Swal.fire({
                        title: 'Are you sure you want to delete?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Delete'
                      }).then(result => {
                        if (result.isConfirmed) {
                          deleteMedicationUsingValue(
                            item?.medication?.DispensableDrugId
                          );
                        }
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div style={{ width: '50%' }}>
                    <h4>Medication Name</h4>
                    <span>{item?.medication?.DisplayName}</span>
                  </div>
                  <div style={{ width: '50%' }}>
                    <h4>Speciality</h4>
                    <Select
                      disabled
                      className='ant-select-selector'
                      placeholder='Please Select Speciality'
                      value={item?.speciality?.speciality?._id}
                      options={specialities}
                      onChange={e => {
                        handleMedicationsWithSpeciality(e, item?.medication);
                      }}
                    />
                  </div>
                </div>
              </Card>
            );
          })}
          {medicationWithSpeciality?.length > 0 &&
            medicationWithSpeciality?.length < 6 && (
              <h3
                style={{
                  color: '#f76d00',
                  marginTop: '20px',
                  cursor: 'pointer'
                }}
                onClick={() => setIsAddMedModalOpen(true)}
              >
                + Add medication
              </h3>
            )}
        </div>
      </Col>
      {medicationWithSpeciality?.length > 0 && (
        <Col span={10} style={{ marginTop: '10px' }}>
          <Card className='newRefillCheckout'>
            <div className='newRefillCheckoutHeader'>
              <h2>Medications</h2>
              <h3>Price</h3>
            </div>

            <>
              {medicationWithSpeciality?.map((item, index) => (
                <div key={index} className='newRefillCheckoutBody'>
                  <span>{item?.medication?.DisplayName}</span>
                  <p>${item?.speciality?.price}</p>
                </div>
              ))}
              <div>
                {/* <div className="newRefillCheckoutBody">
                  <span>Total</span>
                  <p>
                    $
                    {Number(
                      medicationWithSpeciality.reduce((total, item) => {
                        const price =
                          item?.speciality?.price > 0
                            ? item?.speciality?.price
                            : services?.[0]?.price;
                        return parseInt(total) + parseInt(price);
                      }, 0)
                    ).toFixed(2)}
                  </p>
                </div> */}

                <Alert
                  style={{ marginTop: '20px' }}
                  showIcon
                  className='customRefillInstructionsAlert'
                  type='warning'
                  message='Refill request instruction before check out :'
                  description={
                    <code>
                      <ol>
                        <li>
                          Ohana Doc does not refill medications if you are not
                          currently taking them.
                        </li>
                        <li>
                          Ohana Doc does not offer refills for medications
                          prescribed over 6 months ago.
                        </li>
                        <li>
                          Ohana Doc does not accept dosage change requests.
                        </li>
                      </ol>
                    </code>
                  }
                />

                <SecondaryButton
                  width={'100%'}
                  style={{ width: '100%', marginTop: '20px' }}
                  onClick={() => handleSubmit()}
                >
                  Checkout $
                  {Number(
                    medicationWithSpeciality.reduce((total, item) => {
                      const price = item?.speciality?.price;
                      return parseInt(total) + parseInt(price);
                    }, 0)
                  ).toFixed(2)}
                </SecondaryButton>
              </div>
            </>
          </Card>
        </Col>
      )}
      <Modal
        width={mobileResponsive ? '90%' : '70%'}
        footer={false}
        style={{ height: '50vh' }}
        open={isAddMedModalOpen}
        onCancel={() => {
          setIsAddMedModalOpen(false);
          setSelectedMedicationValue(null);
        }}
        maskClosable={false}
      >
        <Spin spinning={loading}>
          <h3 style={{ marginTop: '20px' }}>Add Medications</h3>
          <DebounceSelect
            allowClear
            showSearch
            placeholder='Search Drug'
            //   mode='multiple'
            selectedMedication={selectedMedicationValue}
            fetchOptions={fetchDrugList}
            onChange={newValue => {
              handleMedications(newValue?.value);
              // setSelectedMedication(newValue);
            }}
            className='customPrescriptionSearch'
          />

          <div>
            {
              selectedMedication && (
                // selectedMedications?.length > 0 ? (
                //   selectedMedications?.map((item, index) => {
                //     return (
                <Card
                  // key={index}
                  style={{ marginTop: '10px', position: 'relative' }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px'
                    }}
                  >
                    <CloseOutlined
                      onClick={() =>
                        Swal.fire({
                          title: 'Are you sure you want to delete?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Delete'
                        }).then(result => {
                          if (result.isConfirmed) {
                            setSelectedMedication(null);
                          }
                        })
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ width: '50%' }}>
                      <h4>Medication Name</h4>
                      <span>{selectedMedication?.medication?.DisplayName}</span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <h4>Speciality</h4>
                      <Select
                        className='ant-select-selector'
                        placeholder='Please Select Speciality'
                        options={specialities}
                        onChange={e => {
                          handleSingleMedication(
                            e,
                            selectedMedication.medication
                          );
                        }}
                      />
                    </div>
                  </div>
                </Card>
              )
              //     );
              //   })
              // ) : (
              //   <Empty />
              // )
            }
          </div>

          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <SecondaryButton
              onClick={() => {
                if (selectedMedication?.medication) {
                  if (selectedMedication?.speciality?._id) {
                    if (
                      selectedMedication?.medication?.preQuestions?.length > 0
                    ) {
                      setMedicationWithSpeciality(prev => [
                        ...prev,
                        selectedMedication
                      ]);
                      setSelectedMedication(null);
                      setIsAddMedModalOpen(false);
                      setSelectedMedicationValue(null);
                    } else {
                      message.warning('Please fill questions');
                      setMedicationQuestionsModal(true);
                    }
                  } else {
                    message.warning('Please select speciality');
                  }
                } else {
                  message.warning('Please select medcation');
                }
              }}
            >
              Add Medication
            </SecondaryButton>
          </div>
        </Spin>
      </Modal>

      <Modal
        title={`${selectedMedication?.speciality?.speciality?.speciality_name} Questionnaire`}
        width={mobileResponsive ? '70%' : '50%'}
        footer={false}
        open={medicationQuestionsModal}
        onCancel={() => setMedicationQuestionsModal(false)}
        maskClosable={false}
      >
        <RefillPreQuestions
          setMedicationQuestionsModal={setMedicationQuestionsModal}
          selectedMedication={selectedMedication}
          setSelectedMedication={setSelectedMedication}
        />
      </Modal>
    </Row>
  );
};

export default RefillNewMedication;
