import { Col, Form, Input, Row, Spin, message } from 'antd';
import React, { useState } from 'react';
import { strongPasswordValidator } from '../../../../Utils/helper';
import BlueButton from '../../../../Components/UI/BlueButton/BlueButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postRequest } from '../../../../service/ApisFunctions';
import { Login } from '../constant';
import { setSpeacilityId, setUser } from '../../../../Redux/Reducers/authSlice';
import { DEPLOYMENT_MODE } from '../../../../Utils/baseUrl';

const LoginStep = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async e => {
    if (e?.email && e?.password) {
      setLoading(true);
      const onSuccess = res => {
        message.success(res?.message);
        dispatch(
          setUser({
            email: e?.email,
            password: e?.password,
            role: 'customer'
          })
        );

        navigate('/otp');
        setLoading(false);
      };
      const onError = err => {
        message.error(err?.message);
        setLoading(false);
      };

      const formData = {
        email: e?.email,
        password: e?.password,
        role: 'customer'
      };

      await postRequest(formData, Login, true, onSuccess, onError);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className='login-right-side'>
        <div>
          <h2>Login</h2>
          <p>Welcome to OhanaDoc.</p>
        </div>

        <div className='formArea'>
          <Form form={form} onFinish={handleSubmit} layout='vertical'>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  name='email'
                  label='Secure Email Address'
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter valid email'
                    }
                  ]}
                >
                  <Input
                    placeholder='Enter Your Secure Email Address'
                    className='ant-input-affix-wrapper'
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name='password'
                  label='Profile Password'
                  rules={[
                    { required: true, message: 'Please enter a password' },
                    { validator: password && strongPasswordValidator }
                  ]}
                >
                  <Input.Password
                    onChange={e => setPassword(e?.target?.value)}
                    placeholder='Enter Your Password'
                  />
                </Form.Item>
              </Col>

              <Col span={24} className='forgot-password'>
                <p onClick={() => navigate('/forgot')}>Forgot password?</p>
              </Col>

              <Col style={{ marginTop: '20px' }} span={24}>
                <BlueButton width='100%'>Login</BlueButton>
              </Col>
            </Row>
          </Form>
        </div>
        {DEPLOYMENT_MODE === 'dev' ? (
          <p className='alreadyAccount'>
            Don’t have an account?
            <span onClick={() => navigate('/signUp')}> Sign Up</span>
          </p>
        ) : null}
      </div>
    </Spin>
  );
};

export default LoginStep;
