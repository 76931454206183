import { useEffect, useState } from "react";
import "./profile.scss";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spin, message } from "antd";
import { Updatepatient } from "./constant";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import { setCompleteUser } from "../../Redux/Reducers/gernalSlice";
import { putRequestFormData } from "../../service/ApisFunctions";
import SimpleButton from "../../Components/UI/SimpleButton/SimpleButton";
import ProfileMedicalHistory from "../../Components/ProfileDetails/History/profileMedicalHistory";
import SocialHistory from "../../Components/ProfileDetails/History/socialHistory";
import Encounters from "../../Components/ProfileDetails/History/encounters";
import Allergies from "../../Components/ProfileDetails/Allergies&Pharmacy&Lab/allergies";
import PharmacyOrLab from "../../Components/ProfileDetails/Allergies&Pharmacy&Lab/pharmacyOrLab";
import ChangePassword from "../../Components/ProfileDetails/changePassword";
import { DomyP, EditIcons, ProfileDummy } from "../../assets";
import Layouts from "../../Components/Layouts/Layout/Layouts";
import PersonalInfo from "../../Components/ProfileDetails/MyAccount/personalInfo";
import ProfileConsentHistory from "../../Components/ProfileDetails/History/consentHistory";
import { FormatFullName } from "../../Utils/helper";

const Profile = () => {
  const [active, setActive] = useState("Profile");
  const [loading, setLoading] = useState(false);

  const handleProfile = (tab) => {
    setActive(tab);
  };

  const dispatch = useDispatch();

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const [searchParams] = useSearchParams();

  const isProfileFilled = searchParams.get("isProfileFilled");

  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const [formState, setFormState] = useState({
    image: null,
    file: null,
  });

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0];
    UpdateProfileImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFormState({ image: reader.result, file: file });
    };
  };

  const UpdateProfileImage = async (file) => {
    setLoading(true);

    const onSuccess = (res) => {
      setLoading(false);
      message.success(res?.message);
      dispatch(setCompleteUser(res?.data));
    };

    const onError = (err) => {
      setLoading(false);
      message.error(err?.message);
    };

    const formdata = {
      profile_image: file,
    };

    await putRequestFormData(formdata, Updatepatient, onSuccess, onError);
  };

  useEffect(() => {
    const profileImage =
      completeUser?.profile_image !== null && completeUser?.profile_image !== ""
        ? completeUser?.profile_image
        : "";
    setFormState({ image: profileImage, file: null });
  }, [completeUser]);

  useEffect(() => {
    if (isProfileFilled === "filled") {
      message.warning("Please update your profile information");
    }
  }, [isProfileFilled]);

  return (
    <Layouts>
      <div className="profile">
        <Row>
          <Col span={mobileResponsive ? 0 : 6}></Col>
          <Col span={mobileResponsive ? 24 : 18}>
            <div className="actions">
              <SimpleButton
                onClick={() => handleProfile("Profile")}
                className={active === "Profile" ? "simpleHover" : "profileBtn"}
              >
                Profile Settings
              </SimpleButton>
              <SimpleButton
                onClick={() => handleProfile("History")}
                className={active === "History" ? "simpleHover" : "medicalBtn"}
              >
                History
              </SimpleButton>

              <SimpleButton
                onClick={() => handleProfile("Allergies & Pharmacy/Lab")}
                className={
                  active === "Allergies & Pharmacy/Lab"
                    ? "simpleHover"
                    : "medicalBtn"
                }
              >
                Allergies & Pharmacy/Lab
              </SimpleButton>
              <SimpleButton
                onClick={() => handleProfile("changePassword")}
                className={
                  active === "changePassword" ? "simpleHover" : "medicalBtn"
                }
              >
                Change Password
              </SimpleButton>
            </div>
          </Col>
        </Row>
        <hr />

        <Row gutter={[10, 10]} className="profileBody">
          <Col span={mobileResponsive ? 24 : 6}>
            <div className="profileLeft">
              <Spin spinning={loading}>
                {formState.image ? (
                  <>
                    <label className="imageUploadButton" htmlFor="image-upload">
                      <img src={formState?.image || DomyP} alt="uploaded" />
                      <span>
                        <ReactSVG src={EditIcons} />
                      </span>
                    </label>
                  </>
                ) : (
                  <>
                    <label className="imageUploadButton" htmlFor="image-upload">
                      <ReactSVG src={ProfileDummy} />
                      <span>
                        <ReactSVG src={EditIcons} />
                      </span>
                    </label>
                  </>
                )}
                <input
                  style={{ display: "none" }}
                  required
                  id="image-upload"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  onChange={handleImageUpload}
                />
              </Spin>
              <h1>
                {FormatFullName(
                  completeUser?.prefix,
                  completeUser?.first_name,
                  completeUser?.middle_name,
                  completeUser?.last_name,
                  completeUser?.suffix
                )}
              </h1>
              <p>{completeUser?.user_id?.email || completeUser?.email || ""}</p>
            </div>
          </Col>

          <Col span={mobileResponsive ? 24 : 18}>
            {active === "Profile" && (
              <>
                <div className="profileRight">
                  <PersonalInfo completeUser={completeUser} />
                </div>

                {/* <div style={{ marginTop: "10px" }} className="profileRight">
                  <HealthEquityInfo completeUser={completeUser} />
                </div> */}
              </>
            )}

            {active === "History" && (
              <>
                <div className="profileRight">
                  <ProfileMedicalHistory completeUser={completeUser} />
                </div>

                <div style={{ marginTop: "10px" }} className="profileRight">
                  <SocialHistory completeUser={completeUser} />
                </div>

                <div style={{ marginTop: "10px" }} className="profileRight">
                  <ProfileConsentHistory completeUser={completeUser} />
                </div>

                {/* <div style={{ marginTop: "10px" }} className="profileRight">
                  <Encounters completeUser={completeUser} />
                </div> */}
              </>
            )}

            {active === "Allergies & Pharmacy/Lab" && (
              <>
                <div className="profileRight">
                  <Allergies completeUser={completeUser} />
                </div>

                <div style={{ marginTop: "10px" }} className="profileRight">
                  <PharmacyOrLab completeUser={completeUser} />
                </div>
              </>
            )}

            {active === "changePassword" && (
              <div className="profileRight">
                <ChangePassword />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Layouts>
  );
};

export default Profile;
