import { Col, Form, Modal, Row, Space, Spin, message } from 'antd';
import './allergies.scss';
import { useEffect, useState } from 'react';
import AllergiesTable from '../../ConsultationDetail/EHR/ConsDetails/allergiesTable';
import { getRequest } from '../../../service/ApisFunctions';
import { useMediaQuery } from 'react-responsive';
import FreetextAllergies from './FreetextAllergies';
import SearchAllergies from './SearchAllergies';
import UpdateAllergies from './updateAllergies';
import dayjs from 'dayjs';

const Allergies = ({ completeUser }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addAllergieType, setAddAllergieType] = useState('');
  const [singleAllergieDetails, setSingleAllergieDetails] = useState(null);
  const [form] = Form.useForm();
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setAddAllergieType('');
    setSingleAllergieDetails(null);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAddAllergieType('');
    setSingleAllergieDetails(null);
    form.resetFields();
  };

  const [allergies, setAllergies] = useState([]);
  const [allergiesLoading, setAllergiesLoading] = useState(false);
  const getAllAllergies = async () => {
    setAllergiesLoading(true);

    const onSuccess = res => {
      setAllergies(res?.data?.Items?.reverse());

      setAllergiesLoading(false);
    };
    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setAllergiesLoading(false);
    };

    const route = `allergy/retrieve-allergy-details/${completeUser?.doseSpotId}?pageNumber=${2}`;
    await getRequest('', route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (completeUser?.doseSpotId) {
      getAllAllergies();
    }
  }, [completeUser]);

  const getAllergieById = object => {
    setSingleAllergieDetails(object);
    showModal();
  };

  const ModalTitle = singleAllergieDetails?.PatientAllergyId
    ? `Update allergy`
    : addAllergieType
    ? addAllergieType === 'Freetext'
      ? 'Add Freetext allergy'
      : 'Add allergy'
    : 'Update Health Equity Information';

  useEffect(() => {
    if (singleAllergieDetails?.PatientAllergyId) {
      form.setFieldsValue({
        allergyName: singleAllergieDetails?.DisplayName || null,
        Reaction: singleAllergieDetails?.Reaction || null,
        ReactionType: singleAllergieDetails?.ReactionType || null,
        StatusType: singleAllergieDetails?.StatusType || null,
        OnsetDate: singleAllergieDetails?.OnsetDate
          ? dayjs(singleAllergieDetails?.OnsetDate)
          : null
      });
    }
  }, [singleAllergieDetails, isModalVisible]);

  return (
    <Spin spinning={allergiesLoading}>
      <Row className='allergies'>
        <Col className='allergiesHeader' span={24}>
          <h2>Allergies</h2>
          <Space
            onClick={() => {
              showModal();
              setAddAllergieType('Search');
            }}
            className='customBlueIconButton'
          >
            Add Allergies
          </Space>
        </Col>
        <Col span={24}>
          <div className='allergiesBody'>
            <AllergiesTable
              getAllergieById={getAllergieById}
              data={allergies}
              getAllAllergies={getAllAllergies}
              setAllergiesLoading={setAllergiesLoading}
            />
          </div>
        </Col>
        <Modal
          title={ModalTitle}
          width={mobileResponsive ? '90%' : '50%'}
          footer={false}
          open={isModalVisible}
          onCancel={handleCancel}
          onOk={handleOk}
          className='ehr-action-modal'
          centered
        >
          {singleAllergieDetails?.PatientAllergyId ? (
            <UpdateAllergies
              form={form}
              handleOk={handleOk}
              getAllAllergies={getAllAllergies}
              singleAllergieDetails={singleAllergieDetails}
              isModalVisible={isModalVisible}
            />
          ) : addAllergieType === 'Freetext' ? (
            <FreetextAllergies
              form={form}
              handleOk={handleOk}
              getAllAllergies={getAllAllergies}
            />
          ) : (
            <SearchAllergies
              form={form}
              handleOk={handleOk}
              getAllAllergies={getAllAllergies}
              setAddAllergieType={setAddAllergieType}
            />
          )}
        </Modal>
      </Row>
    </Spin>
  );
};

export default Allergies;
