import { Link, useNavigate } from "react-router-dom";
import classes from "./Footer.module.scss";

import { logo, envelop } from "../../../assets";
import { ReactSVG } from "react-svg";
import IconFields from "../../UI/IconFields/IconFields";
import { socialIcon } from "../../../Utils/constant";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../service/ApisFunctions";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setSpeacilityDetails,
  setSpeacilityImage,
  setbookingType,
} from "../../../Redux/Reducers/authSlice";
import {
  setBookableFlowCondition,
  setBookableFlowProgram,
  setBookableFlowSpeciality,
  setBookingFlowPrice,
  setBookingFlowType,
} from "../../../Redux/Reducers/bookingSlice";
import { DEPLOYMENT_MODE } from "../../../Utils/baseUrl";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const windowLoaction = window?.location.pathname;

  const websiteContent = useSelector(
    (state) => state.generalReducer.websiteContent
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [popularBookings, setPopularBookings] = useState(null);
  const [services, setServices] = useState(null);
  const [newsLetter, setNewsLetter] = useState("");

  const getServices = async () => {
    const onSuccess = (res) => {
      setServices(res?.data);
    };
    const onError = (err) => {
      message.error(err.message);
    };

    await getRequest(
      "",
      "booking_type/listing_booking_type",
      true,
      onSuccess,
      onError
    );
  };

  const getPopularBooking = async () => {
    const onSuccess = (res) => {
      setPopularBookings(res?.data);
    };
    const onError = (err) => {
      message.error(err.message);
    };

    await getRequest(
      "",
      "booking/get-popular-bookings",
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getServices();
    getPopularBooking();
  }, []);

  const getConditionById = (item) => {
    const service = services?.find(
      (item) => item?.flow === "Medical consultation"
    );
    dispatch(setbookingType("Medical consultation"));
    dispatch(setBookableFlowCondition(item?.categories[0]));
    dispatch(setBookableFlowSpeciality(item));
    dispatch(setSpeacilityDetails(item?.categories[0]));
    dispatch(setBookingFlowType("Medical consultation"));
    dispatch(setSpeacilityImage(item?.image));
    dispatch(
      setBookingFlowPrice(
        (service?.price > 0 && service?.price) ||
          (item?.price > 0 && item?.price) ||
          item?.categories[0]?.price
      )
    );
    navigate(`/booking-details?type=${item?.categories?.[0]?.category_name}`);
  };

  const getProgramById = (item) => {
    const service = services?.find(
      (item) => item?.flow === "Treatment programs"
    );

    dispatch(setSpeacilityDetails(item));
    dispatch(setSpeacilityImage(item?.image));
    dispatch(setbookingType("Treatment programs"));
    dispatch(setBookableFlowProgram(item));
    dispatch(setBookingFlowType("Treatment programs"));
    dispatch(
      setBookingFlowPrice((service?.price > 0 && service?.price) || item?.price)
    );
    navigate(`/treatment-consultations-details?type=${item?.treatment_name}`);
  };

  const sendNewsLetter = async () => {
    if (newsLetter) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(newsLetter)) {
        message.warning("Please enter a valid email address");
        return;
      }

      const onSuccess = (res) => {
        setNewsLetter("");
        message.success(res?.message);
      };

      const onError = (err) => {
        message.error(err.message);
      };

      await postRequest(
        { email: newsLetter },
        "newsLetter/add_news_letter_request",
        true,
        onSuccess,
        onError
      );
    } else {
      message.warning("Please enter your email");
    }
  };

  return (
    <div
      className={classes.footer}
      style={
        window?.location?.pathname === "/join-as-a-provider"
          ? { marginTop: 0 }
          : {}
      }
    >
      <div
        style={
          windowLoaction?.includes("/")
            ? {}
            : windowLoaction?.includes("/profile")
            ? { marginTop: "0px", paddingTop: "50px" }
            : { paddingTop: "50px" }
        }
        className={classes.body}
      >
        <div className={classes.col1}>
          <Link to="/">
            <ReactSVG className={classes.footerLogo} src={logo} />
          </Link>
          <p>{websiteContent?.footer?.text}</p>
          <IconFields
            placeholder="Email Address"
            icon={envelop}
            border={true}
            setMessage={setNewsLetter}
            message={newsLetter}
            onClick={sendNewsLetter}
          />
          <div className={classes.icons}>
            {socialIcon.map((icon, index) => {
              return (
                <a href={icon?.links} target="_blank" key={index}>
                  <ReactSVG src={icon.img} />
                </a>
              );
            })}
          </div>
        </div>
        <div className={classes.col2}>
          <div className={classes.col5}>
            <h5>{websiteContent?.footer?.popular?.text}</h5>
            <ul>
              {popularBookings?.map((item, index) =>
                item?.type === "TreatmentProgram" ? (
                  <a onClick={() => getProgramById(item)}>
                    <li>{item?.treatment_name || "-"}</li>
                  </a>
                ) : (
                  <a onClick={() => getConditionById(item)}>
                    <li>{item?.categories[0]?.category_name || "-"}</li>
                  </a>
                )
              )}
              {/* <Link>
                <li>Weight Loss Treatment</li>
              </Link>
              <Link>
                <li>Teeth Whitening</li>
              </Link>
              <Link>
                <li>Skin rejuvenation</li>
              </Link>
              <Link>
                <li>Urgent Care</li>
              </Link>
              <Link>
                <li>Skin & Hair</li>
              </Link> */}
            </ul>
          </div>

          <div className={classes.col4}>
            <h5>{websiteContent?.footer?.connect?.heading}</h5>
            <ul>
              <Link to="/contact">
                <li>{websiteContent?.footer?.connect?.text1}</li>
              </Link>
              <Link>
                <li>{websiteContent?.footer?.connect?.text2}</li>
              </Link>
            </ul>
          </div>
          <div className={classes.col4}>
            <h5>{websiteContent?.footer?.careers?.heading}</h5>
            <ul>
              <Link
              target='_blank'
                to={
                  DEPLOYMENT_MODE === "dev"
                    ? "https://dubaidesertsafarigo.com/provider/login"
                    : "https://ohanadoc.com/provider/login"
                }
              >
                <li>{websiteContent?.footer?.careers?.text1}</li>
              </Link>
            </ul>
          </div>
          <div className={classes.col3}>
            <h5>{websiteContent?.footer?.learn?.heading}</h5>
            <ul>
              <Link to="/terms-of-use">
                <li>{websiteContent?.footer?.learn?.text1}</li>
              </Link>
              <Link>
                <li>{websiteContent?.footer?.learn?.text2}</li>
              </Link>
              <Link>
                <li>{websiteContent?.footer?.learn?.text3}</li>
              </Link>
              <Link>
                <li>{websiteContent?.footer?.learn?.text4}</li>
              </Link>
              <Link>
                <li>{websiteContent?.footer?.learn?.text5}</li>
              </Link>
              <Link>
                <li>{websiteContent?.footer?.learn?.text6}</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className={classes.rights}>
        <h3>
          © {currentYear} {websiteContent?.footer?.footerBottom?.text1}
        </h3>
        <h4>{websiteContent?.footer?.footerBottom?.text2}</h4>
        <a
          style={{ textDecoration: "none" }}
          href="/privacy-policy"
          target="_blank"
        >
          <h5>{websiteContent?.footer?.footerBottom?.text3}</h5>
        </a>
      </div>
    </div>
  );
};

export default Footer;
