import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import VirtualConsultation from "../Components/VirtualConsultation/VirtualConsultation";
import Profile from "../Pages/Profile/Profile";
import Review from "../Pages/Review/Review";
import MessagesData from "../Pages/Messages/MessagesData";
import Notifications from "../Pages/Notifications/Notifications";
import MyConsultations from "../Pages/MyConsultations/MyConsultations";
import Consultations from "../Pages/Consultation/Consultations";
import ViewConsultations from "../Pages/MyConsultations";
import { getRequest, putRequest } from "../service/ApisFunctions";
import { onMessage, getToken } from "firebase/messaging";
import { ConsultationBooking } from "../Pages/Booking/ConsultationBooking";
import ConsultationDetail from "../Pages/MyConsultations/consultationDetail";
import { ConsultationMedicalInfo } from "../Pages/Booking/ConsultationMedicalInfo";
import { LandingPage } from "../Pages/LandingPage/LandingPage";
import {
  setCompleteUser,
  setMessagesCount,
  setnotificationCount,
} from "../Redux/Reducers/gernalSlice";
import { socket } from "../Utils/helper";
import {
  setIsConsentForm,
  setIsProfileFilled,
  setUser,
} from "../Redux/Reducers/authSlice";
import { messaging } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal, Spin } from "antd";
import MedicalSteps from "../Components/MedicalSteps/MedicalSteps";
import TreatmentConsultations from "../Pages/Consultation/treatmentConsultations";
import ContactUs from "../Pages/ContactUs/ContactUs";
import { JoinOhana } from "../Components/LandingPage2/JoinOhana";
import Refills from "../Pages/Refills";
import { CloseOutlined } from "@ant-design/icons";
import ProfilePopup from "../Components/ProfileDetails/ProfilePopup";
import { useMediaQuery } from "react-responsive";
import AllRefillsView from "../Pages/Refills/AllRefillsView";
import SingleRefillView from "../Pages/Refills/SingleRefillView";
import SpecialityDetails from "../Components/Booking/DetailPages/SpecialityDetails";
import RefillDetailPage from "../Components/Refills/DetailPages/RefillDetailPage";
import UrgnetChatBox from "../Components/ConsultationDetail/UrgentChatBox";
import UpdateConsentForm from "../Components/ConsentForm/UpdateConsentForm";
import ConsentForm from "../Components/ConsentForm/ConsentForm";

const Pages = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const isProfileFilled = useSelector(
    (state) => state.authReducer.isProfileFilled
  );
  const isConsentForm = useSelector((state) => state.authReducer.isConsentForm);
  const token = useSelector((state) => state.authReducer.paitentToken);

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  const me = async () => {
    const onSuccess = (res) => {
      dispatch(setCompleteUser(res.data));
    };

    const onError = (err) => {
      console.log(err?.message);
    };

    await getRequest(
      "",
      "auth/auth_me?role=customer",
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (token) {
      me();
      dispatch(setUser({}));
    }
  }, [token]);

  const requestFortoken = async () => {
    return await getToken(messaging, {
      vapidKey:
        "BOyE8suldA_fl5ec-4qhC4-_SH4qCswAvFfnpCD2rSrCPESxk6-RBXg3zL19NipJsB9MJJ58Ju3PcOMitUTbnxc",
    })
      .then((currentToken) => {
        if (currentToken && token) {
          handleSubmit(currentToken);
        } else {
          console.log("no token");
        }
      })
      .catch((err) => console.log(err, "err"));
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  onMessageListener()?.then((payload) => {
    toast.success(payload?.notification?.body, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  });

  useEffect(() => {
    requestFortoken();
  }, []);

  const handleSubmit = async (isFireBaseToken) => {
    const onSuccess = () => {};
    const onError = (err) => {
      console.log(err?.message);
    };

    const formData = {
      firebaseToken: isFireBaseToken,
    };

    await putRequest(
      formData,
      "auth/replace-fcm-token",
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (completeUser && completeUser?._id) {
      socket.emit("notiifcationJoin", {
        _id: completeUser?._id,
        role: "customer",
      });
    }
  }, [completeUser]);

  useEffect(() => {
    if (completeUser && completeUser?._id) {
      socket.emit("MessagesCountJoin", {
        id: completeUser?.user_id?._id,
        role: "customer",
      });
    }
  }, [completeUser]);

  useEffect(() => {
    const handleNotification = (notice) => {
      if (
        completeUser?._id !== notice?.senderId &&
        !location?.pathname?.includes("messages") &&
        !location?.pathname?.includes("urgent-chat")
      ) {
        toast.success(notice?.msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    };

    socket.on("sendNotification", handleNotification);

    return () => {
      socket.off("sendNotification", handleNotification);
    };
  }, [socket, completeUser]);

  useEffect(() => {
    socket.on("countMessages", (countMessage) => {
      dispatch(setMessagesCount(countMessage));
    });
    return () => {
      socket.off("countMessages", () => {});
    };
  }, [completeUser]);

  useEffect(() => {
    socket.on("countNotification", (notice) => {
      dispatch(setnotificationCount(notice?.count));
    });
    return () => {
      socket.off("countNotification", () => {});
    };
  }, [completeUser]);

  useEffect(() => {
    if (completeUser?._id && completeUser?.isProfileFilled) {
      dispatch(setIsProfileFilled({ personalInfo: false }));
    }
    if (completeUser?._id && completeUser?.allowConsent) {
      dispatch(setIsConsentForm(false));
    }
  }, [completeUser]);

  const allowConsent = (() => {
    if (
      window.location.pathname.includes("terms-of-use") ||
      window.location.pathname.includes("responsibility-and-billing") ||
      window.location.pathname.includes("privacy-policy")
    ) {
      return true;
    }
  })();

  return completeUser?._id ? (
    <>
      <Routes>
        {/* <Route path={'*'} element={<Navigate to='/' replace />} /> */}

        {/* Coming Soon */}
        {/* <Route path='/' element={<ComingSoon />} /> */}

        {/* Landing page */}
        <Route path="/" element={<LandingPage />} />

        {/* Virtual Consultation */}
        {/* <Route path="/virtualConsultation" element={<VirtualConsultation />} /> */}

        {/* Consultation Booking */}
        <Route
          path="/consultation-booking"
          element={
            // completeUser?.isProfileFilled ? (
            <ConsultationBooking />
            // ) : (
            //   <>
            //     <Navigate to={'/profile?isProfileFilled=filled'} />/{' '}
            //   </>
            // )
          }
        />

        {/* Medical Steps */}
        <Route path="/medical" element={<MedicalSteps />} />

        {/* Medical Informations */}
        <Route
          path="/medical-info/:bookingId"
          element={<ConsultationMedicalInfo />}
        />

        {/* Profile */}
        <Route path="/profile" element={<Profile />} />

        {/* Patient Review */}
        <Route path="/review/:id" element={<Review />} />

        {/* Chats Messages */}
        <Route path="/messages" element={<MessagesData />} />

        {/* Notifications */}
        <Route path="/notifications" element={<Notifications />} />

        {/* View Consultation */}
        <Route path="/view-consultations" element={<ViewConsultations />} />

        {/* Consultations Render with Id */}
        <Route path="/consultations/:id" element={<MyConsultations />} />

        {/* Consultations */}
        <Route path="/consultations" element={<MyConsultations />} />

        {/* Booking Details */}
        <Route path="/booking-details" element={<Consultations />} />
        <Route
          path="/speciality-booking-details/:id"
          element={<SpecialityDetails />}
        />

        {/* join-as-a-provider */}

        <Route path="/join-as-a-provider" element={<JoinOhana />} />

        {/* Treatment program details page */}

        <Route
          path="/treatment-consultations-details"
          element={<TreatmentConsultations />}
        />
        <Route path="/refill-request-detail" element={<RefillDetailPage />} />

        <Route path="/refill-request" element={<Refills />} />
        <Route path="/refills" element={<AllRefillsView />} />
        <Route path="/single-refill/:id" element={<SingleRefillView />} />

        {/* Consultation details */}
        <Route
          path="/consultation-detail/:id"
          element={<ConsultationDetail />}
        />

        {/* Contact page */}

        <Route path="/contact" element={<ContactUs />} />
        <Route path="/urgent-chat/:id" element={<UrgnetChatBox />} />

        <Route path="/terms-of-use" element={<ConsentForm />} />
        <Route path="/responsibility-and-billing" element={<ConsentForm />} />
        <Route path="/privacy-policy" element={<ConsentForm />} />
        <Route path="/consent" element={<ConsentForm />} />

        {/* Consent Form */}
      </Routes>

      <Modal
        closable={false}
        width={mobileResponsive ? "90%" : "70%"}
        footer={false}
        centered
        open={
          // !completeUser?.isMedicationConsentAllowed ||  !completeUser?.isElectronicCommunicationConsentAllowed ||
          // !completeUser?.isUseOhanaDocConsentAllowed ||
          allowConsent ? false : isConsentForm || isProfileFilled?.personalInfo
        }
      >
        {/* {
        !completeUser?.isMedicationConsentAllowed ||
        !completeUser?.isElectronicCommunicationConsentAllowed ||
        !completeUser?.isUseOhanaDocConsentAllowed ||
        !completeUser?.isEmergencyConsentAllowed ? (
          <LoginConsentForm />
        ) : ( */}
        {isConsentForm ? (
          <div>
            <div
              style={{ float: "right", position: "absolute", right: "20px" }}
            >
              <CloseOutlined
                onClick={() => dispatch(setIsConsentForm(false))}
              />
            </div>
            <UpdateConsentForm />
          </div>
        ) : (
          <div>
            <div
              style={{ float: "right", position: "absolute", right: "20px" }}
            >
              <CloseOutlined
                onClick={() =>
                  dispatch(setIsProfileFilled({ personalInfo: false }))
                }
              />
            </div>
            <ProfilePopup />
          </div>
        )}
        {/* )} */}
      </Modal>
    </>
  ) : (
    <Spin size="large">
      <div style={{ height: "100vh" }}></div>
    </Spin>
  );
};

export default Pages;
