import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  paitentToken: "",
  FirstTime: true,
  isProfileFilled: {personalInfo:true},
  isConsentForm: true,
  orderBbyId: {},
  speacilityDetails: {},
  treatmentProgram: "",
  speacilityId: "",
  speacilityImage: "",
  booking_price: 0,
  bookingType: "",
  userBookingId: "",
  videoToken: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.paitentToken = action.payload;
    },
  
    logOut: (state, action) => {
      localStorage.setItem("patientToken", "");
      state.user = {};
      state.paitentToken = "";
      state.isProfileFilled = {personalInfo:false}
    },
    setSpeacilityDetails: (state, action) => {
      state.speacilityDetails = action.payload;
    },
    setSpeacilityId: (state, action) => {
      state.speacilityId = action.payload;
    },
    setSpeacilityImage: (state, action) => {
      state.speacilityImage = action.payload;
    },

    getOrderDetailById: (state, action) => {
      state.orderBbyId = action.payload;
    },
    setbooking: (state, action) => {
      state.booking_price = action.payload;
    },
    setbookingType: (state, action) => {
      state.bookingType = action.payload;
    },
    setUserBookingId: (state, action) => {
      state.userBookingId = action.payload;
    },
    setVideoToken: (state, action) => {
      state.videoToken = action.payload;
    },
    setIsProfileFilled: (state, action) => {
      state.isProfileFilled = action.payload;
    },

    setIsConsentForm: (state, action) => {
      state.isConsentForm = action.payload;
    },

    setTraetmentProgram: (state, action) => {
      state.treatmentProgram = action.payload;
    }
   
  },
});

export const {
  setUser,
  logOut,
  Bording,
  setToken,
  setSpeacilityImage,
  getOrderDetailById,
  setSpeacilityDetails,
  setbooking,
  setSpeacilityId,
  setbookingType,
  setUserBookingId,
  setVideoToken,
  setTraetmentProgram,
  setIsProfileFilled,
  setIsConsentForm
} = userSlice.actions;
export const authReducer = userSlice.reducer;
