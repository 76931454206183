import { Card, Rate, Tag, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./patientReviews.scss";
import { ReactSVG } from "react-svg";
import { DomyD, DomyP, arrowLeft, arrowRight } from "../../assets";
import OwlCarousel from "react-owl-carousel";
import { GradientBackground } from "../UI/GradientBackground/GradientBackground";
import { getRequest } from "../../service/ApisFunctions";

export const PatientReviews = () => {
  const carouselRef = useRef(null);
  const [bookingsReview, setBookingsReview] = useState(null);

  const getAllBookingsReview = async () => {
    const onSuccess = (res) => {
      setBookingsReview(res?.data);
    };
    const onError = (err) => {
      message.error(err);
    };

    await getRequest(
      "",
      "booking/get-review-bookings",
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getAllBookingsReview();
  }, []);

  const PrevActive = () => {
    carouselRef.current.prev();
  };
  const NextActive = () => {
    carouselRef.current.next();
  };

  // const cardsArray = [
  //   {
  //     img: '',
  //     title: 'Ebola effected substances',
  //     description:
  //       'Lorem ipsum dolor sit amet consectetur. Luctus orci est est porttitor morbi a dui. Sagittis tellus enim lectus auctor condimentum nunc nec pharetra cum. Sed sem sit ipsum nibh enim ornare. Nunc cursus enim cras vivamus consequat facilisi bibendum. In id in nulla euismod.'
  //   },
  //   {
  //     img: '',
  //     title: 'Ebola effected substances',
  //     description:
  //       'Lorem ipsum dolor sit amet consectetur. Luctus orci est est porttitor morbi a dui. Sagittis tellus enim lectus auctor condimentum nunc nec pharetra cum. Sed sem sit ipsum nibh enim ornare. Nunc cursus enim cras vivamus consequat facilisi bibendum. In id in nulla euismod.'
  //   },
  //   {
  //     img: '',
  //     title: 'Ebola effected substances',
  //     description:
  //       'Lorem ipsum dolor sit amet consectetur. Luctus orci est est porttitor morbi a dui. Sagittis tellus enim lectus auctor condimentum nunc nec pharetra cum. Sed sem sit ipsum nibh enim ornare. Nunc cursus enim cras vivamus consequat facilisi bibendum. In id in nulla euismod.'
  //   },
  //   {
  //     img: '',
  //     title: 'Ebola effected substances',
  //     description:
  //       'Lorem ipsum dolor sit amet consectetur. Luctus orci est est porttitor morbi a dui. Sagittis tellus enim lectus auctor condimentum nunc nec pharetra cum. Sed sem sit ipsum nibh enim ornare. Nunc cursus enim cras vivamus consequat facilisi bibendum. In id in nulla euismod.'
  //   },
  //   {
  //     img: 's',
  //     title: 'Ebola effected substances',
  //     description:
  //       'Lorem ipsum dolor sit amet consectetur. Luctus orci est est porttitor morbi a dui. Sagittis tellus enim lectus auctor condimentum nunc nec pharetra cum. Sed sem sit ipsum nibh enim ornare. Nunc cursus enim cras vivamus consequat facilisi bibendum. In id in nulla euismod.'
  //   }
  // ];
  return (
    <>
      {bookingsReview?.length > 0 && (
        <GradientBackground>
          <div className="reviewWrapper">
            <h2>
              Patients Love <span>Us </span>{" "}
            </h2>
            <div className="reviewCarouselWrap">
              <OwlCarousel
                className="owl-theme"
                loop
                ref={carouselRef}
                dots={false}
                // center
                // margin={10}
                items={1}
                responsive={{
                  600: {
                    items: 2.5,
                  },
                }}
              >
                {bookingsReview?.map((item, index) => {
                  return (
                    <div key={index} style={{ paddingInline: "15px" }}>
                      <Card className="carouselCard" key={index}>
                        <img
                          src={item?.patient?.profile_image || DomyD}
                          alt="client "
                          className="clientImg"
                        />
                        <p className="clientReviewText">{item?.review}</p>

                        <div className="clientReview">
                          <div className="clientName">
                            <h3>{`${item?.provider_id?.first_name || ""} ${
                              item?.provider_id?.middle_name || ""
                            } ${item?.provider_id?.last_name || ""}`}</h3>
                            <Tag color="#009ddd">
                              {item?.provider_id?.specialities[0]
                                ?.speciality_name || "-"}
                            </Tag>
                          </div>
                          <div className="clientRating">
                            <Rate disabled value={item?.rating || 0} />
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </OwlCarousel>
              <div className="arrowBtns">
                <div onClick={PrevActive} className="filledCircle">
                  {/* <img src={ArrowLeft} alt='' /> */}
                  <ReactSVG src={arrowLeft} />
                </div>
                <div onClick={NextActive} className="filledCircle">
                  {/* <img src={ArrowRight} alt='' /> */}
                  <ReactSVG src={arrowRight} />
                </div>
              </div>
            </div>
          </div>
        </GradientBackground>
      )}
    </>
  );
};
