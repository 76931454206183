import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./combineReducer"; // Ensure this is correctly pointing to your root reducer
import { DEPLOYMENT_MODE } from "../Utils/baseUrl";

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["generalReducer", "refills"], // Reducers to not persist
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // Enable Redux DevTools in non-production environments
  devTools: DEPLOYMENT_MODE !== "prod",
});

// Create the persistor for the store
export const persistor = persistStore(store);

// Export the store as the default export
export default store;
