import { Steps } from 'antd';
import classes from './MedicalSteps.module.scss';
import { useSelector } from 'react-redux';
import Questions from '../Questionnaire1/Questions';
import StepsLayout from './StepsLayout';

const steps = [
  // {
  //   title: 'Medical History',
  //   content: <MedicalHistory />,
  //   disabled: true
  // },
  {
    title: 'Pre Questions',
    content: <Questions />,
    disabled: true
  },
  // {
  //   title: 'Pharmacy',
  //   content: <Pharmacy />,
  //   disabled: true
  // }
];

const MedicalSteps = () => {
  const bookingFlowMedialInfoSteps = useSelector((state) => state.booking.bookingFlowMedialInfoSteps);

  const items = steps.map(item => ({
    key: item.title,
    title: item.title,
    disabled: item?.disabled
  }));

  return (
    <StepsLayout>
      <div className={classes.container}>
        <div className={classes.steps}>
          <Steps
            width='20%'
            current={bookingFlowMedialInfoSteps}
            items={items}
            labelPlacement='vertical'
      
          />
          <div className={classes.content}>{steps[0].content}</div>
        </div>
      </div>
    </StepsLayout>
  );
};
export default MedicalSteps;
