import { Steps } from "antd";
import React, { useEffect, useState } from "react";
import PersonalInfoPopup from "./personalInfoPopup";
import { useSelector } from "react-redux";
import HealthEquityInfoPopup from "./healthEquityInfoPopup";
import PharmacyPopup from "./pharmacyPopup";
import { useMediaQuery } from "react-responsive";
import ProfileMedicalHistoryPopup from "./profileMedicalHistoryPopup";
import ProfileSocialHistoryPopup from "./profileSocialHistoryPopup";

const ProfilePopup = () => {
  const [profileStep, setProfileStep] = useState(0);

  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const steps = [
    {
      title: "Personal Info",
      disabled: true,
    },
    {
      title: "Health Equity",
      disabled: true,
    },
    {
      title: "Medical History",
      disabled: true,
    },
    {
      title: "Social History",
      disabled: true,
    },
    {
      title: "Pharmacy",
      disabled: true,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    // disabled: false,
  }));

  const profileComponents = [
    <PersonalInfoPopup
      completeUser={completeUser}
      setProfileStep={setProfileStep}
    />,
    <HealthEquityInfoPopup
      completeUser={completeUser}
      setProfileStep={setProfileStep}
    />,
    <ProfileMedicalHistoryPopup
      completeUser={completeUser}
      setProfileStep={setProfileStep}
    />,
    <ProfileSocialHistoryPopup
      completeUser={completeUser}
      setProfileStep={setProfileStep}
    />,
    <PharmacyPopup completeUser={completeUser} />,
  ];

  useEffect(() => {
    if (completeUser?.height?.value) {
      setProfileStep(1);
    }  if (completeUser?.height?.value && completeUser?.sexualOrientation) {
      setProfileStep(2);
    }  if (
      completeUser?.height?.value &&
      completeUser?.sexualOrientation &&
      completeUser?.past_medical_history?.detail?.length > 0
    ) {
      setProfileStep(3);
    }  if (
      completeUser?.height?.value &&
      completeUser?.sexualOrientation &&
      completeUser?.past_medical_history?.detail?.length > 0 &&
      completeUser?.past_social_history?.detail?.length >= 0
    ) {
      setProfileStep(4);
    }  if (
      completeUser?.height?.value &&
      completeUser?.sexualOrientation &&
      completeUser?.past_medical_history?.detail?.length > 0 &&
      completeUser?.past_social_history?.detail?.length >= 0 &&
      completeUser?.pharmacy?.PharmacyId
    ) {
      setProfileStep(4);
    }
  }, [completeUser]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: mobileResponsive ? "100%" : "80%" }}>
          <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
            Update Profile
          </h1>
          <Steps
            size={mobileResponsive ? "small" : "default"}
            current={profileStep}
            items={items}
            labelPlacement="vertical"
          />
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>{profileComponents[profileStep]}</div>
    </>
  );
};

export default ProfilePopup;
