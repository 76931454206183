import { Col } from "antd";
import React from "react";
import "./consultationCard.scss";
import { DomyP } from "../../assets";

const ConsultationCards = ({ icon, color, text, counter,mobileResponsive }) => {
  return (
    <Col span={mobileResponsive? 24 :8}>
      <div className="consultationCards">
        <div className="ViewConsultationCardBody">
          <div>
            <div className="content">
              <div className="image">
                <img width="100%" height="100%" src={icon} />
              </div>
              <div>
                <h3 style={{ color: color }}>{text}</h3>
              </div>
            </div>
          </div>
          <div className="content-right-side">
            <h3>{counter || "--"}</h3>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ConsultationCards;
