
import {
  AffoardablePrice,
  HeartIcon,
  HippaIcon,
  HomeLabTesting,
  InjectionIcon,
  JoinOurOhana,
  LocalCompoundingPharmacy,
  LocalPharmacy,
  RXIcon,
  SexualHealthIcon,
  StdTesting,
  TreeIcon,
  VirtualConsultationIcon,
} from "../../../assets";

export const specialityList = 'speciality/popular-specialities';


export const headerMarqueeTags = [
  {
    image: HippaIcon,
    text: "HIPPA COMPLIANT",
  },
  {
    image: RXIcon,
    text: "RX HOME DELIVERY",
  },
  {
    image: VirtualConsultationIcon,
    text: "VIRTUAL MEDICAL VISITS",
  },
  {
    image: InjectionIcon,
    text: "SEMAGLUTIDE",
  },
  {
    image: HeartIcon,
    text: "TIRZEPATIDE",
  },
  {
    image: SexualHealthIcon,
    text: "SEXUAL HEALTH",
  },
  {
    image: TreeIcon,
    text: "ANTI-AGING PEPTIDES",
  },
  {
    image: StdTesting,
    text: "STD TESTING/TREATMENlT",
  },
  {
    image: LocalPharmacy,
    text: "RX TO LOCAL PHARMACY",
  },
  {
    image: JoinOurOhana,
    text: "JOIN OUR OHANA",
  },
  {
    image: HomeLabTesting,
    text: "HOME LAB TEST",
  },
  {
    image: LocalCompoundingPharmacy,
    text: "LOCAL COMPOUNDING PHARMACY",
  },
  {
    image: AffoardablePrice,
    text: "AFFORDABLE PRICES",
  },
];
