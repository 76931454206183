import { Col, Divider, Empty, Row } from "antd";
import { useState } from "react";
import "./consentHistory.scss";
import moment from "moment";
import { CollapseArrow } from "../../../assets";
import { useDispatch } from "react-redux";
import { setConsentForms } from "../../../Redux/Reducers/gernalSlice";

const ProfileConsentHistory = ({ completeUser }) => {
  const dispatch = useDispatch()
  const [collapseOpenNumber, setCollapseOpenNumber] = useState(0);
  const allDetails = completeUser?.past_consent_history || null;
  // .slice() // create a shallow copy to avoid mutating original array
  // .sort((a, b) => new Date(b?.date) - new Date(a?.date));

  const toggleCollapse = (value) => {
    if (value === collapseOpenNumber) {
      setCollapseOpenNumber(null);
    } else {
      setCollapseOpenNumber(value);
    }
  };

  return (
    <Row className="profileConsentHistory">
      <Col className="profileSocialHistoryHeader" span={24}>
        <h2>Consent history</h2>
      </Col>
      {allDetails?.length > 0
        ? allDetails?.map((item, index) => {
            return (
              <Col span={24} key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    cursor:"pointer"
                  }}
                  onClick={() => toggleCollapse(index)} 
                >
                  <h5>{moment(item?.date).format("YYYY-MM-DD hh:mm A")}</h5>
                  <img
                    className={collapseOpenNumber === index ? "active" : ""}
                    src={CollapseArrow}
                    alt="$$$"
                  />
                </div>
                {collapseOpenNumber === index ? (
                  <Row className="consentFormHistory">
                    {item?.detail?.map((subItem, subIndex) => {
                      return (
                        <Col onClick={()=>dispatch(setConsentForms(item?.detail))} key={subIndex} span={24}>
                          <a href="/consent" target='_blank' ><h5>{subItem?.name}</h5></a>
                          {/* <div
                          dangerouslySetInnerHTML={{
                            __html: subItem?.details ? subItem?.details : "-",
                          }}
                        ></div> */}
                        </Col>
                      );
                    })}
                  </Row>
                ) : null}
              </Col>
            );
          })
        : null}

      {/* <Col
        span={24}
        className={
          collapseOpenNumber ? "socialHistoryBox open" : "socialHistoryBox"
        }
      >
        {collapseOpenNumber ? (
          allDetails?.detail?.length > 0 ? (
            allDetails?.detail?.map((subItem, subIndex) => (
              <Col key={subIndex} span={24}>
                <SingleQuestion
                  key={subIndex}
                  question={subItem}
                  number={subIndex}
                />
                <Divider style={{ margin: "10px 0px !important" }} />
              </Col>
            ))
          ) : (
            <Empty style={{ marginTop: "10px" }} />
          )
        ) : null}
      </Col> */}
    </Row>
  );
};

export default ProfileConsentHistory;
