import { ReactSVG } from "react-svg";
import {
  DomyD,
  DomyP,
  EducationHatIcon,
  ExprienceBagIcon,
  GraduationCap,
  ReviewIcon,
  Stethoscope,
  arrowBack,
} from "../../../../assets";
import { Badge, Col, Empty, Rate, Row, Tag } from "antd";
import moment from "moment";
import "./SingleDoctorView.scss";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
export const SingleDoctorView = ({
  singleDoctor,
  setSingleDoctor,
  setActiveInfoTab,
}) => {
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const bookingFlowDoctor = useSelector(
    (state) => state?.booking?.bookingFlowDoctor
  );

  return (
    <>
      <Row
        className="singleDoctorModal"
        gutter={mobileResponsive ? [0, 10] : [20, 20]}
        style={{ width: "100%" }}
      >
        <Col span={24} className="modalParts">
          <div className="leftSide">
            <div className="leftSideImage">
              <img
                src={
                  bookingFlowDoctor?.profile_image
                    ? bookingFlowDoctor?.profile_image
                    : DomyD
                }
                alt="$$$"
              />
            </div>
            <div className="doctorDetails">
              <div className="doctorDetailsTop">
                <p
                  style={{ fontSize: "24px", fontWeight: "700" }}
                  className="doctorName"
                >{`${bookingFlowDoctor?.first_name || "-"} ${
                  bookingFlowDoctor?.middle_name || ""
                } ${bookingFlowDoctor?.last_name || "-"}`}</p>
                <div className="rightSide">
                  <p style={{ color: "#F76D00" }}>
                    <Rate
                      disabled
                      // style={{ color: "#F76D00" }}
                      allowHalf
                      value={bookingFlowDoctor?.ratings?.averageRating}
                    />
                    {/* {bookingFlowDoctor?.ratings?.averageRating.toFixed(2)} */}
                  </p>
                  <p>
                    {bookingFlowDoctor?.ratings?.averageRating.toFixed(2) ||
                      "0"}{" "}
                    Reviews
                  </p>
                </div>
                <Tag style={{marginTop:"5px",fontSize:"14px"}} color="#009ddd">{bookingFlowDoctor?.specialities[0]?.speciality_name}</Tag>
              </div>
            </div>
          </div>
        </Col>

        <Col span={24}>
          {/* Bio */}
          <div className="singleDoctorDetailsCard">
            <h1>Bio</h1>
            <p>{bookingFlowDoctor?.bio || "-"}</p>
          </div>
        </Col>
        <Col span={24}>
          {/* Description */}
          <div className="singleDoctorDetailsCard">
            <h1>Description</h1>
            <p>{bookingFlowDoctor?.description || "-"}</p>
          </div>
        </Col>

        <Col span={24}>
          {/* Education */}
          <div className="singleDoctorEducation">
            <h1>Education</h1>
            <div className="heading">
              <p>Institute</p>
              <p>Degree</p>
            </div>
            {bookingFlowDoctor?.education?.length > 0 ? (
              bookingFlowDoctor?.education?.map((item, index) => (
                <div key={index} className="singleDoctorEducationCard">
                  <div className="leftSide">
                    <div className="doctorDetails">
                      <div className="doctorDetailsTop">
                        <p className="doctorName">{item?.institute || ""}</p>
                        <div>
                          <p className="doctorName">
                            {item?.title || ""}{" "}
                            {new Date(item?.year).getFullYear()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Empty />
            )}
          </div>
        </Col>

        <Col span={24}>
          {/* Experience */}
          <div className="singleDoctorEducation">
            <h1>Experience</h1>
            <div className="heading">
              <p>Institute</p>
              <p>Years</p>
            </div>
            {bookingFlowDoctor?.experience?.length > 0 ? (
              bookingFlowDoctor?.experience?.map((item, index) => (
                <div className="singleDoctorEducationCard">
                  <div className="leftSide">
                    <div className="doctorDetails">
                      <div className="doctorDetailsTop">
                        <p className="doctorName">{item?.institute || ""}</p>
                        <div>
                          <p className="doctorName">
                            {`${moment(item?.start_date).format(
                              "MMM YYYY"
                            )} - ${moment(item?.end_date).format("MMM YYYY")}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Empty />
            )}
          </div>
        </Col>


        <Col span={24}>
          {/* Reviews */}
          <div className="singleDoctorEducation">
            <h1>Reviews</h1>
            {bookingFlowDoctor?.bookings?.length > 0 ? (
              bookingFlowDoctor?.bookings?.map((item, index) => (
                <div
                  style={{ marginTop: "15px" }}
                  className="singleDoctorEducationCard"
                >
                  <div className="leftSide">
                    <div className="leftSideReviewIcon">
                      <img
                        src={item?.patient?.profile_image || DomyP}
                        alt="$$$"
                      />
                    </div>
                    <div className="doctorDetails">
                      <div className="doctorDetailsTop">
                        <p className="doctorName" style={{ fontWeight: "700" }}>
                          {item?.booking_as === "patient"
                            ? `${item?.patient?.first_name || "-"} ${
                                item?.patient?.last_name || "-"
                              }`
                            : `${item?.guardian?.first_name || "-"} ${
                                item?.guardian?.last_name || "-"
                              }` || "-"}
                        </p>
                        <div
                          className="rightSide"
                          style={{ marginTop: "10px" }}
                        >
                          <Rate disabled allowHalf value={item?.rating || 0} />
                        </div>
                      </div>
                      <p className="doctorDescription">{item?.review || "-"}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Empty />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
