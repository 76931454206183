import "./HowItWorkCard.scss";

const HowItWorkCard = ({ card }) => {
  return (
    <div className="HowItWorkCard">
      <div className="card-header">
        <div className="card-image">
          <img src={card?.icon} alt="$$$" />
        </div>
      </div>

        <h1>{card?.heading}</h1>
        <p>{card?.cardDescription}</p>
    </div>
  );
};

export default HowItWorkCard;
