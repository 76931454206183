import React, { useEffect, useState } from "react";
import "./Refills.scss";
import Layouts from "../../Components/Layouts/Layout/Layouts";
import RefillServices from "../../Components/Refills/RefillServices";
import RefillMedications from "../../Components/Refills/RefillMedications";
import RefillPayment from "../../Components/Refills/RefillPayment";
import RefillSuccess from "../../Components/Refills/RefillSuccess";
import RefillsGuestSignup from "../../Components/Refills/RefillsGuestSignup";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import RefillPreQuestions from "../../Components/Refills/RefillPreQuestions";
import RefillNewMedication from "../../Components/Refills/RefillNewMedication";

const Refills = () => {
  const [searchParams] = useSearchParams();
  const isSpecialitySelected = searchParams.get("isSpecialitySelected");
  const step = searchParams.get("step");

  const patientToken = useSelector((state) => state.authReducer.paitentToken);
  const [refillBookingProgressStep, setRefillBookingProgressStep] = useState(
    patientToken ? (step ? step : "medications") : "guestService"
  );

  const refillSteps = {
    medications: (
      <RefillNewMedication
        setRefillBookingProgressStep={setRefillBookingProgressStep}
      />
    ),
    // services: (
    //   <RefillServices
    //     setRefillBookingProgressStep={setRefillBookingProgressStep}
    //   />
    // ),
    guestService: (
      <RefillsGuestSignup
        setRefillBookingProgressStep={setRefillBookingProgressStep}
      />
    ),
    // medications: (
    //   <RefillMedications
    //     setRefillBookingProgressStep={setRefillBookingProgressStep}
    //   />
    // ),
    refillPayment: (
      <RefillPayment
        setRefillBookingProgressStep={setRefillBookingProgressStep}
      />
    ),
    // refillPreQuestions: (
    //   <RefillPreQuestions
    //     setRefillBookingProgressStep={setRefillBookingProgressStep}
    //   />
    // ),

    refillSuccess: (
      <RefillSuccess
        setRefillBookingProgressStep={setRefillBookingProgressStep}
      />
    ),
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [refillBookingProgressStep]);
  return (
    <Layouts>
      <div className="RefillsWrapper">
        <h1 style={{ textAlign: "center" }}>Refill Request</h1>
        {refillSteps[refillBookingProgressStep]}
      </div>
    </Layouts>
  );
};

export default Refills;
