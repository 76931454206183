import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { DomyP, arrowBack } from '../../../assets';

const SingleRefillHeader = ({ refill }) => {
  const navigate = useNavigate();
  return (
    <div className='consultationHeader'>
      <div className='left-side'>
        <div className='consultationHeaderContainer'>
          <ReactSVG
            src={arrowBack}
            onClick={() => navigate(-1)}
            className='back-arrow'
          />
          <div className='consultationHeaderPatient'>
          <div className="doctorImage">
              <img
                src={
                  refill?.relatedSpecialities?.provider_id?.profile_image
                    ? refill?.relatedSpecialities?.provider_id?.profile_image
                    : DomyP
                }
                alt="$$$"
              />
              <div
                style={{
                  background: refill?.relatedSpecialities?.provider_id?.is_online
                    ? ""
                    : "lightGray",
                }}
                className="onlineDot"
              ></div>
            </div>
            <div>
              <h2>{`${refill?.relatedSpecialities?.provider_id?.first_name || '-'} ${
                refill?.relatedSpecialities?.provider_id?.middle_name || ''
              } ${refill?.relatedSpecialities?.provider_id?.last_name || '-'}`}</h2>
              <p>{refill?.relatedSpecialities?.category?.category_name || refill?.relatedSpecialities?.speciality?.speciality_name }- ${refill?.relatedSpecialities?.payment}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleRefillHeader;
