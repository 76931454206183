import React from "react";
import "./personalInfo.scss";

const PersonalInfo = ({booking}) => {

  const information = [
    {
      heading: "Name",
      title:
      `${booking?.provider_id?.first_name || "-"} ${booking?.provider_id?.middle_name || ""} ${
        booking?.provider_id?.last_name || "-"
      }` || "-",
    },
    {
      heading: "Email Address",
      title: booking?.provider_id?.user_id?.email || "-",

    },
  ];

  return (
    <div className="personalInfo">
      <h2>Doctor’s Information</h2>
      {information.map((item,index)=>
      <div key={index} className="personalInfoOptions">
        <h3>{item?.heading}</h3>
        <p>{item?.title}</p>
      </div>
      )}
    </div>
  );
};

export default PersonalInfo;
