import React from 'react';
import SecondaryButton from '../UI/SecondaryButton/SecondaryButton';
import { RefillSucess } from '../../assets';
import { useNavigate } from 'react-router-dom';

const RefillSuccess = ({ setRefillBookingProgressStep }) => {
  const navigate = useNavigate();
  return (
    <div className='refillRequestSucess'>
      <div className='refillRequestContent'>
        <img src={RefillSucess} alt='' />
        <h3>Thank You!</h3>
        <h4>Your refill request has been successfully submitted.</h4>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <SecondaryButton
          onClick={() => navigate('/refills')}
          className='primaryActive'
        >
          Done
        </SecondaryButton>
      </div>
    </div>
  );
};

export default RefillSuccess;
