import './howItWorks.scss';
import { Col, Row } from 'antd';
import HowItWorkCard from './HowItWorkCard';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import {
  BookingConsultationIcon,
  PauseIcon,
  PlayIcon,
  RxPharmacyIcon,
  video_MessageIcon
} from '../../assets';
import { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

const HowItWorks = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const websiteContent = useSelector(
    state => state.generalReducer.websiteContent
  );

  const [serviceBtnActive, setServiceBtnActive] = useState(0);

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const HowItWorkData = [
    {
      icon: BookingConsultationIcon,
      heading: websiteContent?.howItsWorks?.card1?.heading1 || '',
      cardDescription: websiteContent?.howItsWorks?.card1?.heading2 || ''
    },
    {
      icon: video_MessageIcon,
      heading: websiteContent?.howItsWorks?.card2?.heading1 || '',
      cardDescription: websiteContent?.howItsWorks?.card2?.heading2 || ''
    },
    {
      icon: RxPharmacyIcon,
      heading: websiteContent?.howItsWorks?.card3?.heading1 || '',
      cardDescription: websiteContent?.howItsWorks?.card3?.heading2 || ''
    }
  ];

  const handlePlayPauseButton = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className='howItWork'>
      <h1>
        {websiteContent?.howItsWorks?.headingBlack || ''}{' '}
        <span>{websiteContent?.howItsWorks?.headingBlue || ''}</span>
      </h1>
      <Row gutter={20}>
        {/* <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='service-header'>
            <div className='service-header-box'>
              <p
                className={serviceBtnActive === 0 && 'active'}
                onClick={() => setServiceBtnActive(0)}
              >
                Quick Overview
              </p>
              <p
                className={serviceBtnActive === 1 && 'active'}
                onClick={() => setServiceBtnActive(1)}
              >
                What To Expect Guide
              </p>
            </div>
          </div>
        </Col> */}

        <Row
          gutter={mobileResponsive ? [20, 100] : 40}
          style={
            mobileResponsive
              ? { marginTop: serviceBtnActive == 0 ? '100px' : '50px' }
              : {
                  marginTop: serviceBtnActive == 0 ? '90px' : 20,
                  width: serviceBtnActive == 1 && '100%'
                }
          }
        >
          {serviceBtnActive == 0 ? (
            HowItWorkData?.map((item, index) => (
              <Col key={index} span={mobileResponsive ? 24 : 8}>
                <HowItWorkCard card={item} key={index} />
              </Col>
            ))
          ) : (
            <Col className='videoSection' span={24}>
              <div className='videoWrapper'>
                <video
                  ref={videoRef}
                  className='video'
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  controls
                  muted
                  // loop
                  // onPause={() => setIsPlaying(false)}
                  // onPlay={() => setIsPlaying(true)}
                >
                  <source
                    src='https://ohanadoc.s3.amazonaws.com/videos/Ohana+Doc+3Rd+Dec+2023.mp4'
                    type='video/mp4'
                  />
                </video>
                {/* <>
                  {isPlaying && isHovered ? (
                    <div
                      className='videoOverlay'
                      onClick={handlePlayPauseButton}
                    >
                      {isPlaying ? (
                        <ReactSVG
                          className='playPauseButton'
                          src={PauseIcon}
                          alt=''
                        />
                      ) : (
                        <ReactSVG
                          className='playPauseButton'
                          src={PlayIcon}
                          alt=''
                        />
                      )}
                    </div>
                  ) : (
                    !isPlaying && (
                      <div
                        className='videoOverlay'
                        onClick={handlePlayPauseButton}
                      >
                        <ReactSVG
                          className='playPauseButton'
                          src={PlayIcon}
                          alt=''
                        />
                      </div>
                    )
                  )}
                </> */}
              </div>
            </Col>
          )}
        </Row>
      </Row>
    </div>
  );
};

export default HowItWorks;
