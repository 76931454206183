import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { calendarSimple } from "../../assets";
import "./consultationFollow.scss";
import { getRequest } from "../../service/ApisFunctions";
import moment from "moment";
import { Modal } from "antd";
import { FollowUpPayment } from "./consultationFollowUpPayment";
import { useMediaQuery } from "react-responsive";

const ConsultationFollow = ({ setLoading, booking,setUnpaidFollowUps,unpaidFollowups }) => {
  const [followups, setFollowUps] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [singleFollowUp, setSingleFollowUp] = useState({});

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSingleFollowUp({});
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSingleFollowUp({});
  };

  const getAllFollowUps = async () => {
    const onSuccess = (res) => {
      setLoading(false);

      const sortData = res?.data.sort((a, b) => {
        const aDateTime = new Date(a.schedule.start_time);
        const bDateTime = new Date(b.schedule.start_time);
        return aDateTime - bDateTime;
      });
      setFollowUps(sortData);
      const filter = res?.data?.filter((item)=> !item?.payment_status)
      setUnpaidFollowUps(filter)
    };

    const onError = (err) => {
      setLoading(false);
    };

    const route = `notes/get-followUps/${
      booking?.parentBookingId ? booking?.parentBookingId : booking?._id
    }`;

    await getRequest("", route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (booking?._id) {
      getAllFollowUps();
    }
  }, [booking]);



  return (
    <>
      {unpaidFollowups?.length > 0 ? (
        <div className="DetailFollowUps">
          {followups?.map(
            (item, index) =>
              !item?.payment_status && (
                <div
                  onClick={() => {
                    setSingleFollowUp(item);
                    showModal();
                  }}
                  className="DetailFollowUpsBody"
                >
                  <div className="DetailsFollowUpsLeft">
                    <h2>
                      Follow-up scheduled:{" "}
                      <b style={{ fontSize: "10px" }}>
                        {`${moment(item?.schedule?.date).format(
                          "MMM D, YYYY"
                        )}`}{" "}
                        {`${moment(item?.schedule?.start_time).format(
                          "hh:mm A"
                        )} - ${moment(item?.schedule?.end_time).format(
                          "hh:mm A"
                        )}`}
                      </b>
                    </h2>
                  </div>
                  <ReactSVG src={calendarSimple} />
                </div>
              )
          )}

          <Modal
          centered
            width={mobileResponsive ? "90%" : "70%"}
            footer={false}
            open={isModalOpen}
            onCancel={handleCancel}
            onOk={handleOk}
          >
            <FollowUpPayment
              handleOk={handleOk}
              singleFollowUp={singleFollowUp}
              bookingDetails={booking}
              getAllFollowUps={getAllFollowUps}
            />
          </Modal>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ConsultationFollow;
