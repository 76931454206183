import { Col, Divider, Empty, Row } from "antd";
import { useState } from "react";
import "./socialHistory.scss";
import moment from "moment";
import { CollapseArrow } from "../../../assets";
import SingleQuestion from "../../SingleQuestion";

const ProfileSocialHistory = ({ completeUser }) => {
  const [collapseOpenNumber, setCollapseOpenNumber] = useState(true);

  const allDetails = completeUser?.past_social_history || null;
  // .slice() // create a shallow copy to avoid mutating original array
  // .sort((a, b) => new Date(b?.date) - new Date(a?.date));

  const toggleCollapse = () => {
    setCollapseOpenNumber(!collapseOpenNumber);
  };

  return (
    <Row className="profileSocialHistory">
      <Col
        onClick={() => toggleCollapse()}
        className="profileSocialHistoryHeader"
        span={24}
      >
        <h2>Social history</h2>
        <img
          className={collapseOpenNumber ? "active" : ""}
          src={CollapseArrow}
          alt="$$$"
        />
      </Col>

      <Col
        onClick={() => toggleCollapse()}
        span={24}
        className={
          collapseOpenNumber ? "socialHistoryBox open" : "socialHistoryBox"
        }
      >
        {collapseOpenNumber ? (
          allDetails?.detail?.length > 0 ? (
            allDetails?.detail?.map((subItem, subIndex) => (
              <Col key={subIndex} span={24}>
                <SingleQuestion
                  key={subIndex}
                  question={subItem}
                  number={subIndex}
                />
                <Divider style={{margin:"10px 0px !important"}} />
                </Col>
            ))
          ) : (
            <Empty style={{ marginTop: "10px" }} />
          )
        ) : null}
      </Col>
    </Row>
  );
};

export default ProfileSocialHistory;
