import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from "react-router-dom";
import ScrollTop from "./Components/ScrollTop/ScrollTop.jsx";
import store from "./Redux/store.js";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./Styles/index.scss";
import App from './App.jsx'




if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Service worker registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.error("Service worker registration failed, error:", err);
    });
}


ReactDOM.createRoot(document.getElementById('root')).render(
  <Router>
    <ScrollTop />
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
)
