import { Col, Row } from "antd";
import "./aboutOhana.scss";
// import ThirdButton from "../UI/ThirdButton/ThirdButton";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
export const AboutOhana = () => {


  const websiteContent = useSelector(
    (state) => state.generalReducer.websiteContent
  );

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });
  return (
    <div className="aboutOhanaWrapper">
      <div className="landingPageContainer">
        <Row style={{width:"100%"}} gutter={mobileResponsive ? [0,10] : 50}>
          <Col span={ mobileResponsive?24 : 12}>
            <video className="video" autoPlay controls loop muted>
              {/* <source src="https://ohanadoc.s3.amazonaws.com/videos/video.mp4" type="video/mp4" /> */}
              <source src="https://ohanadoc.s3.amazonaws.com/videos/Ohana+Doc+3Rd+Dec+2023.mp4" type="video/mp4" />
              
            </video>
          </Col>
          <Col span={ mobileResponsive?24 : 12}>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}} >
              <h4>{websiteContent?.about?.heading || ""}</h4>
              <h2>
              {websiteContent?.about?.headings?.heading1 || ""}
                <span style={{marginLeft:"7px"}}>
                {websiteContent?.about?.headings?.heading2 || ""} <br /> {websiteContent?.about?.headings?.heading3 || ""}
                </span>{" "}
                {websiteContent?.about?.headings?.heading4 || ""}
              </h2>
              <p>
              {websiteContent?.about?.text || ""}
              </p>
              {/* <div style={{marginTop:"10px"}} >
              <ThirdButton status="active">{websiteContent?.about?.buttonText || ""}</ThirdButton>
              </div> */}
            </div>
            {/* <h3>Mission statement</h3>
            <p>
              OhanaDoc is dedicated to pioneering accessible and compassionate
              healthcare for the diverse communities of Hawaii through our
              innovative telemedicine platform. Our mission is to bridge the gap
              between patients and healthcare providers, ensuring convenient,
              high-quality, and personalized virtual medical services.
            </p>
            <Row gutter={20}>
              <Col lg={12}>
                <div className='aboutIcons'>
                  <div className='icon'>
                    <img src={VideoVector} alt='video icon' />
                  </div>
                  <h3>Video consultations</h3>
                </div>
                <div className='aboutIcons'>
                  <div className='icon'>
                    <img src={MessageVector} alt='video icon' />
                  </div>
                  <h3>Message-based Consultations</h3>
                </div>
              </Col>
              <Col lg={12}>
                <div className='aboutIcons'>
                  <div className='icon'>
                    <img src={MedicalBag} alt='video icon' />
                  </div>
                  <h3>specialized treatments</h3>
                </div>
              </Col>
            </Row>
            <div className='aboutReviews'>
              <Row gutter={50}>
                <Col lg={12}>
                  <Divider orientation='center' />
                  <h3>5000+</h3>
                  <p>Happy Patients</p>

                  <Divider orientation='center' />
                  <h3>700+</h3>
                  <p>Expert Doctors</p>
                </Col>
                <Col lg={12}>
                  <Divider orientation='center' />
                  <h3>200+</h3>

                  <p>Hospitals</p>

                  <Divider orientation='center' />
                  <h3>1000+</h3>
                  <p>Laboratories</p>
                </Col>
              </Row>
            </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};
