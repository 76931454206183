import {
  Col,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Table
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from '../../service/ApisFunctions';
import { pharmacyType } from '../../Utils/constant';
import * as mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { setCompleteUser } from '../../Redux/Reducers/gernalSlice';
import { setIsProfileFilled } from '../../Redux/Reducers/authSlice';
const Pharmacy = () => {
  let debounceTimeout;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isSearched, setisSearched] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageResult, setPageResult] = useState(null);
  const [searchedPharmacy, setSearchedPharmacy] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [allState, setAllState] = useState([]);

  const [pharmacyLoading, setPharmacyLoading] = useState(false);
  const completeUser = useSelector(state => state.generalReducer.completeUser);
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });
  const deletePatientPharmacy = async value => {
    if (completeUser?.pharmacy?.PharmacyId) {
      setPharmacyLoading(true);

      const onSuccess = () => {
        handleSubmit(value);
      };
      const onError = err => {
        if (err?.message?.Message) {
          const modelState = err.message.ModelState;
          for (const key in modelState) {
            message.error(`${key}: ${modelState[key]}`);
          }
        } else {
          message.error(err.message);
        }
        setPharmacyLoading(false);
      };

      const route = `pharmacy/delete-patient-pharamacy?patientId=${completeUser?.doseSpotId}&pharmacyId=${completeUser?.pharmacy?.PharmacyId}`;
      await deleteRequest('', route, true, onSuccess, onError);
    } else {
      handleSubmit(value);
    }
  };
  const handleSubmit = async value => {
    const dataToSend = {
      PharmacyId: value?.PharmacyId,
      StoreName: value?.StoreName,
      ZipCode: value?.ZipCode,
      State: value?.State,
      Address1: value?.Address1,
      Address2: value?.Address2,
      City: value?.City,
      Latitude: value?.Latitude,
      Longitude: value?.Longitude,
      ...(value?.PharmacySpecialties?.length && {
        pharmacyType: value?.PharmacySpecialties
      })
    };

    setPharmacyLoading(true);
    const onSuccess = res => {
      message.success(res?.message);
      setSearchedPharmacy([]);
      setisSearched(false);
      setFetching(false);
      dispatch(setCompleteUser({ ...completeUser, pharmacy: dataToSend }));
      setPharmacyLoading(false);
      if (completeUser?.height?.value && completeUser?.sexualOrientation) {
        UpdateUser();
      }
      form.setFieldsValue({
        pharmacy: '',
        pharmacyType: null,
        zipCode: '',
        city: '',
        state: null,
        distance: '10'
      });
    };
    const onError = err => {
      setSearchedPharmacy([]);
      setisSearched(false);
      setFetching(false);
      setPharmacyLoading(false);
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
    };

    const route = `pharmacy/add-patient-pharmacy-self`;
    await postRequest(
      {
        pharmacy: dataToSend
      },
      route,
      true,
      onSuccess,
      onError
    );
  };
  const UpdateUser = async e => {
    const onSuccess = res => {
      setPharmacyLoading(false);
      dispatch(setCompleteUser(res?.data));
      dispatch(setIsProfileFilled({ personalInfo: false }));
    };
    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setPharmacyLoading(false);
    };

    const formdata = {
      isProfileFilled: true
    };
    await putRequest(
      formdata,
      'customer/edit_customer',
      true,
      onSuccess,
      onError
    );
  };
  const searchPharmacy = async (changedFields, allFields) => {
    const formFields = form.getFieldsValue();
    setisSearched(true);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(async () => {
      if (
        (formFields?.pharmacy && formFields?.pharmacy.length >= 3) ||
        (formFields?.zipCode && formFields?.zipCode.length >= 3) ||
        (formFields?.city && formFields?.city.length >= 3) ||
        formFields?.pharmacyType ||
        formFields?.state
      ) {
        setPharmacyLoading(true);
        const onSuccess = res => {
          setSearchedPharmacy(res?.data?.Items);
          setPageResult(res?.data?.PageResult);
          if (!currentPage) {
            setCurrentPage(1);
          }
          setPharmacyLoading(false);
          setFetching(true);
        };

        const onError = err => {
          setSearchedPharmacy([]);

          setPharmacyLoading(false);
        };
        await getRequest(
          '',
          `pharmacy/search-pharmacy?name=${
            formFields?.pharmacy ? formFields?.pharmacy : ''
          }&city=${formFields?.city ? formFields?.city : ''}&state=${
            formFields?.state?.value ? formFields?.state?.value : ''
          }&zip=${formFields?.zipCode ? formFields?.zipCode : ''}&specialty=${
            formFields?.pharmacyType?.value
              ? formFields?.pharmacyType?.value
              : ''
          }&pageNumber=${currentPage || 1}`,
          true,
          onSuccess,
          onError
        );
      }
    }, 800);
  };
  const getAllSatates = async () => {
    setPharmacyLoading(true);
    const onSuccess = res => {
      setAllState(res?.data);
      setPharmacyLoading(false);
    };

    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setPharmacyLoading(false);
    };

    await getRequest(
      '',
      'state/active_state_listing',
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getAllSatates();
    form.setFieldsValue({
      distance: '10'
    });
  }, []);
  useEffect(() => {
    if (currentPage) {
      searchPharmacy();
    }
  }, [currentPage]);
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 9,
      accessToken:
        'pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ'
    });
    const markerCoordinates = [];
    const defaultHawaiiCoordinates = [-157.8583, 21.3069];
    const addMarker = (coord, isUserLocation = false) => {
      const markerCoord = [coord?.Longitude, coord?.Latitude];
      markerCoordinates?.push(markerCoord);
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      }).setHTML(
        `<h3 style={{wordBreak:"break-all"}} >${coord?.StoreName}</h3><p style={{wordBreak:"break-all"}}><strong style={{wordBreak:"break-all"}} >Address:</strong> ${coord?.Address1}, ${coord?.City}, ${coord?.State}, ${coord?.ZipCode}</p>`
      );
      const marker = new mapboxgl.Marker({
        color: isUserLocation ? 'red' : ''
      })
        .setLngLat(markerCoord)
        .setPopup(popup)
        .addTo(map);
      marker.getElement().style.cursor = 'pointer';
      marker
        .getElement()
        .addEventListener('mouseenter', () => marker.togglePopup());
      marker
        .getElement()
        .addEventListener('mouseleave', () => marker.togglePopup());
    };

    if (searchedPharmacy && searchedPharmacy.length > 0) {
      searchedPharmacy?.forEach(coord => {
        if (!isNaN(coord?.Longitude) && !isNaN(coord?.Latitude)) {
          addMarker(coord);
        }
      });
    } else if (
      completeUser?.pharmacy?.Longitude &&
      completeUser?.pharmacy?.Latitude &&
      !isSearched
    ) {
      addMarker(completeUser?.pharmacy, true);
    }
    // Fit the map bounds to the marker coordinates
    if (markerCoordinates.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();
      markerCoordinates?.forEach(coord => bounds.extend(coord));
      map.fitBounds(bounds, { padding: 50, maxZoom: 15 });
    } else {
      map.setCenter(defaultHawaiiCoordinates);
      map.setZoom(6);
    }

    return () => {
      map.remove();
    };
  }, [searchedPharmacy, completeUser]);

  const columns = [
    {
      key: '1',
      title: 'Name',
      render: (_, object) => object?.StoreName || '-',
      width: '18.56%'
    },
    {
      key: '3',
      title: 'Street Address',
      width: '18.56%',
      render: (_, object) => object?.Address1 || '-'
    },
    {
      key: '2',
      title: 'City',
      render: (_, object) => object?.City || '-',
      width: '14.28%'
    },
    {
      key: '4',
      title: 'State Province',
      render: (_, object) => object?.State || '-',
      width: '10%'
    },
    {
      key: '4',
      title: 'Zip Code',
      render: (_, object) => object?.ZipCode || '-',
      width: '10%'
    },
    {
      key: '4',
      title: 'Pharmacy Type',
      render: (_, object) =>
        object?.PharmacySpecialties?.map(item => item + ' | ') || '-',
      width: '14.28%'
    },
    {
      key: '6',
      title: 'Action',
      align: 'center',
      render: (_, object) => (
        <div>
          <p
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
            onClick={() => deletePatientPharmacy(object)}
            type='primary'
          >
            Select
          </p>
        </div>
      ),
      width: '14.28%'
    }
  ];
  return (
    <Spin spinning={pharmacyLoading}>
      <Form onFieldsChange={searchPharmacy} form={form} layout='vertical'>
        <Row gutter={10} className='allergies'>
          <Col className='allergiesHeader' span={24}>
            <h2 id='pharmacy'>
              Pharmacy (
              <span style={{ color: '#FF0000', fontWeight: 700 }}>
                {' '}
                {completeUser?.pharmacy?.StoreName
                  ? completeUser?.pharmacy?.StoreName
                  : ''}
              </span>
              )
            </h2>
          </Col>
          <Col style={{ marginTop: '20px' }} span={mobileResponsive ? 24 : 14}>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name='pharmacy'
                  label='Pharmacy'
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please select your pharmacy'
                  //   }
                  // ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Enter pharmacy name'
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='pharmacyType' label='Pharmacy Type'>
                  <Select
                    labelInValue
                    allowClear
                    className='ant-select-selector'
                    showSearch
                    placeholder='Search Pharmacy type'
                    style={{ width: '100%' }}
                  >
                    {pharmacyType?.map((item, index) => (
                      <Select.Option key={index} value={item?.id}>
                        {item?.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='state' label='State Province'>
                  <Select
                    labelInValue
                    className='ant-select-selector'
                    showSearch
                    placeholder='Select state province'
                    style={{ width: '100%' }}
                    allowClear
                  >
                    {allState?.map((item, index) => (
                      <Select.Option key={index} value={item?.state}>
                        {item?.state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='zipCode' label='Zip Postal Code'>
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Enter your Zip Code'
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='city' label='City'>
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Enter your city'
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name='distance' label='Distance'>
                  <Input
                    disabled
                    className='ant-input-affix-wrapper'
                    placeholder='Enter distance'
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col style={{ marginTop: '20px' }} span={mobileResponsive ? 24 : 10}>
            <div
              id='map'
              style={{
                width: '100%',
                height: mobileResponsive ? '200px' : '100%',
                marginBottom: '20px',
                borderRadius: '20px'
              }}
            />
          </Col>

          {fetching ? (
            searchedPharmacy?.length > 0 ? (
              <>
                <Divider />
                <Col className='allergiesHeader' span={24}>
                  <h2>Search Results</h2>
                </Col>
                <Col style={{ marginTop: '20px' }} span={24}>
                  <div className='MainTable'>
                    <Table
                      className='antd-custom-table'
                      pagination={{
                        showSizeChanger: false,
                        pageSize: pageResult?.PageSize,
                        total: pageResult?.TotalCount,
                        onChange(page) {
                          setCurrentPage(page);
                        }
                      }}
                      columns={columns}
                      dataSource={searchedPharmacy}
                      scroll={mobileResponsive ? { x: 1300, y: 660 } : {}}
                      size='small'
                    />
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Divider />
                <Col className='allergiesHeader' span={24}>
                  <h2>Search Results</h2>
                </Col>

                <Col style={{ marginTop: '20px' }} span={24}>
                  <Empty />
                </Col>
              </>
            )
          ) : (
            ''
          )}
        </Row>
      </Form>
    </Spin>
  );
};

export default Pharmacy;
