import { useState } from "react";
import classes from "./Review.module.scss";
import { ConfigProvider, Form, Input, Rate, Spin, message } from "antd";
import { putRequest } from "../../service/ApisFunctions";
import SecondaryButton from "../../Components/UI/SecondaryButton/SecondaryButton";

const Review = ({
  bookingId,
  setIsReviewModal,
  getAllBookings,
  isTable = false,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      if (isTable) {
        setIsReviewModal(false);
        getAllBookings(1, 10);
      } else {
        window.location.pathname = "/consultations";
      }
    };

    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    const formDataa = {
      status: "completed",
      rating: values?.rating,
      review: values?.review || "",
    };
    await putRequest(
      formDataa,
      `booking/update_booking_status/${bookingId}`,
      true,
      onSuccess,
      onError
    );
  };
  const handleSkipReviewSubmit = async (e) => {
    
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      if (isTable) {
        setIsReviewModal(false);
        getAllBookings(1, 10);
      } else {
        window.location.pathname = "/consultations";
      }
    };

    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    const formDataa = {
      status: "completed",
    };
    await putRequest(
      formDataa,
      `booking/update_booking_status/${bookingId}`,
      true,
      onSuccess,
      onError
    );
  };

  return (
    <Spin spinning={loading}>
      <div className={classes.container}>
        <Form layout="vertical" className="fields" onFinish={handleSubmit}>
          <h1>Review Your Experience</h1>
          <Form.Item
            name="rating"
            label="Rating"
            rules={[
              {
                required: true,
                message: "Please give Rating",
              },
            ]}
          >
            <Rate className="customReviewRatingStars" />
          </Form.Item>
          <Form.Item
            name="review"
            label="Review"
            rules={[
              {
                required: true,
                message: "Please give review",
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              className="noBgBtn"
              onClick={() => handleSkipReviewSubmit()}
            >
              Skip
            </button>

            <SecondaryButton className={classes.reviewButton} type="submit">
              Submit Review
            </SecondaryButton>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default Review;
