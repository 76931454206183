import React, { useState } from "react";
import "./consultationEHR.scss";
import { electronicHealthBtns } from "./constant";
import EHR from "./ConsDetails";
import NoteAndDx from "./Note&Dx/note&Dx";
import PharmacyAndRx from "./Pharmacy&Rx/pharmacy&Rx";
import ProgramInvoice from "./Invoice";

const ConsultationEHR = ({ booking, getConsultationById }) => {
  const [activeBtn, setActiveBtn] = useState("Cons details");

  const filteredBtns = electronicHealthBtns.filter((item) => {
    if (booking?.booking_type === "video") {
      return item.name !== "Invoice";
    }
    return true;
  });

  return (
    <div className="ConsultationEHR">
      <h2 className="electronic-health">
        {activeBtn === "Cons details" ? "Consultation Details" : activeBtn}
      </h2>
      <div className="electronic-health-btns">
        {filteredBtns.map((item, index) =>
          booking?.booking_type === "program" && item?.name === "Invoice" ? (
            <button
              onClick={() => setActiveBtn(item?.name)}
              key={index}
              className={
                item?.name === activeBtn
                  ? "primaryBtn activePrimaryBtn"
                  : "primaryBtn"
              }
            >
              {item?.name}
            </button>
          ) : (
            <button
              onClick={() => setActiveBtn(item?.name)}
              key={index}
              className={
                item?.name === activeBtn
                  ? "primaryBtn activePrimaryBtn"
                  : "primaryBtn"
              }
            >
              {item?.name}
            </button>
          )
        )}
      </div>

      {activeBtn === "Invoice" && (
        <ProgramInvoice
          getConsultationById={getConsultationById}
          booking={booking}
        />
      )}
      {activeBtn === "Cons details" && <EHR booking={booking} />}
      {activeBtn === "Notes & Dx" && <NoteAndDx booking={booking} />}
      {activeBtn === "Pharmacy & Rx" && <PharmacyAndRx booking={booking} />}
    </div>
  );
};

export default ConsultationEHR;
