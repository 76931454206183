import React from "react";
import "./healthInformation.scss"

const HealthInformation = ({ question, answer }) => {
  return (
    <div className="informationCard">
      <h3>{question}</h3>
      <p>{answer}</p>
    </div>
  );
};

export default HealthInformation;
