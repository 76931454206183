import './heroSection.scss';
import { Col, Row } from 'antd';
import Card from './card';
import { useMediaQuery } from 'react-responsive';
import Services from './services';
import { useDispatch, useSelector } from 'react-redux';
import {
  BannerMentalHealthVector,
  BannerRefillVector,
  BannerUrgentCareVector,
  BannerWeightLossVector,
  LandingHeroBanner,
  ScheduleConsultationIcon,
  UrgentConsultationIcon
} from '../../assets';
import { setHeaderNavigation } from '../../Redux/Reducers/gernalSlice';
import { getRequest } from '../../service/ApisFunctions';
import { useEffect, useState } from 'react';
import {
  setBookableFlowCondition,
  setBookableFlowProgram,
  setBookableFlowSpeciality,
  setBookingFlowPrice,
  setBookingFlowType
} from '../../Redux/Reducers/bookingSlice';
import { useNavigate } from 'react-router-dom';
import {
  setbookingType,
  setSpeacilityDetails,
  setSpeacilityImage
} from '../../Redux/Reducers/authSlice';
export const HeroSection = () => {
  const navigate = useNavigate();
  const headerNavigation = useSelector(
    state => state.generalReducer.headerNavigation
  );
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const websiteContent = useSelector(
    state => state.generalReducer.websiteContent
  );
  const handleSpeciltyClick = item => {
    dispatch(setBookableFlowProgram(null));

    dispatch(setBookableFlowSpeciality(item?.speciality));
    dispatch(setBookableFlowCondition(null));
    dispatch(setBookingFlowType(item?.service?.flow));

    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          (item?.specialilty?.price > 0 && item?.specialilty?.price) ||
          item?.specialilty?.categories?.[0]?.price
      )
    );
    navigate(`/speciality-booking-details/${item?.speciality?._id}`);
  };
  const getConditionById = item => {
    dispatch(setBookableFlowProgram(null));

    dispatch(setbookingType(item?.service?.flow));
    dispatch(setBookableFlowSpeciality(item?.specialilty));
    dispatch(setBookableFlowCondition(item?.specialilty?.categories?.[0]));
    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          (item?.specialilty?.price > 0 && item?.specialilty?.price) ||
          item?.specialilty?.categories?.[0]?.price
      )
    );
    dispatch(setSpeacilityDetails(item?.specialilty?.categories?.[0]));
    dispatch(setBookingFlowType(item?.service?.flow));
    dispatch(setSpeacilityImage(item?.specialilty?.image));

    navigate(`/booking-details?type=${item?.name}`);
  };

  const getProgramById = item => {
    dispatch(setBookableFlowSpeciality(null));
    dispatch(setBookableFlowCondition(null));
    dispatch(setSpeacilityDetails(item?.program));
    dispatch(setSpeacilityImage(item?.program?.image));
    dispatch(setbookingType(item?.service?.flow));
    dispatch(setBookableFlowProgram(item?.program));
    dispatch(setBookingFlowType(item?.service?.flow));
    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          item?.program?.price
      )
    );
    navigate(`/treatment-consultations-details?type=${item?.name}`);
  };

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const CardData = [
    {
      icon: UrgentConsultationIcon,
      heading: websiteContent?.banner?.card1?.heading || '',
      cardDescription: websiteContent?.banner?.card1?.text,
      cardButton: websiteContent?.banner?.card1?.buttonText,
      type: 'secondry'
    }
    // {
    //   icon: ScheduleConsultationIcon,
    //   heading: websiteContent?.banner?.card2?.heading || '',
    //   cardDescription: websiteContent?.banner?.card2?.text,
    //   cardButton: websiteContent?.banner?.card2?.buttonText,
    //   type: 'primary'
    // }
  ];
  const getAllNavigations = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setisLoading(false);

      dispatch(setHeaderNavigation(res.data));
    };
    const onError = err => {
      setisLoading(false);

      // message.error(err.message);
    };

    await getRequest(
      '',
      'navigation/get-navigations',
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getAllNavigations();
  }, []);
  return (
    <div className='bannerWrapper'>
      {/* <div style={{display:"flex"}} className="landingPageContainer"> */}
      <Row
        gutter={mobileResponsive ? [0, 20] : 10}
        style={{ height: 'auto', width: '100%' }}
      >
        <Col span={mobileResponsive ? 24 : 12}>
          <div className='bannerHeads'>
            <h4>
              {websiteContent?.banner?.headings?.heading1}
              <br />
              <span className='secondaryColorText'>
                {websiteContent?.banner?.headings?.heading2}
              </span>
              <br />
              {websiteContent?.banner?.headings?.heading3}
            </h4>
            <h6>{websiteContent?.banner?.subheading}</h6>
          </div>
        </Col>
        <Col className='right-side' span={mobileResponsive ? 24 : 12}>
          <div className='landingBannerImg'>
            <img src={LandingHeroBanner} alt='Banner' />
          </div>
          {/* <LandingHeroBanner/> */}
          {/* <Row
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            gutter={mobileResponsive ? [0, 20] : 30}
          >
            {CardData?.map((card, index) => (
              <Col
                style={{ display: 'flex', justifyContent: 'center' }}
                key={index}
                span={mobileResponsive ? 24 : 14}
              >
                <Card card={card} number={index} />
              </Col>
            ))}
          </Row> */}
        </Col>
        <Col span={24} className='ohanaServices'>
          <Row gutter={[20, 20]}>
            {headerNavigation?.map(navigation => {
              if (
                navigation?.name === 'Mental Health' ||
                navigation?.name === 'Primary Care' ||
                navigation?.name === 'Weight Loss'
              ) {
                return (
                  <Col lg={6} md={6} sm={12} xs={24} key={navigation.name}>
                    <div
                      className='singleServiceBox'
                      onClick={() => {
                        if (navigation?.type === 'specility') {
                          handleSpeciltyClick(navigation);
                        } else if (navigation?.type === 'program') {
                          getProgramById(navigation);
                        } else if (navigation?.type === 'condition') {
                          getConditionById(navigation);
                        }
                      }}
                    >
                      <div className='boxImg'>
                        <img
                          src={
                            navigation?.name === 'Mental Health'
                              ? BannerMentalHealthVector
                              : navigation?.name === 'Primary Care'
                              ? BannerUrgentCareVector
                              : navigation?.name === 'Weight Loss'
                              ? BannerWeightLossVector
                              : null
                          }
                          alt={navigation?.name}
                        />
                      </div>
                      <div className='boxContent'>
                        <h5>{navigation?.name}</h5>
                        <p>
                          {navigation?.name === 'Mental Health'
                            ? 'Support and treatment for mental well-being'
                            : navigation?.name === 'Primary Care'
                            ? 'Immediate medical attention'
                            : navigation?.name === 'Weight Loss'
                            ? 'To achieve healthy body mass'
                            : ''}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              }
              return null;
            })}

            <Col lg={6} md={6} sm={12} xs={24}>
              <div
                className='singleServiceBox'
                onClick={() => navigate('/refill-request')}
              >
                <div className='boxImg'>
                  <img src={BannerRefillVector} alt='' />
                </div>
                <div className='boxContent'>
                  <h5>Refill Request</h5>
                  <p>Medication refill service available.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        {/* Service Section */}

        {/* <Col span={24}>
          <Services />
        </Col> */}
      </Row>
      {/* </div> */}
    </div>
  );
};
