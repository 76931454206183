import React from "react";
import "./consultationMessage.scss";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { arrowBack, messageLines } from "../../assets";

const ConsultationMessage = ({ booking }) => {
  const navigate = useNavigate();

  const gotoChat = () => {
    booking?.urgent_care
      ? navigate(`/urgent-chat/${booking?._id}`)
      : navigate(`/messages?messageId=${booking?.chat_id}`);
  };

  return (
    <div onClick={() => gotoChat()} className="DetailsMessage">
      <div className="DetailsMessageLeft">
        <h2>Message Dr.</h2>
        <ReactSVG src={arrowBack} />
      </div>
      <img src={messageLines} />
    </div>
  );
};

export default ConsultationMessage;
