import {
  Col,
  Dropdown,
  Empty,
  Form,
  Modal,
  Row,
  Space,
  Spin,
  message
} from 'antd';
import React, { useEffect, useState } from 'react';
import AllergiesTable from '../../../ConsultationDetail/EHR/ConsDetails/allergiesTable';
import { getRequest } from '../../../../service/ApisFunctions';
import { useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import FreetextAllergies from '../../../ProfileDetails/Allergies&Pharmacy&Lab/FreetextAllergies';
import SearchAllergies from '../../../ProfileDetails/Allergies&Pharmacy&Lab/SearchAllergies';
import dayjs from 'dayjs';
import UpdateAllergies from '../../../ProfileDetails/Allergies&Pharmacy&Lab/updateAllergies';
import SkeletonInput from '../../../UI/Skeleton/skeletonInput';

export const ReviewAllergies = () => {
  const completeUser = useSelector(state => state.generalReducer.completeUser);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addAllergieType, setAddAllergieType] = useState('');
  const [allergies, setAllergies] = useState([]);
  const [singleAllergieDetails, setSingleAllergieDetails] = useState(null);

  const [allergiesLoading, setAllergiesLoading] = useState(false);

  const [form] = Form.useForm();

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const items = [
    {
      key: '1',
      label: (
        <a
          onClick={() => {
            showModal();
            setAddAllergieType('Search');
          }}
        >
          Search Database
        </a>
      )
    },
    {
      key: '2',
      label: (
        <a
          onClick={() => {
            showModal();
            setAddAllergieType('Freetext');
          }}
        >
          Free Text
        </a>
      )
    }
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setAddAllergieType('');
    setSingleAllergieDetails(null);
    form.resetFields();
  };

  const getAllAllergies = async () => {
    setAllergiesLoading(true);

    const onSuccess = res => {
      setAllergies(res?.data?.Items?.reverse());

      setAllergiesLoading(false);
    };
    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setAllergiesLoading(false);
    };

    const route = `allergy/retrieve-allergy-details/${completeUser?.doseSpotId}`;
    await getRequest('', route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (completeUser?.doseSpotId) {
      getAllAllergies();
    }
  }, [completeUser]);

  const getAllergieById = object => {
    setSingleAllergieDetails(object);
    showModal();
  };

  const ModalTitle = singleAllergieDetails?.PatientAllergyId
    ? `Update allergy`
    : addAllergieType
    ? addAllergieType === 'Freetext'
      ? 'Add Freetext allergy'
      : 'Add allergy'
    : 'Update Health Equity Information';

  useEffect(() => {
    if (singleAllergieDetails?.PatientAllergyId) {
      form.setFieldsValue({
        allergyName: singleAllergieDetails?.DisplayName || null,
        Reaction: singleAllergieDetails?.Reaction || null,
        ReactionType: singleAllergieDetails?.ReactionType || null,
        StatusType: singleAllergieDetails?.StatusType || null,
        OnsetDate: singleAllergieDetails?.OnsetDate
          ? dayjs(singleAllergieDetails?.OnsetDate)
          : null
      });
    }
  }, [singleAllergieDetails, isModalVisible]);

  return (
    <div className='reviewAllergiesWrap'>
      <div className='customReviewComponentHeading'>
        <p>Allergies</p>

        <Space
          className='noBgBtn'
          onClick={() => {
            showModal();
            setAddAllergieType('Search');
          }}
        >
          Add Allergies
        </Space>

        {/* <button className='noBgBtn'>Change</button> */}
      </div>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          {allergiesLoading ? (
            <>
              <div>
                <SkeletonInput block={true} />
              </div>
              <div style={{ marginTop: '10px' }}>
                <SkeletonInput block={true} />
              </div>
            </>
          ) : allergies?.length > 0 ? (
            <AllergiesTable
              getAllergieById={getAllergieById}
              data={allergies}
              getAllAllergies={getAllAllergies}
              setAllergiesLoading={setAllergiesLoading}
            />
          ) : (
            <Empty />
          )}
        </Col>
      </Row>

      <Modal
      centered
        title={ModalTitle}
        width={mobileResponsive ? '90%' : '50%'}
        footer={false}
        open={isModalVisible}
        onCancel={handleOk}
        onOk={handleOk}
        className='ehr-action-modal'
      >
        <Spin spinning={allergiesLoading}>
          {singleAllergieDetails?.PatientAllergyId ? (
            <UpdateAllergies
              form={form}
              handleOk={handleOk}
              getAllAllergies={getAllAllergies}
              singleAllergieDetails={singleAllergieDetails}
              isModalVisible={isModalVisible}
            />
          ) : addAllergieType === 'Freetext' ? (
            <FreetextAllergies
              form={form}
              handleOk={handleOk}
              getAllAllergies={getAllAllergies}
            />
          ) : (
            <SearchAllergies
              form={form}
              handleOk={handleOk}
              getAllAllergies={getAllAllergies}
              setAddAllergieType={setAddAllergieType}
            />
          )}
        </Spin>
      </Modal>
    </div>
  );
};
