import React, { useState } from "react";
import "./login.scss";
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "antd";
import {
  ForgetPasswordVector,
  LoginVector,
  OtpVector,
  ResetPasswordVector,
  logo,
} from "../../../assets";
import LoginStep from "./Steps/LoginStep";
import OtpStep from "./Steps/OtpStep";
import ForgotPasswordStep from "./Steps/ForgotPasswordStep";
import ForgotPasswordOtpStep from "./Steps/ForgotPasswordOtpStep";
import ResetPasswordStep from "./Steps/ResetPasswordStep";
import { useNavigate } from "react-router-dom";

const LoginFrom = () => {
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const navigate = useNavigate();

  const location = window.location.pathname;

  //   const handleSubmit = async (data) => {
  //     setLoading(true);
  //     const onSuccess = (res) => {
  //       message.success(res?.message);
  //       dispatch(setIsProfileFilled({ personalInfo: true }));
  //       setLoading(false);
  //       navigate("/login");
  //     };

  //     const onError = (err) => {
  //       if (err?.message?.Message) {
  //         const modelState = err.message.ModelState;
  //         for (const key in modelState) {
  //           message.error(`${key}: ${modelState[key]}`);
  //         }
  //       } else {
  //         message.error(err.message);
  //       }
  //       setLoading(false);
  //     };

  //     await postRequest(data, CreatePatient, true, onSuccess, onError);
  //   };

  return (
    <div className="login">
      <Row>
        <Col span={mobileResponsive ? 24 : 12}>
          <div className="login-header">
            <img
              width={mobileResponsive ? "120px" : "152px"}
              height={mobileResponsive ? "30px" : "auto"}
              onClick={() => navigate("/")}
              src={logo}
              alt=" $$$"
            />
          </div>
        </Col>

        {!mobileResponsive && (
          <Col span={12}>
            <div style={{ background: "none" }} className="login-header"></div>
          </Col>
        )}
        {!mobileResponsive && (
          <Col span={12}>
            <div className="login-left-side">
              <img
                src={
                  (location === "/login" && LoginVector) ||
                  (location === "/otp" && OtpVector) ||
                  (location === "/forgot-otp" && OtpVector) ||
                  (location === "/forgot" && ForgetPasswordVector) ||
                  (location === "/reset" && ResetPasswordVector)
                }
                alt=""
              />
            </div>
          </Col>
        )}
        <Col span={mobileResponsive ? 24 : 12}>
          {(location === "/login" && <LoginStep />) ||
            (location === "/otp" && <OtpStep />) ||
            (location === "/forgot" && <ForgotPasswordStep />) ||
            (location === "/forgot-otp" && <ForgotPasswordOtpStep />) ||
            (location === "/reset" && <ResetPasswordStep />)}
        </Col>
      </Row>
    </div>
  );
};

export default LoginFrom;
