import React from 'react';
import classes from './IconFields.module.scss';

const IconFields = ({
  label,
  placeholder,
  icon,
  border,
  type,
  name,
  setMessage,
  message,
  onClick
}) => {
  return (
    <div className={classes.fieldLabel}>
      <label>{label}</label>
      <div className={border ? classes.borderField : classes.iconField}>
        <input
          onChange={e => setMessage(e?.target?.value)}
          placeholder={placeholder}
          className={classes.field}
          type={type}
          name={name}
          value={message}
        />
          <img onClick={()=>onClick()} style={{ cursor: 'pointer' }} src={icon} />
      </div>
    </div>
  );
};

export default IconFields;
