import React, { useEffect, useState } from "react";
import "./programMenu.scss";
import { Col, Row, message } from "antd";
import { getRequest } from "../../../../service/ApisFunctions";
import { useDispatch } from "react-redux";
import {
  setBookableFlowProgram,
  setBookableFlowSpeciality,
  setBookingFlowPrice,
  setBookingFlowType,
} from "../../../../Redux/Reducers/bookingSlice";
import SkeletonInput from "../../../UI/Skeleton/skeletonInput";
import {
  setSpeacilityDetails,
  setSpeacilityImage,
  setbookingType,
} from "../../../../Redux/Reducers/authSlice";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ProgramMenu = ({
  setOpenDrawer,
  service,
  setOpenPopoverIndex,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });


  const [speacilityLoading, setSpeacilityLoading] = useState(false);
  const [allPrograms, setAllPrograms] = useState(null);
  const [singleProgram, setSingleProgram] = useState(null);

  // get all active specialization listing

  const getPrograms = async () => {
    setSpeacilityLoading(true);
    const onSuccess = (res) => {
      setAllPrograms(res?.data);
      setSingleProgram(res?.data?.[0]);
      dispatch(setBookableFlowSpeciality(res?.data[0]));
      setSpeacilityLoading(false);
    };
    const onError = (err) => {
      if (err?.message) {
        message?.error(err?.message);
        setSpeacilityLoading(false);
      } else {
        message.error("Something Went Wrong");
      }
    };
    const route = `treatment_program/active_program_by_booking_type/${service?.id}`;
    await getRequest("", route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (service?.id) {
      getPrograms();
    }
  }, [service]);


  const selectProgram = (item) => {
    setSingleProgram(item);
    dispatch(setSpeacilityDetails(item));
    dispatch(setSpeacilityImage(item?.image));
    dispatch(setbookingType(service?.service?.flow));
    dispatch(setBookingFlowType(service?.service?.flow));
    dispatch(setBookableFlowProgram(item));
    dispatch(
      setBookingFlowPrice(
        (service?.service?.price > 0 && service?.service?.price) || item?.price
      )
    );
    setOpenPopoverIndex(null);
    setOpenDrawer(false)
    navigate(`/treatment-consultations-details`);
  };

  return (
    <Row gutter={[10, 10]} className="programMenu">
      {speacilityLoading
        ? [1, 2, 3, 4]?.map((item, index) => (
            <Col key={index} span={mobileResponsive ? 24 : 12}>
              <SkeletonInput block={true} />
            </Col>
          ))
        : allPrograms?.map((item, index) => (
            <Col span={mobileResponsive ? 24 : 12}>
              <div
                onClick={() => selectProgram(item)}
                key={index}
                className={`${"program-box"}  ${
                  item?._id === singleProgram?._id && "active"
                }}}`}
              >
                <div className="program-image">
                  <img src={item?.image} alt="$$$" />
                </div>
                <p>{item?.treatment_name || "-"}</p>
              </div>
            </Col>
          ))}
    </Row>
  );
};

export default ProgramMenu;
