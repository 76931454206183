import { Col, Row } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import "./singleQuestion.scss";

const SingleQuestion = ({ question, number }) => {
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  return (
    <>
      <div className="singleQuestion">
        <h3 className="question">
          {`Q${number + 1} :-`} {question?.name}
        </h3>

        {question?.CheckBoxAnswer?.length > 0 && (
          <Row style={{ marginTop: 5 }}>
            {question?.CheckBoxAnswer?.map((checkbox, index) => (
              <Col span={mobileResponsive ? 12 : 8}>
                <p className="answer">
                  {index + 1}: {checkbox?.name || ""}
                </p>
              </Col>
            ))}
          </Row>
        )}
        {question?.option?.name && (
          <Row style={{ marginTop: 5 }}>
            <Col span={mobileResponsive ? 12 : 8}>
              <p className="answer">{question?.option?.name || ""}</p>
            </Col>
          </Row>
        )}
        {question?.images?.length > 0 && (
          <Row style={{ marginTop: 5 }}>
            {question?.images?.map((image, index) => (
              <Col span={mobileResponsive ? 12 : 3}>
                <a href={image} rel="noreferrer" target="_blank">
                  <img
                    width="100px"
                    height="100px"
                    style={{
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    src={image}
                    alt="$$$"
                  />
                </a>
              </Col>
            ))}
          </Row>
        )}

        {question?.inputData && (
          <Row style={{ marginTop: 5 }}>
            <Col span={mobileResponsive ? 12 : 8}>
              <p className="answer">{question?.inputData || ""}</p>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default SingleQuestion;
