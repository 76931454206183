import { Col, Form, Input, Row, Select, Spin, Upload, message } from "antd";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import {
  getRequest,
  postRequest,
  postRequestFormData,
} from "../../service/ApisFunctions";
import { paperclip } from "../../assets";
import "./joinAsAProvider.scss";
import { useMediaQuery } from "react-responsive";
import {
  formatPhoneNumber,
  ValidateMaxLength,
  ValidateMaxLengthAndContent,
  validatePhoneNumber,
} from "../../Utils/helper";
import BlueButton from "../UI/BlueButton/BlueButton";
import BackButton from "../UI/BackButton/BackButton";

const JoinAsProvider = () => {
  const [form] = Form.useForm();

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const [isLoading, setisLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [data, setData] = useState([]);
  const [attachmentS3Urls, setAttachS3Urls] = useState([]);
  const [step, setStep] = useState(0);
  const [providerRequestData, setProvideRrequestData] = useState(null);

  const getAllSpeciality = async (e) => {
    setisLoading(true);
    const onSuccess = (res) => {
      setData(res?.data);
      setisLoading(false);
    };

    const onError = (err) => {
      setisLoading(false);
    };

    await getRequest(
      "",
      "speciality/active_speciality_listing",
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getAllSpeciality();
  }, []);

  const StepOnehandleSubmit = async (values) => {
    const formdata = {
      firstName: values.first_name,
      middleName: values.middle_name,
      lastName: values.last_name,
      email: values.email,
      phoneNumber: values?.phoneNumber,
    };

    setProvideRrequestData(formdata);
    setStep(1);
  };

  const StepTwohandleSubmit = async (values) => {
    if (values?.suggestedSpeciality || values?.speciality) {
      if (attachmentS3Urls?.length > 0) {
        setisLoading(true);
        const onSuccess = (res) => {
          setisLoading(false);
          message.success(res?.message);
          form.resetFields();
          setFileList([]);
          setAttachS3Urls([]);
          setStep(0);
        };

        const onError = (err) => {
          setisLoading(false);
          message.error(err?.message);
        };

        const formdata = {
          firstName: providerRequestData.firstName,
          middleName: providerRequestData.middleName,
          lastName: providerRequestData.lastName,
          email: providerRequestData.email,
          suggestedSpeciality: values.suggestedSpeciality,
          message: values.message,
          attachment: attachmentS3Urls,
          phoneNumber: providerRequestData?.phoneNumber,
          speciality: values.speciality,
        };

        await postRequest(
          formdata,
          "provider/provider-request",
          true,
          onSuccess,
          onError
        );
      } else {
        message?.warning("Please Upload Resume/CV");
      }
    } else {
      message.warning(
        "Please fill atleast one field like (Speciality or Suggested Speciality)"
      );
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      attachmentS3Urls.splice(index, 1);
      setFileList(newFileList);
      setAttachS3Urls(attachmentS3Urls);
    },
    beforeUpload: async (file) => {
      if (file) {
        setisLoading(true);
        const onSuccess = (res) => {
          setisLoading(false);
          setFileList([...fileList, file]);
          setAttachS3Urls([...attachmentS3Urls, res?.data]);
          message.success(res?.message);
        };

        const onError = (err) => {
          setisLoading(false);
        };

        const formData = {
          image: file,
        };

        await postRequestFormData(
          formData,
          "auth/upload_image_s3",
          true,
          onSuccess,
          onError
        );
      }

      return false;
    },
    fileList,
  };

  const handleChangePhoneNumberPattren = (e) => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);

    form.setFieldsValue({
      phoneNumber: formatted,
    });
  };

  return (
    <div className="joinAsAprovider">
      {step === 0 ? (
        <Form
          className="joinAsAprovider"
          onFinish={StepOnehandleSubmit}
          layout="vertical"
          form={form}
        >
          <Row style={{ width: "100%" }} gutter={mobileResponsive ? 0 : 20}>
            <Col span={mobileResponsive ? 24 : 8}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter first name" },
                  ValidateMaxLengthAndContent(35, "First Name"),
                ]}
              >
                <Input
                  className="ant-input-affix-wrapper"
                  placeholder="Please enter first name"
                />
              </Form.Item>
            </Col>
            <Col span={mobileResponsive ? 24 : 8}>
              <Form.Item
                name="middle_name"
                label="Middle Name"
                rules={[
                  { required: false },
                  ValidateMaxLengthAndContent(35, "Middle Name"),
                ]}
              >
                <Input
                  className="ant-input-affix-wrapper"
                  placeholder="Please enter middle name"
                />
              </Form.Item>
            </Col>
            <Col span={mobileResponsive ? 24 : 8}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter last name" },
                  ValidateMaxLengthAndContent(35, "Last name"),
                ]}
              >
                <Input
                  className="ant-input-affix-wrapper"
                  placeholder="Please enter last name"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Secure Email Address"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter email address",
                  },
                  ValidateMaxLength(80, "Email"),
                ]}
              >
                <Input
                  className="ant-input-affix-wrapper"
                  placeholder="Please enter email address"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  {
                    validator: validatePhoneNumber,
                  },
                ]}
              >
                <Input
                  onChange={handleChangePhoneNumberPattren}
                  className="ant-input-affix-wrapper"
                  placeholder="Please enter email address"
                />
              </Form.Item>
            </Col>
            <Col style={{ marginTop: "20px" }} span={24}>
              <BlueButton width="100%" type="submit">
                Next
              </BlueButton>
            </Col>
          </Row>
        </Form>
      ) : (
        <Spin spinning={isLoading}>
          <Form
            className="joinAsAprovider"
            onFinish={StepTwohandleSubmit}
            layout="vertical"
            form={form}
          >
            <Row gutter={mobileResponsive ? 10 : 20}>
              <Col span={12}>
                <Form.Item name="speciality" label="Speciality">
                  <Select
                    mode="multiple"
                    style={{ fontSize: "14px", fontWeight: "400" }}
                    className="ant-select-selector"
                    placeholder="Please select speciality"
                    showSearch // Enable search functionality
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {data?.map((item, index) => (
                      <Select.Option key={index} value={item?._id}>
                        {item?.speciality_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="suggestedSpeciality"
                  label="Suggested Speciality"
                  rules={[
                    {
                      required: false,
                    },
                    ValidateMaxLength(80, "Suggested Speciality"),
                  ]}
                >
                  <Input
                    className="ant-input-affix-wrapper"
                    placeholder="Please enter speciality"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Attach CV">
                  <Upload style={{ width: "100%" }} {...props}>
                    <div className="formInputs">
                      <div className={`${"fileField"}`}>
                        <label
                          className="fileLabel"
                          accept="image/png, image/jpeg, image/jpg, image/gif"
                          htmlFor="guardianID"
                        >
                          <p style={{ color: "lightgray" }}>{"Attachment"}</p>
                          <ReactSVG src={paperclip} />
                        </label>
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="message"
                  label="Tell us about why you like to join Ohana Doc"
                  rules={[
                    { required: true, message: "Please enter message" },
                    ValidateMaxLength(1000, "Message"),
                  ]}
                >
                  <Input.TextArea
                    rows={5}
                    className="ant-input-affix-wrapper"
                    placeholder="Please enter message"
                  />
                </Form.Item>
              </Col>

              <Col style={{ marginTop: "20px" }} span={6}>
                <BackButton
                  onClick={() => setStep(0)}
                  type="button"
                  width="100%"
                >
                  Back
                </BackButton>
              </Col>

              <Col style={{ marginTop: "20px" }} span={18}>
                <BlueButton width="100%" type="submit">
                  Submit
                </BlueButton>
              </Col>
            </Row>
          </Form>
        </Spin>
      )}
    </div>
  );
};

export default JoinAsProvider;
