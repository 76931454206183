import { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const Layouts = ({ children }) => {
  const [showMega, setShowMega] = useState(false);
  
  return (
    <>
      <Header showMega={showMega} setShowMega={setShowMega} />
      <main onClick={() => setShowMega(false)}>{children}</main>
      <Footer />
    </>
  );
};

export default Layouts;
