import { Table } from 'antd';

const PastconsultationHistory = ({ mobileResponsive }) => {
  const columns = [
    {
      key: '1',
      title: 'Drug name',
      render: (_, object) => object?.patient,
      width: '16.66%'
    },
    {
      key: '4',
      title: 'Dosage',
      render: (_, object) => (object?.gender ? object?.gender : '-'),
      width: '16.66%'
    },
    {
      key: '3',
      title: 'Currently taking',
      width: '16.66%',
      render: _ => '29'
    },
    {
      key: '5',
      title: 'Reported date',
      render: (_, object) => (object?.condition ? object?.condition : '-'),
      width: '16.66%'
    },
    {
      key: '6',
      title: 'Reported by',
      render: (_, object) =>
        object?.plan == 'Inactive' ? (
          object?.plan
        ) : (
          <p style={{ color: '#F76D00' }}>{object?.plan}</p>
        ),
      width: '16.66%'
    },
    {
      key: '6',
      title: 'Source',
      render: (_, object) =>
        object?.plan == 'Inactive' ? (
          object?.plan
        ) : (
          <p style={{ color: '#F76D00' }}>{object?.plan}</p>
        ),
      width: '16.66%'
    }
  ];

  return (
    <div className='MainTable'>
      <Table
        scroll={mobileResponsive ? { x: 1200, y: 660 } : {}}
        className='antd-custom-table'
        pagination={true}
        columns={columns}
        dataSource={data}
        size='small'
      />
    </div>
  );
};

const data = [
  {
    key: '1',
    patient: 'Alexis John',
    consultation: new Date(),
    doctor: 'John Doe',
    gender: 'Male',
    condition: 'Ureaplasma',
    plan: 'Active',
    payment: 'paid',
    status: 'Completed'
  },
  {
    key: '2',
    patient: 'Alexis John',
    consultation: new Date(),
    doctor: 'John Doe',
    gender: 'Male',
    condition: 'Ureaplasma',
    plan: 'Active',
    payment: 'paid',
    status: 'true'
  },
  {
    key: '3',
    id: '34567',
    patient: 'Alexis John',
    consultation: new Date(),
    doctor: 'John Doe',
    gender: 'Male',
    condition: 'Ureaplasma',
    plan: 'Inactive',
    payment: 'paid',
    status: 'Completed'
  },
  {
    key: '4',
    id: '34567',
    patient: 'Alexis John',
    consultation: new Date(),
    doctor: 'John Doe',
    gender: 'Male',
    condition: 'Ureaplasma',
    plan: 'Active',
    payment: 'pending',
    status: 'Completed'
  },
  {
    key: '5',
    id: '34567',
    patient: 'Alexis John',
    consultation: new Date(),
    doctor: 'John Doe',
    gender: 'Male',
    condition: 'Ureaplasma',
    plan: 'Active',
    payment: 'paid',
    status: <div style={{ color: '#EB5757' }}>Failed </div>
  },
  {
    key: '6',
    id: '34567',
    patient: 'Alexis John',
    consultation: new Date(),
    doctor: 'John Doe',
    gender: 'Male',
    condition: 'Ureaplasma',
    plan: 'Active',
    payment: 'paid',
    status: 'true'
  },
  {
    key: '7',
    id: '34567',
    patient: 'Alexis John',
    consultation: new Date(),
    doctor: 'John Doe',
    gender: 'Male',
    condition: 'Ureaplasma',
    plan: 'Active',
    payment: 'paid',
    status: 'Completed'
  },
  {
    key: '8',
    id: '34567',
    patient: 'Alexis John',
    consultation: new Date(),
    doctor: 'John Doe',
    gender: 'Male',
    condition: 'Ureaplasma',
    plan: 'Active',
    payment: 'paid',
    status: <div style={{ color: '#EB5757' }}>Failed </div>
  }
];

export default PastconsultationHistory;
