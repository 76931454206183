import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './Reducers/authSlice';
import { generalReducer } from './Reducers/gernalSlice';
import { bookingReducer } from './Reducers/bookingSlice';
import { refillReducer } from './Reducers/refillsSlice';

export default combineReducers({
  generalReducer: generalReducer,
  authReducer: authReducer,
  booking: bookingReducer,
  refills: refillReducer
});
