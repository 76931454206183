import { useState } from "react";
import classes from "./DropDowns.module.scss";
import SecondaryButton from "../../../UI/SecondaryButton/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { setCompleteUser } from "../../../../Redux/Reducers/gernalSlice";
import {
  setIsConsentForm,
  setIsProfileFilled,
  setSpeacilityDetails,
  setSpeacilityImage,
  setbookingType,
} from "../../../../Redux/Reducers/authSlice";
import {
  setBookableFlowCondition,
  setBookingFlowInformationSteps,
  setBookingFlowPrice,
} from "../../../../Redux/Reducers/bookingSlice";
import { chevronDown, chevronUp, fillDown, fillUp } from "../../../../assets";
import { Col, Empty, Row } from "antd";
import { useMediaQuery } from "react-responsive";

const DropDowns = ({ specilityObject, bookingId, bookingType }) => {
  const [active, setActive] = useState(0);

  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  const token = useSelector((state) => state.authReducer.paitentToken);

  const getStartedBtn = (el) => {
    const price =
      (bookingId?.price > 0 && bookingId?.price) ||
      (specilityObject?.price > 0 && specilityObject?.price) ||
      el.price;
    if (!token) {
      dispatch(setCompleteUser({}));
      dispatch(setSpeacilityDetails(el));
      dispatch(setSpeacilityImage(specilityObject?.image));
      dispatch(setBookableFlowCondition(el));
      dispatch(setBookingFlowPrice(price));
      dispatch(setbookingType(bookingType?.flow));
      dispatch(setBookingFlowInformationSteps(0));
      navigate("/consultation-booking");
      return; // Exit early if token is not available
    } else if (completeUser?._id && !completeUser?.isProfileFilled) {
      dispatch(setIsProfileFilled({ personalInfo: true }));
    } else if (completeUser?._id && !completeUser?.allowConsent) {
      dispatch(setIsConsentForm(true));
    } else {
      dispatch(setSpeacilityDetails(el));
      dispatch(setSpeacilityImage(specilityObject?.image));
      dispatch(setBookableFlowCondition(el));
      dispatch(setBookingFlowPrice(price));
      dispatch(setbookingType(bookingType?.flow));
      dispatch(setBookingFlowInformationSteps(0));
      navigate("/consultation-booking");
    }
  };

  const toggleDropDown = (value) => {
    if (value === active) {
      setActive(null);
      return;
    }
    setActive(value);
  };

  return (
    <>
      {specilityObject?.categories?.map((el, index) => {
        return (
          <div key={index} className={classes.container}>
            <div
              className={classes.title}
              onClick={() => toggleDropDown(index)}
            >
              <div>
                <h4
                  className={
                    active === index
                      ? classes.boldHeading
                      : classes.unboldHeading
                  }
                >
                  {el?.category_name}
                </h4>
              </div>
              {active === index ? (
                <ReactSVG src={fillUp} />
              ) : (
                <ReactSVG src={fillDown} />
              )}
            </div>
            {active === index ? (
              <div className={classes.description}>
                <div className={classes.header}>
                  <div>
                    <h3>{el?.category_name}</h3>
                    {/* <p>{el?.description}</p> */}
                    <SecondaryButton
                      className={classes.button}
                      children="Get Started"
                      onClick={() => {
                        getStartedBtn(el);
                      }}
                    />
                  </div>
                  <img
                    style={{ borderRadius: "10px" }}
                    width={160}
                    height={160}
                    src={specilityObject?.image}
                    alt="$$$"
                  />
                  {/* <ReactSVG src={el.img} /> */}
                </div>
                <div className={classes.body}>
                  <Row style={{ width: "100%" }} gutter={[15, 15]}>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <div className={classes.bodyContent}>
                        <h5>Overview</h5>
                        <p>{el?.overview || "--"}</p>
                      </div>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <div className={classes.bodyContent}>
                        <h5>Common Symptoms</h5>
                        <div
                          className={classes.customHtmlRenderingDiv}
                          dangerouslySetInnerHTML={{
                            __html: el?.common_symptoms
                              ? el?.common_symptoms
                              : "-",
                          }}
                        />
                      </div>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <div className={classes.bodyContent}>
                        <h5>OhanaDoc Treatment Options</h5>
                        <div
                          className={classes.customHtmlRenderingDiv}
                          dangerouslySetInnerHTML={{
                            __html: el?.common_symptoms
                              ? el?.common_symptoms
                              : "-",
                          }}
                        />
                      </div>
                    </Col>
                    <Col span={mobileResponsive ? 24 : 12}>
                      <div className={classes.bodyContent}>
                        <h5>Medications Offered at OhanaDoc</h5>
                        {el?.medicationsOffered?.medicationNames?.length > 0 ? (
                          el?.medicationsOffered?.medicationNames?.map(
                            (item, index) => (
                              <p key={index}>
                                {index + 1 + ". "}
                                {item?.medicationName || "--"}
                              </p>
                            )
                          )
                        ) : (
                          <Empty />
                        )}

                        <h5 style={{ marginTop: "20px" }}>Note</h5>

                        <p>{el?.medicationsOffered?.note || "--"}</p>
                      </div>
                    </Col>

                    <Col span={24}>
                      <div className={classes.bodyContent}>
                        <h5>
                          Why Choose OhanaDoc for {el?.category_name} Care ?
                        </h5>
                        <p>{el?.why || "--"}</p>

                        <h5 style={{ marginTop: "20px" }}>Remember</h5>
                        <p>{el?.remember || "--"}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <div className={classes?.getStarted}>
                  <SecondaryButton
                    className={classes.button}
                    children="Get Started"
                    onClick={() => {
                      getStartedBtn(el);
                    }}
                  />
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </>
  );
};

export default DropDowns;
