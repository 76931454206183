import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import classes from "./Schedule.module.scss";
import moment from "moment";
import { Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../../service/ApisFunctions";
import PrimaryButton from "../UI/PrimaryButton/PrimaryButton";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";

export const RescheduleConsultation = ({
  bookingDetails,
  isModalOpen,
  getBooking,
  apiUrl,
  schedule
}) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [availableTimeSlot, setAvailableTimeSlot] = useState([]);
  const [selected, setSelected] = useState(null);

  const getTimeSlots = async () => {
    if (date && bookingDetails?.provider_id?._id) {
      setLoading(true);
      const onSuccess = (res) => {
        setLoading(false);
        setAvailableTimeSlot(res.data);
      };
      const onError = (err) => {
        setLoading(false);
      };
      const route = `provider/get_timeslots_by_provider_id/${
        bookingDetails?.provider_id?._id
      }&${moment(date).format("YYYY-MM-DD")}`;

      await getRequest("", route, true, onSuccess, onError);
    }
  };
  const rescheduleRequest = async () => {
    if (date && bookingDetails?.provider_id?._id) {
      setLoading(true);
      const onSuccess = (res) => {
        setLoading(false);
        getBooking(1, 10);
        isModalOpen(false);
        navigate("/consultations");
        message.success(res.message);
      };

      const onError = (err) => {
        message?.error(err?.message);
        setLoading(false);
      };

      const formData = {
        provider_id: bookingDetails?.provider_id?._id,
        timeslot_id: selected?._id,
        ...(schedule && {schedule : schedule})
      };

      apiUrl
        ? await putRequest(
            formData,
            `${apiUrl}/${bookingDetails?._id}`,
            true,
            onSuccess,
            onError
          )
        : await postRequest(
            formData,
            `booking/reschedule-booking/${bookingDetails?._id}`,
            true,
            onSuccess,
            onError
          );
    }
  };
  useEffect(() => {
    if (bookingDetails?.provider_id?._id) {
      getTimeSlots();
    }
  }, [date]);
  return (
    <Spin spinning={loading}>
      <div className={classes.container}>
        <div>
          <Calendar
            onChange={(e) => {
              setDate(e);
            }}
            minDate={new Date()}
            value={date}
            maxDate={null}
            className={classes.calendar}
          />
        </div>
        <hr />
        <span className="bold">Available time slots</span>
        <div className={classes.calendarButtons}>
          {availableTimeSlot?.length ? (
            availableTimeSlot?.map((button, index) => {
              return (
                <PrimaryButton
                  key={index}
                  className={
                    button?._id == selected?._id
                      ? `${classes.button} ${classes.activeBtn}`
                      : classes.button
                  }
                  onClick={() => {
                    setSelected(button);
                  }}
                >
                  {`${moment(button?.start).format("hh:mm A")} - ${moment(
                    button?.end
                  ).format("hh:mm A")}`}
                </PrimaryButton>
              );
            })
          ) : (
            <div style={{ width: "100%" }}>
              <p style={{ textAlign: "center" }}>Slots not available</p>
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ width: "200px" }}>
            <SecondaryButton
              type="submit"
              width="100%"
              onClick={() => rescheduleRequest()}
            >
              {apiUrl ? "Schedule" : "Reschedule"}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </Spin>
  );
};
