import { useEffect, useRef } from "react";
import classes from "./Messages.module.scss";
import "./message.scss";
import { ReactSVG } from "react-svg";
import { Button, Col, Empty, Form, Input, Row, Spin, Upload } from "antd";
import moment from "moment";
import { CloseCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  DomyD,
  MessageFileBlack,
  MessageFileWhite,
  arrowLeft,
  magnifyingGlassSolid,
  paperclipLight,
  send,
} from "../../../assets";
import SearchField from "../../UI/SearchField/SearchField";
import { IMAGE_URL } from "../../../Utils/baseUrl";
import SecondaryButton from "../../UI/SecondaryButton/SecondaryButton";

const Messages = ({
  docterData,
  filteredMessages,
  patientDetial,
  sendMessage,
  setMessage,
  message,
  completeUser,
  onMessageSearch,
  props,
  fileToSend,
  ringLimit,
  setFileToSend,
  setMobileSelectedDoctor,
  chatLoading,
  imageLoading,
  getbookingById,
  style,
  requestPending,
  inputRef,
}) => {
  const myRef = useRef(null);
  const btnRef = useRef();

  const scrollToBottom = () => {
    myRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (filteredMessages?.length) {
      scrollToBottom();
    }
  }, [filteredMessages?.length]);

  if (fileToSend) {
    btnRef.current.focus();
  }

  return (
    <div style={{ ...style }} className={classes.messages}>
      <Spin spinning={chatLoading}>
        <div className={classes.docterDes}>
          <ReactSVG
            src={arrowLeft}
            onClick={() => setMobileSelectedDoctor({})}
            className={classes.leftArrow}
          />
          <img
            src={patientDetial?.provider?.profile_image || DomyD}
            alt="$$$"
          />
          <div>
            <h5>{`${patientDetial?.provider?.first_name || "-"} ${
              patientDetial?.provider?.last_name || "-"
            }`}</h5>
            <p>{docterData?.status}</p>
          </div>
        </div>
        <div className={classes.messagesHeader}>
          <h1>{requestPending ? "Request Pending" : "Messages"} </h1>
          <div className={classes.searchMsg}>
            {requestPending && (
              <Button onClick={() => getbookingById()} type="default">
                <ReloadOutlined />
              </Button>
            )}
            <SearchField
              onChange={onMessageSearch}
              icon={magnifyingGlassSolid}
              placeholder="Search Messages"
              background={true}
            />
          </div>
        </div>
        <hr />
        {ringLimit ? (
          <div className="requestPending">
            <p>
              Your urgent consultation request is pending due to the
              unavailability of any doctors. Please wait for 2 to 3 hours.
            </p>
          </div>
        ) : (
          requestPending && (
            <div className="requestPending">
              <p>{requestPending}</p>
            </div>
          )
        )}
        {patientDetial?._id ? (
          <>
            <div className={classes.msgScroll}>
              {/* {messages?.length > 0 && <h5>{MessagesData?.date}</h5>} */}
              <div className="all-messages-box">
                {filteredMessages?.length ? (
                  filteredMessages?.map((item, index) => (
                    <div ref={myRef} key={index}>
                      {item?.sender !== completeUser?.user_id?._id ? (
                        <Row key={item?._id}>
                          <Col className="message-icon" span={1}>
                            <img
                              src={
                                patientDetial?.provider?.profile_image || DomyD
                              }
                              alt="$$$"
                            />
                          </Col>
                          <Col className="message-text-area" span={13}>
                            <div className="message-text-box">
                              <div className="message-text">
                                {item?.file ? (
                                  <a
                                    className="message-image"
                                    href={`${item?.file?.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={
                                        item?.file?.url?.includes("pdf")
                                          ? MessageFileBlack
                                          : `${item?.file?.url}`
                                      }
                                      alt="$$$"
                                    />
                                  </a>
                                ) : (
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      lineBreak: "anywhere",
                                    }}
                                  >
                                    {item?.message}
                                  </p>
                                )}
                              </div>
                              <span>
                                {moment(item?.date).format("hh:mm A")}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row
                          key={item?._id}
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                          }}
                        >
                          <Col
                            style={{ display: "flex", justifyContent: "end" }}
                            className="message-text-area"
                            span={12}
                          >
                            <div className="message-text-box">
                              <div
                                style={{ background: "#009DDD" }}
                                className="message-text"
                              >
                                {item?.file ? (
                                  <a
                                    className="message-image"
                                    href={`${item?.file?.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={
                                        item?.file?.url?.includes("pdf")
                                          ? MessageFileBlack
                                          : `${item?.file?.url}`
                                      }
                                      alt="$$$"
                                    />
                                  </a>
                                ) : (
                                  <p
                                    style={{
                                      color: "white",
                                      fontWeight: "600",
                                      lineBreak: "anywhere",
                                    }}
                                  >
                                    {item?.message}
                                  </p>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                    gap: "20px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "white",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {moment(item?.date).format("hh:mm A")}
                                  </p>
                                  <p
                                    style={
                                      item?.status == "sent"
                                        ? {
                                            fontSize: "12px",
                                            color: "darkblue",
                                            fontStyle: "italic",
                                            textTransform: "capitalize",
                                          }
                                        : {
                                            fontSize: "12px",
                                            color: "#0808d6",
                                            fontStyle: "italic",
                                            textTransform: "capitalize",
                                          }
                                    }
                                  >
                                    {item?.status != "neutral"
                                      ? item?.status
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Empty description={"No messages"} />
                  </div>
                )}
              </div>
            </div>
            {patientDetial?.status && (
              <Form onFinish={sendMessage}>
                <div className={classes.fieldButton}>
                  <div className={classes.searchField}>
                    {fileToSend ? (
                      <div className={classes.attachmentField}>
                        <div className={classes.attachmentName}>
                          <p>{fileToSend}</p>
                          <CloseCircleOutlined
                            onClick={() => {
                              setFileToSend(null);
                              setMessage("");
                            }}
                          />
                        </div>
                        <img
                          style={{ cursor: "pointer" }}
                          src={paperclipLight}
                          alt="$$$"
                        />
                      </div>
                    ) : (
                      <Input
                        ref={inputRef}
                        onChange={(e) => setMessage(e?.target?.value)}
                        value={message}
                        suffix={
                          <Upload fileList={null} {...props}>
                            <img
                              style={{ cursor: "pointer" }}
                              src={paperclipLight}
                              alt="$$$"
                            />
                          </Upload>
                        }
                        setMessage={setMessage}
                        placeholder="Write your message..."
                        className="ant-input-affix-wrapper"
                      />
                    )}
                  </div>
                  <div style={{ width: "15%" }}>
                    <Spin spinning={imageLoading}>
                      <SecondaryButton
                        disabled={imageLoading}
                        btnRef={btnRef}
                        className={classes.button}
                        style={{ height: "36px", width: "100%" }}
                      >
                        {/* <Spin spinning={imageLoading}> */}
                        <ReactSVG src={send} />
                        Send
                        {/* </Spin> */}
                      </SecondaryButton>
                    </Spin>
                  </div>
                </div>
              </Form>
            )}
          </>
        ) : (
          <>
            <div className={classes?.emptyScreen}>
              <Empty />
            </div>
            <Form onFinish={sendMessage}>
              <div className={classes.fieldButton}>
                <div className={classes.searchField}>
                  {fileToSend ? (
                    <div className={classes.attachmentField}>
                      <div className={classes.attachmentName}>
                        <p>{fileToSend}</p>
                        <CloseCircleOutlined
                          onClick={() => {
                            setFileToSend(null);
                            setMessage("");
                          }}
                        />
                      </div>
                      <img
                        style={{ cursor: "pointer" }}
                        src={paperclipLight}
                        alt="$$$"
                      />
                    </div>
                  ) : (
                    <Input
                      ref={inputRef}
                      onChange={(e) => setMessage(e?.target?.value)}
                      value={message}
                      disabled
                      suffix={
                        <img
                          style={{ cursor: "pointer" }}
                          src={paperclipLight}
                          alt="$$$"
                        />
                      }
                      setMessage={setMessage}
                      placeholder="Write your message..."
                      className="ant-input-affix-wrapper"
                    />
                  )}
                </div>
                <div style={{ width: "15%" }}>
                  <Spin spinning={imageLoading}>
                    <SecondaryButton
                      disabled={true}
                      btnRef={btnRef}
                      className={classes.button}
                      style={{ height: "36px", width: "100%" }}
                    >
                      {/* <Spin spinning={imageLoading}> */}
                      <ReactSVG src={send} />
                      Send
                      {/* </Spin> */}
                    </SecondaryButton>
                  </Spin>
                </div>
              </div>
            </Form>
          </>
        )}
      </Spin>
    </div>
  );
};

export default Messages;
