import { useEffect, useState } from 'react';
import './consultation.scss';
import SecondaryButton from '../../Components/UI/SecondaryButton/SecondaryButton';
import {
  AddBookingIcon,
  AttendedIcon,
  DomyP,
  DoubleArrow,
  MissedIcon,
  UpcomingIcon
} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Modal, Row, Spin, message } from 'antd';
import {
  getRequest,
  postRequest,
  putRequest
} from '../../service/ApisFunctions';
import { useSelector } from 'react-redux';
import { getViewBookings } from './constant';
import OtherPatientCard from '../../Components/Cards/otherPatientCard';
import ConsultationCards from '../../Components/Cards/consulataionCard';
import AddAnOtherpatient from './addOtherpatient';
import { useMediaQuery } from 'react-responsive';
import Header from '../../Components/Layouts/Header/Header';

const ViewConsultations = () => {
  const navigate = useNavigate();
  const [showMega, setShowMega] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [allOthersPatient, setAllOthersPatient] = useState([]);
  const [mySelfConsultations, setMySelfConsultations] = useState([]);

  const [attendedCounter, setAttendedCounter] = useState(0);
  const [missedCounter, setMissedCounter] = useState(0);
  const [upcommingCounter, setUpcommingCounter] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [singlePatient, setSinglePatient] = useState({});

  const completeUser = useSelector(state => state.generalReducer.completeUser);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSinglePatient({});
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSinglePatient({});
    form.resetFields();
  };

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const getAllBookings = async (page, limit) => {
    setLoading(true);
    const onSuccess = res => {
      // const filterData = res?.data?.bookings?.filter((item) => !item?.parentBookingId);
      const currentDateTime = new Date();
      let attended = 0,
        missed = 0,
        upcoming = 0;

      const processedData = res?.data?.bookings?.map(item => {
        if (
          new Date(item?.schedule?.end_time) <= currentDateTime &&
          item?.nextFollowUp?._id &&
          item?.followUps?.length > 0
        ) {
          const followUpItem = {
            ...item?.nextFollowUp,
            followUpLength: item?.followUps?.length,
            currentFollowUpIndex: item?.nextFollowUpIndex + 1
          };
          return followUpItem;
        }

        // Update counters based on consultation status
        switch (item?.consultation_status) {
          case 'completed':
            attended++;
            break;
          case 'missed':
            missed++;
            break;
          case 'upcoming':
            upcoming++;
            break;
        }

        return item;
      });

      setAttendedCounter(attended);
      setMissedCounter(missed);
      setUpcommingCounter(upcoming);
      setMySelfConsultations(processedData);
      setLoading(false);
    };

    const onError = err => {
      message.error(err?.message);
      setLoading(false);
    };

    const formData = {
      status: true
    };

    await postRequest(
      formData,
      `${getViewBookings}?page=${page}&limit=${limit}`,
      true,
      onSuccess,
      onError
    );
  };

  const getAllOtherPatients = async () => {
    setLoading(true);
    const onSuccess = res => {
      setAllOthersPatient(res?.data);
      setLoading(false);
    };

    const onError = err => {
      message.error(err?.message);
      setLoading(false);
    };

    await getRequest('', `patient/get-others`, true, onSuccess, onError);
  };

  useEffect(() => {
    getAllOtherPatients();
    getAllBookings(1, 10);
  }, []);

  const consultationCard = [
    {
      icons: AttendedIcon,
      text: 'Attended',
      color: '#009DDD',
      counter: attendedCounter || '0'
    },
    {
      icons: MissedIcon,
      text: 'Missed',
      color: '#F76D00',
      counter: missedCounter || '0'
    },
    {
      icons: UpcomingIcon,
      text: 'Upcoming',
      color: '#25D366',
      counter: upcommingCounter || '0'
    }
  ];

  // Change Paient Status

  const deletePatientApi = async id => {
    setLoading(true);
    const onSuccess = res => {
      getAllOtherPatients();
      setLoading(false);
      message.success(res?.message);
    };

    const onError = err => {
      message.error(err?.message);
      setLoading(false);
    };

    await putRequest(
      '',
      `patient/change-patient-state/${id}`,
      true,
      onSuccess,
      onError
    );
  };

  return (
    <Spin spinning={loading}>
      <Header showMega={showMega} setShowMega={setShowMega} />
      <div className='container' onClick={() => setShowMega(false)}>
        <h1 style={{ paddingBottom: '10px' }}>
          Welcome{' '}
          {`${completeUser?.first_name || '-'} ${
            completeUser?.middle_name || '-'
          } ${completeUser?.last_name || '-'}!`}
        </h1>
        <div className='consultation'>
          <div className='card'>
            <div className='ViewcardBody'>
              <div>
                <div className='content'>
                  <div className='image'>
                    <img
                      width='100%'
                      height='100%'
                      src={completeUser?.profile_image || DomyP}
                      alt='$$$'
                    />
                  </div>
                  <div>
                    <h3>My Self</h3>
                    <p className='description'>
                      {`${completeUser?.first_name || '-'} ${
                        completeUser?.middle_name || '-'
                      } ${completeUser?.last_name || '-'}`}
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate('/consultations')}
                className='content-right-side'
              >
                <div>
                  <h3>{mySelfConsultations?.length || '--'}</h3>
                  <p className='description'>Consultations</p>
                </div>
                <img src={DoubleArrow} />
              </div>
            </div>
          </div>

          {/* Consultation Card */}
          <div className='consultationCards'>
            <Row
              style={mobileResponsive ? { width: '100%' } : { width: '81%' }}
              gutter={[15, 15]}
            >
              {consultationCard?.map((item, index) => (
                <ConsultationCards
                  key={index}
                  icon={item?.icons}
                  color={item?.color}
                  text={item?.text}
                  counter={item?.counter}
                  mobileResponsive={mobileResponsive}
                />
              ))}
            </Row>
          </div>

          {/* OtherPatient Cards */}

          <div className='otherpatientsCards'>
            <Row
              style={mobileResponsive ? { width: '100%' } : { width: '81%' }}
              gutter={[15, 15]}
            >
              <Col span={mobileResponsive ? 24 : 18}>
                <div className='otherpatientLeftSide'>
                  <h3 className='heading'>Other patients</h3>
                  <div className='main'>
                    {allOthersPatient?.map((el, index) => {
                      return (
                        <OtherPatientCard
                          key={index}
                          image={el?.profile_image}
                          full_name={`${el?.patient?.first_name || '-'} ${
                            el?.patient?.middle_name || ''
                          } ${el?.patient?.last_name || '-'}`}
                          editpatient={() => {
                            showModal();
                            setSinglePatient(el);
                          }}
                          deletePatient={() => {
                            deletePatientApi(el?._id);
                          }}
                          detailPatient={() =>
                            navigate(`/consultations/${el?._id}`)
                          }
                        />
                      );
                    })}
                    <div
                      style={
                        allOthersPatient?.length > 0 ? {} : { height: '120px' }
                      }
                      onClick={() => showModal()}
                      className='addNewpatient'
                    >
                      <p className='addIcon'>+</p>
                      <p className='text'>Add new patient</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={mobileResponsive ? 24 : 6}>
                <div className='otherpatientRightSide'>
                  <img src={AddBookingIcon} />
                  <h3>No upcoming consultation</h3>
                  <div style={{ width: '100%', padding: '10px 20px' }}>
                    <SecondaryButton
                      onClick={() => navigate('/virtualConsultation')}
                      width='100%'
                    >
                      Book now
                    </SecondaryButton>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Modal
        width={mobileResponsive ? '90%' : '70%'}
        footer={false}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        centered
      >
        <Spin spinning={modalLoading}>
          <AddAnOtherpatient
            setSinglePatient={setSinglePatient}
            singlePatient={singlePatient}
            getAllOtherPatients={getAllOtherPatients}
            setModalLoading={setModalLoading}
            handleOk={handleOk}
            form={form}
            completeUser={completeUser}
          />
        </Spin>
      </Modal>
    </Spin>
  );
};

export default ViewConsultations;
