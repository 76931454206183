import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formatPhoneNumber,
  strongPasswordValidator,
  ValidateMaxLength,
  validatePhoneNumber
} from '../../../../Utils/helper';
import BlueButton from '../../../../Components/UI/BlueButton/BlueButton';
import { PhoneTypes } from '../../../../Utils/constant';
import { useMediaQuery } from 'react-responsive';

const FirstStep = ({ setSignUpSteps, setSignUpData, signUpData }) => {
  const [form] = Form.useForm();

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const passwordRequirements = [
    'At least 8 characters',
    'Includes both uppercase and lowercase letters',
    'Contains at least one number',
    'Includes special characters (e.g., !@#$%^&*)'
  ];

  const [validationResults, setValidationResults] = useState(
    Array(passwordRequirements.length).fill(false)
  );

  const handleChange = e => {
    const newPassword = e.target.value;

    // Validate the password against each requirement
    const newValidationResults = passwordRequirements.map(
      (requirement, index) => {
        switch (index) {
          case 0:
            return newPassword.length >= 8;
          case 1:
            return /[A-Z]/.test(newPassword) && /[a-z]/.test(newPassword);
          case 2:
            return /\d/.test(newPassword);
          case 3:
            return /[\!@#$%^&*()_+\-=\[\]{};'`:"\\|,.<>\/?]/.test(newPassword);
          default:
            return false;
        }
      }
    );

    setValidationResults(newValidationResults);
  };

  const handleChangePhoneNumberPattren = e => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);

    form.setFieldsValue({
      phone_number: formatted
    });
  };

  const handleSubmit = async e => {
    const filterPhoneTypeByLable = PhoneTypes.find(
      item => item?.id == e?.PrimaryPhoneType
    );

    const createPatient = {
      email: e.email,
      password: e?.password,
      phone_number: e?.phone_number,
      PrimaryPhoneType: filterPhoneTypeByLable?.id
    };

    setSignUpData(prev => ({ ...prev, ...createPatient }));
    setSignUpSteps(1);
  };

  useEffect(() => {
    const filterPhoneTypeById = PhoneTypes.find(
      item => item?.id == signUpData?.PrimaryPhoneType
    );

    form.setFieldsValue({
      email: signUpData?.email || null,
      password: signUpData?.password || null,
      confirmPassword: signUpData?.password || null,
      phone_number: signUpData?.phone_number || null,

      PrimaryPhoneType: filterPhoneTypeById?.id || 4
    });
  }, []);

  return (
    <Form form={form} onFinish={handleSubmit} layout='vertical'>
      <Row gutter={20}>
        <Col span={24}>
          <Form.Item
            name='email'
            label='Secure Email Address'
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter valid email'
              },
              ValidateMaxLength(80,"Email")
            ]}
          >
            <Input
              placeholder='Enter Your Secure Email Address'
              className='ant-input-affix-wrapper'
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <Form.Item
              name='phone_number'
              label='Phone Number'
              style={{ width: mobileResponsive ? '50%' : '80%' }}
              rules={[
                {
                  validator: validatePhoneNumber
                }
              ]}
            >
              <Input
                min={0}
                onChange={handleChangePhoneNumberPattren}
                className='ant-input-affix-wrapper'
                placeholder='Enter your phone number'
              />
            </Form.Item>
            <Form.Item
              name='PrimaryPhoneType'
              label='Phone Type'
              style={{ width: mobileResponsive ? '50%' : '20%' }}
              rules={[
                {
                  required: true,
                  message: 'Please select phone type'
                }
              ]}
            >
              <Select
                className='ant-select-selector'
                placeholder='Select phone type'
              >
                {PhoneTypes?.map((item, index) => (
                  <Select.Option key={index} value={item?.id}>
                    {item?.label || '-'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col span={12}>
          <Form.Item
            name='password'
            label='Profile Password'
            rules={[{ validator: strongPasswordValidator }]}
          >
            <Input.Password
              onChange={handleChange}
              placeholder='Enter Your Password'
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='confirmPassword'
            label='Confirm Password'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password does not match!'));
                }
              })
            ]}
          >
            <Input.Password placeholder='Confirm your Password' />
          </Form.Item>
        </Col>

        <Col style={{ marginTop: '20px' }} span={24}>
          <BlueButton width='100%'>Next</BlueButton>
        </Col>
      </Row>
    </Form>
  );
};

export default FirstStep;
