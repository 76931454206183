import { Col, DatePicker, Form, Input, Row, Select, Spin, message } from "antd";
import React, { useState } from "react";
import { postRequest } from "../../../service/ApisFunctions";
import { Reaction, ReactionType, StatusType } from "../../../Utils/constant";
import SecondaryButton from "../../UI/SecondaryButton/SecondaryButton";
import { disabledFutureDate } from "../../../Utils/helper";
import { useMediaQuery } from "react-responsive";

const FreetextAllergies = ({ form, handleOk, getAllAllergies }) => {
  const [modalLoading, setModalLoading] = useState(false);

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });


  const handlesubmit = async (value) => {
    setModalLoading(true);
    const onSuccess = (res) => {
      handleOk();
      setModalLoading(false);
      getAllAllergies();
      message.success(res?.message);
    };
    const onError = (err) => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setModalLoading(false);
    };
    const formData = {
      FreeTextDisplayName: value?.FreeTextDisplayName,
      ...(value?.Reaction && { Reaction: value?.Reaction }),
      ReactionType: value?.ReactionType,
      StatusType: value?.StatusType,
      ...(value?.OnsetDate && { OnsetDate: value?.OnsetDate }),
    };

    const route = `allergy/addFreeText-allergy-by-customer`;
    await postRequest(formData, route, true, onSuccess, onError);
  };

  return (
    <Spin spinning={modalLoading}>
      <Form form={form} onFinish={handlesubmit} layout="vertical">
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Form.Item
              name="FreeTextDisplayName"
              label="Name"
              rules={[{ required: true, message: "Please enter any allergy" }]}
            >
              <Input
                className="ant-input-affix-wrapper"
                min={0}
                placeholder="Please Enter Any Allergy"
              />
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 :12}>
            <Form.Item
              name="StatusType"
              label="Status Type"
              rules={[{ required: true, message: "Please select status type" }]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Please Select Status Type"
              >
                {StatusType?.map((item, index) => (
                  <Select.Option key={index} value={item?.value}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 :12}>
            <Form.Item name="Reaction" label="Severity of Reaction">
              <Select
                className="ant-select-selector"
                placeholder="mild, moderate, and severe"
              >
                {Reaction?.map((item, index) => (
                  <Select.Option key={index} value={item?.label}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 :12}>
            <Form.Item
              name="ReactionType"
              label="Reaction Type"
              rules={[
                { required: true, message: "Please select reaction type" },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Please Select Reaction Type"
              >
                {ReactionType?.map((item, index) => (
                  <Select.Option key={index} value={item?.label}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 :12}>
            <Form.Item name="OnsetDate" label="On Set Date">
              <DatePicker
                disabledDate={disabledFutureDate}
                className="ant-input-affix-wrapper"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                onClick={() => handleOk()}
                type="button"
                className="noBgBtn"
                style={{ padding: "0px 30px" }}
              >
                Cancel
              </button>
              <SecondaryButton
                style={{ padding: "0px 30px" }}
                type="submit"
                className="primaryButton"
              >
                Add Allergy
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FreetextAllergies;
