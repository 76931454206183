import React, { useEffect, useState } from "react";
import "./faqs.scss";
import { Col, Collapse, Row, Spin, message } from "antd";
import { FaqsMinus, FaqsPlus, down } from "../../assets";
import { getRequest } from "../../service/ApisFunctions";
import ThirdButton from "../UI/ThirdButton/ThirdButton";
import SkeletonInput from "../UI/Skeleton/skeletonInput";
import { ReactSVG } from "react-svg";
import { useMediaQuery } from "react-responsive";
export const Faqs = () => {
  const [active, setActive] = useState("");
  const [showQuestions, setShowQuestion] = useState({
    numbers: 4,
    active: false,
  });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const getFaqsList = async () => {
    setLoading(true);

    const onSuccess = (res) => {
      const data = {};
      res?.list?.forEach((item) => {
        const category = item.category;
        const faqs = item.faqs.map(({ question, answer }) => ({
          label: question,
          children: answer,
        }));
        data[category] = faqs;
      });
      setData(data);
      setActive(Object.keys(data)[0] || "");
      setLoading(false);
    };

    const onError = (err) => {
      message?.error(err?.message);
      setLoading(false);
    };

    await getRequest("", "faqs/active_faq_listing", true, onSuccess, onError);
  };

  useEffect(() => {
    getFaqsList();
  }, []);

  const seeAllFaqs = (numbers, status) => {
    setShowQuestion({ numbers: numbers, active: status });
  };

  return (
    <div className="faqsWrapper">
      <div className="landingPageContainer">
        <h2>
          Most Commonly Asked<span> Questions</span>{" "}
        </h2>

        <Row
          gutter={20}
          style={{ marginTop: mobileResponsive ? "30px" : "50px" }}
        >
          <Col className="faqsGroups" span={mobileResponsive ? 24 : 8}>
            <p>
              Everything you might need to know about the medical services we
              provide and how it works.
            </p>
            <Row gutter={[10, 10]} className="faqsOptionsWrap">
              {Object.keys(data)?.map((elem) => (
                <Col span={12}>
                  <button
                    className={`customFaqsButton ${
                      elem === active ? "active" : ""
                    }`}
                    onClick={() => setActive(elem)}
                    key={elem}
                  >
                    {elem}
                  </button>
                </Col>
              ))}
            </Row>
          </Col>

          <Col span={mobileResponsive ? 24 : 16}>
            {loading ? (
              <Row
                style={{ marginTop: mobileResponsive && "20px" }}
                gutter={[0, 20]}
              >
                {[1, 2, 3, 4, 5]?.map((item, index) => (
                  <Col key={index} span={24}>
                    <SkeletonInput block={true} />
                  </Col>
                ))}
                <SkeletonInput block={false} />
              </Row>
            ) : (
              <>
                <div className="faqsWrap">
                  <Collapse
                    items={data[active]?.slice(0, showQuestions?.numbers)}
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <img
                          className="activeQuestionArrow"
                          src={down}
                          alt="-"
                        />
                      ) : (
                        <img className="questionArrow" src={down} alt="+" />
                      )
                    }
                  />
                </div>
                <>
                  {data?.[active]?.length >= 4 ? (
                    data[active]?.length === showQuestions?.numbers ? (
                      data?.[active]?.length <= 4 ? null : (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {/* <ThirdButton status="active" onClick={() => setShowQuestion(4)}> */}
                          <div
                            onClick={() => seeAllFaqs(4, false)}
                            className={
                              showQuestions?.active
                                ? "seeAll activeIcon"
                                : "seeAll"
                            }
                          >
                            See Less FAQs
                            <ReactSVG src={down} />
                          </div>
                          {/* </ThirdButton> */}
                        </div>
                      )
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {/* <ThirdButton status="active"
                    onClick={() => setShowQuestion(data[active]?.length)}
                  > */}
                        <div
                          onClick={() => seeAllFaqs(data[active]?.length, true)}
                          className="seeAll"
                        >
                          See All FAQs
                          <ReactSVG src={down} />
                        </div>
                        {/* </ThirdButton> */}
                      </div>
                    )
                  ) : null}
                </>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
