import { Col, DatePicker, Form, Input, Row, Select, Spin, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGeolocated } from 'react-geolocated';
import { ReactSVG } from 'react-svg';
import {
  getRequest,
  postRequestFormData
} from '../../../../service/ApisFunctions';
import {
  setBookingFlowLocation,
  setBookingFlowPatientData
} from '../../../../Redux/Reducers/bookingSlice';
import {
  disabledFutureDate,
  formatPhoneNumber,
  validateHeight,
  ValidateMaxLength,
  validatePhoneNumber,
  validateWeight
} from '../../../../Utils/helper';
import {
  AllLanguages,
  EthnicityList,
  RaceList,
  birthGendersList,
  gendersList,
  pronounList,
  sexualOrientation,
  PhoneTypes,
  HeightUnit,
  WeightUnit
} from '../../../../Utils/constant';
import SecondaryButton from '../../../UI/SecondaryButton/SecondaryButton';
import { calendarSimple, paperclip } from '../../../../assets';
import { useMediaQuery } from 'react-responsive';

const PatientPersonalInfoForm = ({ setIsModal }) => {
  const bookingData = useSelector(state => state.booking);
  const dispatch = useDispatch();
  const [allState, setAllState] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [location, setLocation] = useState({});
  const [guardianFile, setGuardianFile] = useState(null);
  const [guardianValidation, setGuardianValidation] = useState('fill');
  const [childHeightUnit, setChildHeightUnit] = useState(1);
  const [childWeightUnit, setChildWeightUnit] = useState(1);
  const [guardianHeightUnit, setGuardianHeightUnit] = useState(1);
  const [guardianWeightUnit, setGuardianWeightUnit] = useState(1);

  const [form] = Form.useForm();

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const handleInputChange = async e => {
    const file = e.target.files[0];
    UploadS3(file);
  };
  const getAllSatates = async () => {
    setModalLoading(true);
    const onSuccess = res => {
      setAllState(res?.data);
      setModalLoading(false);
    };

    const onError = err => {
      message.error(err?.message);
      setModalLoading(false);
    };

    await getRequest(
      '',
      'state/active_state_listing',
      true,
      onSuccess,
      onError
    );
  };
  const UploadS3 = async file => {
    setModalLoading(true);
    const onSuccess = res => {
      setModalLoading(false);
      setGuardianFile(res?.data);
      setGuardianValidation('fill');
    };

    const onError = () => {
      setModalLoading(false);
    };

    const formData = {
      image: file
    };

    await postRequestFormData(
      formData,
      'auth/upload_image_s3',
      true,
      onSuccess,
      onError
    );
  };

  const handleSubmit = async e => {
    if (guardianFile == null) {
      setGuardianValidation('');
    } else {
      setGuardianValidation('fill');
    }
    const currentYear = new Date().getFullYear();
    const patientAge = new Date(e?.date_of_birth?.$d).getFullYear();
    if (18 <= currentYear - patientAge) {
      const filterPhoneTypeByLable = PhoneTypes.find(
        item => item?.id === e?.PrimaryPhoneType
      );
      dispatch(
        setBookingFlowPatientData({
          first_name: e?.firstName || '',
          last_name: e?.lastname || '',
          middle_name: e?.middle_name || '',
          gender: e?.gender || '',
          zipCode: e?.ZipCode || '',
          city: e?.City || '',
          state: e?.state || '',
          address: e?.address || e?.guardianAddress || '',
          PrimaryPhoneType: filterPhoneTypeByLable?.id,
          phone_number: e?.phone_number || e?.guardianPhone_number || '',
          guardianFirstName: e?.guardianFirstName || '',
          guardian_middle_name: e?.guardian_middle_name || '',
          guardianLastName: e?.guardianLastName || '',
          guardianPhone_number: e?.guardianPhone_number || '',
          guardianDob: e?.date_of_birth || '',
          ...(bookingData?.bookingFlowAs === 'guardian'
            ? { dob: e?.date_of_birth_patient?.$d || '' }
            : { dob: e?.date_of_birth?.$d || '' }),

          email: e?.email || e?.genderEmail,
          location: location,
          ...(e?.relation && { relation: e?.relation }),
          ...(bookingData?.bookingFlowAs === 'guardian' && {
            file: guardianFile
          }),
          ...(e?.birthGender && {
            birthGender: e?.birthGender
          }),
          ...(e?.sexualOrientation && {
            sexualOrientation: e?.sexualOrientation
          }),
          ...(e?.race && { race: e?.race }),
          ...(bookingData?.bookingFlowAs === 'patient'
            ? { height: { value: e?.height, unit: e?.heightUnit } }
            : {
                height: { value: e?.heightPatient, unit: e?.heightUnitPatient }
              }),
          ...(bookingData?.bookingFlowAs === 'patient'
            ? { weight: { value: e?.weight, unit: e?.weightUnit } }
            : {
                weight: { value: e?.weightPatient, unit: e?.weightUnitPatient }
              }),
          ...(e?.ethnicity && { ethnicity: e?.ethnicity }),
          ...(e?.language && { language: e?.language || [] }),

          ...(e?.birthGenderPatient && {
            birthGender: e?.birthGenderPatient
          }),
          ...(e?.sexualOrientationPatient && {
            sexualOrientation: e?.sexualOrientationPatient
          }),
          ...(e?.pronounPatient && {
            pronoun: e?.pronounPatient
          }),
          ...(e?.pronoun && {
            pronoun: e?.pronoun
          }),
          ...(e?.racePatient && { race: e?.racePatient }),
          ...(e?.ethnicityPatient && {
            ethnicity: e?.ethnicityPatient
          }),
          ...(e?.languagePatient && {
            language: e?.languagePatient || []
          })
        })
      );
      setIsModal(false);
    } else {
      message.warning('Under 18 you are unable to book consultation');
    }
  };

  // Get Location

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false
      },
      userDecisionTimeout: 5000
    });

  useEffect(() => {
    getAllSatates();
  }, []);

  
  useEffect(() => {
    const filterPhoneTypeById = PhoneTypes.find(
      item => item?.id == bookingData?.bookingFlowPatientData?.PrimaryPhoneType
    );
    if (bookingData?.bookingFlowPatientData) {
      form.setFieldsValue({
        firstName: bookingData?.bookingFlowPatientData?.first_name || '',
        lastname: bookingData?.bookingFlowPatientData?.last_name || '',
        middle_name: bookingData?.bookingFlowPatientData?.middle_name || '',
        address: bookingData?.bookingFlowPatientData?.address || '',
        City: bookingData?.bookingFlowPatientData?.city || '',
        ZipCode: bookingData?.bookingFlowPatientData?.zipCode || '',
        state: bookingData?.bookingFlowPatientData?.state || null,
        PrimaryPhoneType: filterPhoneTypeById?.id || null,
        email: bookingData?.bookingFlowPatientData?.email || '',
        genderEmail: bookingData?.bookingFlowPatientData?.email || '',
        gender: bookingData?.bookingFlowPatientData?.gender || null,
        date_of_birth_patient: bookingData?.bookingFlowPatientData?.dob
          ? dayjs(bookingData?.bookingFlowPatientData?.dob)
          : null,
        date_of_birth:
          bookingData?.bookingFlowAs === 'guardian'
            ? dayjs(bookingData?.bookingFlowPatientData?.guardianDob)
            : dayjs(bookingData?.bookingFlowPatientData?.dob),
        phone_number: bookingData?.bookingFlowPatientData?.phone_number || '',
        guardianFirstName:
          bookingData?.bookingFlowPatientData?.guardianFirstName || '',
        guardian_middle_name:
          bookingData?.bookingFlowPatientData?.guardian_middle_name || '',
        guardianLastName:
          bookingData?.bookingFlowPatientData?.guardianLastName || '',
        guardianPhone_number:
          bookingData?.bookingFlowPatientData?.phone_number || '',
        guardianAddress: bookingData?.bookingFlowPatientData?.address || '',
        relation: bookingData?.bookingFlowPatientData?.relation || null,
        birthGender: bookingData?.bookingFlowPatientData?.birthGender || null,
        sexualOrientation:
          bookingData?.bookingFlowPatientData?.sexualOrientation || null,
        race: bookingData?.bookingFlowPatientData?.race || null,
        ethnicity: bookingData?.bookingFlowPatientData?.ethnicity || null,
        height: bookingData?.bookingFlowPatientData?.height?.value || '',
        weight: bookingData?.bookingFlowPatientData?.weight?.value || '',
        heightUnit:
          parseInt(bookingData?.bookingFlowPatientData?.height?.unit) || 3,
        weightUnit:
          parseInt(bookingData?.bookingFlowPatientData?.weight?.unit) || 1,
        language: bookingData?.bookingFlowPatientData?.language || [],

        birthGenderPatient:
          bookingData?.bookingFlowPatientData?.birthGender || null,
        sexualOrientationPatient:
          bookingData?.bookingFlowPatientData?.sexualOrientation || null,
        pronounPatient: bookingData?.bookingFlowPatientData?.pronoun || null,
        pronoun: bookingData?.bookingFlowPatientData?.pronoun || null,
        racePatient: bookingData?.bookingFlowPatientData?.race || null,
        ethnicityPatient:
          bookingData?.bookingFlowPatientData?.ethnicity || null,
        heightPatient: bookingData?.bookingFlowPatientData?.height?.value || '',
        weightPatient: bookingData?.bookingFlowPatientData?.weight?.value || '',
        heightUnitPatient:
          parseInt(bookingData?.bookingFlowPatientData?.height?.unit) || 1,
        weightUnitPatient:
          parseInt(bookingData?.bookingFlowPatientData?.weight?.unit) || 1,
        languagePatient: bookingData?.bookingFlowPatientData?.language || []
      });

      setGuardianHeightUnit(
        parseInt(bookingData?.bookingFlowPatientData?.height?.unit) || 1
      );
      setGuardianWeightUnit(
        parseInt(bookingData?.bookingFlowPatientData?.weight?.unit) || 1
      );

      setChildHeightUnit(
        parseInt(bookingData?.bookingFlowPatientData?.height?.unit) || 1
      );
      setChildWeightUnit(
        parseInt(bookingData?.bookingFlowPatientData?.weight?.unit) || 1
      );

      if (bookingData?.bookingFlowPatientData?.file) {
        setGuardianValidation('fill');
        setGuardianFile(bookingData?.bookingFlowPatientData?.file);
      }
      setLocation(bookingData?.bookingFlowLocation);
    }
  }, [bookingData?.bookingFlowPatientData]);

  const handleChangePhoneNumberPattren = e => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);

    form.setFieldsValue({
      phone_number: formatted
    });
  };

  return (
    <Spin spinning={modalLoading}>
      <div className='updateContainer'>
        {bookingData?.bookingFlowAs === 'patient' && (
          <Form layout='vertical' form={form} onFinish={handleSubmit}>
            <Row gutter={[35,10]}>
              <Col span={mobileResponsive ? 24 : 8}>
                <Form.Item
                  name='firstName'
                  label='First Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your first name'
                    }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='First name'
                  />
                </Form.Item>
              </Col>
              <Col span={mobileResponsive ? 24 : 8}>
                <Form.Item name='middle_name' label='Middle Name'>
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Middle name'
                  />
                </Form.Item>
              </Col>

              <Col span={mobileResponsive ? 24 : 8}>
                <Form.Item
                  name='lastname'
                  label='Last Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your last name'
                    }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Last name'
                  />
                </Form.Item>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Form.Item
                    style={{ width: '70%' }}
                    name='height'
                    label='Height'
                    rules={[
                      // { required: true },
                      { validator: validateHeight(guardianHeightUnit) }
                    ]}
                  >
                    <Input
                      min={1}
                      type='numeric'
                      className='ant-input-affix-wrapper'
                      placeholder='Height'
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '30%' }}
                    name='heightUnit'
                    label='Unit'
                    rules={[
                      {
                        required: true,
                        message: 'Please select unit'
                      }
                    ]}
                  >
                    <Select
                      onChange={e => {
                        setGuardianHeightUnit(e);
                      }}
                      className='ant-select-selector'
                      placeholder='Unit'
                    >
                      {HeightUnit?.map((item, index) => (
                        <Select.Option key={index} value={item?.id}>
                          {item?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Form.Item
                    style={{ width: '70%' }}
                    name='weight'
                    label='Weight'
                    rules={[
                      { required: true },
                      { validator: validateWeight(guardianWeightUnit) }
                    ]}
                  >
                    <Input
                      min={1}
                      type='number'
                      className='ant-input-affix-wrapper'
                      placeholder='Weight'
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '30%' }}
                    name='weightUnit'
                    label='Unit'
                    rules={[
                      {
                        required: true,
                        message: 'Please select unit'
                      }
                    ]}
                  >
                    <Select
                      onChange={e => {
                        setGuardianWeightUnit(e);
                      }}
                      className='ant-select-selector'
                      placeholder='Unit'
                    disabled

                    >
                      {WeightUnit?.map((item, index) => (
                        <Select.Option key={index} value={item?.id}>
                          {item?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='City'
                  label='City'
                  rules={[
                    { required: true, message: 'Please enter city' },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: 'City must contain only alphabets'
                    }
                  ]}
                >
                  <Input
                    autoComplete='off'
                    className='ant-input-affix-wrapper'
                    placeholder='Please enter city'
                  />
                </Form.Item>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='state'
                  label='State'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter state'
                    }
                  ]}
                >
                  <Select
                    maxLength={2}
                    className='ant-select-selector'
                    placeholder='State'
                  >
                    {allState?.map((item, index) => (
                      <Select.Option key={index} value={item?.state}>
                        {item?.state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='ZipCode'
                  label='Zip Code'
                  rules={[
                    { required: true, message: 'Please enter only digits' },
                    {
                      pattern: /^[0-9]{5}$/,
                      message: 'Zip Code must be exactly 5 digits'
                    }
                  ]}
                >
                  <Input
                    type='number'
                    maxLength={5}
                    autoComplete='off'
                    className='ant-input-affix-wrapper'
                    placeholder='Please enter zip code'
                  />
                </Form.Item>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='address'
                  label='Street'
                  rules={[
                    { required: true, message: 'Please enter your Street' }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Street'
                    onChange={e =>
                      dispatch(
                        setBookingFlowLocation({
                          address: e.target.value,
                          coordinates: [
                            coords?.longitude ? coords?.longitude : 0,
                            coords?.latitude ? coords?.latitude : 0
                          ]
                        })
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={mobileResponsive ? 24 : 12}>
                {' '}
                <Form.Item
                  name='ethnicity'
                  label='Ethnicity'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your ethnicity'
                    }
                  ]}
                >
                  <Select
                    mode='multiple'
                    className='ant-select-selector'
                    placeholder='Please select ethnicity'
                  >
                    {EthnicityList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='race'
                  label='Race'
                  rules={[
                    { required: true, message: 'Please enter your race' }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Please select race'
                  >
                    {RaceList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='sexualOrientation'
                  label='Sexual orientation'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your sexual orientation'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Please select sexual orientation'
                  >
                    {sexualOrientation?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='pronoun'
                  label='Pronouns'
                  rules={[
                    {
                      required: true,
                      message: 'Please select your pronoun'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Select pronoun'
                  >
                    {pronounList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='birthGender'
                  label='Birth Gender'
                  rules={[
                    {
                      required: true,
                      message: 'Please select your birth gender'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Birth Gender'
                  >
                    {birthGendersList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='gender'
                  label='Current Gender'
                  rules={[
                    { required: true, message: 'Please select your gender' }
                  ]}
                >
                  <Select className='ant-select-selector' placeholder='Gender'>
                    <Select.Option value='Male'>Male</Select.Option>
                    <Select.Option value='Female'>Female</Select.Option>
                    <Select.Option value='Refuse to disclose'>
                      Refuse to disclose
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name='language'
                  label='Language'
                  rules={[
                    {
                      required: true,
                      message: 'Please select your language'
                    }
                  ]}
                >
                  <Select
                    mode='multiple'
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className='ant-select-selector'
                    placeholder='language'
                  >
                    {AllLanguages?.map((item, index) => (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='date_of_birth'
                  label='Date Of Birth'
                  rules={[
                    {
                      required: true,
                      message: 'Please select your date of birth'
                    }
                  ]}
                >
                  <DatePicker
                    disabled
                    disabledDate={disabledFutureDate}
                    className='ant-input-affix-wrapper'
                    placeholder='Date of birth'
                    suffixIcon={<ReactSVG width='20px' src={calendarSimple} />}
                    format='MM-DD-YYYY'
                    picker='date'
                  />
                </Form.Item>
              </Col>

              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='email'
                  label='Email Address'
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter your valid email'
                    },
                    ValidateMaxLength(35, "Email")
                  ]}
                >
                  <Input
                    disabled={
                      bookingData?.bookingFlowPatientData?.email ? true : false
                    }
                    className='ant-input-affix-wrapper'
                    placeholder='Email address'
                    type='email'
                  />
                </Form.Item>
              </Col>

              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='PrimaryPhoneType'
                  label='Phone Type'
                  rules={[
                    {
                      required: true,
                      message: 'Please select phone type'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Select phone type'
                  >
                    {PhoneTypes?.map((item, index) => (
                      <Select.Option key={item?.id} value={item?.id}>
                        {item?.label || '-'}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={mobileResponsive ? 24 : 12}>
                <Form.Item
                  name='phone_number'
                  label='Phone Number'
                  rules={[
                    {
                      validator: validatePhoneNumber
                    }
                  ]}
                >
                  <Input
                    onChange={handleChangePhoneNumberPattren}
                    className='ant-input-affix-wrapper'
                    placeholder='Phone number'
                    min={0}
                  />
                </Form.Item>
              </Col>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <div style={{ width: '200px', marginBottom: '20px' }}>
                  <SecondaryButton type='submit' width='100%'>
                    Update
                  </SecondaryButton>
                </div>
              </div>
            </Row>
          </Form>
        )}

        {bookingData?.bookingFlowAs === 'guardian' && (
          <div className='information'>
            <h1>Patient Info</h1>
            <Form
              layout='vertical'
              form={form}
              className='fields'
              onFinish={handleSubmit}
            >
              <div style={{ display: 'flex', gap: '20px' }}>
                <Form.Item
                  style={{ width: '33.33%' }}
                  name='firstName'
                  label='Patient First Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your first name'
                    }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Patient First name'
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '33.33%' }}
                  name='middle_name'
                  label='Patient Middle Name'
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Patient Middle name'
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '33.33%' }}
                  name='lastname'
                  label='Patient Last Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your last name'
                    }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Patient Last name'
                  />
                </Form.Item>
              </div>

              <div style={{ display: 'flex', gap: '20px' }}>
                <Form.Item
                  style={{ width: '50%' }}
                  name='ethnicityPatient'
                  label='Patient Ethnicity'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your ethnicity'
                    }
                  ]}
                >
                  <Select
                    mode='multiple'
                    className='ant-select-selector'
                    placeholder='Please select ethnicity'
                  >
                    {EthnicityList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ width: '50%' }}
                  name='racePatient'
                  label='Race'
                  rules={[
                    { required: true, message: 'Please enter your race' }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Please select race'
                  >
                    {RaceList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div style={{ display: 'flex', gap: '20px' }}>
                <div style={{ width: '50%', display: 'flex', gap: '20px' }}>
                  <Form.Item
                    style={{ width: '70%' }}
                    name='heightPatient'
                    label='Patient Height'
                    rules={[
                      // { required: true },
                      { validator: validateHeight(childHeightUnit) }
                    ]}
                  >
                    <Input
                      type='number'
                      className='ant-input-affix-wrapper'
                      placeholder='Patient Height'
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '30%' }}
                    name='heightUnitPatient'
                    label='Unit'
                    rules={[
                      {
                        required: true,
                        message: 'Please select unit'
                      }
                    ]}
                  >
                    <Select
                      onChange={e => {
                        setChildHeightUnit(e);
                      }}
                      className='ant-select-selector'
                      placeholder='Unit'
                    >
                      {HeightUnit?.map((item, index) => (
                        <Select.Option key={index} value={item?.id}>
                          {item?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ width: '50%', display: 'flex', gap: '20px' }}>
                  <Form.Item
                    style={{ width: '70%' }}
                    name='weightPatient'
                    label='Patient Weight'
                    rules={[
                      { required: true },
                      { validator: validateWeight(childWeightUnit) }
                    ]}
                  >
                    <Input
                      min={1}
                      type='number'
                      className='ant-input-affix-wrapper'
                      placeholder='Patient Weight'
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '30%' }}
                    name='weightUnitPatient'
                    label='Unit'
                    rules={[
                      {
                        required: true,
                        message: 'Please select unit'
                      }
                    ]}
                  >
                    <Select
                      onChange={e => {
                        setChildWeightUnit(e);
                      }}
                      className='ant-select-selector'
                      placeholder='Unit'
                    disabled

                    >
                      {WeightUnit?.map((item, index) => (
                        <Select.Option key={index} value={item?.id}>
                          {item?.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div style={{ display: 'flex', gap: '20px' }}>
                <Form.Item
                  style={{ width: '50%' }}
                  name='sexualOrientationPatient'
                  label='Patient Sexual orientation'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your sexual orientation'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Please select sexual orientation'
                  >
                    {sexualOrientation?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ width: '50%' }}
                  name='pronounPatient'
                  label='Pronouns'
                  rules={[
                    {
                      required: true,
                      message: 'Please select your pronoun'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Select pronoun'
                  >
                    {pronounList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div style={{ display: 'flex', gap: '20px' }}>
                <Form.Item
                  style={{ width: '50%' }}
                  name='gender'
                  label='Patient Current Gender'
                  rules={[
                    {
                      required: true,
                      message: 'Please select your gender'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Patient gender'
                  >
                    {gendersList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ width: '50%' }}
                  name='birthGenderPatient'
                  label='Patient Birth Gender'
                  rules={[
                    {
                      required: true,
                      message: 'Please select your birth gender'
                    }
                  ]}
                >
                  <Select
                    className='ant-select-selector'
                    placeholder='Patient BirthGender'
                  >
                    {birthGendersList?.map((item, index) => (
                      <Select.Option key={index} value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                name='relation'
                label='Relationship with Patient'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your relationship'
                  }
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className='ant-select-selector'
                  placeholder='Relationship'
                >
                  <Select.Option value='parent'>Parent</Select.Option>
                  <Select.Option value='guardian'>Guardian</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='languagePatient'
                label='Patient Language'
                rules={[
                  {
                    required: true,
                    message: 'Please select your language'
                  }
                ]}
              >
                <Select
                  mode='multiple'
                  showSearch
                  className='ant-select-selector'
                  placeholder='language'
                >
                  {AllLanguages?.map((item, index) => (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='date_of_birth_patient'
                label='Patient Date Of Birth'
                rules={[
                  {
                    required: true,
                    message: 'Please select your date of birth'
                  }
                ]}
              >
                <DatePicker
                  disabled
                  disabledDate={disabledFutureDate}
                  className='ant-input-affix-wrapper'
                  placeholder='Date of birth'
                  suffixIcon={<ReactSVG width='20px' src={calendarSimple} />}
                  format='MM-DD-YYYY'
                />
              </Form.Item>

              <h1>Guardian Info</h1>
              <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
                <Form.Item
                  style={{ width: '33.33%' }}
                  name='guardianFirstName'
                  label='Guardian First Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter guardian first name'
                    }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Guardian first name'
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '33.33%' }}
                  name='guardian_middle_name'
                  label='Guardian Middle Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Middle name'
                    }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Guardian Middle name'
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '33.33%' }}
                  name='guardianLastName'
                  label='Guardian Last Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter guardian last name'
                    }
                  ]}
                >
                  <Input
                    className='ant-input-affix-wrapper'
                    placeholder='Guardian last name'
                  />
                </Form.Item>
              </div>

              <Form.Item
                name='date_of_birth'
                label='Date Of Birth'
                rules={[
                  {
                    required: true,
                    message: 'Please select your date of birth'
                  }
                ]}
              >
                <DatePicker
                  disabled
                  disabledDate={disabledFutureDate}
                  className='ant-input-affix-wrapper'
                  placeholder='Date of birth'
                  suffixIcon={<ReactSVG width='20px' src={calendarSimple} />}
                  format='MM-DD-YYYY'
                />
              </Form.Item>

              <Form.Item label='Attachment'>
                <div className='formInputs'>
                  <div
                    className={`fileField ${!guardianValidation && 'error'}`}
                  >
                    <input
                      className='field'
                      id='guardianID'
                      name='guardianID'
                      type='file'
                      placeholder='Guardian’s ID'
                      // value={guardianFile}
                      onChange={handleInputChange}
                      style={{ boxShadow: 'none', display: 'none' }}
                    />
                    <label
                      className='fileLabel'
                      type='file'
                      htmlFor='guardianID'
                      // style={{
                      //   minHeight: '45px',
                      //   width: '100%',
                      //   alignItems: 'center',
                      //   display: 'flex',
                      //   justifyContent: 'space-between',
                      //   padding: '0px 10px'
                      // }}
                    >
                      <p
                        style={
                          guardianFile
                            ? { color: 'gray' }
                            : { color: 'lightgray' }
                        }
                      >
                        {guardianFile ? guardianFile : 'Attachment'}
                      </p>
                      <ReactSVG src={paperclip} />
                    </label>
                  </div>
                  {!guardianValidation && (
                    <p className='errorMessage'>Please select file</p>
                  )}
                </div>
              </Form.Item>
              <Form.Item
                name='genderEmail'
                label='Guardian Email Address'
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please enter your valid guardian email'
                  }
                ]}
              >
                <Input
                  disabled={
                    bookingData?.bookingFlowPatientData?.email ? true : false
                  }
                  className='ant-input-affix-wrapper'
                  placeholder='Guardian email address'
                  type='email'
                />
              </Form.Item>
              <Form.Item
                name='guardianPhone_number'
                label='Guardian Phone Number'
                rules={[
                  {
                    validator: validatePhoneNumber
                  }
                ]}
              >
                <Input
                  onChange={handleChangePhoneNumberPattren}
                  className='ant-input-affix-wrapper'
                  placeholder='Guardian phone number'
                  min={0}
                />
              </Form.Item>
              <Form.Item
                name='guardianAddress'
                label='Guardian Address'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your guardian address'
                  }
                ]}
              >
                <Input
                  className='ant-input-affix-wrapper'
                  placeholder='Guardian reachable address'
                  onChange={e =>
                    dispatch(
                      setBookingFlowLocation({
                        address: e.target.value,
                        coordinates: [
                          coords?.longitude ? coords?.longitude : 0,
                          coords?.latitude ? coords?.latitude : 0
                        ]
                      })
                    )
                  }
                  // suffix={
                  //   <ReactSVG
                  //     style={{ cursor: "pointer" }}
                  //     // onClick={getLocation}
                  //     src={locationIcon}
                  //   />
                  // }
                />
              </Form.Item>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                <div style={{ width: '200px', marginBottom: '20px' }}>
                  <SecondaryButton type='submit' width='100%'>
                    Update
                  </SecondaryButton>
                </div>
              </div>
            </Form>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default PatientPersonalInfoForm;
