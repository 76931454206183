import  { useEffect, useState } from "react";
import classes from "./Questions.module.scss";
import {
  AnswerSave,
  AnswerSaveProgram,
  GetPreQuestion,
  GetPreQuestionTreatementPrograms,
} from "./constant";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Form, Spin } from "antd";
import { getRequest, putRequest } from "../../service/ApisFunctions";
import { setBookingFlowMedialInfoSteps } from "../../Redux/Reducers/bookingSlice";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";
import Questionnaire1 from "./nestedQuestion";

const Questions = () => {
  const [loading, setLoading] = useState(false);
  const [preQuestions, setpPreQuestions] = useState([]);
  const [changeQuestionNo, setChangeQuestionNo] = useState(0);
  const [progressLine, setProgressLine] = useState(0);
  const [answerData, setAnswerData] = useState([]);
  const [CheckBoxAnswer, setCheckBoxAnswer] = useState([]);
  const [InputAnswer, setInputAnswer] = useState("");
  const [optionAnswer, setOptionAnswer] = useState("");
  const [ImagesAnswer, setImagesAnswer] = useState([]);
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState(
    preQuestions[changeQuestionNo]?._id
  );
  const dispatch = useDispatch();
  const speacilityDetails = useSelector(
    (state) => state.authReducer.speacilityDetails
  );

  const userBookingId = useSelector(
    (state) => state?.authReducer?.userBookingId
  );

  const bookingType = useSelector((state) => state.authReducer.bookingType);

  const GetConditions = async () => {
    setLoading(true);
    const onSuccess = (res) => {
      setpPreQuestions(res?.data?.questions);
      setCurrentQuestion(res?.data?.questions[0]);
      setLoading(false);
    };

    const onError = (err) => {
      setLoading(false);
    };
    await getRequest(
      "",
      bookingType === "Treatment programs"
        ? `${GetPreQuestionTreatementPrograms}/${speacilityDetails?._id}`
        : `${GetPreQuestion}/${speacilityDetails?._id}`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (speacilityDetails?._id) {
      GetConditions();
    }
  }, [speacilityDetails]);

  const ChangeQuestion = async () => {
    if (changeQuestionNo + 1 == preQuestions?.length) {
      setLoading(true);
      setProgressLine(100);
      const onSuccess = () => {
        setLoading(false);
        dispatch(setBookingFlowMedialInfoSteps(2));
      };
      const onError = () => {
        setLoading(false);
      };

      const formData = {
        pre_questions: answerData,
        isPreQuestionAttempted: true,
      };

      if (bookingType === "Treatment programs") {
        const route = `${AnswerSaveProgram}/${userBookingId}`;

        await putRequest(formData, route, true, onSuccess, onError);
      } else {
        const route = `${AnswerSave}/${userBookingId}`;

        await putRequest(formData, route, true, onSuccess, onError);
      }
    } else {
      setChangeQuestionNo((pre) => pre + 1);
      setCurrentQuestion(preQuestions[changeQuestionNo + 1]);
    }
  };

  const questionHandler = () => {
    if (preQuestions?.length <= 0) {
      dispatch(setBookingFlowMedialInfoSteps(2));
    }
    if (changeQuestionNo == preQuestions?.length) {
      dispatch(setBookingFlowMedialInfoSteps(2));
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        className={classes.fields}
        onFinish={ChangeQuestion}
      >
        {/* <h1 className={classes.questionHeading}>
          
          Question
          {completeQuestionCounter < 10
            ? ` 0${completeQuestionCounter}`
            : completeQuestionCounter}
        </h1> */}
        <div className={classes.progressBox}>
          <p
            style={{ width: progressLine + "%", transition: "all .3s" }}
            className={classes.innerLine}
          >
            <span className={classes.percentageNumber}>{progressLine}%</span>
          </p>
        </div>
        {preQuestions?.length > 0 ? (
          <Questionnaire1
            questionHandler={questionHandler}
            setCurrentQuestionId={setCurrentQuestionId}
            currentQuestionId={currentQuestionId}
            setCurrentQuestion={setCurrentQuestion}
            currentQuestion={currentQuestion}
            ChangeQuestion={ChangeQuestion}
            answerData={answerData}
            setAnswerData={setAnswerData}
            setPreviousQuestions={setPreviousQuestions}
            previousQuestions={previousQuestions}
            setProgressLine={setProgressLine}
            progressLine={progressLine}
            setCheckBoxAnswer={setCheckBoxAnswer}
            CheckBoxAnswer={CheckBoxAnswer}
            setInputAnswer={setInputAnswer}
            setImagesAnswer={setImagesAnswer}
            InputAnswer={InputAnswer}
            ImagesAnswer={ImagesAnswer}
            setOptionAnswer={setOptionAnswer}
            optionAnswer={optionAnswer}
            setChangeQuestionNo={setChangeQuestionNo}
          />
        ) : (
          <>
            <Empty />
            <SecondaryButton onClick={() => dispatch(setBookingFlowMedialInfoSteps(2))}>
              Next
            </SecondaryButton>
          </>
        )}
      </Form>
    </Spin>
  );
};

export default Questions;
