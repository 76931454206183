import { createSlice } from "@reduxjs/toolkit";

const gernalSlice = createSlice({
  name: "gernal",
  initialState: {
    loader: false,
    headerNavigation: null,
    userDetails: {
      first_name: "",
      last_name: "",
      gender: "",
      Guardiangender: "",
      email: "",
      genderEmail: "",
      phone_number: "",
      guardianPhone_number: "",
      address: "",
      guardianAddress: "",
      guardianFirstName: "",
      guardianLastName: "",
      dob: null,
      dob_patient: null,
      file: "",
      relation: "",
      birthGender: "",
      sexualOrientation: "",
      race: "",
      ethnicity: "",
      height: {},
      weight: {},
      language: "",
      birthGenderPatient: "",
      sexualOrientationPatient: "",
      racePatient: "",
      ethnicityPatient: "",
      heightPatient: {},
      weightPatient: {},
      languagePatient: "",
    },

    selectedState: "",
    setConsent: false,
    bookingFor: "patient",
    isExisting: false,
    otherPatient: {},
    infoSteps: { state: "", condition: false },
    completeUser: {},
    clientSecret: {},
    step: 0,
    doctorId: "",
    timeSlot: {},
    launchVideoGlobal: false,
    isAudioGlobal: true,
    isVideoGlobal: true,
    location: {},
    videoChat: false,
    onlineUsers: [],
    consulatationType: "schedule",
    messageCount: 0,
    notificationCount: 0,
    fullScreen: false,
    websiteContent: null,
    consentForms: null,
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setInfoSteps: (state, action) => {
      state.infoSteps = action.payload;
    },
    setHeaderNavigation: (state, action) => {
      state.headerNavigation = action.payload;
    },
    setSteps: (state, action) => {
      state.step = action.payload;
    },
    setCompleteUser: (state, action) => {
      state.completeUser = action.payload;
    },

    setClinetSecret: (state, action) => {
      state.clientSecret = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },

    setSelectedState: (state, action) => {
      state.selectedState = action.payload;
    },
    setConsent: (state, action) => {
      state.consent = action.payload;
    },
    setBookingFor: (state, action) => {
      state.bookingFor = action.payload;
    },
    setDoctorId: (state, action) => {
      state.doctorId = action.payload;
    },
    setTimeSlot: (state, action) => {
      state.timeSlot = action.payload;
    },
    setLaunchVideoGlobal: (state, action) => {
      state.launchVideoGlobal = action.payload;
    },
    setAudioGlobal: (state, action) => {
      state.isAudioGlobal = action.payload;
    },
    setVideoGlobal: (state, action) => {
      state.isVideoGlobal = action.payload;
    },
    setVideoChat: (state, action) => {
      state.videoChat = action.payload;
    },
    setIsExisting: (state, action) => {
      state.isExisting = action.payload;
    },
    setOtherpatient: (state, action) => {
      state.otherPatient = action.payload;
    },
    setCurrentLocation: (state, action) => {
      state.location = action.payload;
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload;
    },
    setConsultationType: (state, action) => {
      state.consulatationType = action.payload;
    },
    setMessagesCount: (state, action) => {
      state.messageCount = action.payload;
    },
    setnotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setFullScreen: (state, action) => {
      state.fullScreen = action.payload;
    },
    setWebsiteContent: (state, action) => {
      state.websiteContent = action.payload;
    },
    setConsentForms: (state, action) => {
      state.consentForms = action.payload;
    },
  },
});

export const {
  setLoader,
  setUserDetails,
  setInfoSteps,
  setCompleteUser,
  setClinetSecret,
  setSteps,
  setSelectedState,
  setConsent,
  setBookingFor,
  setDoctorId,
  setTimeSlot,
  setLaunchVideoGlobal,
  setAudioGlobal,
  setVideoGlobal,
  setIsExisting,
  setOtherpatient,
  setCurrentLocation,
  setVideoChat,
  setOnlineUsers,
  setConsultationType,
  setMessagesCount,
  setnotificationCount,
  setFullScreen,
  setHeaderNavigation,
  setWebsiteContent,
  setConsentForms,
} = gernalSlice.actions;
export const generalReducer = gernalSlice.reducer;
