import { Empty, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest, putRequest } from "../../../service/ApisFunctions";
import { setBookingFlowMedialInfoSteps } from "../../../Redux/Reducers/bookingSlice";
import SecondaryButton from "../../UI/SecondaryButton/SecondaryButton";
import { useNavigate, useParams } from "react-router-dom";
import Questionnaire1 from "../../Questionnaire1/nestedQuestion";
import "./consultationMedicalHistory.scss";
import SkeletonInput from "../../UI/Skeleton/skeletonInput";

export const ConsultationPreQues = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bookingIdParam = useParams();

  const [loading, setLoading] = useState(false);
  const [singleBooking, setSingleBooking] = useState(null);
  const [allQuestionsLoading, setAllQuestionsLoading] = useState(false);
  const [preQuestions, setpPreQuestions] = useState([]);
  const [changeQuestionNo, setChangeQuestionNo] = useState(0);
  const [progressLine, setProgressLine] = useState(0);
  const [answerData, setAnswerData] = useState([]);
  const [CheckBoxAnswer, setCheckBoxAnswer] = useState([]);
  const [InputAnswer, setInputAnswer] = useState("");
  const [optionAnswer, setOptionAnswer] = useState("");
  const [ImagesAnswer, setImagesAnswer] = useState([]);
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState(
    preQuestions?.[changeQuestionNo]?._id
  );

  const getbookingById = async () => {
    setAllQuestionsLoading(true);
    const onSuccess = (res) => {
      setSingleBooking(res?.data);
    };
    const onError = (err) => {
      setAllQuestionsLoading(false);
    };

    const route = `booking/booking_detail/${bookingIdParam?.bookingId}`;
    await getRequest("", route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (bookingIdParam?.bookingId) {
      getbookingById();
    }
  }, [bookingIdParam]);

  const GetConditions = async (booking) => {
    const onSuccess = (res) => {
      setpPreQuestions(res?.data?.questions);
      setCurrentQuestion(res?.data?.questions[0]);
      setAllQuestionsLoading(false);
    };

    const onError = (err) => {
      setAllQuestionsLoading(false);
    };

    await getRequest(
      "",
      singleBooking?.booking_type === "program"
        ? `${"treatment_program/program_question_listing"}/${
            singleBooking?.program?._id?._id
          }`
        : singleBooking?.category?._id
        ? `${"speciality/category_question_listing"}/${
            singleBooking?.category?._id
          }`
        : `preQuestion/get-pre-question/${singleBooking?.speciality?.pre_questions}`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (singleBooking?._id) {
      GetConditions();
    }
  }, [singleBooking]);

  const ChangeQuestion = async (array) => {
    if (changeQuestionNo + 1 == preQuestions?.length) {
      setLoading(true);
      setProgressLine(100);
      const onSuccess = () => {
        setLoading(false);
        singleBooking?.urgent_care
          ? navigate(`/urgent-chat/${singleBooking?._id}`)
          : navigate("/consultations");
      };
      const onError = () => {
        setLoading(false);
      };

      const formData = {
        pre_questions: array,
        isPreQuestionAttempted: array?.length ? true : false,
        ...(singleBooking?.program
          ? { program: singleBooking?.program }
          : { speciality: singleBooking?.speciality }),
        ...(singleBooking?.category && {
          category: singleBooking?.category,
        }),
      };

      if (singleBooking?.booking_type === "program") {
        const route = `${"booking/update_program_booking"}/${
          bookingIdParam?.bookingId
        }`;

        await putRequest(formData, route, true, onSuccess, onError);
      } else {
        const route = `${"booking/update_video_booking"}/${
          bookingIdParam?.bookingId
        }`;

        await putRequest(formData, route, true, onSuccess, onError);
      }
    } else {
      setChangeQuestionNo((pre) => pre + 1);
      setCurrentQuestion(preQuestions[changeQuestionNo + 1]);
    }
  };

  const questionHandler = () => {
    if (preQuestions?.length <= 0) {
      dispatch(setBookingFlowMedialInfoSteps(1));
    }
    if (changeQuestionNo == preQuestions?.length) {
      dispatch(setBookingFlowMedialInfoSteps(1));
    }
  };

  return (
    <Spin spinning={loading}>
      {/* <h2 style={{ textAlign: "center" }}>Questionnaire</h2> */}
      {allQuestionsLoading ? (
        <>
          <div style={{ marginTop: "20px" }}>
            <SkeletonInput size="small" block={true} />
          </div>
          <div style={{ marginTop: "10px" }}>
            <SkeletonInput block={true} />
          </div>

          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "45%" }}>
              <SkeletonInput size="small" block={false} />
            </div>
            <div style={{ width: "45%" }}>
              <SkeletonInput size="small" block={false} />
            </div>
            <div style={{ width: "45%" }}>
              <SkeletonInput size="small" block={false} />
            </div>
            <div style={{ width: "45%" }}>
              <SkeletonInput size="small" block={false} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              gap: "20px",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <SkeletonInput active block={false} />
            </div>
            <div>
              <SkeletonInput active block={false} />
            </div>
          </div>
        </>
      ) : (
        <div className={"medicalInfoSteps"}>
          <Form layout="vertical" className={"fields"}>
            <div className={"progressBox"}>
              <p
                style={{ width: progressLine + "%", transition: "all .3s" }}
                className={"innerLine"}
              >
                <span className={"percentageNumber"}>{progressLine}%</span>
              </p>
            </div>
            {preQuestions?.length > 0 ? (
              <Questionnaire1
                questionHandler={questionHandler}
                setCurrentQuestionId={setCurrentQuestionId}
                currentQuestionId={currentQuestionId}
                setCurrentQuestion={setCurrentQuestion}
                currentQuestion={currentQuestion}
                ChangeQuestion={ChangeQuestion}
                answerData={answerData}
                setAnswerData={setAnswerData}
                setPreviousQuestions={setPreviousQuestions}
                previousQuestions={previousQuestions}
                setProgressLine={setProgressLine}
                progressLine={progressLine}
                setCheckBoxAnswer={setCheckBoxAnswer}
                CheckBoxAnswer={CheckBoxAnswer}
                setInputAnswer={setInputAnswer}
                setImagesAnswer={setImagesAnswer}
                InputAnswer={InputAnswer}
                ImagesAnswer={ImagesAnswer}
                setOptionAnswer={setOptionAnswer}
                optionAnswer={optionAnswer}
                setChangeQuestionNo={setChangeQuestionNo}
              />
            ) : (
              <>
                <Empty />
                <SecondaryButton onClick={() => navigate("/consultations")}>
                  Next
                </SecondaryButton>
              </>
            )}
          </Form>
        </div>
      )}
    </Spin>
  );
};
