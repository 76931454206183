import React, { useEffect, useState } from 'react';
import './consultationDetails.scss';
import { Col, Divider, Input, Row, Select, Spin, message } from 'antd';
import HealthInformation from '../healthInformation';
import { SearchOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import AllergiesTable from './allergiesTable';
import PastconsultationHistory from './pastconsultationHistory';
import { getRequest } from '../../../../service/ApisFunctions';
import OtherDetails from './otherDetails';

const ConsultationDetails = ({ booking }) => {
  const [pastConsultations, setPastConsultations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allergiesLoading, setAllergiesLoading] = useState(false);
  const [allAllergies, setAllAllergies] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [patientDetail, setPatientDetail] = useState(null);

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const getPastConsultations = async () => {
    setLoading(true);

    const onSuccess = res => {
      setPastConsultations(
        booking?.booking_as == 'patient' ? res?.data?.self : res?.data?.others
      );

      setLoading(false);
    };
    const onError = err => {
      setLoading(false);
    };

    const route = `booking/get-others-self-data/${booking?._id}`;
    await getRequest('', route, true, onSuccess, onError);
  };

  const getbookingAllAllergies = async () => {
    setAllergiesLoading(true);

    const onSuccess = res => {
      setAllAllergies(res?.data?.Items);
      setAllergies(res?.data?.Items?.reverse());

      setAllergiesLoading(false);
    };
    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setAllergiesLoading(false);
    };

    const route = `allergy/retrieve-allergy-details/${booking?.doseSpotId}`;
    await getRequest('', route, true, onSuccess, onError);
  };

  const getbookingPatientDetail = async () => {
    const onSuccess = res => {
      setPatientDetail(res?.data?.Item);
    };
    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
    };

    const route = `customer/get-customer-details/${booking?.doseSpotId}`;
    await getRequest('', route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (booking?.doseSpotId) {
      getbookingPatientDetail();
    }
  }, [booking]);

  useEffect(() => {
    if (booking?._id) {
      getPastConsultations();
      getbookingAllAllergies();
    }
  }, [booking?._id]);

  const searchFilterAllergies = value => {
    const filterData = allAllergies?.filter(item =>
      item?.DisplayName?.toLowerCase()
        ?.trim()
        ?.includes(value?.toLowerCase()?.trim())
    );

    setAllergies(filterData);
  };

  return (
    <div className='ehr'>
      <Row gutter={[10, 10]}>
        {/* Health Equity Information */}
        <Col span={mobileResponsive ? 24 : 12}>
          <div className='healthEquity'>
            <div className='healthEquityHeader'>
              <h2>Health Equity Information</h2>
            </div>
            <Divider />

            <Row gutter={[10, 10]}>
              <Col span={12}>
                <HealthInformation
                  question='Race'
                  answer={booking?.patient?.race || '--'}
                />
              </Col>
              <Col span={12}>
                <HealthInformation
                  question='Ethnicity'
                  answer={
                    booking?.patient?.ethnicity?.length
                      ? booking?.patient?.ethnicity?.map(item =>
                          booking?.patient?.ethnicity?.[
                            booking?.patient?.ethnicity?.length - 1
                          ] === item
                            ? item
                            : item + ' | '
                        )
                      : '-'
                  }
                />
              </Col>
              <Col span={12}>
                <HealthInformation
                  question='Pronoun'
                  answer={booking?.patient?.pronoun || '--'}
                />
              </Col>
              <Col span={12}>
                <HealthInformation
                  question='Birth Gender'
                  answer={booking?.patient?.birthGender || '--'}
                />
              </Col>
              <Col span={12}>
                <HealthInformation
                  question='Current Gender'
                  answer={booking?.patient?.gender || '--'}
                />
              </Col>
              <Col span={12}>
                <HealthInformation
                  question='Sexual orientation'
                  answer={booking?.patient?.sexualOrientation || '--'}
                />
              </Col>
            </Row>
          </div>
        </Col>

        {/* Allergies */}

        <Col span={mobileResponsive ? 24 : 12}>
          <Spin spinning={allergiesLoading}>
            <div className='allergies'>
              <div className='allergiesHeader'>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <h2>Allergies</h2>

                  <Input
                    onChange={e => searchFilterAllergies(e?.target?.value)}
                    className='ant-input-affix-wrapper'
                    suffix={
                      <SearchOutlined size={15} style={{ color: '#958070' }} />
                    }
                    placeholder='Search here'
                  />
                </div>
              </div>
              <Divider />

              <AllergiesTable data={allergies} />
            </div>
          </Spin>
        </Col>

        <Col span={24}>
          <OtherDetails
            title='Medical history'
            questions={booking?.medicalHistory}
            check={true}
          />
        </Col>

        {/* Questionnaire */}

        <Col span={24}>
          <OtherDetails
            title='Questionnaire'
            questions={booking?.pre_questions}
            check={false}
          />
        </Col>

        {/* Social history */}

        <Col span={24}>
          <OtherDetails
            title='Social history'
            questions={booking?.socialHistory}
            check={false}
          />
        </Col>

        {/* Encounters */}

        {/* <Col span={24}>
          <OtherDetails
            title='Encounters'
            questions={[]}
            encounter={patientDetail?.Encounter}
            check={false}
          />
        </Col> */}
      </Row>
    </div>
  );
};

export default ConsultationDetails;
