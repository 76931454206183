import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookingFlowPatientData: null,
  bookingFlowOtherPatient: null,
  bookingFlowOtherPatientIsExisting: false,
  bookableFlowSpeciality: null,
  bookableFlowCondition: null,
  bookableFlowProgram: null,
  bookableFlowMedicalHistory: null,
  bookableFlowSocialHistory: null,
  bookingFlowType: "",
  bookingFlowDoctor: null,
  bookingFlowSchedule: null,
  bookingFlowConsent: false,
  bookingFlowAs: "patient",
  bookingFlowInformationSteps: 0,
  bookingFlowMedialInfoSteps: 0,
  bookingFlowMedicalStep: 0,
  bookingFlowLocation: null,
  bookingFlowPrice: null,
  bookingFlowMedialHistoryIsUpdated: false,
  bookingFlowSocialHistoryIsUpdated: true,
  bookingFlowCouponDetail: null,
  bookingFlowIsUrgent: false,
  bookingFlowAccessType: "",
  bookingFlowLogin: null,
};
const bookingSlice = createSlice({
  name: "booking",
  initialState: initialState,

  reducers: {
    resetBookingState: () => initialState,
    setBookableFlowSpeciality: (state, action) => {
      state.bookableFlowSpeciality = action.payload;
    },
    setBookableFlowCondition: (state, action) => {
      state.bookableFlowCondition = action.payload;
    },
    setBookingFlowType: (state, action) => {
      state.bookingFlowType = action.payload;
    },
    setBookableFlowProgram: (state, action) => {
      state.bookableFlowProgram = action.payload;
    },
    setBookableFlowMedicalHistory: (state, action) => {
      state.bookableFlowMedicalHistory = action.payload;
    },
    setBookableFlowSocialHistory: (state, action) => {
      state.bookableFlowSocialHistory = action.payload;
    },
    setBookingFlowConsent: (state, action) => {
      state.bookingFlowConsent = action.payload;
    },
    setBookingFlowPatientData: (state, action) => {
      state.bookingFlowPatientData = action.payload;
    },
    setbookingFlowAs: (state, action) => {
      state.bookingFlowAs = action.payload;
    },
    setBookingFlowInformationSteps: (state, action) => {
      state.bookingFlowInformationSteps = action.payload;
    },
    setBookingFlowOtherPatient: (state, action) => {
      state.bookingFlowOtherPatient = action.payload;
    },
    setBookingFlowOtherPatientIsExisting: (state, action) => {
      state.bookingFlowOtherPatientIsExisting = action.payload;
    },
    setBookingFlowDoctor: (state, action) => {
      state.bookingFlowDoctor = action.payload;
    },
    setBookingFlowSchedule: (state, action) => {
      state.bookingFlowSchedule = action.payload;
    },
    setBookingFlowLocation: (state, action) => {
      state.bookingFlowLocation = action.payload;
    },
    setBookingFlowPrice: (state, action) => {
      state.bookingFlowPrice = action.payload;
    },
    setBookingFlowMedialInfoSteps: (state, action) => {
      state.bookingFlowMedialInfoSteps = action.payload;
    },
    setBookingFlowMedialHistoryIsUpdated: (state, action) => {
      state.bookingFlowMedialHistoryIsUpdated = action.payload;
    },
    setBookingFlowSocialHistoryIsUpdated: (state, action) => {
      state.bookingFlowSocialHistoryIsUpdated = action.payload;
    },
    setBookingFlowCouponDetail: (state, action) => {
      state.bookingFlowCouponDetail = action.payload;
    },
    setBookingFlowIsUrgent: (state, action) => {
      state.bookingFlowIsUrgent = action.payload;
    },
    setBookingFlowAccessType: (state, action) => {
      state.bookingFlowAccessType = action.payload;
    },
    setBookingFlowLogin: (state, action) => {
      state.bookingFlowLogin = action.payload;
    },
  },
});

export const {
  resetBookingState,
  setBookableFlowCondition,
  setBookingFlowType,
  setBookableFlowSpeciality,
  setBookableFlowProgram,
  setBookingFlowConsent,
  setBookingFlowPatientData,
  setbookingFlowAs,
  setBookingFlowInformationSteps,
  setBookingFlowOtherPatient,
  setBookingFlowDoctor,
  setBookingFlowSchedule,
  setBookingFlowOtherPatientIsExisting,
  setBookingFlowLocation,
  setBookingFlowPrice,
  setBookingFlowMedialInfoSteps,
  setBookableFlowMedicalHistory,
  setBookableFlowSocialHistory,
  setBookingFlowMedialHistoryIsUpdated,
  setBookingFlowSocialHistoryIsUpdated,
  setBookingFlowCouponDetail,
  setBookingFlowIsUrgent,
  setBookingFlowAccessType,
  setBookingFlowLogin,
} = bookingSlice.actions;
export const bookingReducer = bookingSlice.reducer;
