import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import { OhanaPng } from '../../assets';

export const TreatmentInvoice = ({ bookingDetails, offer }) => {

  const styles = StyleSheet.create({
    page: {
      padding: 30
    },
    logo: {
      height: '40px',
      width: '200px',
      marginBottom: '30px'
    },
    titleContainer: {
      borderBottom: '1px solid black',
      marginBottom: '30px'
    },
    reportTitle: {
      // Add styles for the report title
    },
    invoiceNumberContainer: {
      // Add styles for the invoice number container
    },
    invoiceNumberTitle: {
      // Add styles for the invoice number title
    },
    invoiceNumber: {
      // Add styles for the invoice number
    },
    headerContainer: {
      // Add styles for the header container
    },
    billTo: {
      // Add styles for the bill-to section
    },
    flex: {
      display: 'flex'
    },
    // ... Add more styles for body, footer, etc.
    table: {
      width: '100%'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8
    },
    header: {
      borderTop: 'none'
    },
    bold: {
      fontWeight: 'bold'
    },
    row1: {
      width: '40%'
    },
    row2: {
      width: '20%'
    },
    row3: {
      width: '20%'
    },
    row4: {
      width: '20%'
    },
    textStyle: {
      fontSize: '12px',
      width: '100%'
    },
    infoText: {
      fontSize: '10px',
      width: '100%',
      paddingRight: '20px',
      marginBottom: '10px'
    },
    infoTextBold: {
      fontSize: '10px',
      width: '100%',
      paddingRight: '20px',
      marginBottom: '10px',
      fontWeight: 'bold'
    },
    tableTextBold: {
      fontSize: '10px',
      fontWeight: 'bold'
    },
    tableText: {
      fontSize: '10px'
    },

    infoContainerWrapper: {
      width: '100%',
      paddingBottom: '20px',

      marginBottom: '20px',
      borderBottom: '1px solid black'
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    info: {
      width: '50%'
    },
    totalContainerWrapper: {
      width: '100%',
      borderTop: '1px solid black'
    },
    mr10: {
      marginRight: '40px'
    },
    customRightText: {
      textAlign: 'right',
      width: '100%'
    },
    footer: {
      width: '100%',
      margin: '0 auto',
      position: 'absolute',
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center'
    },
    footerDetails: {
      width: '100%',

      borderTop: '1px solid black',
      marginRight: '30px',
      marginLeft: '30px'
    },
    footerText: {
      marginTop: '20px',
      fontSize: 10,
      color: 'gray'
    }
  });

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.titleContainer}>
          <Image style={styles.logo} src={OhanaPng} alt='&&&&' />
          <Text style={styles.reportTitle}>Treatment Program Invoice</Text>
        </View>
        <View style={styles.infoContainerWrapper}>
          <View style={styles.infoContainer}>
            <View style={styles.info}>
              <Text style={[styles.bold, styles.infoText]}>
                Name: {`${bookingDetails?.patient?.first_name|| "-"} ${bookingDetails?.patient?.last_name|| "-"}`}
              </Text>
              <Text style={[styles.bold, styles.infoText]}>
                Address: {bookingDetails?.patient?.location?.address}
              </Text>
              <Text style={[styles.bold, styles.infoText]}>
                Email: {bookingDetails?.patient?.email}
              </Text>
              <Text style={[styles.bold, styles.infoText]}>
                Phone: {bookingDetails?.patient?.phone_number}
              </Text>
            </View>
            <View style={styles.info}>
              <Text style={[styles.row1, styles.bold, styles.infoText]}>
                Provider:{' '}
                {bookingDetails?.provider_id?.first_name +
                  ' ' +
                  bookingDetails?.provider_id?.last_name}
              </Text>
              <Text style={[styles.row1, styles.bold, styles.infoText]}>
                Payment Status:{' '}
                {offer?.status === 'selected' ? 'Paid' : 'Pending'}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.row, styles.bold, styles.header]}>
            <Text style={[styles.row1, styles.bold, styles.tableTextBold]}>
              Program
            </Text>
            <Text style={[styles.row2, styles.bold, styles.tableTextBold]}>
              Duration
            </Text>
            <Text style={[styles.row2, styles.bold, styles.tableTextBold]}>
            Description
            </Text>
            <Text style={[styles.row3, styles.bold, styles.tableTextBold]}>
              Price
            </Text>
            <Text style={[styles.row3, styles.bold, styles.tableTextBold]}>
              Amount
            </Text>
          </View>
          <View style={styles.row} wrap={false}>
            <Text style={[styles.row1, styles.tableText]}>
              {bookingDetails?.program?.treatment_name}
            </Text>
            <Text style={[styles.row2, styles.tableText]}>
              {offer?.totalDays} Days
            </Text>
            <Text style={[styles.row2, styles.tableText]}>
              {offer?.description}
            </Text>
            <Text style={[styles.row3, styles.tableText]}>
              ${offer?.payment}
            </Text>
            <Text style={[styles.row3, styles.tableText]}>
              ${offer?.payment}
            </Text>
          </View>
        </View>
        <View style={styles.totalContainerWrapper}>
          <View style={styles.row} wrap={false}>
            <Text style={[styles.row1, styles.tableText]}></Text>
            <Text style={[styles.row2, styles.tableText]}></Text>
            <Text style={[styles.row2, styles.tableText]}></Text>
            <Text style={[styles.row3, styles.tableText]}>Total:</Text>
            <Text style={[styles.row3, styles.tableText]}>
              ${offer?.payment}/-
            </Text>
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.footerDetails}>
            <Text style={styles.footerText}>
              © 2023 OhanaDoc. All rights reserved.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
