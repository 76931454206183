import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { logo } from "../../assets";
import "./consent.scss";
import { useSelector } from "react-redux";

const ConsentForm = () => {
  const [allconsentForms, setAllconsentForms] = useState(null);

  const consentForms = useSelector(
    (state) => state.generalReducer.consentForms
  );

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  useEffect(() => {
    if (consentForms?.length > 0) {
      let filterData = [];
      if (window.location.pathname.includes("terms-of-use")) {
        filterData = consentForms?.filter(
          (item) => item?.type === "terms of use"
        );
      } else if (
        window.location.pathname.includes("responsibility-and-billing")
      ) {
        filterData = consentForms?.filter(
          (item) => item?.type === "financial responsibility and billing"
        );
      } else if (window.location.pathname.includes("consent")) {
        filterData = consentForms;
      } else {
        filterData = consentForms?.filter(
          (item) => item?.type === "privacy policy"
        );
      }
      setAllconsentForms(filterData);
    }
  }, [consentForms]);

  return (
    <>
      <div className="consent">
        <div className="consent-header">
          <Link to="/">
            <img
              width={mobileResponsive ? "120px" : "152px"}
              height={mobileResponsive ? "30px" : "auto"}
              src={logo}
              alt="$$$"
            />
          </Link>
        </div>
        {allconsentForms?.map((item, index) => {
          return (
            <div key={index} className="mainConsentFormWrap">
              <h2 className="ConsentHeading">{item?.name}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.details ? item?.details : "-",
                }}
              ></div>
            </div>
          );
        })}
        {/* {updateConsent?.map((item, index) => (
          <div className="mainConsentFormWrap">
            <h2 className="ConsentHeading">{item?.heading}</h2>

            <p className="ConsentText">{item?.topContent}</p>
            <ul style={{ listStyleType: "none" }}>
              {item?.points?.map((item, index) => (
                <li
                  key={index}
                  style={{ marginBottom: "10px", fontWeight: "700" }}
                >
                  {index + 1}. {item?.heading} : <span>{item?.content}</span>
                  {item?.subpoints?.length > 0 && (
                    <ul style={{ paddingLeft: "30px", marginTop: "10px" }}>
                      {item.subpoints.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          style={{ marginBottom: "10px", fontWeight: "700" }}
                        >
                          {subItem?.title} {subItem?.title ? " : " : null}
                          {Array.isArray(subItem?.content) ? (
                            <ul
                              style={{
                                paddingLeft: "30px",
                                marginTop: "10px",
                                listStyleType: "none",
                              }}
                            >
                              {subItem?.content?.map(
                                (thirdNested, thirdNestedIndex) =>
                                  thirdNested?.title ? (
                                    <li
                                      key={thirdNestedIndex}
                                      style={{
                                        marginBottom: "10px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      {thirdNestedIndex + 1} .{" "}
                                      {thirdNested?.title}{" "}
                                      {thirdNested?.title ? " : " : null}
                                      <span>{thirdNested?.content}</span>
                                    </li>
                                  ) : (
                                    <li
                                      key={thirdNestedIndex}
                                      style={{
                                        marginBottom: "10px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      <span>
                                        {thirdNestedIndex + 1} . {thirdNested}
                                      </span>
                                    </li>
                                  )
                              )}
                            </ul>
                          ) : (
                            <span>{subItem?.content}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
        {window.location.pathname.includes("terms-of-use") && (
          <div className="mainConsentFormWrap">
            <p className="ConsentText" style={{ marginTop: "20px" }}>
              I have read this document carefully and understand the risks,
              benefits, and terms related to telemedicine consultations,
              including both synchronous and asynchronous modalities, the use
              and disclosure of my health information, the risks and benefits of
              electronic communication, the terms of use for the OhanaDoc.com
              platform, the selected compounding pharmacy for my medications, my
              financial responsibilities and the billing practices of
              OhanaDoc.com, the procedures for emergency medical situations, and
              the use of my health data for analytics purposes. I hereby give my
              informed consent to participate and use these services, including
              communicating with OhanaDoc.com healthcare providers and staff via
              electronic communication methods, under the terms described
              herein, and I assume financial responsibility for the telemedicine
              services provided. Additionally, I have read and understand the
              limitations of liability as outlined in the Terms and Conditions
              section of this document, including the limitations of liability
              for both OhanaDoc LLC and Harrington Maui Enterprises LLC
            </p>
          </div>
        )} */}
      </div>
    </>
  );
};

export default ConsentForm;
