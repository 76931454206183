import { Col, Divider, Row } from "antd";
import { useState } from "react";
import "./profileMedicalHistory.scss";
import { CollapseArrow } from "../../../assets";
import SingleQuestion from "../../SingleQuestion";

const ProfileMedicalHistory = ({ completeUser }) => {
  const [collapseOpenNumber, setCollapseOpenNumber] = useState(true);

  const allDetails = completeUser?.past_medical_history || null;
  // .slice() // create a shallow copy to avoid mutating original array
  // .sort((a, b) => new Date(b?.date) - new Date(a?.date));

  const toggleCollapse = () => {
    setCollapseOpenNumber(!collapseOpenNumber);
  };

  return (
    <Row className="profileMedicalHistory">
      <Col onClick={() => toggleCollapse()} className="profileMedicalHistoryHeader" span={24}>
        <h2>Medical history</h2>
        <img
          className={collapseOpenNumber ? "active" : ""}
          src={CollapseArrow}
          alt="$$$"
        />
      </Col>

      <Col
        onClick={() => toggleCollapse()}
        span={24}
        className={
          collapseOpenNumber ? "socialHistoryBox open" : "socialHistoryBox"
        }
      >
        {collapseOpenNumber &&
          allDetails?.detail?.map((subItem, subIndex) => (
            <Col key={subIndex} span={24}>
              <SingleQuestion
                key={subIndex}
                question={subItem}
                number={subIndex}
              />
                <Divider style={{margin:"10px 0px !important"}} />

            </Col>
          ))}
      </Col>
    </Row>
  );
};

export default ProfileMedicalHistory;
