import { Link, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import classes from "./Header.module.scss";
import { down, logo, DomyP, MenuIcon, CrossIcon } from "../../../assets";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Col,
  Drawer,
  Dropdown,
  Modal,
  Popover,
  Row,
  message,
} from "antd";
import { GetProviderRequests } from "./Requests/constant";
import { useMediaQuery } from "react-responsive";
import LogoutModal from "../../Logout";
import { headerMarqueeTags } from "./constant";
import {
  setCompleteUser,
  setHeaderNavigation,
  setWebsiteContent,
} from "../../../Redux/Reducers/gernalSlice";
import {
  setSpeacilityDetails,
  setSpeacilityImage,
  setUser,
  setbookingType,
} from "../../../Redux/Reducers/authSlice";
import { getRequest } from "../../../service/ApisFunctions";
import { BellOutlined } from "@ant-design/icons";
import ServiceMenu from "./ServiceMenu";
import ProgramMenu from "./ProgramMenu";
import SkeletonInput from "../../UI/Skeleton/skeletonInput";
import {
  setBookableFlowCondition,
  setBookableFlowProgram,
  setBookableFlowSpeciality,
  setBookingFlowPrice,
  setBookingFlowType,
} from "../../../Redux/Reducers/bookingSlice";
import MobileMenu from "./MobileMenu";
import { DEPLOYMENT_MODE, LOGIN_AS_A_PROVIDER } from "../../../Utils/baseUrl";
import Marquee from "react-fast-marquee";

const Header = ({ showMega }) => {
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const websiteContent = useSelector(
    (state) => state.generalReducer.websiteContent
  );
  const token = useSelector((state) => state.authReducer.paitentToken);
  const notificationCount = useSelector(
    (state) => state.generalReducer.notificationCount
  );

  const headerNavigation = useSelector(
    (state) => state.generalReducer.headerNavigation
  );

  const [searchParams] = useSearchParams();

  const typeSearchParams = searchParams.get("type");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeService, setActiveService] = useState(null);
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
  const [isModal, setIsModal] = useState(false);

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const windowLocation = window.location.pathname;

  const menuToggleHandler = () => {
    setOpenDrawer(!openDrawer);
  };

  const getAllNavigations = async () => {
    const onSuccess = (res) => {
      dispatch(setHeaderNavigation(res.data));
    };
    const onError = (err) => {
      message.error(err.message);
    };

    await getRequest(
      "",
      "navigation/get-navigations",
      true,
      onSuccess,
      onError
    );
  };

  const getWebsiteContent = async () => {
    const onSuccess = (res) => {
      dispatch(setWebsiteContent(res.data[0]?.content));
    };
    const onError = (err) => {
      message.error(err.message);
    };

    await getRequest(
      "",
      `website_content/website_content_detail`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getAllNavigations();
    getWebsiteContent();
  }, []);
  const me = async () => {
    const onSuccess = (res) => {
      dispatch(setCompleteUser(res.data));
    };

    const onError = () => {};

    await getRequest(
      "",
      "auth/auth_me?role=customer",
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    if (token) {
      me();
      dispatch(setUser({}));
    }
  }, [token]);
  const getProviderRequestList = async () => {
    const onSuccess = () => {};

    const onError = (err) => {
      message.error(err?.message);
    };

    await getRequest(
      "",
      `${GetProviderRequests}/${completeUser?.user_id?._id}`,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    if (completeUser?.user_id?._id) {
      getProviderRequestList();
    }
  }, [completeUser]);
  const handleModal = () => {
    setIsModal(true);
  };
  const handleOk = () => {
    setIsModal(false);
  };
  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <div
    //       onClick={() => navigate("/view-consultations")}
    //       style={
    //         windowLocation.includes("view-consultations")
    //           ? {
    //               display: "flex",
    //               alignItems: "center",
    //               gap: "10px",
    //               cursor: "pointer",
    //               fontWeight: "600",
    //               width: "100%",
    //               color: "#f76d00",
    //             }
    //           : {
    //               display: "flex",
    //               alignItems: "center",
    //               gap: "10px",
    //               cursor: "pointer",
    //               fontWeight: "600",
    //               width: "100%",
    //             }
    //       }
    //     >
    //       Dashboard
    //     </div>
    //   ),
    // },
    {
      key: "6",
      label: (
        <div
          onClick={() => navigate("/consultations")}
          style={
            windowLocation.includes("consultations")
              ? {
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "100%",
                  color: "#f76d00",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "100%",
                }
          }
        >
          My Consultations
        </div>
      ),
    },
    {
      key: "15",
      label: (
        <div
          onClick={() => navigate("/refills")}
          style={
            windowLocation.includes("refills")
              ? {
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "100%",
                  color: "#f76d00",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "100%",
                }
          }
        >
          Refills
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => navigate("/messages")}
          style={
            windowLocation.includes("/messages")
              ? {
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "100%",
                  color: "#f76d00",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "100%",
                }
          }
        >
          Messages
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => navigate("/profile")}
          style={
            windowLocation.includes("/profile")
              ? {
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "100%",
                  color: "#f76d00",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  fontWeight: "600",
                  width: "100%",
                }
          }
        >
          Profile Settings
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() => handleModal()}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            fontWeight: "600",
            width: "100%",
            color: "#F76D00",
          }}
        >
          Logout
        </div>
      ),
    },
  ];
  const handlePopoverToggle = (index) => {
    if (openPopoverIndex === index) {
      setOpenPopoverIndex(null); // Close the popover if it's already open
      toggleServiceMenu("");
    } else {
      setOpenPopoverIndex(index); // Open the popover of the clicked item
    }
  };
  const handleSpeciltyClick = (item) => {
    dispatch(setBookableFlowProgram(null));

    dispatch(setBookableFlowSpeciality(item?.speciality));
    dispatch(setBookableFlowCondition(null));
    dispatch(setBookingFlowType(item?.service?.flow));

    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          (item?.specialilty?.price > 0 && item?.specialilty?.price) ||
          item?.specialilty?.categories?.[0]?.price
      )
    );
    navigate(`/speciality-booking-details/${item?.speciality?._id}`);
  };
  const getConditionById = (item) => {
    dispatch(setBookableFlowProgram(null));

    dispatch(setbookingType(item?.service?.flow));
    dispatch(setBookableFlowSpeciality(item?.specialilty));
    dispatch(setBookableFlowCondition(item?.specialilty?.categories?.[0]));
    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          (item?.specialilty?.price > 0 && item?.specialilty?.price) ||
          item?.specialilty?.categories?.[0]?.price
      )
    );
    dispatch(setSpeacilityDetails(item?.specialilty?.categories?.[0]));
    dispatch(setBookingFlowType(item?.service?.flow));
    dispatch(setSpeacilityImage(item?.specialilty?.image));

    navigate(`/booking-details?type=${item?.name}`);
  };

  const getProgramById = (item) => {
    dispatch(setBookableFlowSpeciality(null));
    dispatch(setBookableFlowCondition(null));
    dispatch(setSpeacilityDetails(item?.program));
    dispatch(setSpeacilityImage(item?.program?.image));
    dispatch(setbookingType(item?.service?.flow));
    dispatch(setBookableFlowProgram(item?.program));
    dispatch(setBookingFlowType(item?.service?.flow));
    dispatch(
      setBookingFlowPrice(
        (item?.service?.price > 0 && item?.service?.price) ||
          item?.program?.price
      )
    );
    navigate(`/treatment-consultations-details?type=${item?.name}`);
  };

  const toggleServiceMenu = (item) => {
    setActiveService(item);
    if (item?._id === activeService?._id) {
      setActiveService(null);
      return;
    }
  };

  useEffect(() => {
    setOpenDrawer(false);
  }, [mobileResponsive]);

  return (
    <>
      {!windowLocation.includes("/urgent-chat") ? (
        DEPLOYMENT_MODE === "dev" ? (
          <div className={classes.top_haeder}>
            <Row>
              {mobileResponsive ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "50px" }}
                >
                  <p>{websiteContent?.topheader?.leftSide}</p>
                  {/* <p>Join OhanaDoc today and get 10% off your first service!</p> */}
                </div>
              ) : (
                <Col span={14}>
                  <div style={{ display: "flex", gap: 50 }}>
                    <p>{websiteContent?.topheader?.leftSide}</p>
                    {/* <p>Join OhanaDoc today and get 10% off your first service!</p> */}
                  </div>
                </Col>
              )}
              <Col
                style={{ marginTop: mobileResponsive && 10 }}
                span={mobileResponsive ? 24 : 10}
              >
                <div className={classes.top_header_right}>
                  <p onClick={() => navigate("/join-as-a-provider")}>
                    {websiteContent?.topheader?.rightSide?.joinProvider || "-"}
                  </p>
                  <a
                    href={LOGIN_AS_A_PROVIDER}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>
                      {websiteContent?.topheader?.rightSide?.providerLogin ||
                        "-"}
                    </p>
                  </a>
                  {!token && (
                    <>
                      <p onClick={() => navigate("/signUp")}>
                        {websiteContent?.topheader?.rightSide?.signUp || "-"}
                      </p>
                      <p onClick={() => navigate("/login")}>
                        {websiteContent?.topheader?.rightSide?.login || "-"}
                      </p>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ) : null
      ) : null}

      <header className={classes.header}>
        <div className={classes.header__content}>
          <Link to="/" className={classes.header__content__logo}>
            <img
              width={mobileResponsive ? "120px" : "152px"}
              height={mobileResponsive ? "30px" : "auto"}
              src={logo}
              alt="$$$"
            />
          </Link>
          <nav className={classes.header__content__nav}>
            <>
              <ul>
                {!windowLocation.includes("/urgent-chat") ? (
                  !headerNavigation ? (
                    <>
                      <li>
                        <SkeletonInput size="small" block={false} />
                      </li>

                      <li>
                        <SkeletonInput size="small" block={false} />
                      </li>

                      <li>
                        <SkeletonInput size="small" block={false} />
                      </li>

                      <li>
                        <SkeletonInput size="small" block={false} />
                      </li>
                    </>
                  ) : (
                    headerNavigation?.map((item, index) => (
                      <li key={index}>
                        {item?.type === "Medical consultation" ||
                        item?.type === "Treatment programs" ? (
                          <Popover
                            arrow={false}
                            content={
                              item?.type === "Medical consultation" ? (
                                <ServiceMenu
                                  setOpenDrawer={setOpenDrawer}
                                  service={item}
                                  setOpenPopoverIndex={setOpenPopoverIndex}
                                />
                              ) : item?.type === "Treatment programs" ? (
                                <ProgramMenu
                                  setOpenDrawer={setOpenDrawer}
                                  service={item}
                                  setOpenPopoverIndex={setOpenPopoverIndex}
                                />
                              ) : // ) : item?.type === 'specility' ? (
                              //   <SpecialityMenu
                              //     setOpenDrawer={setOpenDrawer}
                              //     service={item}
                              //     setOpenPopoverIndex={setOpenPopoverIndex}
                              //   />
                              // )
                              null
                            }
                            trigger="click"
                            open={openPopoverIndex === index}
                            onOpenChange={() => handlePopoverToggle(index)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "7px",
                              }}
                              onClick={() => toggleServiceMenu(item)}
                            >
                              <a
                                className={
                                  item?._id === activeService?._id &&
                                  classes?.activeA
                                }
                                rel="noreferrer"
                              >
                                {item?.name}
                              </a>

                              <div
                                className={classes.downSvg}
                                // onClick={() => {
                                //   setShowMega(!showMega);
                                // }}
                              >
                                {item?._id === activeService?._id ? (
                                  <ReactSVG
                                    className={classes.activeDown}
                                    src={down}
                                  />
                                ) : (
                                  <ReactSVG
                                    className={classes.down}
                                    src={down}
                                  />
                                )}
                              </div>
                            </div>
                          </Popover>
                        ) : item?.type === "condition" ? (
                          <div
                            className={
                              item?.name === typeSearchParams
                                ? `${classes.activeA} ${classes.single}`
                                : classes.single
                            }
                            onClick={() => getConditionById(item)}
                          >
                            {item?.name}
                          </div>
                        ) : item?.type === "program" ? (
                          <div
                            className={
                              item?.name === typeSearchParams
                                ? `${classes.activeA} ${classes.single}`
                                : classes.single
                            }
                            onClick={() => getProgramById(item)}
                          >
                            {item?.name}
                          </div>
                        ) : item?.type === "specility" ? (
                          <div
                            className={
                              item?.name === typeSearchParams
                                ? `${classes.activeA} ${classes.single}`
                                : classes.single
                            }
                            onClick={() => handleSpeciltyClick(item)}
                          >
                            {item?.name}
                          </div>
                        ) : null}
                      </li>
                    ))
                  )
                ) : null}

                {!windowLocation.includes("/urgent-chat") && (
                  <>
                    {/* {DEPLOYMENT_MODE === "dev" && (
                      <li>
                        <Link
                          className={
                            windowLocation.includes("labTest") &&
                            classes.activeA
                          }
                        >
                          Lab Testing
                        </Link>
                      </li>
                    )} */}
                    <li>
                      <Link
                        className={
                          windowLocation.includes("refill-request") &&
                          classes.activeA
                        }
                        to={"/refill-request"}
                      >
                        Refill Request
                      </Link>
                    </li>
                  </>
                )}
                <li className={classes.leftBorder}>{""}</li>
                <li className={classes.contact}>
                  <Link
                    className={
                      windowLocation.includes("contact") && classes.activeA
                    }
                    to="/contact"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
              {token && (
                <>
                  <Badge size="small" count={notificationCount}>
                    <Link
                      className={
                        windowLocation.includes("notifications")
                          ? classes.activeNotificationsIcon
                          : classes.notificationsIcon
                      }
                      to="/notifications"
                    >
                      <BellOutlined />
                    </Link>
                  </Badge>
                  <Dropdown
                    menu={{ items }}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <div>
                      <img
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          border: "1px solid gray",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        alt="$$$"
                        // onClick={() => navigate("/profile")} // Add your desired click action
                        src={
                          completeUser?.profile_image !== "null" &&
                          completeUser?.profile_image !== ""
                            ? completeUser?.profile_image
                            : DomyP
                        }
                        className={classes.menu1}
                      />
                    </div>
                  </Dropdown>
                </>
              )}
            </>
          </nav>
          <div
            className={classes.header__content__toggle}
            // onClick={() => setOpenDrawer(false)}
          >
            <img
              src={MenuIcon}
              onClick={menuToggleHandler}
              className={classes.menuImg}
              alt="$$$"
            />
            {token && (
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                trigger={["click"]}
              >
                <div>
                  <img
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "50%",
                      border: "1px solid gray",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    alt="$$$"
                    // onClick={() => navigate("/profile")} // Add your desired click action
                    src={
                      completeUser?.profile_image !== "null" &&
                      completeUser?.profile_image !== "" &&
                      completeUser?.profile_image
                        ? completeUser?.profile_image
                        : DomyP
                    }
                    className={classes.menu1}
                  />
                </div>
              </Dropdown>
            )}
          </div>
        </div>

        <Drawer
          placement="left"
          title="Basic Drawer"
          width={window.innerWidth}
          onClose={menuToggleHandler}
          open={openDrawer}
        >
          <div className={classes.drawerHeader}>
            <Link onClick={() => menuToggleHandler()} to="/">
              <img width={"120px"} height={"30px"} src={logo} alt="$$$" />
            </Link>

            <div className={classes.drawerHeaderRight}>
              <Badge size="small" count={notificationCount}>
                <Link
                  className={
                    windowLocation.includes("notifications")
                      ? classes.activeNotificationsIcon
                      : classes.notificationsIcon
                  }
                  to="/notifications"
                >
                  <BellOutlined />
                </Link>
              </Badge>
              <img
                onClick={() => menuToggleHandler()}
                src={CrossIcon}
                alt="$$$"
              />
            </div>
          </div>
          <MobileMenu
            setOpenDrawer={setOpenDrawer}
            headerNavigation={headerNavigation}
          />
        </Drawer>

        <Modal
          width={mobileResponsive ? "90%" : "50%"}
          footer={false}
          open={isModal}
          onCancel={handleOk}
          onOk={handleOk}
          centered
        >
          <LogoutModal handleOk={handleOk} />
        </Modal>
      </header>

      {windowLocation === "/" && (
        <Marquee pauseOnHover gradient={false}>
          <div className={classes.header_marquee}>
            {headerMarqueeTags?.map((item, index) => (
              <div key={index} className={classes?.item}>
                <ReactSVG src={item?.image} alt="$$$" />
                <p>{item?.text}</p>
              </div>
            ))}
          </div>
        </Marquee>
      )}
    </>
  );
};

export default Header;
