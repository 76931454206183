import { Col, Row } from 'antd';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './couponAppled.scss';
import OrangeButton from '../UI/OrangeButton/OrangeButton';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setBookingFlowCouponDetail } from '../../Redux/Reducers/bookingSlice';

const CouponAppled = ({ bookingPrice, finalPrice }) => {
  const dispatch = useDispatch();

  const bookingFlowCouponDetail = useSelector(
    state => state.booking?.bookingFlowCouponDetail
  );

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const discountedPrice = bookingPrice - finalPrice;

  return (
    <Row gutter={20} className='apply-coupon'>
      <Col span={mobileResponsive ? 16 : 19}>
        <div className='couponDetails'>
          <div className='icon'>
            <CheckCircleOutlined />
          </div>

          <div className='details'>
            <div className='details-top'>
              <p className='code'>{bookingFlowCouponDetail?.code}</p>
              <span>applied</span>
            </div>
            <div className='details-bottom'>
              <p>
                -{discountedPrice}${' '}
                {`(${
                  bookingFlowCouponDetail?.discountType === 'value'
                    ? bookingFlowCouponDetail?.discountValue + '$'
                    : bookingFlowCouponDetail?.discountValue + '%'
                } off)`}
              </p>
            </div>
          </div>
        </div>
      </Col>

      <Col span={mobileResponsive ? 8 : 5}>
        <OrangeButton
          width='100%'
          onClick={() => dispatch(setBookingFlowCouponDetail(null))}
        >
          Remove
        </OrangeButton>
      </Col>
    </Row>
  );
};

export default CouponAppled;
