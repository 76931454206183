import { DomyD } from '../../assets';
import classes from '../WeightLoss/WeightLoss.module.scss';
import TreatmentPlans from '../../Components/VirtualConsultation/Treatment/TreatmentPlans/TreatmentPlans';
import Layouts from '../../Components/Layouts/Layout/Layouts';

const Aging = () => {
  const Diseases = [
    {
      title: 'Aging',
      description:
        'With OhanaDoc, you can receive an online diagnosis and prescription 7 days a week from the convenience of your home.',
      img: DomyD,
      title1: 'Overview',

      title2: 'Choosing a reputable Aging management service',
      title3: 'Healthy lifestyle to follow'
    }
  ];
  const descriptions1 = [
    {
      description:
        'Aging management services are offered by accredited practising dietitians, GPs, commercial weight loss programs and kilojoule-controlled meal providers.'
    },
    {
      description:
        ' Always consult with your GP before starting with any Aging management service.'
    },
    {
      description:
        ' A reputable weight management service encourages you to Aging slowly and safely, and offers advice on how to keep to a healthy weight for the long term.'
    },
    {
      description:
        ' Staying at your most comfortable weight can be achieved by focusing on a balanced lifestyle – combining healthy foods, in appropriate portion sizes, with regular exercise.'
    }
  ];
  const descriptions2 = [
    {
      description:
        ' Aging management services are offered by accredited practising dietitians, GPs, commercial weight loss programs and kilojoule-controlled meal providers.'
    },
    {
      description:
        ' Always consult with your GP before starting with any weight management service.'
    },
    {
      description:
        ' A reputable weight management service encourages you to lose weight slowly and safely, and offers advice on how to keep to a healthy weight for the long term.'
    },
    {
      description:
        ' Staying at your most comfortable weight can be achieved by focusing on a balanced lifestyle – combining healthy foods, in appropriate portion sizes, with regular exercise.'
    }
  ];
  const diteDescription = [
    {
      heading: 'Eat a Variety of Foods:',
      description:
        'Incorporate a wide range of fruits, vegetables, whole grains, lean proteins, and healthy fats into your diet. This ensures you get a diverse array of nutrients, vitamins, and minerals.'
    },
    {
      heading: 'Portion Control:',
      description:
        "Be mindful of portion sizes to avoid overeating. Listen to your body's hunger and fullness cues, and try not to eat in excess."
    },
    {
      heading: 'Hydration:',
      description:
        'Drink plenty of water throughout the day. Staying hydrated is essential for digestion, circulation, and overall body function.'
    },
    {
      heading: 'Limit Processed Foods:',
      description:
        'Minimize your consumption of processed foods, which often contain excessive salt, sugar, and unhealthy fats. Opt for whole, natural foods whenever possible.'
    },
    {
      heading: 'Reduce Sugar and Salt Intake:',
      description:
        'Excess sugar and salt can contribute to various health issues. Read food labels and choose options with lower added sugars and sodium.'
    },
    {
      heading: 'Choose Healthy Fats:',
      description:
        ' Include sources of healthy fats in your diet, such as avocados, nuts, seeds, and olive oil. These fats are beneficial for heart health and overall well-being.'
    },
    {
      heading: 'Include Protein:',
      description:
        'Protein is essential for muscle repair and growth, as well as various other bodily functions. Incorporate lean sources of protein like chicken, fish, tofu, beans, and legumes.'
    },
    {
      heading: "Don't Skip Breakfast:",
      description:
        'Start your day with a nutritious breakfast to kickstart your metabolism and provide you with energy throughout the morning.'
    },
    {
      heading: 'Eat Mindfully:',
      description:
        'Avoid distractions while eating, such as watching TV or working at your desk. Pay attention to the taste and texture of your food, and stop eating when you feel full.'
    },
    {
      heading: 'Plan Ahead:',
      description:
        'Plan your meals and snacks in advance to avoid impulsive and unhealthy food choices.'
    },
    {
      heading: 'Limit Alcohol and Caffeine:',
      description:
        'Moderation is key when it comes to alcohol and caffeine consumption. Excessive intake can have negative effects on your health.'
    },
    {
      heading: 'Enjoy Treats in Moderation:',
      description:
        "It's okay to indulge in your favorite treats occasionally, but try not to make them a regular part of your daily diet."
    },
    {
      heading: 'Listen to Your Body:',
      description:
        'Every person is unique, and what works for one individual may not work for another. Pay attention to how your body responds to different foods and adjust your diet accordingly.'
    },
    {
      heading: 'Regular Eating Schedule:',
      description:
        'Aim to have regular meal times, and try not to skip meals. This can help regulate your metabolism and prevent excessive hunger.'
    },
    {
      heading: 'Consult a Healthcare Professional:',
      description:
        "If you have specific health concerns or dietary restrictions, it's a good idea to consult with a registered dietitian or healthcare professional to create a personalized eating plan."
    }
  ];
  return (
    <Layouts>
      <div className={classes.width}>
        <TreatmentPlans
          background={true}
          data={Diseases}
          dite={diteDescription}
          description1={descriptions1}
          description2={descriptions2}
          bgColor={false}
        />
      </div>
    </Layouts>
  );
};

export default Aging;
