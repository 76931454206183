import React, { useState } from "react";
import "./otherDetails.scss";
import { CollapseArrow } from "../../../../assets";
import { Empty } from "antd";
import SingleQuestion from "../../../SingleQuestion";

const OtherDetails = ({ title, questions,encounter, check }) => {
  const [CollapseOpen, setcollapseOpen] = useState(check);

  


  const toggleCollapse = () => {
    setcollapseOpen(!CollapseOpen);
  };

  return (
    <div className="otherDetails">
      <div onClick={() => toggleCollapse()} className="collapseBody">
        <div className="collapseHead">
          <img
            className={CollapseOpen && "active"}
            src={CollapseArrow}
            alt="$$$"
          />
          <h2>{title}</h2>
        </div>
        {CollapseOpen && (
          <div className="collapsItem">
            {encounter ? <p>{encounter}</p> :questions?.length > 0 ? (
              questions.map((question, index) => (
                <SingleQuestion key={index} question={question} number={index} />
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Empty />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherDetails;
