import { ConfigProvider } from "antd";
import { antdConfiguration } from "./Utils/constant";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "./Utils/helper";
import { setToken } from "./Redux/Reducers/authSlice";
import Pages from "./Routes/Pages";
import Auth from "./Routes/Auth";
import "./Styles/index.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { setCompleteUser, setConsentForms } from "./Redux/Reducers/gernalSlice";
import { getRequest } from "./service/ApisFunctions";

function App() {
  const token = useSelector((state) => state.authReducer.paitentToken);
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const dispatch = useDispatch();

  useEffect(() => {
    socket.emit("online", { userId: completeUser?.user_id?._id }, () => {});
  }, [completeUser]);

  if (!localStorage.getItem("patientToken")) {
    dispatch(setCompleteUser(null));
    dispatch(setToken(null));
  }

  const getAllConsentForms = async () => {
    const onSuccess = (res) => {
      dispatch(setConsentForms(res?.data));
    };

    const onError = (err) => {};

    await getRequest(
      "",
      `consentForm/get-consent-forms`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getAllConsentForms();
  }, []);

  return (
    <div className="app-container">
      <ConfigProvider theme={antdConfiguration}>
        {token ? (
          <>
            <ToastContainer />
            <Pages />
          </>
        ) : (
          <Auth />
        )}
      </ConfigProvider>
    </div>
  );
}

export default App;
