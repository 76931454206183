import React from "react";
import "./consultationProviderDetails.scss";
import PersonalInfo from "./personalInfo";
import Bio from "./bio";
import Specialization from "./specialization";
import EdAndEx from "./ed&Ex";

const ConsultationProviderDetails = ({ booking }) => {
  return (
    <>
    {/* Personal Info */}
      <div className="DetailsPatient">
        <PersonalInfo booking={booking} />
      </div>

      {/* Bio */}
      <div className="DetailsPatient">
        <Bio title='Bio' text={booking?.provider_id?.bio || "-"} />
      </div>

      {/* Description */}
      <div className="DetailsPatient">
        <Bio title='Description' text={booking?.provider_id?.description || "-"} />
      </div>

      {/* Specialization */}
      <div className="DetailsPatient">
        <Specialization booking={booking} />
      </div>

       {/* Education */}
       <div className="DetailsPatient">
        <EdAndEx title="Education" edAndEx={booking?.provider_id?.education}  />
      </div>

      {/* Experience */}
      <div className="DetailsPatient">
        <EdAndEx title="Experience" edAndEx={booking?.provider_id?.experience}  />
      </div>
    </>
  );
};

export default ConsultationProviderDetails;
