import { Empty, Form, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getRequest, putRequest } from "../../service/ApisFunctions";
import SkeletonInput from "../UI/Skeleton/skeletonInput";
import Questionnaire1 from "../Questionnaire1/nestedQuestion";
import { useDispatch } from "react-redux";
import { resetRefillState } from "../../Redux/Reducers/refillsSlice";
import { useSearchParams } from "react-router-dom";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";

const RefillPreQuestions = ({
  setMedicationQuestionsModal,
  selectedMedication,
  setSelectedMedication,
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const refill_id = searchParams.get("refill_id");

  const [isloading, setisloading] = useState(false);
  const [allQuestionsLoading, setAllQuestionsLoading] = useState(false);
  const [preQuestions, setpPreQuestions] = useState([]);
  const [changeQuestionNo, setChangeQuestionNo] = useState(0);
  const [progressLine, setProgressLine] = useState(0);
  const [answerData, setAnswerData] = useState([]);
  const [CheckBoxAnswer, setCheckBoxAnswer] = useState([]);
  const [InputAnswer, setInputAnswer] = useState("");
  const [optionAnswer, setOptionAnswer] = useState("");
  const [ImagesAnswer, setImagesAnswer] = useState([]);
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState(
    preQuestions?.[changeQuestionNo]?._id
  );

  // const getRefillQuestions = async () => {
  //   setAllQuestionsLoading(true);
  //   const onSuccess = (res) => {
  //     setisloading(false);
  //     if (res?.data?.length) {
  //       getPreQuestions(res?.data[0]?.pre_questions);
  //     }
  //   };
  //   const onError = (err) => {
  //     setAllQuestionsLoading(false);
  //     message.error(err?.message);
  //   };
  //   await getRequest(
  //     "",
  //     `refill_admin/get-all-admin-refills`,
  //     true,
  //     onSuccess,
  //     onError
  //   );
  // };

  // useEffect(() => {
  //   getRefillQuestions();
  // }, []);

  const resetState = () => {
    setChangeQuestionNo(0);
    setProgressLine(0);
    setCheckBoxAnswer([]);
    setInputAnswer("");
    setOptionAnswer("");
    setImagesAnswer([]);
    setPreviousQuestions([]);
    setCurrentQuestion(null);
    setCurrentQuestionId(preQuestions?.[changeQuestionNo]?._id);
    setAnswerData([]);
  };

  const getPreQuestions = async () => {
    setisloading(true);
    const onSuccess = (res) => {
      resetState();
      setpPreQuestions(res?.data?.questions);
      setCurrentQuestion(res?.data?.questions[0]);
      setisloading(false);
    };

    const onError = (err) => {
      setisloading(false);
    };

    await getRequest(
      "",
      `preQuestion/get-pre-question/${selectedMedication?.speciality?.pre_questions}`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getPreQuestions();
  }, [selectedMedication]);

  const ChangeQuestion = async (array) => {
    if (changeQuestionNo + 1 == preQuestions?.length) {
      setProgressLine(100);

      setSelectedMedication((prev) => ({
        ...prev,
        medication: {
          ...prev.medication,
          preQuestions: array,
        },
      }));
      setMedicationQuestionsModal(false);
    } else {
      setChangeQuestionNo((pre) => pre + 1);
      setCurrentQuestion(preQuestions[changeQuestionNo + 1]);
    }
  };

  return (
    <div className="refillRequestPaymentWrapper">
      <Spin spinning={isloading}>
        {allQuestionsLoading ? (
          <>
            <div style={{ marginTop: "20px" }}>
              <SkeletonInput size="small" block={true} />
            </div>
            <div style={{ marginTop: "10px" }}>
              <SkeletonInput block={true} />
            </div>

            <div
              style={{
                display: "flex",
                flexFlow: "wrap",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "45%" }}>
                <SkeletonInput size="small" block={false} />
              </div>
              <div style={{ width: "45%" }}>
                <SkeletonInput size="small" block={false} />
              </div>
              <div style={{ width: "45%" }}>
                <SkeletonInput size="small" block={false} />
              </div>
              <div style={{ width: "45%" }}>
                <SkeletonInput size="small" block={false} />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexFlow: "wrap",
                gap: "20px",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <SkeletonInput block={false} />
              </div>
              <div>
                <SkeletonInput block={false} />
              </div>
            </div>
          </>
        ) : (
          <div className={"medicalInfoSteps"}>
            <Form layout="vertical" className={"fields"}>
              <div className={"progressBox"} style={{ top: "10px" }}>
                <p
                  style={{ width: progressLine + "%", transition: "all .3s" }}
                  className={"innerLine"}
                >
                  <span className={"percentageNumber"}>{progressLine}%</span>
                </p>
              </div>
              {preQuestions?.length > 0 ? (
                <Questionnaire1
                  setCurrentQuestionId={setCurrentQuestionId}
                  currentQuestionId={currentQuestionId}
                  setCurrentQuestion={setCurrentQuestion}
                  currentQuestion={currentQuestion}
                  ChangeQuestion={ChangeQuestion}
                  answerData={answerData}
                  setAnswerData={setAnswerData}
                  setPreviousQuestions={setPreviousQuestions}
                  previousQuestions={previousQuestions}
                  setProgressLine={setProgressLine}
                  progressLine={progressLine}
                  setCheckBoxAnswer={setCheckBoxAnswer}
                  CheckBoxAnswer={CheckBoxAnswer}
                  setInputAnswer={setInputAnswer}
                  setImagesAnswer={setImagesAnswer}
                  InputAnswer={InputAnswer}
                  ImagesAnswer={ImagesAnswer}
                  setOptionAnswer={setOptionAnswer}
                  optionAnswer={optionAnswer}
                  setChangeQuestionNo={setChangeQuestionNo}
                  selectedMedication={selectedMedication}
                />
              ) : (
                <>
                  <Empty />
                  <SecondaryButton
                    onClick={() => {
                      // setRefillBookingProgressStep('refillSuccess');
                      dispatch(resetRefillState());
                    }}
                  >
                    Next
                  </SecondaryButton>
                </>
              )}
            </Form>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default RefillPreQuestions;
