import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Spin,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { postRequestFormData } from "../../service/ApisFunctions";
import classes from "../../Components/Questionnaire1/Questions.module.scss";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";

const QuestionComponent = ({
  question,
  currentQuestionId,
  setCurrentQuestionId,
  setCurrentQuestion,
  ChangeQuestion,
  answerData,
  setAnswerData,
  setPreviousQuestions,
  previousQuestions,
  counter,
  setProgressLine,
  progressLine,
  fileList,
  setFileList,
  setCheckBoxAnswer,
  CheckBoxAnswer,
  setInputAnswer,
  setImagesAnswer,
  InputAnswer,
  ImagesAnswer,
  loading,
  setLoading,
  setChangeQuestionNo,
  setOptionAnswer,
  optionAnswer,
  setCheckRequired,
  key,
}) => {
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setCurrentQuestionId(value);
    const option = question?.options?.find(
      (nestedQuestion) => nestedQuestion?.id == value
    );
    setOptionAnswer(option?.name);
    setCheckRequired(true);
  };

  const handleCheckBoxChange = (event) => {
    const selectedIds = Array.isArray(event) ? event : [event];

    const updatedCheckBoxes = question?.checkboxes.filter((checkbox) =>
      selectedIds.includes(checkbox?.id)
    );

    setCheckBoxAnswer(updatedCheckBoxes);

    setCheckRequired(updatedCheckBoxes.length > 0);
  };

  const handleInputChnage = (event) => {
    setInputAnswer(event);
  };

  const nextQuestion = () => {
    const option = question?.options?.find(
      (nestedQuestion) => nestedQuestion?.id == currentQuestionId
    );

    if (progressLine <= 50) {
      setProgressLine((pre) => pre + 10);
    } else if (progressLine > 50 && progressLine <= 80) {
      setProgressLine((pre) => pre + 5);
    } else if (progressLine > 80 && progressLine <= 100) {
      setProgressLine((pre) => pre + 3);
    }
    if (option?.questions?.length > 0) {
      setPreviousQuestions([...previousQuestions, question]);
      setCurrentQuestion(option?.questions[0]);
    } else {
      setPreviousQuestions([...previousQuestions, question]);

      ChangeQuestion([
        ...answerData,
        {
          id: question?.id,
          name: question?.name,
          option: option,
          CheckBoxAnswer: CheckBoxAnswer,
          inputData: InputAnswer,
          images: ImagesAnswer,
        },
      ]);
    }

    setAnswerData([
      ...answerData,
      {
        id: question?.id,
        name: question?.name,
        option: option,
        CheckBoxAnswer: CheckBoxAnswer,
        inputData: InputAnswer,
        images: ImagesAnswer,
      },
    ]);

    setCheckBoxAnswer([]);
    setInputAnswer("");
    setImagesAnswer([]);
    setFileList([]);
    setOptionAnswer("");
    setCheckRequired(false);
  };

  // Skip Questions

  const skipBtn = () => {
    if (progressLine <= 50) {
      setProgressLine((pre) => pre + 10);
    } else if (progressLine > 50 && progressLine <= 80) {
      setProgressLine((pre) => pre + 5);
    } else if (progressLine > 80 && progressLine <= 100) {
      setProgressLine((pre) => pre + 3);
    }
    setPreviousQuestions([...previousQuestions, question]);
    ChangeQuestion(answerData);
    setCheckRequired(false);
  };

  // Move Previous Questions

  const prevQuestion = () => {
    if (counter <= previousQuestions?.length) {
      const option = question?.options?.find(
        (nestedQuestion) => nestedQuestion?.id == currentQuestionId
      );

      if (progressLine <= 60) {
        setProgressLine((pre) => pre - 10);
      } else if (progressLine > 50 && progressLine <= 80) {
        setProgressLine((pre) => pre - 5);
      } else if (progressLine > 80 && progressLine <= 100) {
        setProgressLine((pre) => pre - 3);
      } else if (previousQuestions?.length == 1) {
        setProgressLine((pre) => pre - pre);
      }

      // if (option?.questions?.length) {
      // } else {
      if (!option?.question?.length > 0) {
        setChangeQuestionNo((pre) => {
          if (pre - 1 < 0) {
            return 0;
          }
          return pre - 1;
        });
      }
      setCurrentQuestion(
        previousQuestions[previousQuestions?.length - counter]
      );
      // setCounter((pre) => pre + 1);

      if (previousQuestions?.length >= 0) {
        previousQuestions.splice(-1);
        answerData.splice(-1);
        setPreviousQuestions(previousQuestions);
        setAnswerData(answerData);
        setImagesAnswer([]);
        setFileList([]);
        setInputAnswer("");
        setCheckBoxAnswer([]);
        setOptionAnswer("");
        setCheckRequired(false);
      }
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      ImagesAnswer.splice(index, 1);
      setImagesAnswer(ImagesAnswer);
      setFileList(newFileList);
      if (newFileList?.length > 0) {
        setCheckRequired(true);
      } else {
        setCheckRequired(false);
      }
    },
    beforeUpload: async (file) => {
      const isPdfOrImage =
      (file.type.startsWith("image/") && file.type !== "image/svg+xml") || file.type === "application/pdf";

      if (!isPdfOrImage) {
        message.warning("You can only upload PDF and image files!");
        return false;
      }
      if (file) {
        setLoading(true);
        const onSuccess = (res) => {
          setLoading(false);
          setImagesAnswer((pre) => [...pre, res?.data]);
          setFileList([...fileList, file]);
          if (fileList?.length > 0) {
            setCheckRequired(true);
          } else {
            setCheckRequired(false);
          }
          message.success(res?.message);
        };

        const onError = (err) => {
          setLoading(false);
        };

        const formData = {
          image: file,
        };

        await postRequestFormData(
          formData,
          "auth/upload_image_s3",
          true,
          onSuccess,
          onError
        );
      }

      return false;
    },
    fileList,
  };

  return (
    <div key={key}>
      <div style={{ minHeight: 200 }}>
        <h2>
          {question?.required && <b style={{ color: "red" }}> *</b>}{" "}
          {question?.name}
        </h2>
        <>
          {question?.type === "radio" && (
            <>
              {question.options && (
                <Form.Item rules={[{ required: question?.required }]}>
                  <Radio.Group
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={handleOptionChange}
                  >
                    <Row gutter={[20, 20]}>
                      {question?.options?.map((option) => (
                        <Col span={12}>
                          <Radio value={option.id}>{option.name}</Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              )}
            </>
          )}
          {question?.type === "checkbox" && (
            <>
              {question.checkboxes && (
                <Form.Item rules={[{ required: question?.required }]}>
                  <Checkbox.Group
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={handleCheckBoxChange}
                  >
                    <Row gutter={[20, 20]}>
                      {question?.checkboxes?.map((checkbox) => (
                        <Col span={12}>
                          <Checkbox value={checkbox.id}>
                            {checkbox?.name}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              )}
            </>
          )}
          {question?.type === "textarea" && (
            <Row>
              <Col span={24}>
                <Form.Item rules={[{ required: question?.required }]}>
                  <Input.TextArea
                    onChange={(e) => handleInputChnage(e?.target?.value)}
                    placeholder="Write here..."
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {question?.type === "image_upload" && (
            <Spin spinning={loading}>
              <Row>
                <Col span={24}>
                  <Form.Item rules={[{ required: question?.required }]}>
                    <Upload style={{ width: "100%" }} {...props}>
                      <Button
                        style={{ width: "100%", marginTop: "20px" }}
                        icon={<UploadOutlined />}
                      >
                        Select File
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Spin>
         )}
        </>
      </div>

      <div
        className={classes.questionActionBtn}
        style={
          counter <= previousQuestions?.length ? {} : { justifyContent: "end" }
        }
      >
        {counter <= previousQuestions?.length && (
          <button
            className={classes.PreviousBtn}
            type="button"
            onClick={prevQuestion}
          >
            Previous
          </button>
        )}

        <div style={{ display: "flex", gap: "10px" }}>
          {!question?.required && (
            <button className="noBgBtn" type="submit" onClick={skipBtn}>
              Skip
            </button>
          )}

          {InputAnswer ||
          optionAnswer ||
          CheckBoxAnswer?.length > 0 ||
          ImagesAnswer?.length > 0 ? (
            <SecondaryButton type="submit" onClick={nextQuestion}>
              Next
            </SecondaryButton>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

const MedicalHistoryQuestionnaire = ({
  setCurrentQuestionId,
  currentQuestionId,
  currentQuestion,
  setCurrentQuestion,
  ChangeQuestion,
  answerData,
  setAnswerData,
  previousQuestions,
  setPreviousQuestions,
  setProgressLine,
  progressLine,
  setCheckBoxAnswer,
  CheckBoxAnswer,
  setInputAnswer,
  setImagesAnswer,
  InputAnswer,
  ImagesAnswer,
  setChangeQuestionNo,
  setOptionAnswer,
  optionAnswer,
}) => {
  // Assuming you have your data as a JSON object

  const [counter, setCounter] = useState(1);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkRequired, setCheckRequired] = useState(false);

  return (
    <div>
      <QuestionComponent
        key={currentQuestion?._id}
        question={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        currentQuestionId={currentQuestionId}
        setCurrentQuestionId={setCurrentQuestionId}
        ChangeQuestion={ChangeQuestion}
        setAnswerData={setAnswerData}
        answerData={answerData}
        previousQuestions={previousQuestions}
        setPreviousQuestions={setPreviousQuestions}
        setCounter={setCounter}
        counter={counter}
        setProgressLine={setProgressLine}
        progressLine={progressLine}
        fileList={fileList}
        setFileList={setFileList}
        uploading={uploading}
        setUploading={setUploading}
        setCheckBoxAnswer={setCheckBoxAnswer}
        CheckBoxAnswer={CheckBoxAnswer}
        setInputAnswer={setInputAnswer}
        setImagesAnswer={setImagesAnswer}
        InputAnswer={InputAnswer}
        ImagesAnswer={ImagesAnswer}
        setLoading={setLoading}
        loading={loading}
        optionAnswer={optionAnswer}
        setOptionAnswer={setOptionAnswer}
        setChangeQuestionNo={setChangeQuestionNo}
        setCheckRequired={setCheckRequired}
        checkRequired={checkRequired}
      />
    </div>
  );
};

export default MedicalHistoryQuestionnaire;
