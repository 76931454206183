import { patientHome } from "../../assets";
import classes from "./Consultations.module.scss";
import SecondaryButton from "../../Components/UI/SecondaryButton/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompleteUser } from "../../Redux/Reducers/gernalSlice";
import {
  setBookingFlowInformationSteps,
  setBookingFlowIsUrgent,
} from "../../Redux/Reducers/bookingSlice";
import Layouts from "../../Components/Layouts/Layout/Layouts";
import { useEffect } from "react";
import {
  setIsConsentForm,
  setIsProfileFilled,
} from "../../Redux/Reducers/authSlice";
import { Col, Empty, Row } from "antd";
import { useMediaQuery } from "react-responsive";
import {
  setRefillCondition,
  setRefillPrescriptions,
  setRefillSpeciality,
  setServiceMedications,
} from "../../Redux/Reducers/refillsSlice";
import OrangeButton from "../../Components/UI/OrangeButton/OrangeButton";
import { CheckEmptyContent } from "../../Utils/helper";

const Consultations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const conditiondetails = useSelector(
    (state) => state.booking.bookableFlowCondition
  );
  const specialityDetail = useSelector(
    (state) => state.booking.bookableFlowSpeciality
  );
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  const token = useSelector((state) => state.authReducer.paitentToken);

  const getStartedBtn = (urgent) => {
    if (urgent) {
      dispatch(setBookingFlowIsUrgent(true));
    } else {
      dispatch(setBookingFlowIsUrgent(false));
    }
    if (!token) {
      dispatch(setCompleteUser({}));
      dispatch(setBookingFlowInformationSteps(0));
      navigate("/consultation-booking");
      return;
    } else if (completeUser?._id && !completeUser?.isProfileFilled) {
      dispatch(setIsProfileFilled({ personalInfo: true }));
    } else if (completeUser?._id && !completeUser?.allowConsent) {
      dispatch(setIsConsentForm(true));
    } else {
      dispatch(setBookingFlowInformationSteps(0));
      navigate("/consultation-booking");
    }
  };
  const handleRefillRequest = () => {
    dispatch(setRefillSpeciality(specialityDetail?._id));
    dispatch(setRefillCondition(conditiondetails?._id));
    dispatch(setRefillPrescriptions([]));
    dispatch(setServiceMedications([]));

    navigate("/refill-request?isSpecialitySelected=true");
  };
  useEffect(() => {
    dispatch(setBookingFlowIsUrgent(false));
  }, []);

  return (
    <Layouts>
      <div className={classes.consult}>
        <div className={classes.head}>
          <div>
            <h3>{conditiondetails?.category_name || "-"}</h3>
            {/* <p>{conditiondetails?.description || "-"}</p> */}
            <SecondaryButton
              className={classes.button}
              children="Schedule a video or phone call visit "
              onClick={() => getStartedBtn()}
            />
            {specialityDetail?.speciality_name?.replace(/\s/g, "") ===
            "Mental Health"?.replace(/\s/g, "") ? null : (
              <>
                <OrangeButton
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  className={classes.button}
                  children="Messaging visit"
                  onClick={() => getStartedBtn(true)}
                />
                {/* <PrimaryButton
                    className={classes.button}
                    children="Get Refill"
                    onClick={() => handleRefillRequest()}
                  /> */}
              </>
            )}
          </div>
          <div>
            <img
              style={{ borderRadius: "7px  " }}
              src={specialityDetail?.image || patientHome}
              alt="$$$"
            />
          </div>
        </div>
        <div className={classes.body}>
          <Row style={{ width: "100%" }} gutter={[15, 15]}>
            {CheckEmptyContent(specialityDetail?.how_it_work) && (
              <Col span={mobileResponsive ? 24 : 24}>
                <div className={classes.bodyCont}>
                  <h5>How It Works</h5>
                  <div
                    className={classes.customHtmlRenderingDiv}
                    dangerouslySetInnerHTML={{
                      __html: specialityDetail?.how_it_work || "--",
                    }}
                  />
                </div>
              </Col>
            )}

            {conditiondetails?.overview && (
              <Col span={mobileResponsive ? 24 : 12}>
                <div className={classes.bodyCont}>
                  <h5>Overview</h5>
                  <p>{conditiondetails?.overview || "--"}</p>
                </div>
              </Col>
            )}

            {CheckEmptyContent(conditiondetails?.common_symptoms) && (
              <Col span={mobileResponsive ? 24 : 12}>
                <div className={classes.bodyCont}>
                  <h5>Common Symptoms</h5>
                  <div
                    className={classes.customHtmlRenderingDiv}
                    dangerouslySetInnerHTML={{
                      __html: conditiondetails?.common_symptoms,
                    }}
                  />
                </div>
              </Col>
            )}

            {CheckEmptyContent(conditiondetails?.treatment) && (
              <Col span={mobileResponsive ? 24 : 12}>
                <div className={classes.bodyCont}>
                  <h5>OhanaDoc Treatment Options</h5>
                  <div
                    className={classes.customHtmlRenderingDiv}
                    dangerouslySetInnerHTML={{
                      __html: conditiondetails?.treatment,
                    }}
                  />
                </div>
              </Col>
            )}

{(conditiondetails?.medicationsOffered?.medicationNames?.length >
                0 ||
                conditiondetails?.medicationsOffered?.note) && (
                <Col span={mobileResponsive ? 24 : 12}>
                  <div className={classes.bodyCont}>
                    <h5>Medications Offered at OhanaDoc</h5>
                    {conditiondetails?.medicationsOffered?.medicationNames
                      ?.length > 0 ? (
                      conditiondetails?.medicationsOffered?.medicationNames?.map(
                        (item, index) => (
                          <p key={index}>
                            {index + 1 + ". "}
                            {item?.medicationName || "--"}
                          </p>
                        )
                      )
                    ) : (
                      <Empty />
                    )}

                    <h5 style={{ marginTop: "20px" }}>Note</h5>

                    <p>{conditiondetails?.medicationsOffered?.note || "--"}</p>
                  </div>
                </Col>
              )}


{
                conditiondetails?.category_name && (
                <Col span={24}>
                  <div className={classes.bodyCont}>
                    <h5>
                      Why Choose OhanaDoc for {" "}
                      {
                        conditiondetails?.category_name}
                      ?
                    </h5>
                    <p>{conditiondetails?.why || "--"}</p>

                    <h5 style={{ marginTop: "20px" }}>Remember</h5>
                    <p>{conditiondetails?.remember || "--"}</p>
                  </div>
                </Col>
              )}

         
          </Row>
        </div>

        <div className={classes?.getStarted}>
          <SecondaryButton
            className={classes.button}
            children="Schedule a video or phone call visit "
            onClick={() => getStartedBtn()}
          />
        </div>
      </div>
    </Layouts>
  );
};

export default Consultations;
