import React from "react";
import "./specialization.scss";

const Specialization = ({ booking }) => {
  return (
    <>
      <div className="specialization">
        <h2 style={{marginTop:"0px"}}>Specialization</h2>
        <div className="specializationOptions">
          {booking?.provider_id?.specialities?.map((item, index) => (
            <p key={index}>{item?.speciality_name}</p>
          ))}
        </div>
      </div>

      <div className="specialization">
        <h2>Sub-specialties</h2>
        <div className="specializationOptions">
          {booking?.provider_id?.specialities[0]?.categories?.map(
            (item, index) => (
              <p key={index}>{item?.category_name}</p>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Specialization;
