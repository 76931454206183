import { bFacebook, bInstagram, bLinkedin, bTwitter } from "../assets";

export const ReactionType = [
  { id: 1, label: "Allergy" },
  { id: 2, label: "AdverseReaction" },
];

export const Reaction = [
  { id: 1, label: "mild" },
  { id: 2, label: "moderate" },
  { id: 3, label: "severe" },
];

export const StatusType = [
  { id: 1, label: "Current", value: "Active" },
  { id: 2, label: "Past", value: "Inactive" },
];

export const pharmacyType = [
  { id: 2048, label: "EPCS" },
  { id: 64, label: "TwentyFourHourPharmacy/AnyPharmacyType" },
  { id: 32, label: "LongTermCarePharmacy" },
  { id: 1, label: "MailOrder" },
  { id: 8, label: "Retail" },
  { id: 16, label: "Past", value: "SpecialtyPharmacy" },
];

export const antdConfiguration = {
  token: {
    fontFamily: "Nunito Sans",
    colorPrimary: "#f76d00",
    colorLink: "#3d8aff",
    controlOutline: "#d4cfcc",
    colorText: "#1d1e25",
    colorTextBase: "#1d1e25",
    borderRadius: "7px",
    colorBorder: "#d4cfcc",
  },
};

export const sexualOrientation = [
  { name: "straight" },
  { name: "lesbian" },
  { name: "gay" },
  { name: "bisexual" },
  { name: "something else" },
];

export const gendersList = [
  { name: "Male" },
  { name: "Female" },
  { name: "Refuse to disclose" },
];

export const birthGendersList = [{ name: "Male" }, { name: "Female" }];

export const pronounList = [
  { name: "He/Him" },
  { name: "She/Her" },
  { name: "They/Them" },
];

export const EthnicityList = [
  { name: "Hawaiian" },
  { name: "American" },
  { name: "Filipino" },
  { name: "Japanese" },
  { name: "Chinese" },
  { name: "Korean" },
  { name: "Other not listed" },
];

export const RaceList = [
  { name: "Native Hawaiian or Other Pacific Islander" },
  { name: "White" },
  { name: "Black or African American" },
  { name: "American Indian or Alaska Native" },
  { name: "Asian" },
  { name: "Other not listed" },
];

export const PhoneTypes = [
  { id: 4, label: "Home", value: "Home" },
  { id: 2, label: "Mobile", value: "Cell" },
];

export const HeightUnit = [
  { id: 3, label: "Ft / In" },
  // { id: 1, label: 'In' },
  { id: 2, label: "Cm" },
];

export const WeightUnit = [
  { id: 1, label: "Lbs" },
  // { id: 2, label: 'Kg' }
];

export const socialIcon = [
  {
    img: bFacebook,
    links: "https://www.facebook.com/ohanadoc?mibextid=ZbWKwL",
  },
  {
    img: bInstagram,
    links: "https://instagram.com/ohanadochawaii?igshid=NGVhN2U2NjQ0Yg==",
  },
  {
    img: bLinkedin,
    links: "https://www.linkedin.com/company/ohanadoc/",
  },
  {
    img: bTwitter,
    links: "https://x.com/ohanadoc1",
  },
  // {
  //   img: bYoutube,
  //   links: 'https://www.linkedin.com/company/ohanadoc/'
  // }
];

export const consentForm =
  "I (Legal representative of the applicant and as the representative parental guardian if the applicant is a minor and more than one guardian exists) hereby give consent to receive medical care at Ohana Doc. I also authorize physicians, hospitals, and OhanaDoc to release medical information and medical records to other parties when deemed necessary. I consent to receive treatment with imported medicine and dosage variations. I consent for medical photography and the use of the material to be used in teaching, education, research, and publication. I have read and I understand OhanaDoc’s financial policies and I accept responsibility for the payment of any fees associated with my care. I consent to communicate via internet and I will not hold the OhanaDoc responsible for unforseen incidents that may happen during the communications. I herebyconfirm that I have fully understood the risks and benfits of all of the above. My signature also authorizes the release of medical information necessary to pay my claim. Any dispute between arising out of this medical encounter set forth above, the tokyo District Court of the tokyo Summary Court shall have the exclusive original jurisdiction. A photocopy or scanned document of this form shall be considered as effective and valid as the original. I hereby certify that the information above is complete and true to the best of my knowledge.";

export const AllLanguages = [
  "Hawaiian",
  "English",
  "Japanese",
  "Filipino",
  "Spanish",
  "Chinese",
  "Other",
];

export const consentForms = [
  {
    heading: "Terms and Conditions for use of OhanaDoc.com",
    topContent:
      "Aloha and welcome to OhanaDoc! By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. These terms govern your use of the OhanaDoc platform, operated by Ohana Doc LLC under a license from Harrington Maui Enterprises LLC. If you do not agree with these terms, please do not open an account or use our services. Please review these terms carefully.",
    points: [
      {
        heading: "Definitions",
        subpoints: [
          {
            title: "Service",
            content:
              "Refers to the telemedicine services provided by OhanaDoc, including virtual consultations, prescription management, lab services, and other related services.",
          },
          {
            title: "User",
            content:
              "Any individual who accesses or uses the OhanaDoc services and is at least 18 years old.",
          },
          {
            title: "Provider",
            content:
              "Licensed medical professionals who provide healthcare services through OhanaDoc.",
          },
        ],
      },
      {
        heading: "Eligibility",
        content:
          "Users must be at least 18 years old and physically present in the state of Hawaii during their consultation with a Hawaii-licensed provider to use OhanaDoc. By using our services, you attest that you meet these eligibility requirements.",
      },
      {
        heading: "Description of Services",
        content:
          "OhanaDoc acts as an intermediary platform that connects users with licensed healthcare providers for telemedicine consultations and lab services. Our services include virtual medical consultations, prescription management, lab testing, and health advice through a secure and user-friendly platform. Our mission is to bring the spirit of aloha to healthcare, ensuring that everyone feels like part of the ohana (family).",
      },
      {
        heading: "Use of Services",
        subpoints: [
          {
            title: "Account Creation",
            content:
              "Users must create an account to use our services. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. Please protect your login details.",
          },
          {
            title: "Prohibited Uses",
            content:
              "You agree not to use the service for any unlawful purpose or in any way that could harm OhanaDoc or its users. We believe in maintaining a safe and trustworthy environment for all.",
          },
        ],
      },
      {
        heading: "Medical Services",
        subpoints: [
          {
            title: "No Emergency Services",
            content:
              "OhanaDoc does not provide emergency medical services. In case of an emergency, call 911 or go to the nearest emergency room. Your safety is our priority.",
          },
          {
            title: "Telemedicine Consultations",
            content:
              "Consultations through OhanaDoc are conducted by licensed providers. These consultations do not replace the need for an in-person visit to a healthcare provider when necessary.",
          },
          {
            title: "Synchronous and Asynchronous Visits",
            content:
              "Synchronous visits are real-time interactions between you and a provider via video or phone. Asynchronous visits involve submitting your health information, which the provider reviews later, responding within a set timeframe. Both types of visits have their scope and limitations, and not all conditions can be treated through telemedicine.",
          },
          {
            title: "Prescription Policy",
            content:
              "Providers may prescribe medication as appropriate based on their professional judgment. All prescriptions are subject to local regulations and pharmacy fulfillment policies.",
          },
          {
            title: "Lab Services",
            content:
              "OhanaDoc offers lab testing services, where users can order tests recommended by their providers and receive results through the platform.",
          },
        ],
      },
      {
        heading: "Intermediary Role of OhanaDoc",
        content:
          "OhanaDoc functions as an intermediary platform that facilitates the connection between users and healthcare providers. While we provide the technological infrastructure and administrative support, the medical advice, diagnosis, treatment, and other healthcare services are solely provided by the licensed providers. OhanaDoc is not responsible for the medical care provided and does not practice medicine or any other licensed profession. Our role is to ensure a seamless, secure, and user-friendly platform for both users and providers.",
      },
      {
        heading: "Compliance with Hawaiian Telemedicine Law",
        content:
          "OhanaDoc operates in compliance with Hawaiian telemedicine laws, including but not limited to Hawaii Revised Statutes § 453-1.3, which governs the practice of telehealth. Providers using OhanaDoc are required to adhere to these regulations, ensuring that all telehealth services meet the legal standards set forth by the state of Hawaii.",
      },
      {
        heading: "User Responsibilities",
        subpoints: [
          {
            title: "Accurate Information",
            content:
              "You agree to provide accurate, current, and complete information about yourself as prompted by our registration forms. It is your responsibility (kuleana) to ensure the information provided is truthful and accurate.",
          },
          {
            title: "Compliance with Laws",
            content:
              "You agree to comply with all applicable local, state, national, and international laws and regulations regarding your use of our services.",
          },
          {
            title: "Respectful Conduct",
            content:
              "Users must engage with providers and OhanaDoc staff respectfully and professionally. Any abusive or inappropriate behavior can result in termination of services.",
          },
          {
            title: "Technology Requirements",
            content:
              "Users are responsible for ensuring they have the necessary technology (e.g., internet connection, compatible device) to access telemedicine services.",
          },
        ],
      },
      {
        heading: "Privacy and Security",
        subpoints: [
          {
            title: "Data Protection",
            content:
              "OhanaDoc is committed to protecting your privacy and personal information. Please review our Privacy Policy for more details on how we collect, use, and protect your information. We operate under the principle of mālama (to care for).",
          },
          {
            title: "Confidentiality",
            content:
              "All medical information provided through our services is confidential and protected under applicable privacy laws, including the Health Insurance Portability and Accountability Act (HIPAA) and other relevant regulations.",
          },
          {
            title: "Data Use and Sharing",
            content:
              "User data may be used for improving services, research, or aggregated data analysis, ensuring compliance with privacy laws.",
          },
        ],
      },
      {
        heading: "Payment and Fees",
        subpoints: [
          {
            title: "Service Fees",
            content:
              "Fees for using OhanaDoc services are specified at the time of payment. You agree to pay all fees and charges associated with your use of the services. Mahalo (thank you) for your kokua (help) in supporting our platform.",
          },
          {
            title: "Refund Policy",
            content:
              "Fees paid for services are non-refundable unless otherwise stated in our Refund Policy.",
          },
        ],
      },
      {
        heading: "Intellectual Property",
        subpoints: [
          {
            title: "Ownership",
            content:
              "All content and materials on OhanaDoc, including text, graphics, logos, and software, are the property of OhanaDoc or its licensors and are protected by intellectual property laws.",
          },
          {
            title: "Use of Content",
            content:
              "You may use the content provided on OhanaDoc for personal, non-commercial use only. You may not reproduce, distribute, or create derivative works from any content without our express written permission. We respect and protect our intellectual property and ask you to do the same.",
          },
        ],
      },
      {
        heading: "Limitation of Liability",
        content:
          "The services provided by OhanaDoc.com are intended to support, not replace, the relationship that exists between a patient and their healthcare provider. OhanaDoc.com LLC, its associated healthcare providers, and the employed staff will not be held liable for any direct, indirect, incidental, or consequential damages arising out of or in connection with the use of the telemedicine services, electronic communication, the platform, intermediaries, or any other related services. This includes, but is not limited to, any errors or omissions in the content provided, any delays or interruptions in service, or any actions taken based on the information provided during the telemedicine consultation" +
          " Additionally, any legal action or claims for damages against Harrington Maui Enterprises LLC related to the services provided by OhanaDoc.com are subject to the same limitations of liability. Neither Ohana Doc LLC nor Harrington Maui Enterprises LLC will be held liable for any direct, indirect, incidental, or consequential damages arising from the use of their services." +
          " By agreeing to these terms and conditions, you acknowledge and agree to this limitation of liability.",
      },
      {
        heading: "Changes to Terms",
        content:
          "Ohana Doc LLC reserves the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting. Your continued use of the services after any changes constitutes your acceptance of the new terms. We will do our best to keep you informed of significant updates.",
      },
      {
        heading: "Governing Law",
        content:
          "These terms and conditions are governed by and construed in accordance with the laws of the State of Hawaii, without regard to its conflict of law principles. This includes compliance with relevant statutes such as Hawaii Revised Statutes § 453-1.3 and § 431:10A-116.3 (Telehealth Parity Act).",
      },
      {
        heading: "Dispute Resolution",
        content:
          "Any disputes arising out of or relating to these terms and conditions or the use of OhanaDoc services shall be resolved through binding arbitration in the state of Hawaii, in accordance with the rules of the American Arbitration Association. Before initiating arbitration, you agree to first attempt to resolve the dispute informally by contacting us at support@ohanadoc.com.",
      },
      {
        heading: "Contact Information",
        content:
          "For any questions or concerns regarding these terms and conditions, please contact us at support@ohanadoc.com. We are here to help and ensure your experience with OhanaDoc is positive and fulfilling." +
          " By using OhanaDoc, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. Mahalo for choosing OhanaDoc and being a part of our ohana.",
      },
    ],
  },
  {
    // heading: "Informed Consent for Telemedicine Services",
    heading: "Account and Telemedicine Services",
    topContent:
      "OhanaDoc.com is owned by Ohana Doc LLC and licensed to operate by Harrington Maui Enterprises LLC.  I consent to receive healthcare services via telemedicine through the OhanaDoc.com licensed platform. I understand and agree to the following:",
    points: [
      {
        heading: "Nature of Telemedicine",
        content:
          "Telemedicine involves the use of electronic communications to enable healthcare providers at different locations to share individual patient medical information for the purpose of improving patient care. Providers may include primary care practitioners, specialists, and/or subspecialists.",
      },
      {
        heading: "Telemedicine Modalities",
        content:
          "I understand that telemedicine may include both synchronous (live video visits) and asynchronous (store-and-forward) modalities. Synchronous telemedicine involves real-time interaction with my healthcare provider via video conference. Asynchronous telemedicine involves the transmission of medical information and data at one point in time to be reviewed by a healthcare provider at a later time.",
      },
      {
        heading: "Medical Information and Records",
        content:
          "All existing laws regarding access to medical information and copies of my medical records apply to telemedicine consultations. No dissemination of any personally identifiable images or information from the telemedicine interaction will occur without my written consent.",
      },
      {
        heading: "Confidentiality",
        content:
          "Reasonable and appropriate efforts have been made to eliminate any confidentiality risks associated with telemedicine, and all existing confidentiality protections under federal and Hawaii state law apply to information disclosed during telemedicine consultations.",
      },
      {
        heading: "Right to Withdraw Consent",
        content:
          "I have the right to withhold or withdraw my consent to the use of telemedicine in the course of my care at any time, without affecting my right to future care or treatment.",
      },
      {
        heading: "Risks and Benefits",
        content:
          "I understand the potential risks of telemedicine, which include, but are not limited to, the following:",
        subpoints: [
          {
            content:
              "In rare cases, information transmitted may not be sufficient (e.g., poor resolution of images) to allow for appropriate medical decision-making by the healthcare provider(s);",
          },
          {
            content:
              "Delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment;",
          },
          {
            content:
              "In very rare instances, security protocols could fail, causing a breach of privacy of personal medical information;",
          },
          {
            content:
              "In rare cases, a lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other judgment errors.",
          },
        ],
      },
      {
        heading: "Compounding Pharmacy Use",
        content:
          "I understand that OhanaDoc.com utilizes a specific local compounding pharmacy for medications that require sterile compounding processes. This pharmacy has been selected for its rigorous sterile procedures and ability to provide high-quality compounded medications. My prescriptions requiring compounding will be filled by this pharmacy to ensure safety and efficacy.",
      },
      {
        heading: "Hawaii Telehealth Statutes Compliance",
        content:
          "This consent complies with Hawaii Revised Statutes (HRS) §453-1.3, which governs the use of telehealth in the state of Hawaii.",
      },
    ],
    bottomContent:
      "I have read this document carefully and understand the risks and benefits of the telemedicine consultation, including both synchronous and asynchronous modalities, and I hereby give my informed consent to participate in a telemedicine consultation under the terms described herein.",
    consent:
      "I have read and agree to the Informed Consent for Telemedicine Services",
  },
  {
    heading: "Disclose Health Information",
    // heading: "Consent to Use and Disclose Health Information",
    topContent:
      "I consent to the use and disclosure of my health information through the OhanaDoc.com platform as necessary to facilitate my healthcare. I understand and agree to the following:",
    points: [
      {
        heading: "Use of Health Information",
        content:
          "My health information may be used by healthcare providers within the OhanaDoc.com platform to provide diagnosis, treatment, and recommendations. This may include sharing my health information with other healthcare professionals involved in my care.",
      },
      {
        heading: "Disclosure of Health Information",
        content:
          "My health information may be disclosed to third parties for the purposes of treatment, payment, and healthcare operations as permitted by federal and Hawaii state law. This includes disclosures to insurance companies for claims processing and to other entities as required for legal and regulatory compliance.",
      },
      {
        heading: "Confidentiality and Privacy",
        content:
          "OhanaDoc.com is committed to maintaining the confidentiality and privacy of my health information. All disclosures and uses of my health information will comply with the Health Insurance Portability and Accountability Act (HIPAA) and Hawaii state privacy laws.",
      },
      {
        heading: "Right to Revoke Consent",
        content:
          "I have the right to revoke this consent at any time by providing written notice to OhanaDoc.com. However, I understand that revoking this consent will not affect any disclosures or uses of my health information that occurred prior to the receipt of my revocation.",
      },
      {
        heading: "Hawaii Privacy Statutes Compliance",
        content:
          "This consent complies with Hawaii Revised Statutes (HRS) §323B-3, which governs the use and disclosure of health information in the state of Hawaii.",
      },
    ],
    bottomContent:
      "I have read this document carefully and understand the use and disclosure of my health information as described herein. I hereby give my consent to use and disclose my health information for the purposes of treatment, payment, and healthcare operations as necessary.",
    consent:
      "I have read and agree to the Consent to Use and Disclose Health Information.",
  },
  {
    heading: "Electronic Communication",
    // heading: "Consent for Electronic Communication",
    topContent:
      "I consent to communicate with OhanaDoc.com enrolled independent healthcare providers and Ohana Doc associated staff via electronic communication methods, including but not limited to email, text messaging, and secure messaging through the OhanaDoc.com platform. I understand and agree to the following:",
    points: [
      {
        heading: "Nature of Electronic Communication",
        content:
          "Electronic communication involves the transmission of information over the internet or via text messaging. This may include, but is not limited to, appointment scheduling, treatment plans, follow-up instructions, and responses to medical inquiries.",
      },
      {
        heading: "Confidentiality and Security",
        content:
          "While OhanaDoc.com employs reasonable security measures to protect the confidentiality of electronic communications, I understand that there are inherent risks associated with electronic transmission, including the possibility of unauthorized access, interception, or misuse of my information.",
      },
      {
        heading: "Scope of Communication",
        content:
          "I understand that electronic communication is not appropriate for all types of medical issues, particularly emergencies or urgent situations. In such cases, I should seek immediate medical attention or contact emergency services.",
      },
      {
        heading: "Responsiveness",
        content:
          "OhanaDoc.com healthcare providers and staff will make reasonable efforts to respond to electronic communications in a timely manner. However, I understand that there may be delays in response, and I should not rely on electronic communication for urgent matters.",
      },
      {
        heading: "Record Keeping",
        content:
          "Electronic communications may be retained in my medical record as part of my health information. This information will be accessible to authorized OhanaDoc.com healthcare providers and staff as needed for my care.",
      },
      {
        heading: "Right to Withdraw Consent",
        content:
          "I have the right to withdraw my consent for electronic communication at any time by providing written notice to OhanaDoc.com. However, I understand that withdrawing consent may limit my ability to communicate efficiently with my healthcare providers.",
      },
      {
        heading: "Hawaii Electronic Communication Compliance",
        content:
          "This consent complies with Hawaii Revised Statutes (HRS) §431:10A-116.3, which governs the use of electronic communication in healthcare.",
      },
    ],
    bottomContent:
      "I have read this document carefully and understand the risks and benefits of electronic communication as described herein. I hereby give my informed consent to communicate with OhanaDoc.com healthcare providers and staff via electronic communication methods.",
    consent:
      "I have read and agree to the Consent for Electronic Communication",
  },
  {
    heading: "Financial Responsibility and Billing",
    // heading: "Consent to Financial Responsibility and Billing",
    topContent:
      "I agree to assume financial responsibility for the healthcare services provided by OhanaDoc.com healthcare providers through the OhanaDoc.com platform. I understand and agree to the following:",
    points: [
      {
        heading: "Financial Responsibility",
        content:
          "I acknowledge that I am responsible for all charges associated with the telemedicine services I receive, including but not limited to consultation fees, treatment costs, and any additional services provided through the OhanaDoc.com platform.",
      },
      {
        heading: "Billing and Payment",
        content:
          "I understand that OhanaDoc.com will provide billing for services rendered, and I agree to provide accurate and up-to-date payment information. I consent to the processing of payments through the methods provided by OhanaDoc.com, which may include credit/debit cards and electronic payment systems.",
      },
      {
        heading: "Insurance Claims",
        content:
          "If applicable, OhanaDoc.com will submit claims to my insurance provider for reimbursement. I understand that it is my responsibility to provide accurate insurance information and to follow up with my insurance provider regarding coverage and reimbursement. Any amounts not covered by insurance will be my responsibility.",
      },
      {
        heading: "Non-Covered Services",
        content:
          "I understand that certain services provided by OhanaDoc.com may not be covered by my insurance plan. In such cases, I will be responsible for the full cost of those services.",
      },
      {
        heading: "Cancellation and No-Show Policy",
        content:
          "I acknowledge that OhanaDoc.com has a cancellation and no-show policy. I agree to notify OhanaDoc.com in advance if I need to cancel or reschedule an appointment. Failure to do so may result in a fee as outlined in the policy.",
      },
      {
        heading: "Right to Review",
        content:
          "I have the right to review my billing statements and to request clarification or correction of any discrepancies. I agree to contact OhanaDoc.com promptly if I have any questions or concerns about my billing.",
      },
      {
        heading: "Hawaii Statutes Compliance",
        content:
          "This consent complies with Hawaii Revised Statutes (HRS) §432:1-601.5, which governs patient financial responsibility and billing practices in the state of Hawaii.",
      },
    ],
    bottomContent:
      "I have read this document carefully and understand my financial responsibilities and the billing practices of OhanaDoc.com. I hereby give my consent to assume financial responsibility for the telemedicine services provided and to comply with the billing procedures as described herein.",
    consent:
      "I have read and agree to the Consent to Financial Responsibility and Billing",
  },

  {
    // heading: "Consent for Use of the OhanaDoc Platform",
    heading: "Use of the OhanaDoc Platform",
    topContent:
      "I consent to the use of the OhanaDoc.com licensed platform to act as the intermediary for the delivery of healthcare services from independently licensed providers.  I understand and agree to the following:",
    points: [
      {
        heading: "Platform Usage",
        content:
          "The OhanaDoc.com platform is a secure, HIPAA-compliant system designed to facilitate telemedicine services, including synchronous (live video) and asynchronous (store-and-forward) consultations. I agree to use the platform in accordance with the terms and conditions provided by OhanaDoc.com.",
      },
      {
        heading: "Technology Requirements",
        content:
          "I acknowledge that the use of the OhanaDoc.com platform requires access to certain technology, including a reliable internet connection, a device capable of video communication, and compatible software. I am responsible for ensuring that my equipment meets these requirements.",
      },
      {
        heading: "User Responsibilities",
        content:
          "I agree to provide accurate and complete information during my use of the OhanaDoc.com platform. This includes updating my personal, medical, and payment information as necessary. I understand that failure to provide accurate information may affect the quality and outcome of my healthcare services.",
      },
      {
        heading: "Security and Privacy",
        content:
          "OhanaDoc.com is committed to maintaining the security and privacy of my personal and medical information. The platform utilizes advanced encryption and security protocols to protect my data. I agree to take reasonable steps to ensure the security of my personal devices and to report any security concerns to OhanaDoc.com promptly.",
      },
      {
        heading: "Technical Support",
        content:
          "OhanaDoc.com provides technical support to assist with any issues related to the use of the platform. I agree to contact OhanaDoc.com support if I encounter any technical difficulties that may impact my ability to receive healthcare services.",
      },
      {
        heading: "Compliance with Laws and Regulations",
        content:
          "I understand that the use of the OhanaDoc.com platform is subject to compliance with all applicable federal and state laws, including but not limited to HIPAA and Hawaii Revised Statutes. OhanaDoc.com will adhere to these laws to ensure the protection of my health information and the integrity of the telemedicine services provided.",
      },
      {
        heading: "Appropriate Conduct",
        content:
          "I agree to conduct myself appropriately when using the OhanaDoc.com platform. This includes, but is not limited to:",
        subpoints: [
          {
            title: "Avoiding Drug-Seeking Behavior",
            content:
              "I will not use the platform to seek prescriptions for controlled substances or other medications for non-medical use.",
          },
          {
            title: "Maintaining Professionalism",
            content:
              "I will interact with healthcare providers and staff respectfully and avoid any offensive, threatening, or inappropriate behavior.",
          },
          {
            title: "Sobriety and Safety",
            content:
              "I will not participate in telemedicine consultations while under the influence of drugs or alcohol, while driving, or in any situation where my safety or the safety of others may be compromised.",
          },
        ],
      },
      {
        heading: "Right to Discontinue Use",
        content:
          "I have the right to discontinue the use of the OhanaDoc.com platform at any time. However, I understand that discontinuing use may impact my ability to receive ongoing telemedicine services from OhanaDoc.com.",
      },
      {
        heading: "Acknowledgment of Terms",
        content:
          "By using the OhanaDoc.com platform, I acknowledge that I have read and understood the terms and conditions associated with its use. I agree to comply with these terms to ensure a safe and effective telemedicine experience.",
      },
    ],
    bottomContent:
      "I have read this document carefully and understand the terms of use for the OhanaDoc.com platform. I hereby give my informed consent to use the platform for the delivery of telemedicine services.",
    consent:
      "I have read and agree to the Consent for Use of the OhanaDoc Platform",
  },
  {
    heading: "Patient Location",
    // heading: "Consent for Patient Location",
    topContent:
      "I confirm that I am physically located within the state of Hawaii at the time of my telemedicine consultation through the OhanaDoc.com platform. I understand and agree to the following:",
    points: [
      {
        heading: "Provider Licensure",
        content:
          "OhanaDoc.com healthcare providers are licensed to practice medicine in the state of Hawaii. To ensure compliance with state regulations and guarantee provider licensure, telemedicine services are only available to patients who are physically located within Hawaii.",
      },
      {
        heading: "Verification of Location",
        content:
          "I agree to provide accurate information regarding my location at the time of my telemedicine consultation. I understand that providing false information about my location may result in termination of services and could affect the quality and legality of the care provided.",
      },
      {
        heading: "Right to Refuse Service",
        content:
          "OhanaDoc.com reserves the right to refuse service if it is determined that I am not physically located within the state of Hawaii during the telemedicine consultation.",
      },
    ],
    bottomContent:
      "I confirm that I am physically located within the state of Hawaii at the time of my telemedicine consultation through the OhanaDoc.com platform.",
    consent: " I have read and agree to the Consent for Patient Location.",
  },
  {
    // heading: "Consent for Compounding Pharmacy Use",
    heading: "Compounding Pharmacy Use",
    topContent:
      "I consent to the use of a specific local compounding pharmacy for medications that require sterile compounding processes as prescribed by my OhanaDoc.com healthcare providers. I understand and agree to the following:",
    points: [
      {
        heading: "Compounding Pharmacy Selection",
        content:
          "OhanaDoc.com utilizes a specific local compounding pharmacy for medications that require sterile compounding processes. This pharmacy has been selected for its rigorous sterile procedures and ability to provide high-quality compounded medications.",
      },
      {
        heading: "Purpose of Compounded Medications",
        content:
          "Compounded medications are custom-made based on my specific medical needs as determined by my healthcare provider. These medications are prepared by the compounding pharmacy to ensure that I receive the most appropriate treatment.",
      },
      {
        heading: "Disclosure of Health Information",
        content:
          "I consent to the disclosure of my relevant health information to the compounding pharmacy for the purpose of preparing my medications. This includes my medical history, prescription details, and any other information necessary for safe and effective compounding.",
      },
      {
        heading: "Safety and Quality Assurance",
        content:
          "The selected compounding pharmacy adheres to strict sterile procedures to ensure the safety and efficacy of my medications. I understand that this collaboration is intended to provide me with the highest quality compounded medications available.",
      },
      {
        heading: "Delivery and Convenience",
        content:
          "The compounding pharmacy chosen by OhanaDoc.com is capable of shipping compounded medications directly to my doorstep for added convenience. This ensures timely access to necessary treatments without the need for in-person visits to the pharmacy.",
      },
      {
        heading: "Right to Revoke Consent",
        content:
          "I have the right to withdraw this consent at any time by providing written notice to OhanaDoc.com. However, I understand that withdrawing consent may impact my ability to receive compounded medications through the selected pharmacy.",
      },
      {
        heading: "Hawaii Pharmacy Regulations Compliance",
        content:
          "This consent complies with Hawaii Revised Statutes (HRS) §328-16, which governs the compounding and dispensing of medications in the state of Hawaii.",
      },
    ],
    bottomContent:
      "I have read this document carefully and understand the use of the selected compounding pharmacy for my medications as described herein. I hereby give my informed consent to the use of this pharmacy for the preparation of my compounded medications.",
    consent:
      "I have read and agree to the Consent for Compounding Pharmacy Use.",
  },
  {
    heading: "Emergency Situations Acknowledgment",
    topContent: "I acknowledge that I understand the following:",
    points: [
      {
        heading: "Not for Emergencies",
        content:
          "Telemedicine services provided through the OhanaDoc.com platform are not intended for use in emergency situations. If I experience a medical emergency, I should immediately call 911 or go to the nearest emergency room.",
      },
      {
        heading: "Guidance for Emergencies",
        content:
          "A medical emergency is a serious or potentially life-threatening situation that requires immediate medical attention. Examples of medical emergencies include, but are not limited to:",
        subpoints: [
          { content: "Severe chest pain or pressure" },
          { content: "Shortness of breath or difficulty breathing" },
          { content: "Sudden or severe headache" },
          { content: "Weakness or numbness on one side of the body" },
          {
            content:
              "Sudden confusion, difficulty speaking, or understanding speech",
          },
          { content: "Severe bleeding that cannot be stopped" },
          { content: "Loss of consciousness" },
          {
            content:
              "Severe allergic reactions (e.g., difficulty breathing, swelling of the face or throat)",
          },
          { content: "Severe burns or injuries" },
          { content: "Seizures" },
        ],
      },
      {
        heading: "Steps to Take in an Emergency",
        content:
          "If I experience any of the above symptoms or believe I am experiencing a medical emergency, I will:",
        subpoints: [
          { content: "Call 911 immediately" },
          { content: "Go to the nearest emergency room" },
          { content: "Follow the instructions of emergency medical personnel" },
        ],
      },
    ],
    bottomContent:
      "I have read this document carefully and understand that telemedicine is not suitable for emergency medical situations. I acknowledge that I will seek appropriate emergency care if needed.",
    consent:
      "I have read and agree to the Emergency Situations Acknowledgment.",
  },

  {
    heading: "Health Data Analytics",
    // heading: "Consent for Health Data Analytics",
    topContent:
      "I consent to the use of my health data for analytics purposes to improve the services provided by OhanaDoc.com. I understand and agree to the following:",
    points: [
      {
        heading: "Use of Health Data",
        content:
          "My health data may be used in a de-identified manner for analytics purposes. This data will help OhanaDoc.com improve healthcare services, identify trends, and enhance patient outcomes.",
      },
      {
        heading: "Confidentiality and Anonymity",
        content:
          "All health data used for analytics will be de-identified to protect my privacy. Identifiable information will not be shared without my explicit consent.",
      },
      {
        heading: "Voluntary Participation",
        content:
          "Participation in health data analytics is voluntary. I have the right to withdraw my consent for the use of my health data in analytics at any time by providing written notice to OhanaDoc.com.",
      },
      {
        heading: "Hawaii Statutes Compliance",
        content:
          "This consent complies with Hawaii Revised Statutes (HRS) and other applicable regulations governing the use of health data for analytics purposes.",
      },
    ],
    bottomContent:
      "I have read this document carefully and understand the use of my health data for analytics purposes. I hereby give my informed consent to participate in health data analytics as described herein.",
    consent: "I have read and agree to the Consent for Health Data Analytics.",
  },
];

export const PrivacyPolicy = [
  {
    heading: "OhanaDoc.com Privacy Policy",
    topContent:
      "Aloha and welcome to OhanaDoc! We are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our telemedicine services. Please read this policy carefully. By accessing or using our services, you agree to the terms of this Privacy Policy.",
    points: [
      {
        heading: "Information We Collect",
        content:
          "We collect various types of information to provide and improve our services, including:",
        subpoints: [
          {
            title: "Personal Information",
            content: [
              {
                title: "Identification Information",
                content:
                  " Your name, date of birth, gender, and contact information (e.g., email address, phone number).",
              },
              {
                title: "Health Information",
                content:
                  "Medical history, current health status, medications, and other relevant health data necessary for providing telemedicine services.",
              },
            ],
          },
          {
            title: "Technical Information",
            content: [
              {
                title: "Device Information",
                content:
                  "Information about the device you use to access our services, such as IP address, operating system, and browser type.",
              },
              {
                title: "Usage Information",
                content:
                  "Details about your usage of our services, including access times, pages viewed, and interactions with our platform.",
              },
            ],
          },
          {
            title: "Payment Information",
            content: [
              {
                title: "Billing Information",
                content:
                  "Payment details, including credit card information, billing address, and transaction history.",
              },
            ],
          },
        ],
      },
      {
        heading: "How We Use Your Information",
        content:
          "We use the information we collect for various purposes, including:",
        subpoints: [
          {
            title: "Providing Services",
            content: [
              "To facilitate and manage our telemedicine services.",
              "To diagnose and treat medical conditions.",
              "To enable communication between you and healthcare providers.",
            ],
          },
          {
            title: "Improving Services",
            content: [
              "To enhance our platform and services based on user feedback and usage patterns.",
              "To personalize your experience and provide you with tailored content and recommendations.",
            ],
          },
          {
            title: "Compliance and Security",
            content: [
              "To comply with applicable laws, regulations, and legal processes.",
              "To protect the security and integrity of our platform and services.",
            ],
          },
        ],
      },
      {
        heading: "Information Sharing and Disclosure",
        content:
          "We do not sell, trade, or otherwise transfer your personal information to outside parties except as described in this Privacy Policy:",
        subpoints: [
          {
            title: "Healthcare Providers",
            content:
              "We share your health information with healthcare providers to facilitate telemedicine consultations and treatments.",
          },
          {
            title: "Service Providers",
            content:
              "We may share your information with third-party service providers who assist us in operating our platform and providing our services, such as payment processors and IT service providers. These third parties are obligated to maintain the confidentiality and security of your information.",
          },
          {
            title: "Legal Requirements",
            content:
              "We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., court orders or government agencies).",
          },
        ],
      },
      {
        heading: "Data Security",
        content:
          "We implement a variety of security measures to protect your personal information. These measures include:",
        subpoints: [
          {
            title: "Encryption",
            content:
              "We use industry-standard encryption to protect data transmitted to and from our platform.",
          },
          {
            title: "Access Controls",
            content:
              "Access to your personal information is restricted to authorized personnel who need the information to perform their job duties.",
          },
          {
            title: "Data Retention",
            content:
              "We retain your personal information only as long as necessary to fulfill the purposes for which it was collected or as required by law.",
          },
        ],
      },
      {
        heading: "Your Rights",
        content:
          "You have certain rights regarding your personal information, including:",
        subpoints: [
          {
            title: "Access and Correction",
            content:
              "You have the right to access and correct your personal information. You can update your account information through your account settings or by contacting us directly.",
          },
          {
            title: "Data Portability",
            content:
              "You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format.",
          },
          {
            title: "Deletion",
            content:
              "You have the right to request the deletion of your personal information, subject to certain legal and contractual restrictions.",
          },
          {
            title: "Objection and Restriction",
            content:
              "You have the right to object to or request the restriction of the processing of your personal information under certain circumstances.",
          },
        ],
      },
      {
        heading: "Changes to This Privacy Policy",
        content:
          "We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any significant changes by posting the new Privacy Policy on our platform and updating the effective date. Your continued use of our services after any changes indicates your acceptance of the updated Privacy Policy.",
      },
      {
        heading: "Contact Information",
        content:
          "If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:",
        subpoints: [
          {
            title: "OhanaDoc Support",
            content: "Email : support@ohanadoc.com",
          },
        ],
      },

      {
        heading: "Version Date: 08/01/2024",
        content:
          "By using OhanaDoc, you acknowledge that you have read, understood, and agree to the terms and conditions outlined in this Privacy Policy. Mahalo for trusting OhanaDoc with your healthcare needs.",
      },
    ],
  },
];
