import { useEffect, useState } from 'react';
import Styles from './MyConsultations.module.scss';
import { Modal, Spin, message } from 'antd';
import moment from 'moment';
import {
  getRequest,
  putRequest,
  putRequestFormData
} from '../../service/ApisFunctions';
import Calendar from 'react-calendar';
import classes from './nextSchedule.module.scss';
import { useDispatch } from 'react-redux';
import { setCompleteUser } from '../../Redux/Reducers/gernalSlice';
import SecondaryButton from '../../Components/UI/SecondaryButton/SecondaryButton';
import PrimaryButton from '../../Components/UI/PrimaryButton/PrimaryButton';
import { EmergencyLight } from '../../assets';

const NextSchedule = ({ item, index, completeUser, getAllBookings }) => {
  const [isModalOpenSlots, setIsModalOpenSlots] = useState(false);
  const [selected, setSelected] = useState({});
  const [date, setDate] = useState(new Date());
  const [modalLoading, setModalLoading] = useState(false);
  const [availableTimeSlot, setAvailableTimeSlot] = useState([]);

  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpenSlots(true);
    setSelected({});
  };

  const handleOk = () => {
    setIsModalOpenSlots(false);
    setSelected({});
  };
  const handleCancel = () => {
    setIsModalOpenSlots(false);
    setSelected({});
  };

  const getTimeSlots = async () => {
    if (date && item?.providerId) {
      setModalLoading(true);
      const onSuccess = res => {
        setModalLoading(false);
        setAvailableTimeSlot(res.data);
      };

      const onError = () => {
        setModalLoading(false);
      };
      const route = `provider/get_timeslots_by_provider_id/${
        item?.providerId
      }&${moment(date).format('YYYY-MM-DD')}`;

      await getRequest('', route, true, onSuccess, onError);
    }
  };

  useEffect(() => {
    if (item?.providerId) {
      getTimeSlots();
    }
  }, [date]);

  const createFollowUp = async () => {
    setModalLoading(true);
    const onSuccess = () => {
      UpdateUser();
      getAllBookings(1, 10);
    };

    const onError = err => {
      message.error(err?.message);
    };

    const formData = {
      followUps: [{ timeslot_id: selected?._id, provider_id: item?.providerId }]
    };
    const route = `notes/add-followUps-without-notes/${item?.bookingId}`;

    await putRequest(formData, route, true, onSuccess, onError);
  };

  const UpdateUser = async () => {
    const filterAlerts = completeUser?.alerts?.filter(
      item => item?._id === selected?._id
    );
    const onSuccess = res => {
      setModalLoading(false);
      dispatch(setCompleteUser(res?.data));
      handleOk();
      message.success(res?.message);
    };

    const onError = err => {
      setModalLoading(false);

      message.error(err.message);
    };

    const formdata = {
      alerts: filterAlerts
    };

    await putRequestFormData(
      formdata,
      `customer/remove-alert/${item?._id}`,
      onSuccess,
      onError
    );
  };

  return (
    <div key={index} className={Styles.unfilledConsultationDetail}>
      <div className={Styles.logo}>
        <img src={EmergencyLight} alt='$$$' />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          width: '100%'
        }}
        className={Styles.alertText}
      >
        <div>
          <h4>{item?.text}</h4>
          {/* <p>
            Some consultations require more information. Please complete them.
          </p> */}
        </div>
        <SecondaryButton onClick={() => showModal()}>Schedule</SecondaryButton>
      </div>

      <Modal
        width={'70%'}
        footer={false}
        open={isModalOpenSlots}
        onCancel={handleCancel}
        onOk={handleOk}
        centered
        title='Create Consulatation'
      >
        <Spin spinning={modalLoading}>
          <div className={classes.container}>
            <div>
              <Calendar
                onChange={e => {
                  setDate(e);
                }}
                minDate={new Date()}
                value={date}
                maxDate={null}
                className={classes.calendar}
              />
            </div>
            <hr />
            <span className='bold'>Available time slots</span>
            <div className={classes.calendarButtons}>
              {availableTimeSlot?.length ? (
                availableTimeSlot?.map((button, index) => {
                  return (
                    <PrimaryButton
                      key={index}
                      className={
                        button?._id == selected?._id
                          ? `${classes.button} ${classes.activeBtn}`
                          : classes.button
                      }
                      onClick={() => {
                        setSelected(button);
                      }}
                    >
                      {`${moment(button?.start).format('hh:mm A')} - ${moment(
                        button?.end
                      ).format('hh:mm A')}`}
                    </PrimaryButton>
                  );
                })
              ) : (
                <div style={{ width: '100%' }}>
                  <p style={{ textAlign: 'center' }}>Slots not available</p>
                </div>
              )}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px'
              }}
            >
              <div style={{ width: '200px' }}>
                <SecondaryButton
                  type='submit'
                  width='100%'
                  onClick={() => createFollowUp()}
                >
                  Submit
                </SecondaryButton>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default NextSchedule;
