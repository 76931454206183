export const GetAllDoctors = "chat/chat_listing"
export const GetAllChats = "chat/set_chat_seen"


export const getCategoryName = (item) => {
  const refills = item?.refill_info?.refills;


  if (refills && refills.length > 0) {
    for (const refill of refills) {
      if (refill?.provider_id === item?.provider?._id) {
        return refill?.category?.category_name
          ? `${refill?.category?.category_name} (Refill)`
          : refill?.speciality?.speciality_name
          ? `${refill?.speciality?.speciality_name} (Refill)`
          : item?.booking_info?.program?.treatment_name
          ? item?.booking_info?.program?.treatment_name
          : "-";
      }
    }
  }

  return item?.booking_info?.category?.category_name
    ? item?.booking_info?.category?.category_name
    : item?.booking_info?.speciality?.speciality_name
    ? item?.booking_info?.speciality?.speciality_name
    : "-";
};
