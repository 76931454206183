import React from 'react';
import './note&Dx.scss';

const NoteAndDx = ({ booking }) => {
  const notes = [
    {
      title: 'Assessment notes',
      answer: booking?.assessment?.assessment || '-'
    },
    {
      title: 'Objective notes',
      answer: booking?.objective?.objectiveNote || '-'
    },
   ...(booking?.booking_type === "video" && booking?.treatmentNote ? [{
      title: 'Treatment notes',
      answer:
        booking?.treatmentNote || "-"
    }] : []),
    ...(booking?.booking_type === "video" && booking?.excuseNote  ? [{
      title: 'Excuse notes',
      answer: booking?.excuseNote || '-'
    }] : []),

    ...(booking?.booking_type === "video" && booking?.privateNote  ? [{
      title: 'Additional private Referral note to provider',
      answer: booking?.privateNote || '-'
    }] : []),
  ];

  return (
    <div className='noteAndDx'>
      <div className='heading'>
        <h2>Diagnosis</h2>
      </div>
      <div className='body'>
        <div className='dx'>
          <h3>Primary Diagnose</h3>
          <div className='diagnosis'>
            {booking?.assessment?.primaryDiagnose?.DiagnosisName ? (
              <p>{booking?.assessment?.primaryDiagnose?.DiagnosisName}</p>
            ) : (
              <span>No Primary Diagnose</span>
            )}
          </div>
        </div>

        <div className='dx'>
          <h3>Secondary Diagnose</h3>
          <div className='diagnosis'>
            {booking?.assessment?.secondaryDiagnose?.DiagnosisName ? (
              <p>{booking?.assessment?.secondaryDiagnose?.DiagnosisName}</p>
            ) : (
              <span>No Secondary Diagnose</span>
            )}
          </div>
        </div>
      </div>

      {notes?.map((item, index) => (
        <React.Fragment key={index}>
          <div className='heading'>
            <h2>{item?.title}</h2>
          </div>
          <div className='notebody'>
            <p className='note-item'>{item?.answer}</p>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default NoteAndDx;
