import { Col, Form, Input, Row, Spin, message } from "antd";
import React, { useState } from "react";
import { strongPasswordValidator } from "../../../../Utils/helper";
import BlueButton from "../../../../Components/UI/BlueButton/BlueButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { putRequest } from "../../../../service/ApisFunctions";
import {logOut } from "../../../../Redux/Reducers/authSlice";
import { Reset_Password } from "../constant";

const ResetPasswordStep = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const user = useSelector((state) => state.authReducer.user);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    if (e?.password && e?.confirm_password) {
      setLoading(true);

      const onSuccess = (res) => {
        message.success(res?.message);
        setLoading(false);
        dispatch(logOut());
        navigate("/login");
      };
      const onError = (err) => {
        message.error(err?.message);
        setLoading(false);
      };

      const resetPasseord = {
        email: user?.email,
        password: e?.password,
        confirm_password: e?.confirm_password,
        role: "customer",
      };

      await putRequest(resetPasseord, Reset_Password, true, onSuccess, onError);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="login-right-side">
        <div>
          <h2>Reset Password</h2>
        </div>

        <div className="formArea">
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Row gutter={20}>
              <Col span={24}>
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  { required: true, message: "Please enter a new password" },
                  { validator: newPassword && strongPasswordValidator },
                ]}
              >
                <Input.Password
                  onChange={(e) => setNewPassword(e?.target?.value)}
                  className="ant-input-affix-wrapper"
                  placeholder="Enter new password"
                />
              </Form.Item>
              </Col>

              <Col span={24}>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter a confirm password",
                  },
                  { validator: confirm && strongPasswordValidator },
                ]}
              >
                <Input.Password
                  onChange={(e) => setConfirm(e?.target?.value)}
                  placeholder="Confirm Password"
                  className="ant-input-affix-wrapper"
                />
              </Form.Item>
              </Col>

         
              <Col style={{ marginTop: "20px" }} span={24}>
                <BlueButton width="100%">Reset Password</BlueButton>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default ResetPasswordStep;
