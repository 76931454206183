import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { arrowDown, logo } from "../../../assets";
import "./BookingStepsLayout.scss";
import {
  resetBookingState,
  setBookingFlowInformationSteps,
  setBookingFlowMedialInfoSteps,
} from "../../../Redux/Reducers/bookingSlice";
export const BookingStepsLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookingFlowInformationSteps = useSelector(
    (state) => state.booking.bookingFlowInformationSteps
  );
  const bookingFlowIsUrgent = useSelector(
    (state) => state.booking.bookingFlowIsUrgent
  );
  const bookingFlowMedialInfoSteps = useSelector(
    (state) => state.booking.bookingFlowMedialInfoSteps
  );
  const routesHandler = () => {
    const { pathname } = window.location;

    const handleBookingFlowSteps = () => {
      if (bookingFlowIsUrgent && bookingFlowInformationSteps === 2) {
        dispatch(setBookingFlowInformationSteps(0));
      } else {
        if (bookingFlowInformationSteps > 0) {
          dispatch(
            setBookingFlowInformationSteps(bookingFlowInformationSteps - 1)
          );
        } else {
          dispatch(resetBookingState());
          navigate("/");
        }
      }
    };

    const handleMedicalSteps = () => {
      if (bookingFlowMedialInfoSteps > 0) {
        dispatch(setBookingFlowMedialInfoSteps(bookingFlowMedialInfoSteps - 1));
      } else {
        navigate("/consultations");
      }
    };

    if (pathname.includes("consultation-booking")) {
      handleBookingFlowSteps();
    } else if (pathname.includes("/medical")) {
      handleMedicalSteps();
    } else if (pathname.includes("/booking-review")) {
      dispatch(setBookingFlowMedialInfoSteps(1));
      navigate("/medical");
    }
  };

  return (
    <>
      <div className={"bookingStepWrapper"}>
        <div className={"logo"}>
          <ReactSVG
            src={logo}
            onClick={() => {
              dispatch(resetBookingState());
              navigate("/");
            }}
          />
        </div>
        <div className={"backBtn"}>
          <ReactSVG src={arrowDown} onClick={() => routesHandler()} />
          <span className={"back"} onClick={() => routesHandler()}>
            Back
          </span>
        </div>
      </div>
      <main>{children}</main>
    </>
  );
};
