import { Form, Input, Modal, Spin, message } from "antd";
import React, { useState } from "react";
import { postRequest, putRequest } from "../../service/ApisFunctions";
import ApplyCoupon from "../ApplyCoupon";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import CouponAppled from "../ApplyCoupon/couponAppled";
import { setBookingFlowCouponDetail } from "../../Redux/Reducers/bookingSlice";

export const FollowUpPayment = ({
  handleOk,
  singleFollowUp,
  getAllFollowUps,
  bookingDetails,
}) => {
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [couponDetails, setCouponDetails] = useState({});

  const [form] = Form.useForm();
  const dispatch = useDispatch()

  const bookingFlowCouponDetail = useSelector(
    (state) => state.booking?.bookingFlowCouponDetail
  );

  const handleExpiryDateChange = (event) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedInput = "";

    if (input.length >= 2) {
      const month = input.substr(0, 2);
      if (parseInt(month, 10) > 0 && parseInt(month, 10) <= 12) {
        formattedInput += month;

        const enteredYear = parseInt(input.substr(2, 2), 10);
        if (enteredYear >= 0 && enteredYear < 100) {
          formattedInput += " / " + input.substr(2, 2);
        } else if (input.length > 2) {
          formattedInput += " / " + input.substr(2, 4);
        }
      }
    } else {
      formattedInput += input;
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;

    const enteredYear =
      parseInt(input.substr(2, 2), 10) + currentYear - (currentYear % 100);
    const enteredMonth = parseInt(input.substr(0, 2), 10);

    setIsInvalidDate(
      isNaN(enteredYear) ||
        isNaN(enteredMonth) ||
        enteredYear < currentYear ||
        (enteredYear === currentYear && enteredMonth < currentMonth)
    );

    setIsExpired(
      enteredYear < currentYear ||
        (enteredYear === currentYear && enteredMonth < currentMonth)
    );
    setExpiryDate(formattedInput.trim());
  };

  const handleCardNumberChange = (event) => {
    const input = event?.target?.value?.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedInput = "";

    for (let i = 0; i < input.length; i += 4) {
      formattedInput += input.substr(i, 4) + " ";
    }

    setCardNumber(formattedInput.trim());
  };

  const applyDiscount = (price, discountType, discountValue) => {
    if (discountType === "value") {
      return price - discountValue;
    } else {
      const discountAmount = (discountValue / 100) * price;
      return price - discountAmount.toFixed(2);
    }
  };

  const finalPrice = bookingFlowCouponDetail?._id
    ? applyDiscount(
        singleFollowUp?.payment,
        bookingFlowCouponDetail?.discountType,
        bookingFlowCouponDetail?.discountValue
      )
    : singleFollowUp?.payment;

  const PayPayment = async (values) => {
    if ((cardNumber, cvc, finalPrice, expiryDate, !isExpired)) {
      setLoading(true);
      const onSuccess = (res) => {
        setLoading(false);
        updatePaymentStatus();
      };

      const onError = (res) => {
        setLoading(false);
        message.error(res?.error_message);
      };

      const formData = {
        card_number: cardNumber?.replace(/\s/g, ""),
        card_expiration: expiryDate?.replace(/\s/g, "").replace("/", ""),
        card_code: cvc,
        amount: finalPrice.toString(),
      };
      await postRequest(
        formData,
        "auth/authorized_card_payment",
        true,
        onSuccess,
        onError
      );
    } else {
      message.warning("Please enter valid card details");
    }
  };

  const updatePaymentStatus = async () => {
    setLoading(true);

    const onSuccess = (res) => {
      setLoading(false);
      updateBookingStatus();
    };
    const onError = (err) => {
      setLoading(false);
    };

    const formData = {
      payment_status: true,
    };

    const route = `booking/update_video_booking/${singleFollowUp?._id}`;
    await putRequest(formData, route, true, onSuccess, onError);
  };

  // booking status

  const updateBookingStatus = async () => {
    setLoading(true);

    const onSuccess = (res) => {
      setLoading(false);
      setCVC("");
      setCardNumber("");
      setExpiryDate("");
      handleOk();
      getAllFollowUps();
      dispatch(setBookingFlowCouponDetail(null))

      form.resetFields();
    };
    const onError = (err) => {
      setLoading(false);
    };

    const formData = {
      status: "upcoming",
      couponId: couponDetails?._id ? couponDetails?._id : "",
    };

    const route = `booking/update_booking_status/${singleFollowUp?._id}`;
    await putRequest(formData, route, true, onSuccess, onError);
  };

  const bookingType =
    bookingDetails?.booking_type == "video"
      ? "Medical consultation"
      : "Treatment programs";

  return (
    <div>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={PayPayment}>
          <div className="payment">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>Pay With Card</h3>
            </div>
            <Form.Item
              style={{ marginTop: "10px" }}
              label="Card No"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Card no"
                maxLength={"19"}
                onChange={handleCardNumberChange}
                className="ant-input-affix-wrapper"
                value={cardNumber}
              />
            </Form.Item>
            <Form.Item label="CVC" rules={[{ required: true }]}>
              <Input
                placeholder="CVC"
                type="number"
                value={cvc}
                onChange={(e) => {
                  setCVC((prev) => {
                    if (e.target.value.length === 4) {
                      return prev;
                    }
                    return e.target.value;
                  });
                }}
                className="ant-input-affix-wrapper"
              />
            </Form.Item>
            <Form.Item
              label="Expiry Date ( MM/YY )"
              rules={[
                { required: true, message: "Please enter a valid expiry date" },
              ]}
              validateStatus={isInvalidDate ? "error" : ""}
              help={isInvalidDate && "Please enter a valid expiry date"}
            >
              <Input
                placeholder="Expiry Date MM/YY"
                type="text"
                onChange={handleExpiryDateChange}
                value={expiryDate}
                className={
                  isExpired
                    ? "ant-input-affix-wrapper expired-color"
                    : "ant-input-affix-wrapper"
                }
              />
            </Form.Item>

            {bookingFlowCouponDetail?._id ? (
              <CouponAppled
                bookingPrice={singleFollowUp?.payment}
                finalPrice={finalPrice}
              />
            ) : (
              <ApplyCoupon
                bookingType={bookingType}
                setLoading={setLoading}
                handleOk={handleOk}
                setCouponDetails={setCouponDetails}
                couponDetails={couponDetails}
                category={
                  bookingDetails?.booking_type == "video"
                    ? bookingDetails?.category?._id
                    : bookingDetails?.program?._id
                }
                speciality={bookingDetails?.speciality?._id}
              />
            )}

            <div style={{ display: "flex", justifyContent: "center",marginTop:"20px" }}>
              <SecondaryButton
                className={
                  cvc.length === 3 &&
                  cardNumber.length == 19 &&
                  expiryDate.length === 7
                    ? "pay-btn"
                    : "disablePayBtn"
                }
                disabled={
                  cvc.length === 3 &&
                  cardNumber.length == 19 &&
                  expiryDate.length === 7
                    ? false
                    : true
                }
              >
                Confirm payment ${finalPrice}
              </SecondaryButton>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
