import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import "./consultationHeader.scss";
import { DomyP, arrowBack } from "../../assets";
import { Modal } from "antd";
import PrimaryButton from "../UI/PrimaryButton/PrimaryButton";
import { RescheduleConsultation } from "../RescheduleConsultation/RescheduleConsultation";

const ConsultationHeader = ({ booking, getConsultationById }) => {
  const navigate = useNavigate();
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const backHandler = () => {
    navigate("/consultations");
  };
  return (
    <div className="consultationHeader">
      <div className="left-side">
        <div className="consultationHeaderContainer">
          <ReactSVG
            src={arrowBack}
            onClick={backHandler}
            className="back-arrow"
          />
          <div className="consultationHeaderPatient">
            <div className="doctorImage">
              <img
                src={
                  booking?.provider_id?.profile_image
                    ? booking?.provider_id?.profile_image
                    : DomyP
                }
                alt="$$$"
              />
              <div
                style={{
                  background: booking?.provider_id?.is_online
                    ? ""
                    : "lightGray",
                }}
                className="onlineDot"
              ></div>
            </div>
            <div>
              <h2>{`${booking?.provider_id?.first_name || "-"} ${
                booking?.provider_id?.middle_name || ""
              } ${booking?.provider_id?.last_name || "-"}`}</h2>
              <p>
                {booking?.booking_type == "video"
                  ? booking?.category?.category_name ||
                    booking?.speciality?.speciality_name
                  : booking?.program?.treatment_name}
                - ${booking?.discountedPrice || booking?.payment}
              </p>
            </div>
            {booking?.booking_status === "leave" ||
            new Date() < new Date(booking?.schedule?.start_time) ? (
              <PrimaryButton
                onClick={() => setIsRescheduleModalOpen(true)}
                className="rescheduleButton"
              >
                Reschedule Appointment
              </PrimaryButton>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        open={isRescheduleModalOpen}
        width="60%"
        size="large"
        footer={null}
        onCancel={() => {
          setIsRescheduleModalOpen(false);
        }}
        title="Reschedule Appointment"
        centered
        maskClosable={false}
      >
        <RescheduleConsultation
          bookingDetails={booking}
          isModalOpen={setIsRescheduleModalOpen}
          getBooking={getConsultationById}
          apiUrl="booking/schedule-refered-booking"
          schedule='isSchedule'
        />
      </Modal>
    </div>
  );
};

export default ConsultationHeader;
