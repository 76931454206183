import { Col, Empty, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequest } from '../../../../service/ApisFunctions';
import { SearchIcon, arrowBack } from '../../../../assets';
import SkeletonInput from '../../../UI/Skeleton/skeletonInput';
import SkeletonImage from 'antd/es/skeleton/Image';
import SingleDoctorCard from './singleDoctorCard';
import { useMediaQuery } from 'react-responsive';

export const ScheduleConsultationDoctors = ({
  setSelectedDoctor,
  setIsNext,
  type
}) => {
  const [loading, setLoading] = useState(false);
  const [allDoctor, setAllDoctor] = useState([]);
  const [doctorListing, setDoctorList] = useState([]);
  const speacilityDetails = useSelector(
    state => state.authReducer.speacilityDetails
  );
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const bookingData = useSelector(state => state.booking);

  const getDoctorList = async () => {
    setLoading(true);
    const onSuccess = res => {
      setLoading(false);
      setAllDoctor(res.data);
      setDoctorList(res.data);
    };

    const onError = () => {
      setLoading(false);
    };

    if (bookingData?.bookingFlowType === 'Treatment programs') {
      await getRequest(
        '',
        'provider/provider_listing',
        true,
        onSuccess,
        onError
      );
    } else {
      const route = bookingData?.bookableFlowCondition?._id
        ? `provider/provider_listing_by_category_id/${bookingData?.bookableFlowCondition?._id}`
        : `provider/provider_listing_by_speciality_id_slots/${bookingData?.bookableFlowSpeciality?._id}`;
      await getRequest('', route, true, onSuccess, onError);
    }
  };
  const FilterData = e => {
    const filterData = allDoctor.filter(item =>
      `${item?.first_name}${item?.last_name}`
        .toLowerCase()
        ?.includes(e.toLowerCase().trim())
    );
    setDoctorList(filterData);
  };

  useEffect(() => {
    getDoctorList();
  }, []);
  return (
    <>
      {loading ? (
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <SkeletonInput block={true} />
          </Col>
          <Col span={12}>
            <SkeletonInput block={true} />
          </Col>
          {[1, 2, 3, 4]?.map((item, index) => (
            <Col key={index} span={mobileResponsive ? 24 : 6}>
              <div className='singleDoctorCard'>
                <div
                  className='singleDoctorCardImage'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <SkeletonImage active />
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div>
                    <SkeletonInput size='small' block={true} />
                    <div style={{ marginTop: '10px' }}>
                      <SkeletonInput size='small' block={true} />
                    </div>
                  </div>
                </div>
                <SkeletonInput block={true} />
              </div>
            </Col>
          ))}

          <Col></Col>
        </Row>
      ) : (
        <>
          <Row style={{ marginBottom: '20px' }} align='middle'>
            <Col span={mobileResponsive ? 24 : 12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  paddingBottom: '20px'
                }}
              >
                {type === 'video' && (
                  <img
                    onClick={() => setIsNext(false)}
                    style={{ cursor: 'pointer' }}
                    src={arrowBack}
                    alt='$$$'
                  />
                )}
                <h2 className='heading'>Available Providers</h2>
              </div>
            </Col>
            <Col span={mobileResponsive ? 24 : 12}>
              <Input
                style={{ width: '100%', marginBottom: '20px' }}
                onChange={e => FilterData(e.target.value)}
                prefix={
                  <img
                    src={SearchIcon}
                    style={{ paddingRight: '5px' }}
                    alt='$$$'
                  />
                }
                placeholder='Search a doctor'
              />
            </Col>
          </Row>
          <Row
            className='ScheduleDoctorsWrap'
            style={{ width: '100%', padding: '0px 10px' }}
            gutter={[20, 20]}
          >
            {doctorListing?.map((item, index) => (
              <React.Fragment key={index}>
                <Col span={mobileResponsive ? 24 : 6}>
                  <SingleDoctorCard
                    setSelectedDoctor={setSelectedDoctor}
                    doctor={item}
                  />
                </Col>
              </React.Fragment>
            ))}
            {!doctorListing?.length && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%'
                }}
              >
                <Empty description='No doctors available.' />
              </div>
            )}
          </Row>
        </>
      )}
    </>
  );
};
