import React, { useEffect, useState } from "react";
import "./serviceMenu.scss";
import { Col, Row, message } from "antd";
import { getRequest } from "../../../../service/ApisFunctions";
import { useDispatch } from "react-redux";
import {
  setBookableFlowCondition,
  setBookableFlowSpeciality,
  setBookingFlowPrice,
  setBookingFlowType,
} from "../../../../Redux/Reducers/bookingSlice";
import SkeletonInput from "../../../UI/Skeleton/skeletonInput";
import {
  setSpeacilityDetails,
  setSpeacilityImage,
  setbookingType,
} from "../../../../Redux/Reducers/authSlice";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ServiceMenu = ({setOpenDrawer, service, setOpenPopoverIndex }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const [speacilityLoading, setSpeacilityLoading] = useState(false);
  const [allSpecility, setAllSpecility] = useState(null);
  const [singleSpecility, setSingleSpecility] = useState(null);
  const [singleCondition, setSingleCondition] = useState(null);

  // get all active specialization listing

  const getSpecialization = async () => {
    setSpeacilityLoading(true);
    const onSuccess = (res) => {
      setAllSpecility(res?.data);
      setSingleSpecility(res?.data?.[0]);
      setSingleCondition(res?.data?.[0]?.categories?.[0]);
      dispatch(setBookableFlowSpeciality(res?.data[0]));
      setSpeacilityLoading(false);
      dispatch(setSpeacilityImage(res?.data[0]?.image));
    };
    const onError = (err) => {
      if (err?.message) {
        message?.error(err?.message);
        setSpeacilityLoading(false);
      } else {
        message.error("Something Went Wrong");
      }
    };
    const route = `speciality/active_speciality_by_booking_type/${service?.id}`;
    await getRequest("", route, true, onSuccess, onError);
  };

  useEffect(() => {
    if (service?.id) {
      getSpecialization();
    }
  }, [service]);


  const selectCondition = (item) => {
    dispatch(setbookingType(service?.service?.flow));
    dispatch(setBookableFlowCondition(item));
    dispatch(setSpeacilityDetails(item));
    dispatch(setBookingFlowType(service?.service?.flow));

    dispatch(
      setBookingFlowPrice(
        (service?.service?.price > 0 && service?.service?.price) ||
          (singleSpecility?.price > 0 && singleSpecility?.price) ||
          item?.price
      )
    );
    setSingleCondition(item?.category_name);
    setOpenPopoverIndex(null);
    setOpenDrawer(false)
    navigate("/booking-details");
  };

  return (
    <Row gutter={[10, 10]} className="serviceMenu">
      <Col span={mobileResponsive ? 24 : 8}>
        <div className="left-side">
          {speacilityLoading ? (
            <>
              <div style={{width:"100%"}} >
                <SkeletonInput block={true} />
              </div>
              <div style={{width:"100%", marginTop: mobileResponsive ? "0px" : "10px" }}>
                <SkeletonInput block={true } />
              </div>
              {!mobileResponsive && (
                <>
                  <div style={{ marginTop: "10px" }}>
                    <SkeletonInput block={true} />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <SkeletonInput block={true} />
                  </div>
                </>
              )}
            </>
          ) : (
            allSpecility?.map((item, index) => (
              <p
                key={index}
                onClick={() => {
                  dispatch(setBookableFlowSpeciality(item));
                  dispatch(setSpeacilityImage(item?.image));
                  setSingleSpecility(item);
                  setSingleCondition(item?.categories?.[0]);
                }}
                className={item?._id === singleSpecility?._id && "active"}
              >
                {item?.speciality_name || "-"}
              </p>
            ))
          )}
        </div>
      </Col>
      <Col span={mobileResponsive ? 24 : 16}>
        <Row gutter={[10, 10]}>
          {speacilityLoading
            ? [1, 2, 3, 4]?.map((item, index) => (
                <Col key={index} span={mobileResponsive ?24 : 12}>
                  <SkeletonInput block={true} />
                </Col>
              ))
            : singleSpecility?.categories?.map((item, index) => (
                <Col key={index} span={12}>
                  <div className="right-side">
                    <p
                      onClick={() => selectCondition(item)}
                      className={item?._id === singleCondition?._id && "active"}
                    >
                      {item?.category_name || "-"}
                    </p>
                  </div>
                </Col>
              ))}
        </Row>
      </Col>
    </Row>
  );
};

export default ServiceMenu;
