import React, { useEffect, useState } from 'react';
import { Drawer, message } from 'antd';
import { PatientBookingReview } from './PatientBookingReview';
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton';
import { BookingPaymentForm } from './BookingPayment/BookingPaymentForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBookableFlowMedicalHistory,
  setBookableFlowSocialHistory,
  setBookingFlowLocation,
  setBookingFlowPatientData
} from '../../../Redux/Reducers/bookingSlice';
import dayjs from 'dayjs';
import {
  setIsConsentForm,
  setIsProfileFilled
} from '../../../Redux/Reducers/authSlice';

export const PatientBookingPayment = () => {
  const completeUser = useSelector(state => state.generalReducer.completeUser);
  const bookingFlowPatientData = useSelector(
    state => state.booking.bookingFlowPatientData
  );

  const [isReviewDrawerOpen, setIsReviewDrawerOpen] = useState(true);
  const [isMedicalHistoryQuestions, setIsMedicalHistoryQuestions] =
    useState(false);
  const [socialHistoryQuestions, setSocialHistoryQuestions] = useState([]);

  const dispatch = useDispatch();

  const isFilledMedicalHistory = () => {
    // if (!completeUser?.pharmacy?.PharmacyId) {
    //   message?.warning('Please update pharmacy');
    //   return;
    // }

    // if (isMedicalHistoryQuestions) {
    //   message?.warning("Please update medical history");
    //   return;
    // }
    if (completeUser?._id && !completeUser?.isProfileFilled) {
      dispatch(setIsProfileFilled({ personalInfo: true }));
      return;
    }
    if (completeUser?._id && !completeUser?.allowConsent) {
      dispatch(setIsConsentForm(true));
      return;
    }
    // dispatch(setBookableFlowMedicalHistory(medicalHistoryQuestions));
    // dispatch(setBookableFlowSocialHistory(socialHistoryQuestions));
    setIsMedicalHistoryQuestions(true);
    setSocialHistoryQuestions([]);
    setIsReviewDrawerOpen(false);
  };

  const setSelfPersonalInfo = () => {
    dispatch(
      setBookingFlowPatientData({
        first_name:
          bookingFlowPatientData?.first_name || completeUser?.first_name || '',
        last_name:
          bookingFlowPatientData?.last_name || completeUser?.last_name || '',
        middle_name:
          bookingFlowPatientData?.middle_name ||
          completeUser?.middle_name ||
          '',
        prefix: bookingFlowPatientData?.prefix || completeUser?.prefix || '',
        suffix: bookingFlowPatientData?.suffix || completeUser?.suffix || '',
        email: completeUser?.user_id?.email || '',
        phone_number:
          bookingFlowPatientData?.phone_number ||
          completeUser?.phone_number ||
          '',
        address:
          bookingFlowPatientData?.location?.address ||
          completeUser?.location?.address ||
          '',
        PrimaryPhoneType:
          bookingFlowPatientData?.PrimaryPhoneType ||
          completeUser?.PrimaryPhoneType ||
          '',
        dob: bookingFlowPatientData?.dob
          ? dayjs(bookingFlowPatientData?.dob)
          : null || completeUser?.dob
          ? dayjs(completeUser?.dob)
          : null,
        height: {
          value: completeUser?.height?.value || completeUser?.height?.value,
          unit:
            bookingFlowPatientData?.height?.unit || completeUser?.height?.unit
        },
        weight: {
          value:
            bookingFlowPatientData?.weight?.value ||
            completeUser?.weight?.value,
          unit:
            bookingFlowPatientData?.weight?.unit || completeUser?.weight?.unit
        },
        language:
          bookingFlowPatientData?.language || completeUser?.language || [],
        state: bookingFlowPatientData?.state || completeUser?.state || null,
        birthGender:
          bookingFlowPatientData?.birthGender ||
          completeUser?.birthGender ||
          null,
        sexualOrientation:
          bookingFlowPatientData?.sexualOrientation ||
          completeUser?.sexualOrientation ||
          null,
        race: bookingFlowPatientData?.race || completeUser?.race || null,
        ethnicity:
          bookingFlowPatientData?.ethnicity || completeUser?.ethnicity || null,
        gender: bookingFlowPatientData?.gender || completeUser?.gender || null,
        pronoun:
          bookingFlowPatientData?.pronoun || completeUser?.pronoun || null,
        zipCode:
          bookingFlowPatientData?.ZipCode || completeUser?.ZipCode || null,
        city: bookingFlowPatientData?.City || completeUser?.City || null
      })
    );
    dispatch(
      setBookingFlowLocation({
        address: completeUser?.location?.address,
        coordinates: [
          completeUser?.location?.coordinates[0],
          completeUser?.location?.coordinates[1]
        ]
      })
    );
  };

  useEffect(() => {
    setSelfPersonalInfo();
  }, [completeUser]);

  return (
    <>
      {/* <PaymentForm setLoading={setLoading} loading={loading} />{' '} */}
      <BookingPaymentForm />
      <Drawer
        title='Generate Invoice'
        placement={'right'}
        closable={false}
        open={isReviewDrawerOpen}
        width={'70%'}
        key={'right'}
        className='customInvoiceDrawer'
      >
        <PatientBookingReview
          isMedicalHistoryQuestions={isMedicalHistoryQuestions}
          setIsMedicalHistoryQuestions={setIsMedicalHistoryQuestions}
          setSocialHistoryQuestions={setSocialHistoryQuestions}
          isFilledMedicalHistory={isFilledMedicalHistory}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '30px'
          }}
        >
          <SecondaryButton onClick={() => isFilledMedicalHistory()}>
            Continue to payment
          </SecondaryButton>
        </div>
      </Drawer>
    </>
  );
};
