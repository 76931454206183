import React from 'react';
import './PrimaryButton.scss';

const PrimaryButton = ({ onClick, className, children, type, style }) => {
  return (
    <button
      style={style}
      onClick={onClick}
      type={type}
      className={`primary-button ${className}`}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
