import React, { useState } from 'react';
import './signup.scss';
import { useMediaQuery } from 'react-responsive';
import { Col, Row, Spin, message } from 'antd';
import { SignUpVector, logo } from '../../../assets';
import FirstStep from './Steps/firstStep';
import { useNavigate } from 'react-router-dom';
import SecondStep from './Steps/secondStep';
import ThirdStep from './Steps/thirdStep';
import { setIsProfileFilled } from '../../../Redux/Reducers/authSlice';
import { CreatePatient } from './constant';
import { postRequest } from '../../../service/ApisFunctions';
import { useDispatch } from 'react-redux';

const SignUpFrom = () => {
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signUpSteps, setSignUpSteps] = useState(0);
  const [signUpData, setSignUpData] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async data => {
    setLoading(true);
    const onSuccess = res => {
      message.success(res?.message);
      dispatch(setIsProfileFilled({ personalInfo: true }));
      setLoading(false);
      navigate('/login');
    };

    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setLoading(false);
    };

    await postRequest(data, CreatePatient, true, onSuccess, onError);
  };

  const components = [
    {
      title: 'Create an account',
      Component: (
        <FirstStep
          setSignUpSteps={setSignUpSteps}
          setSignUpData={setSignUpData}
          signUpData={signUpData}
        />
      )
    },
    {
      title: 'Personal Information',
      Component: (
        <SecondStep
          setSignUpSteps={setSignUpSteps}
          setSignUpData={setSignUpData}
          signUpData={signUpData}
        />
      )
    },
    {
      title: 'Complete Address',
      Component: (
        <ThirdStep
          setSignUpSteps={setSignUpSteps}
          setSignUpData={setSignUpData}
          handleSubmitFinal={handleSubmit}
          signUpData={signUpData}
        />
      )
    }
  ];

  return (
    <div className='signUp'>
      <Row>
        <Col span={mobileResponsive ? 24 : 12}>
          <div className='signUp-header'>
            <img onClick={() => navigate('/')} src={logo} alt=' $$$' />
          </div>
        </Col>

        {!mobileResponsive && (
          <Col span={12}>
            <div style={{ background: 'none' }} className='login-header'></div>
          </Col>
        )}
        {!mobileResponsive && (
          <Col span={12}>
            <div className='signUp-left-side'>
              <img src={SignUpVector} alt='' />
            </div>
          </Col>
        )}
        <Col span={mobileResponsive ? 24 : 12}>
          <Spin spinning={loading}>
            <div className='signUp-right-side'>
              <div>
                <h2>Sign Up</h2>
                <p>{components?.[signUpSteps]?.title}</p>
              </div>

              <div className='formArea'>
                {components?.[signUpSteps]?.Component}
              </div>
              <p className='alreadyAccount'>
                Already have an account?
                <span onClick={() => navigate('/login')}> Login</span>
              </p>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default SignUpFrom;
