import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import "./invoice.scss";
import { Col, Empty, Modal, Row, Spin, message } from "antd";
import { getRequest } from "../../../../service/ApisFunctions";
import SecondaryButton from "../../../UI/SecondaryButton/SecondaryButton";
import { TreatmentInvoice } from "../../TreatmentInvoice";
import { SinglePayment } from "../../../payment/SinglePayment";
import { InvoiceCalander, InvoiceSession } from "../../../../assets";

const ProgramInvoice = ({ booking }) => {

  const [allInvoices, setAllInvoices] = useState([]);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentOffer, setOffer] = useState(null);

  const getInvoices = async () => {
    setLoading(true);
    const onSuccess = (res) => {
      setAllInvoices(res?.data?.offers);
      setLoading(false);
    };
    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    await getRequest(
      "",
      `programsOffers/get-booking-offers/${booking?._id}`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(()=>{
    if(booking?._id){
      getInvoices()
    }
  },[booking])

  return (
    <Spin spinning={loading}>
      <div className="InvoiceSection">
        <Row gutter={[10, 10]} className="complaint">
          {allInvoices?.length
            ? allInvoices?.map((invoice, index) => (
                <Col span={12} key={index}>
                  <div className="singleProgramInvoice">
                    <div className="invoiceCardSession1">
                      <div>
                        <img
                          width="65px"
                          height="50px"
                          style={{ borderRadius: "4px" }}
                          src={booking?.program?._id?.image}
                          alt=""
                        />
                      </div>
                      <div className="headings">
                        <h2>{booking?.program?.treatment_name || "-"}</h2>
                        <h2 style={{ color: "#F76D00" }}>${invoice?.payment || "-"}</h2>
                      </div>
                    </div>

                    <div className="invoiceCardSession2">
                      <div className="left">
                        <img src={InvoiceSession} alt="$$$" />
                        <div>
                          <h5>
                            {invoice?.total_sessions || "-"} Free Sessions
                          </h5>
                        </div>
                      </div>

                      <div className="left">
                        <img src={InvoiceCalander} alt="" />
                        <div>
                          <h5>{invoice?.totalDays || "-"} Days</h5>
                        </div>
                      </div>
                    </div>

                    <p>{invoice?.description || "-"}</p>

                    <div className="programActions">
                      <p>
                        <PDFDownloadLink
                          document={
                            <TreatmentInvoice
                              bookingDetails={booking}
                              offer={invoice}
                            />
                          }
                          fileName="invoice.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            "Get Invoice"
                          }
                        </PDFDownloadLink>
                      </p>
                      <SecondaryButton
                      width="50%"
                        onClick={() => {
                          if (invoice?.status !== "selected") {
                            setOffer(invoice);
                            setIsPaymentModalOpen(true);
                          }
                        }}
                        disabled={invoice?.status === "selected"}
                      >
                        {invoice?.status !== "selected"
                          ? "Pay Charges"
                          : "Paid"}
                      </SecondaryButton>
                    </div>
                  </div>
                </Col>
              ))
            : 
            <Col span={24} ><Empty/></Col>
            }
        </Row>
      </div>

      <Modal
        open={isPaymentModalOpen}
        width="60%"
        size="large"
        footer={null}
        onCancel={() => setIsPaymentModalOpen(false)}
        title="Payment"
        centered
        maskClosable={false}
      >
        <SinglePayment
          bookingDetails={booking}
          offer={currentOffer}
          setIsPaymentModalOpen={setIsPaymentModalOpen}
          getBookingDetailsById={getInvoices}
          customSetLoading={setModalLoading}
          customLoading={modalLoading}
        />
      </Modal>
    </Spin>
  );
};

export default ProgramInvoice;
