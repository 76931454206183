import { useEffect, useRef } from "react";
import classes from "./Doctors.module.scss";
import moment from "moment/moment";
import { Empty } from "antd";
import SearchField from "../../UI/SearchField/SearchField";
import { DomyD, magnifyingGlassSolid } from "../../../assets";
import { getCategoryName } from "../../../Pages/Messages/constants";
const Doctors = ({
  allFilterDoctors,
  setActiveDoctor,
  getDoctorDtails,
  onUserSearch,
  activeDoctor,
  onlineUsers,
  setMobileSelectedDoctor,
}) => {
  const myRef = useRef();
  const myRef2 = useRef();

  const scrollToBottom = () => {
    myRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (allFilterDoctors?.length) {
      scrollToBottom();
    }
  }, [allFilterDoctors, myRef?.current]);

  return (
    <div className={classes.doctors}>
      <div className={classes.docHeader}>
        <h1>Messages</h1>
        <SearchField
          onChange={onUserSearch}
          icon={magnifyingGlassSolid}
          placeholder="Search Doctor"
          background={true}
        />
        {/* <div className={classes.header}>
          <div className={classes.allBtn}>
            <span>All</span>
            <ReactSVG src={chevronDown} />
          </div>
          <div className={classes.recentBtn}>
            <span>Most Recent</span>
            <ReactSVG src={chevronDown} />
          </div>
        </div> */}
      </div>
      <div className={classes.docScroll}>
        {allFilterDoctors?.length ? (
          // onlineUsers?.map((item) =>
          allFilterDoctors?.map((el, index) => (
            <div
              className={`${classes.docData} ${
                activeDoctor === el?._id ? classes.docHover : ""
              }`}
              onClick={() => {
                setActiveDoctor(el?._id);
                getDoctorDtails(el?._id, el?.sender);
                setMobileSelectedDoctor(el);
              }}
              key={index}
              ref={activeDoctor === el?._id ? myRef : myRef2}
            >
              <div className={classes.docDes}>
                <div className={classes.image}>
                  <img src={el?.provider?.profile_image || DomyD} alt="$$$" />

                  <div
                    className={
                      onlineUsers?.includes(el?.provider?.user_id)
                        ? classes.onlineDot
                        : classes.oflineDot
                    }
                  ></div>
                </div>
                <div>
                  <h4>
                    {el?.provider?.first_name} {el?.provider?.middle_name || ""} {el?.provider?.last_name}
                  </h4>
                  <h5 style={{ color: "#009DDD" }}>
                    {getCategoryName(el)}
                  </h5>

                  <p className={classes.lastMessage}>
                    {el?.last_message?.message}
                  </p>
                </div>
              </div>
              <h6>{moment(el?.last_message?.date).format("hh:mm A")}</h6>
            </div>
          ))
        ) : (
          // )
          <div className={classes?.emptyScreen}>
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default Doctors;
