import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  disabledFutureDate,
  formatHeight,
  formatPhoneNumber,
  getCurrentLocationAndFetchAddress,
  validateHeight,
  ValidateMaxLength,
  ValidateMaxLengthAndContent,
  validatePhoneNumber,
  validateWeight,
} from "../../../../Utils/helper";
import { ReactSVG } from "react-svg";

import {
  setCompleteUser,
  setSelectedState,
} from "../../../../Redux/Reducers/gernalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGeolocated } from "react-geolocated";
import SecondaryButton from "../../../UI/SecondaryButton/SecondaryButton";
import { setToken, setUser } from "../../../../Redux/Reducers/authSlice";
import { getRequest, postRequest } from "../../../../service/ApisFunctions";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import {
  AllLanguages,
  EthnicityList,
  HeightUnit,
  PhoneTypes,
  RaceList,
  WeightUnit,
  birthGendersList,
  gendersList,
  pronounList,
  sexualOrientation,
} from "../../../../Utils/constant";
import {
  setBookingFlowInformationSteps,
  setBookingFlowLocation,
  setBookingFlowPatientData,
} from "../../../../Redux/Reducers/bookingSlice";
import BackButton from "../../../UI/BackButton/BackButton";
import CustomDesignedDatePicker from "../../../UI/CustomDesignedDatePicker/CustomDesignedDatePicker";

export const InformationSelfForm = ({
  allState,
  patientToken,
  fpHash,
  isModal,
  setModal,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [informationStep, setInformationStep] = useState("general");
  const [guardianHeightUnit, setGuardianHeightUnit] = useState(1);
  const [guardianWeightUnit, setGuardianWeightUnit] = useState(1);
  const [consentCheck, setConsentCheck] = useState(false);

  // Retrieve data from Redux store
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const bookingFor = useSelector((state) => state.booking.bookingFlowAs);
  const otherPatient = useSelector(
    (state) => state.generalReducer.otherPatient
  );
  const consentForms = useSelector(
    (state) => state.generalReducer.consentForms
  );
  const bookingData = useSelector((state) => state.booking);
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });
  // Custom Functions
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const getLocation = async () => {
    if (!isGeolocationAvailable) {
      message.warning("Your browser does not support Geolocation");
    } else if (!isGeolocationEnabled) {
      message.warning("Geolocation is not enabled");
    } else {
      const address = await getCurrentLocationAndFetchAddress();
      form.setFieldsValue({
        address: address,
      });
      dispatch(
        setBookingFlowLocation({
          address: address,
          coordinates: [
            coords?.longitude ? coords?.longitude : 0,
            coords?.latitude ? coords?.latitude : 0,
          ],
        })
      );
    }
  };

  const handleSubmit = (values) => {
    const age = dayjs().diff(dayjs(values?.date_of_birth), "year");
    if (age < 18) {
      message.warning("Under 18 you are unable to book consultation");
      return;
    }
    const filterPhoneTypeByLable = PhoneTypes.find(
      (item) => item?.value === values?.PrimaryPhoneType
    );

    console.log(values?.prefix);

    dispatch(
      setBookingFlowPatientData({
        first_name: values?.firstName,
        last_name: values?.lastname,
        middle_name: values?.middle_name,
        prefix: values?.prefix,
        suffix: values?.suffix,
        email: values?.email,
        phone_number: values?.phone_number,
        address: values?.address,
        dob: values?.date_of_birth?.$d,
        height: { value: values?.height, unit: "3" },
        weight: { value: values?.weight, unit: "1" },
        language: values?.language || [],
        state: values?.state,
        ZipCode: values?.ZipCode,
        City: values?.City,
        PrimaryPhoneType: filterPhoneTypeByLable?.id,
        gender: values?.gender,
      })
    );

    if (!loading) {
      setInformationStep("demographic");
    }
  };
  const handleCensusSubmit = async (values) => {
    dispatch(
      setBookingFlowPatientData({
        ...bookingData?.bookingFlowPatientData,
        gender: values?.gender,
        birthGender: values?.birthGender,
        sexualOrientation: values?.sexualOrientation,
        race: values?.race,
        ethnicity: values?.ethnicity,
        pronoun: values?.pronoun,
      })
    );
    if (!patientToken) {
      setLoading(true);
      const dataToSend = {
        email: bookingData?.bookingFlowPatientData?.email,
        first_name: bookingData?.bookingFlowPatientData?.first_name,
        last_name: bookingData?.bookingFlowPatientData?.last_name,
        middle_name: bookingData?.bookingFlowPatientData?.middle_name,
        prefix: bookingData?.bookingFlowPatientData?.prefix,
        suffix: bookingData?.bookingFlowPatientData?.suffix,
        deviceId: fpHash,
        gender: values?.gender,
        birthGender: values?.birthGender,
        sexualOrientation: values?.sexualOrientation,
        pronoun: values?.pronoun,
        race: values?.race,
        ethnicity: values?.ethnicity,
        height: bookingData?.bookingFlowPatientData?.height,
        weight: bookingData?.bookingFlowPatientData?.weight,
        dob: bookingData?.bookingFlowPatientData?.dob,
        phone_number: bookingData?.bookingFlowPatientData?.phone_number,
        state: bookingData?.bookingFlowPatientData?.state,
        location: bookingData?.bookingFlowLocation,
        language: bookingData?.bookingFlowPatientData?.language || [],
        ZipCode: bookingData?.bookingFlowPatientData?.ZipCode,
        City: bookingData?.bookingFlowPatientData?.City,
        PrimaryPhoneType: bookingData?.bookingFlowPatientData?.PrimaryPhoneType,
        allowConsent: consentCheck,
        consentHistory: consentForms,
      };
      const onSuccess = (res) => {
        localStorage.setItem("patientToken", res?.token);
        dispatch(setToken(res?.token));
        dispatch(setCompleteUser(res.data));
        setLoading(false);
        message.success(res?.message);
        setInformationStep("demographic");
        if (bookingData?.bookingFlowIsUrgent) {
          dispatch(setBookingFlowInformationSteps(2));
        } else {
          dispatch(setBookingFlowInformationSteps(1));
        }
      };
      const onError = (err) => {
        if (err?.message?.Message) {
          const modelState = err.message.ModelState;
          for (const key in modelState) {
            message.error(`${key}: ${modelState[key]}`);
          }
        } else {
          message.error(err.message);
        }
        setLoading(false);
      };
      await postRequest(
        dataToSend,
        "customer/guest_signup",
        true,
        onSuccess,
        onError
      );
    }
    if (!loading && patientToken) {
      if (isModal) {
        setModal(false);
      } else {
        if (bookingData?.bookingFlowIsUrgent) {
          dispatch(setBookingFlowInformationSteps(2));
        } else {
          dispatch(setBookingFlowInformationSteps(1));
        }
      }
    }
  };
  const me = async () => {
    const onSuccess = (res) => {
      dispatch(setCompleteUser(res.data));
    };

    const onError = (err) => {};

    await getRequest(
      "",
      "auth/auth_me?role=customer",
      true,
      onSuccess,
      onError
    );
  };
  //Hooks
  useEffect(() => {
    if (patientToken) {
      me();
      dispatch(setUser({}));
    }
  }, [patientToken]);

  useEffect(() => {
    if (completeUser?._id) {
      const filterPhoneTypeById = PhoneTypes.find(
        (item) => item?.id == completeUser?.PrimaryPhoneType
      );

      form.setFieldsValue({
        firstName:
          bookingFor === "patient"
            ? completeUser?.user_id?._id
              ? completeUser?.first_name || ""
              : ""
            : otherPatient?.patient?.first_name || "",
        lastname:
          bookingFor === "patient"
            ? completeUser?.user_id?._id
              ? completeUser?.last_name || ""
              : ""
            : otherPatient?.patient?.last_name || "",
        middle_name:
          bookingFor === "patient"
            ? completeUser?.user_id?._id
              ? completeUser?.middle_name || ""
              : ""
            : otherPatient?.patient?.middle_name || "",
        prefix:
          bookingFor === "patient"
            ? completeUser?.user_id?._id
              ? completeUser?.prefix || ""
              : ""
            : otherPatient?.patient?.middle_name || "",
        suffix:
          bookingFor === "patient"
            ? completeUser?.user_id?._id
              ? completeUser?.suffix || ""
              : ""
            : otherPatient?.patient?.middle_name || "",

        address: completeUser?.location?.address || "",
        email: completeUser?.user_id?.email || "",
        guardianEmail: completeUser?.user_id?.email || "",
        gender:
          bookingFor === "patient"
            ? completeUser?.gender || null
            : otherPatient?.patient?.gender || null,

        date_of_birth: completeUser?.dob ? dayjs(completeUser?.dob) : null,
        phone_number: completeUser?.phone_number || "",
        birthGender: completeUser?.birthGender || null,
        sexualOrientation: completeUser?.sexualOrientation || null,
        race: completeUser?.race || null,
        ethnicity: completeUser?.ethnicity || null,
        height: completeUser?.height?.value || "",
        weight: completeUser?.weight?.value || "",
        heightUnit: parseInt(completeUser?.height?.unit) || 3,
        weightUnit: parseInt(completeUser?.weight?.unit) || 1,
        language: completeUser?.language || [],
        state: completeUser?.state || null,
        ZipCode: completeUser?.ZipCode || null,
        City: completeUser?.City || null,
        pronoun: completeUser?.pronoun || null,
        PrimaryPhoneType: filterPhoneTypeById?.label || null,
      });
      setGuardianHeightUnit(parseInt(completeUser?.height?.unit));
      setGuardianWeightUnit(parseInt(completeUser?.weight?.unit));
      dispatch(
        setBookingFlowLocation({
          address: completeUser?.location?.address,
          coordinates: [
            completeUser?.location?.coordinates[0],
            completeUser?.location?.coordinates[1],
          ],
        })
      );
    } else {
      form.setFieldsValue({
        weightUnit: 1,
        heightUnit: 3,
      });
    }
  }, [completeUser, otherPatient, bookingFor]);

  const handleChangePhoneNumberPattren = (e) => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);

    form.setFieldsValue({
      phone_number: formatted,
    });
  };
  const handleChangeHeight = (e) => {
    const input = e.target.value;
    const formatted = formatHeight(input);
    form.setFieldsValue({
      height: formatted,
    });
  };
  const height = Form.useWatch("height", form);
  const weight = Form.useWatch("weight", form);

  const calculateBMI = (height, weight) => {
    if (!height || !weight || height.length < 3) {
      form.setFieldsValue({
        bmi: "",
      });
      return;
    }
    const split_height = height.split("'");
    const feet = parseInt(split_height[0], 10);
    const inches = parseInt(split_height[1], 10);
    const heightInInches = feet * 12 + inches;
    const heightInMeters = heightInInches * 0.0254;
    const weightInKg = weight * 0.453592;
    const bmi = weightInKg / (heightInMeters * heightInMeters);
    form.setFieldsValue({
      bmi: bmi.toFixed(1),
    });
  };
  useEffect(() => {
    calculateBMI(height, weight);
  }, [height, weight]);
  // useEffect(() => {
  //   form.setFieldsValue({
  //     state: 'HI' || null,

  //     PrimaryPhoneType: 'Home'
  //   });
  // }, [allState]);

  useEffect(() => {
    form.setFieldsValue({
      weightUnit: 1,
      heightUnit: 3,
    });
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="information">
        {informationStep === "general" ? (
          <>
            <h2>Personal Info</h2>
            <Form
              layout="vertical"
              form={form}
              className={"fields"}
              onFinish={handleSubmit}
              style={{ width: "100%" }}
            >
              <Row gutter={[35, 10]}>
                <Col span={3}>
                  <Form.Item
                    name="prefix"
                    label="Prefix"
                    rules={[
                      { required: false },
                      ValidateMaxLength(10, "Prefix"),
                    ]}
                  >
                    <Input
                      className="ant-input-affix-wrapper"
                      placeholder="Enter your prefix"
                    />
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 6}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your first name",
                      },
                      ValidateMaxLengthAndContent(35, "First Name"),
                    ]}
                  >
                    <Input
                      className="ant-input-affix-wrapper"
                      placeholder="First name"
                    />
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 6}>
                  <Form.Item
                    name="middle_name"
                    label="Middle Name"
                    rules={[
                      { required: false },
                      ValidateMaxLengthAndContent(35, "Middle Name"),
                    ]}
                  >
                    <Input
                      className="ant-input-affix-wrapper"
                      placeholder="Middle name"
                    />
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 6}>
                  <Form.Item
                    name="lastname"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name",
                      },
                      ValidateMaxLengthAndContent(35, "Last name"),
                    ]}
                  >
                    <Input
                      className="ant-input-affix-wrapper"
                      placeholder="Last name"
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item
                    name="suffix"
                    label="Suffix"
                    rules={[
                      { required: false },
                      ValidateMaxLength(10, "Suffix"),
                    ]}
                  >
                    <Input
                      className="ant-input-affix-wrapper"
                      placeholder="Enter your suffix"
                    />
                  </Form.Item>
                </Col>

                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter your valid email",
                      },
                      ValidateMaxLength(35, "Email"),
                    ]}
                  >
                    <Input
                      disabled={completeUser?.user_id?.email ? true : false}
                      className="ant-input-affix-wrapper"
                      placeholder="Email address"
                      type="email"
                    />
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="date_of_birth"
                    label="Date Of Birth"
                    rules={[
                      {
                        required: true,
                        message: "Please select your date of birth",
                      },
                    ]}
                  >
                    <CustomDesignedDatePicker
                      field={"date_of_birth"}
                      form={form}
                      defaultValue={completeUser?.dob}
                    />
                    {/* <DatePicker
                      disabledDate={disabledFutureDate}
                      className='ant-input-affix-wrapper'
                      placeholder='Date of birth'
                      suffixIcon={
                        <ReactSVG width='20px' src={calendarSimple} />
                      }
                      format='MM-DD-YYYY'
                      picker='date'
                    /> */}
                  </Form.Item>
                </Col>
                {/* <Col span={mobileResponsive ? 24 : 12}>
                  <Space.Compact style={{ width: '100%' }}>
                    <Form.Item
                      name='height'
                      label='Height'
                      style={{ width: '80%' }}
                      rules={[
                        { required: true },
                        { validator: validateHeight(guardianHeightUnit) }
                      ]}
                    >
                      <Input
                        type='number'
                        className='ant-input-affix-wrapper'
                        placeholder='Height'
                      />
                    </Form.Item>

                    <Form.Item
                      name='heightUnit'
                      label='    '
                      style={{ width: '20%' }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select your unit'
                        }
                      ]}
                    >
                      <Select
                        onChange={e => {
                          setGuardianHeightUnit(e);
                        }}
                        className='ant-select-selector'
                        placeholder='Unit'
                      >
                        {HeightUnit?.map((item, index) => (
                          <Select.Option key={index} value={item?.id}>
                            {item?.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space.Compact>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Space.Compact style={{ width: '100%' }}>
                    <Form.Item
                      style={{ width: '80%' }}
                      name='weight'
                      label='Weight'
                      rules={[
                        { required: true },
                        { validator: validateWeight(guardianWeightUnit) }
                      ]}
                    >
                      <Input
                        min={1}
                        type='number'
                        className='ant-input-affix-wrapper'
                        placeholder='Weight'
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: '20%' }}
                      name='weightUnit'
                      label='   '
                      rules={[
                        {
                          required: true,
                          message: 'Please select your unit'
                        }
                      ]}
                    >
                      <Select
                        onChange={e => {
                          setGuardianWeightUnit(e);
                        }}
                        className='ant-select-selector'
                        placeholder='Unit'
                        disabled
                      >
                        {WeightUnit?.map((item, index) => (
                          <Select.Option key={index} value={item?.id}>
                            {item?.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space.Compact>
                </Col> */}
                <Col span={mobileResponsive ? 12 : 6}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Item
                      name="height"
                      label="Height"
                      rules={[
                        { required: true },
                        { validator: validateHeight(guardianHeightUnit) },
                      ]}
                    >
                      <Input
                        addonBefore="Ft'In"
                        placeholder="Height"
                        onChange={handleChangeHeight}
                      />
                    </Form.Item>

                    {/* <Form.Item
                style={{ width: '30%' }}
                name='heightUnit'
                label='Unit'
                rules={[{ required: true, message: 'Please select unit' }]}
              >
                <Select
                  onChange={e => {
                    setProfileHeightUnit(e);
                  }}
                  className='ant-select-selector'
                  placeholder='Unit'
                >
                  {HeightUnit?.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      {item?.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
                  </div>
                </Col>
                <Col span={mobileResponsive ? 12 : 6}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Item
                      name="weight"
                      label="Weight"
                      rules={[
                        { required: true },
                        { validator: validateWeight(guardianWeightUnit) },
                      ]}
                    >
                      <Input
                        addonBefore="Lbs"
                        type="number"
                        placeholder="Enter your weight"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="bmi"
                    label="BMI"
                    rules={[
                      {
                        required: false,
                        message: "Please select phone type",
                      },
                    ]}
                  >
                    <Input placeholder="BMI" disabled />
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="language"
                    label="Language"
                    rules={[
                      {
                        required: true,
                        message: "Please select your language",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className="ant-select-selector"
                      placeholder="language"
                    >
                      {AllLanguages?.map((item, index) => (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={mobileResponsive ? 24 : 12}>
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item
                      name="phone_number"
                      label="Phone Number"
                      style={{ width: "75%" }}
                      rules={[
                        {
                          validator: validatePhoneNumber,
                        },
                      ]}
                    >
                      <Input
                        onChange={handleChangePhoneNumberPattren}
                        className="ant-input-affix-wrapper"
                        placeholder="Phone number"
                        min={1}
                      />
                    </Form.Item>
                    <Form.Item
                      name="PrimaryPhoneType"
                      label="     "
                      style={{ width: "25%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select phone type",
                        },
                      ]}
                    >
                      <Select
                        className="ant-select-selector"
                        placeholder="Select phone type"
                      >
                        {PhoneTypes?.map((item, index) => (
                          <Select.Option key={index} value={item?.value}>
                            {item?.label || "-"}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space.Compact>
                </Col>

                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="address"
                    label="Street"
                    rules={[
                      { required: true, message: "Please enter your Street" },
                      ValidateMaxLength(35, "Street"),
                    ]}
                  >
                    <Input
                      className="ant-input-affix-wrapper"
                      placeholder="Reachable Street"
                      onChange={(e) =>
                        dispatch(
                          setBookingFlowLocation({
                            address: e.target.value,
                            coordinates: [
                              coords?.longitude ? coords?.longitude : 0,
                              coords?.latitude ? coords?.latitude : 0,
                            ],
                          })
                        )
                      }
                      // suffix={
                      //   <ReactSVG
                      //     style={{ cursor: "pointer" }}
                      //     // onClick={getLocation}
                      //     src={locationIcon}
                      //   />
                      // }
                    />
                  </Form.Item>
                </Col>

                <Col span={mobileResponsive ? 24 : 12}>
                  {" "}
                  <Form.Item
                    name="City"
                    label="City"
                    rules={[
                      { required: true, message: "Please enter city" },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: "City must contain only alphabets",
                      },
                      ValidateMaxLength(35, "City"),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ant-input-affix-wrapper"
                      placeholder="Please enter city"
                    />
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="ZipCode"
                    label="Zip Code"
                    rules={[
                      { required: true, message: "Please enter only digits" },
                      {
                        pattern: /^[0-9]{5}$/,
                        message: "Zip Code must be exactly 5 digits",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      maxLength={5}
                      autoComplete="off"
                      className="ant-input-affix-wrapper"
                      placeholder="Please enter zip code"
                    />
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      { required: true, message: "please select any state" },
                      ValidateMaxLength(10, "State"),
                    ]}
                  >
                    <Select
                      onChange={(e) => {
                        // setState(e);
                        dispatch(setSelectedState(e));
                      }}
                      className="ant-select-selector"
                      placeholder="Select your state"
                    >
                      {allState?.map((item, index) => (
                        <Select.Option key={index} value={item?.state}>
                          {item?.state}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "200px", marginBottom: "20px" }}>
                    <SecondaryButton type="submit" width="100%">
                      Next
                    </SecondaryButton>
                  </div>
                </div>
              </Row>
            </Form>
          </>
        ) : informationStep === "demographic" ? (
          <>
            <h3>Health Equity</h3>
            <Form
              layout="vertical"
              form={form}
              className={"fields"}
              onFinish={handleCensusSubmit}
            >
              <Row gutter={[35, 10]}>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="birthGender"
                    label="Birth Gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select your birth gender",
                      },
                    ]}
                  >
                    <Select
                      className="ant-select-selector"
                      placeholder="Birth Gender"
                    >
                      {birthGendersList.map((item, index) => (
                        <Select.Option key={index} value={item?.name}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="gender"
                    label="Current Gender"
                    rules={[
                      { required: true, message: "Please select your gender" },
                    ]}
                  >
                    <Select
                      className="ant-select-selector"
                      placeholder="Select gender"
                    >
                      {gendersList?.map((item, index) => (
                        <Select.Option key={index} value={item?.name}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="sexualOrientation"
                    label="Sexual orientation"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your sexual orientation",
                      },
                    ]}
                  >
                    <Select
                      className="ant-select-selector"
                      placeholder="Please select sexual orientation"
                    >
                      {sexualOrientation.map((item, index) => (
                        <Select.Option key={index} value={item?.name}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="pronoun"
                    label="Pronouns"
                    rules={[
                      {
                        required: true,
                        message: "Please select your pronoun",
                      },
                    ]}
                  >
                    <Select
                      className="ant-select-selector"
                      placeholder="Select pronoun"
                    >
                      {pronounList?.map((item, index) => (
                        <Select.Option key={index} value={item?.name}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="ethnicity"
                    label="Ethnicity"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your ethnicity",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      className="ant-select-selector"
                      placeholder="Please select ethnicity"
                    >
                      {EthnicityList.map((item, index) => (
                        <Select.Option key={index} value={item?.name}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={mobileResponsive ? 24 : 12}>
                  <Form.Item
                    name="race"
                    label="Race"
                    rules={[
                      { required: true, message: "Please enter your race" },
                    ]}
                  >
                    <Select
                      className="ant-select-selector"
                      placeholder="Please select race"
                    >
                      {RaceList.map((item, index) => (
                        <Select.Option key={index} value={item?.name}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {completeUser?._id ? null : (
                  <Col className="signUpConsent" span={24}>
                    <Form.Item
                      name="consent"
                      rules={[
                        {
                          required: consentCheck ? false : true,
                          message: "Please agree to the terms and conditions",
                        },
                      ]}
                    >
                      <Checkbox
                        checked={consentCheck}
                        onChange={(e) => setConsentCheck(e.target.checked)}
                      >
                        I have read and agree to
                        <a href="/terms-of-use" target="_blank">
                          <span className="title">Terms of Use</span>
                        </a>
                        ,
                        <a href="/responsibility-and-billing" target="_blank">
                          <span className="title">
                            Financial Responsibility and Billing
                          </span>
                        </a>{" "}
                        and the
                        <span className="title">Privacy policy</span>.
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "25px",
                  }}
                >
                  <div style={{ width: "200px" }}>
                    <BackButton
                      onClick={() => setInformationStep("general")}
                      type="button"
                      width="100%"
                    >
                      Back
                    </BackButton>
                  </div>
                  <div style={{ width: "200px" }}>
                    <SecondaryButton type="submit" width="100%">
                      Next
                    </SecondaryButton>
                  </div>
                </div>
              </Row>
            </Form>
          </>
        ) : null}
      </div>
    </Spin>
  );
};
