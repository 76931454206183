import { useState } from 'react';
import { Button, Modal, Rate, Space, Table, Tag, Tooltip, message } from 'antd';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import classes from './DashboardTable.module.scss';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UnpaidConsultationPayment } from './UnpaidConsultationPayment';
import { OtherCare, UrgentCare, arrowRight, messageLines } from '../../assets';
import {
  setSpeacilityDetails,
  setUserBookingId,
  setbookingType
} from '../../Redux/Reducers/authSlice';
import {
  setBookableFlowCondition,
  setBookableFlowProgram,
  setBookingFlowMedialInfoSteps,
  setBookingFlowType
} from '../../Redux/Reducers/bookingSlice';
import { RescheduleConsultation } from '../RescheduleConsultation/RescheduleConsultation';
import { putRequest } from '../../service/ApisFunctions';
import Review from '../../Pages/Review/Review';

const DashboardTable = ({
  totalCount,
  getAllBookings,
  bookingList,
  setLoading,
  isSearchFilter
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isReviewModal, setIsReviewModal] = useState(false);
  const [activeBooking, setActiveBooking] = useState(null);

  const [unPaidBooking, setUnPaidBooking] = useState(null);
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });
  const isRestrictedHandler = booking => {
    if (booking?.urgent_care && !booking?.provider_id) {
      return {
        restricted: true,
        reason: 'Your consultation request is not accepted yet'
      };
    } else if (booking?.booking_status === 'missed') {
      return {
        restricted: true,
        reason: 'You have missed the consultation'
      };
    } else if (booking?.booking_status === 'completed') {
      return {
        restricted: true,
        reason: 'Your consultation has been completed'
      };
    } else if (booking?.booking_status === 'leave') {
      return {
        restricted: true,
        reason: 'Provider is on leave'
      };
    } else if (booking?.booking_status === 'review pending') {
      return {
        restricted: true,
        reason: 'Your consultation has been completed'
      };
    }
  };
  const columns = [
    {
      title: 'Type',
      render: (_, object) => {
        return (
          <Space size='small'>
            <ReactSVG src={object?.urgent_care ? UrgentCare : OtherCare} />
            {object?.urgent_care ? (
              <p style={{ color: '#F76D00' }}>Instant Consultations</p>
            ) : (
              <p style={{ textTransform: 'capitalize' }}>
                {object?.booking_type || '-'}
              </p>
            )}
          </Space>
        );
      },
      width: '9.125%',
      key: '1'
    },
    // {
    //   title: "Patient",
    //   dataIndex: "patient",
    //   render: (_, object) =>
    //     `${object?.patient?.first_name || "-"} ${
    //       object?.patient?.middle_name || ""
    //     } ${object?.patient?.last_name || "-"}` || "-",
    //   key: "4",
    //   width: "10.77%",
    // },
    {
      title: 'Doctor',
      render: (_, object) =>
        `${object?.provider_id?.first_name || '-'} ${
          object?.provider_id?.middle_name || ''
        } ${object?.provider_id?.last_name || '-'}`,
      key: '4',
      width: '15.125%'
    },
    {
      title: 'Schedule',
      dataIndex: 'date',
      render: (_, object) =>
        object?.schedule?.date
          ? `${moment(object?.schedule?.date).format('YYYY/MM/DD') || '-'}
          ${moment(object?.schedule?.start_time).format('hh:mm A') || '-'}`
          : '--',

      key: '2',
      width: '12.125%'
    },
    {
      title: 'Consultation for',
      render: (_, object) =>
        object?.program?.treatment_name ||
        object?.category?.category_name ||
        object?.speciality?.speciality_name,
      key: '1',
      width: '12.125%'
    },
    {
      title: "Status",
      key: "5",
      width: "12.125%",
      render: (_, object) => {
        const status = object?.consultation_status;
        let color;

        switch (status) {
          case 'pending':
            color = 'purple';
            break;
          case 'upcoming':
            color = 'blue';
            break;
          case 'missed':
            color = 'red';
            break;
          case 'completed':
            color = 'green';
            break;
          case 'leave':
            color = 'purple';
            break;
          case 'review pending':
            color = 'gold';
            break;
          default:
            color = 'gray'; // Default color for unknown or undefined status
        }

        return <Tag style={{textTransform:"capitalize"}} color={color}>{status || 'Unknown'}</Tag>;
      }
    },
    {
      key: '7',
      title: 'Payment Status',
      render: (_, object) =>
        object?.payment === 'paid' ? (
          object?.payment
        ) : (
          <p
            style={
              object?.payment_status
                ? { color: '#25d366', fontWeight: '600' }
                : { color: '#F76D00', fontWeight: '600' }
            }
          >
            {object?.payment_status ? 'Paid' : 'Unpaid'}
          </p>
        ),
      width: '12.125%'
    },
    {
      title: 'Rating',
      key: '5',
      width: '12.125%',

      render: (_, object) =>
        object?.rating > 0 ? (
          <Rate
            style={{ color: '#1890ff' }}
            allowHalf
            disabled
            value={object?.rating || 0}
          />
        ) : (
          '--'
        )
    },
    {
      title: 'Action',
      align: 'center',
      width: '12.125%',

      render: (_, object) => {
        const isRestricted = isRestrictedHandler(object);
        return object?.urgent_care && !object?.provider_id ? (
          'Request Pending'
        ) : (
          <Space
            size='middle'
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center'
            }}
          >
            {renderUnpaidActions(object)}
            {renderPaidActions(object, isRestricted)}
          </Space>
        );
      },
      key: '6'
    }
  ];
  const renderPaidActions = (booking, isRestricted) => {
    const isQuestionsAttempted = booking?.isPreQuestionAttempted;
    return booking?.payment_status ? (
      <>
        {booking?.chat_id && !isRestricted?.restricted && (
          <Tooltip placement='left' title='Message'>
            <div>
              <ReactSVG
                onClick={() => {
                  booking?.urgent_care
                    ? navigate(`/urgent-chat/${booking?._id}`)
                    : navigate(`/messages?messageId=${booking?.chat_id}`);
                }}
                src={messageLines}
                className={classes.messageBtn}
              />
            </div>
          </Tooltip>
        )}
        {booking?.booking_status === 'review pending' ? (
          <Tooltip placement='left' title='Review Pending'>
            <div>
              <ReactSVG
                onClick={() => {
                  setActiveBooking(booking);
                  setIsReviewModal(true);
                }}
                src={arrowRight}
                className={classes.bgBlue}
              />
            </div>
          </Tooltip>
        ) : isQuestionsAttempted ? (
          <Tooltip placement='left' title='View Consultation Detail'>
            <div>
              <ReactSVG
                onClick={() => {
                  navigate(`/consultation-detail/${booking?._id}`);
                }}
                src={arrowRight}
                className={classes.bgGreen}
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            placement='left'
            title='Please fill previous consultation details'
          >
            <div>
              <ReactSVG
                onClick={() => {
                  dispatch(
                    setbookingType(
                      booking?.booking_type === 'program'
                        ? 'Treatment programs'
                        : 'Medical consultation'
                    )
                  );
                  dispatch(
                    setBookingFlowType(
                      booking?.booking_type === 'program'
                        ? 'Treatment programs'
                        : 'Medical consultation'
                    )
                  );
                  dispatch(
                    setSpeacilityDetails(
                      booking?.booking_type === 'program'
                        ? booking?.program
                        : booking?.category
                    )
                  );
                  dispatch(
                    setBookableFlowProgram(
                      booking?.booking_type === 'program'
                        ? booking?.program
                        : null
                    )
                  );
                  dispatch(
                    setBookableFlowCondition(
                      booking?.booking_type === 'video'
                        ? booking?.category
                        : null
                    )
                  );
                  dispatch(
                    setUserBookingId(
                      booking?.booking_type === 'program'
                        ? booking?._id
                        : booking?._id
                    )
                  );
                  dispatch(setBookingFlowMedialInfoSteps(0));
                  navigate(`/medical-info/${booking?._id}`);
                  message.warning('Please fill previous consultation details');
                }}
                src={arrowRight}
                className={classes.bgBlue}
              />
            </div>
          </Tooltip>
        )}
      </>
    ) : !booking?.isRefered && booking?.consultation_status === 'missed' ? (
      <div>
        <ReactSVG
          style={{ opacity: '0.6', pointerEvents: 'none' }}
          src={arrowRight}
          className={classes.bgGreen}
        />
      </div>
    ) : null;
  };
  const renderUnpaidActions = booking => {
    return !booking?.payment_status &&
      booking?.consultation_status === 'pending' &&
      !booking?.isRefered ? (
      <Button
        onClick={() => {
          setUnPaidBooking(booking);
          setIsPaymentModalOpen(true);
        }}
      >
        {'Pay Now'}
      </Button>
    ) : booking?.isRefered ? (
      <Button
        onClick={() => {
          setUnPaidBooking(booking);
          setIsPaymentModalOpen(true);
        }}
      >
        {'Schedule Appointment'}
      </Button>
    ) : null;
  };
  const updatePaymentStatus = async () => {
    setLoading(true);
    const onSuccess = () => {
      updateBookingStatus();
    };
    const onError = () => {
      setLoading(false);
    };
    const formData = {
      payment_status: true
    };
    const route = `booking/update_video_booking/${unPaidBooking?._id}`;
    await putRequest(formData, route, true, onSuccess, onError);
  };

  const updateBookingStatus = async () => {
    const onSuccess = res => {
      setLoading(false);
      setIsPaymentModalOpen(false);
      getAllBookings(1, 10);
    };
    const onError = err => {
      setLoading(false);
    };

    const formData = {
      status: 'upcoming'
    };

    const route = `booking/update_booking_status/${unPaidBooking?._id}`;
    await putRequest(formData, route, true, onSuccess, onError);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={bookingList}
        className={classes}
        pagination={
          isSearchFilter
            ? false
            : {
                showSizeChanger: false,
                pageSize: 10,
                total: totalCount,
                onChange(page) {
                  getAllBookings(page, 10);
                }
              }
        }
        scroll={mobileResponsive ? { x: 1300, y: 660 } : {}}
      />
      {unPaidBooking && isPaymentModalOpen ? (
        <Modal
          title={unPaidBooking?.isRefered ? 'Schedule Appointment' : 'Payment'}
          width={mobileResponsive ? '90%' : '60%'}
          footer={false}
          open={isPaymentModalOpen}
          onCancel={() => setIsPaymentModalOpen(false)}
          className='ehr-action-modal'
          centered
        >
          {unPaidBooking?.isRefered ? (
            <RescheduleConsultation
              bookingDetails={unPaidBooking}
              isModalOpen={setIsPaymentModalOpen}
              getBooking={getAllBookings}
              apiUrl='booking/schedule-refered-booking'
            />
          ) : (
            <UnpaidConsultationPayment
              bookingDetails={unPaidBooking}
              updatePaymentStatus={updatePaymentStatus}
            />
          )}
        </Modal>
      ) : null}
      {isReviewModal ? (
        <Modal
          title={''}
          width={mobileResponsive ? '90%' : '60%'}
          footer={false}
          open={isReviewModal}
          onCancel={() => setIsReviewModal(false)}
          className='ehr-action-modal'
          centered
        >
          <Review
            bookingId={activeBooking?._id}
            setIsReviewModal={setIsReviewModal}
            getAllBookings={getAllBookings}
            isTable={true}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default DashboardTable;
