import "./bookingDetailTab.scss"

const BookingDetailTab = ({question,answer}) => {
  return (
    <div className='Inputcontainer'>
        <p className='question' >{question}</p>
        <p className='answer' >{answer}</p>
    </div>
  )
}

export default BookingDetailTab