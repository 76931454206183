import React, { useEffect, useState } from "react";
import { Col, Empty, Form, Modal, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getRequest, putRequest } from "../../../../service/ApisFunctions";
import { setBookingFlowMedialHistoryIsUpdated } from "../../../../Redux/Reducers/bookingSlice";
import QuestionCollapse from "../../../CollapseDetails/questionsCollapse";
import Questionnaire1 from "../../../Questionnaire1/nestedQuestion";
import SecondaryButton from "../../../UI/SecondaryButton/SecondaryButton";
import "./ReviewMedicalHistory.scss";
import SkeletonInput from "../../../UI/Skeleton/skeletonInput";
import { useMediaQuery } from "react-responsive";
import { CloseOutlined } from "@ant-design/icons";
// import QuestionsList from "../../../QuestionsList";

export const ReviewMedicalHistory = (
  {
    // medicalHistorylist,
  }
) => {
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const bookingData = useSelector((state) => state.booking);

  const [medicalHistorylist, setMedicalHistorylist] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [changeQuestionNo, setChangeQuestionNo] = useState(0);
  const [progressLine, setProgressLine] = useState(0);
  const [answerData, setAnswerData] = useState([]);
  const [CheckBoxAnswer, setCheckBoxAnswer] = useState([]);
  const [InputAnswer, setInputAnswer] = useState("");
  const [optionAnswer, setOptionAnswer] = useState("");
  const [ImagesAnswer, setImagesAnswer] = useState([]);
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState(
    medicalHistorylist?.[changeQuestionNo]?._id
  );

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const showModal = () => {
    setIsModal(true);
    setAnswerData([]);
  };

  const resetState = () => {
    // setMedicalHistorylist([]);
    setModalLoading(false);
    setIsModal(false);
    setChangeQuestionNo(0);
    setProgressLine(0);
    setCheckBoxAnswer([]);
    setInputAnswer("");
    setOptionAnswer("");
    setImagesAnswer([]);
    setPreviousQuestions([]);
    setCurrentQuestion(null);
    setCurrentQuestionId(medicalHistorylist?.[changeQuestionNo]?._id);
  };

  const handleOk = () => {
    setIsModal(false);
    resetState();
  };

  const handleOnCancel = () => {
    setIsModal(false);
    setAnswerData([]);
    resetState();
  };

  const getmedicalHistoryList = async () => {
    setModalLoading(true);
    const onSuccess = (res) => {
      setModalLoading(false);
      const filterMedicalHistory = res?.data?.[0]?.questions?.filter(
        (item) => !item?.isSocialHistory
      );
      setMedicalHistorylist(filterMedicalHistory);
      setCurrentQuestion(filterMedicalHistory?.[changeQuestionNo]);
    };
    const onError = () => {
      setModalLoading(false);
    };

    await getRequest(
      "",
      `medicalHistory/get-medical-history-questions`,
      true,
      onSuccess,
      onError
    );
    // await getRequest(
    //   "",
    //   bookingData?.bookingFlowType === "Treatment programs"
    //     ? `speciality/program_medical_history_question_listing/${bookingData?.bookableFlowProgram?._id}`
    //     : `speciality/category_medical_history_question_listing/${bookingData?.bookableFlowCondition?._id}`,
    //   true,
    //   onSuccess,
    //   onError
    // );
  };

  useEffect(() => {
    setCurrentQuestion(medicalHistorylist?.[changeQuestionNo]);
  }, [medicalHistorylist]);

  useEffect(() => {
    // if (
    //   bookingData?.bookableFlowCondition?._id ||
    //   bookingData?.bookableFlowProgram?._id ||
    // ) {
    getmedicalHistoryList();
    // }
  }, [isModal]);

  const handleMedicalHistory = async (array) => {
    if (changeQuestionNo + 1 === medicalHistorylist?.length) {
      setLoading(true);
      setProgressLine(100);

      const onSuccess = () => {
        setLoading(false);
        resetState();
        handleOk();
      };
      const onError = () => {
        setLoading(false);
      };

      const formData = {
        medicalHistory: array,
      };

      const route = `customer/update_customer_medical_history/${completeUser?.user_id?._id}`;

      await putRequest(formData, route, true, onSuccess, onError);
    } else {
      setChangeQuestionNo((pre) => pre + 1);
      setCurrentQuestion(medicalHistorylist[changeQuestionNo + 1]);
    }
  };

  const questionHandler = () => {
    //   getookingById();
  };

  const past_medical_history = completeUser?.past_medical_history?.detail;

  useEffect(() => {
    form.setFieldsValue({
      radio: past_medical_history
        ?.map((item) => {
          if (
            item?.type === "radio" &&
            item?.name.replace(/\s/g, "").toLowerCase() ===
              currentQuestion?.name.replace(/\s/g, "").toLowerCase()
          ) {
            return item?.option?.id;
          }
        })
        .find((value) => value !== null),
      textarea: past_medical_history
        ?.map((item) => {
          if (
            item?.type === "textarea" &&
            item?.name.replace(/\s/g, "").toLowerCase() ===
              currentQuestion?.name.replace(/\s/g, "").toLowerCase()
          ) {
            return item?.inputData;
          }
        })
        .find((value) => {
          if (value?.length) {
            return value;
          }
        }),

      checkbox: past_medical_history
        ?.map((item) => {
          if (
            item?.type === "checkbox" &&
            item?.name.replace(/\s/g, "").toLowerCase() ===
              currentQuestion?.name.replace(/\s/g, "").toLowerCase()
          ) {
            return item?.CheckBoxAnswer?.map((answer) => answer.id); // Extracting IDs
          }
        })
        .find((value) => value?.length > 0),
    });
    for (let index = 0; index < past_medical_history?.length; index++) {
      if (
        past_medical_history?.[index]?.type == "radio" &&
        past_medical_history?.[index]?.name.replace(/\s/g, "").toLowerCase() ===
          currentQuestion?.name.replace(/\s/g, "").toLowerCase()
      ) {
        setOptionAnswer(past_medical_history[index]?.option);
        setCurrentQuestionId(past_medical_history[index]?.option?.id);
      } else if (
        past_medical_history?.[index]?.type === "textarea" &&
        past_medical_history?.[index]?.name.replace(/\s/g, "").toLowerCase() ===
          currentQuestion?.name.replace(/\s/g, "").toLowerCase()
      ) {
        setInputAnswer(past_medical_history[index]?.inputData);
      } else if (
        past_medical_history?.[index]?.type === "checkbox" &&
        past_medical_history?.[index]?.name.replace(/\s/g, "").toLowerCase() ===
          currentQuestion?.name.replace(/\s/g, "").toLowerCase()
      ) {
        setCheckBoxAnswer(past_medical_history[index]?.CheckBoxAnswer);
      } else if (
        past_medical_history?.[index]?.type === "image_upload" &&
        past_medical_history?.[index]?.name.replace(/\s/g, "").toLowerCase() ===
          currentQuestion?.name.replace(/\s/g, "").toLowerCase()
      ) {
        setImagesAnswer(past_medical_history[index]?.images);
      }
    }
  }, [currentQuestion]);

  const handleUpdate = (id, updatedValues) => {
    setUpdatedData((prevData) => {
      const existingIndex = prevData.findIndex((data) => data.id === id);
      if (existingIndex >= 0) {
        const updatedData = [...prevData];
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          ...updatedValues,
        };
        return updatedData;
      }
      return [...prevData, { id, ...updatedValues }];
    });
  };

  const handleSubmit = () => {};
  return (
    <>
      <div className="customReviewComponentHeading">
        <p>Medical history</p>
        <button className="noBgBtn" onClick={() => showModal()}>
          Change
        </button>
      </div>

      {answerData?.length ? (
        answerData?.map((item, index) => (
          <QuestionCollapse key={index} index={index} item={item} />
        ))
      ) : past_medical_history?.length > 0 ? (
        past_medical_history?.map((item, index) => (
          <QuestionCollapse key={index} index={index} item={item} />
        ))
      ) : (
        <Empty />
      )}

      <Modal
        centered
        title="Update Medical History"
        width={mobileResponsive ? "90%" : "60%"}
        open={isModal} // Change 'open' to 'visible'
        onOk={handleOk}
        footer={false}
        closable={false}
        style={{ position: "relative" }}
      >
        <div
          style={{
            float: "right",
            position: "absolute",
            top: "20px",
            right: "20px",
          }}
        >
          <CloseOutlined onClick={() => handleOnCancel()} />
        </div>
        {modalLoading ? (
          <>
            <div style={{ marginTop: "20px" }}>
              <SkeletonInput size="small" block={true} />
            </div>
            <div style={{ marginTop: "10px" }}>
              <SkeletonInput block={true} />
            </div>
          </>
        ) : medicalHistorylist?.length > 0 ? (
          <Spin spinning={loading}>
            <Row className="medicalHistory" gutter={20}>
              <Form form={form} style={{ width: "100%" }} layout="vertical">
                <div className="progressBox">
                  <p
                    style={{ width: progressLine + "%", transition: "all .3s" }}
                    className="innerLine"
                  >
                    <span className="percentageNumber">{progressLine}%</span>
                  </p>
                </div>
                <Questionnaire1
                  questionHandler={questionHandler}
                  setCurrentQuestionId={setCurrentQuestionId}
                  currentQuestionId={currentQuestionId}
                  setCurrentQuestion={setCurrentQuestion}
                  currentQuestion={currentQuestion}
                  ChangeQuestion={handleMedicalHistory}
                  answerData={answerData}
                  setAnswerData={setAnswerData}
                  setPreviousQuestions={setPreviousQuestions}
                  previousQuestions={previousQuestions}
                  setProgressLine={setProgressLine}
                  progressLine={progressLine}
                  setCheckBoxAnswer={setCheckBoxAnswer}
                  CheckBoxAnswer={CheckBoxAnswer}
                  setInputAnswer={setInputAnswer}
                  setImagesAnswer={setImagesAnswer}
                  InputAnswer={InputAnswer}
                  ImagesAnswer={ImagesAnswer}
                  setOptionAnswer={setOptionAnswer}
                  optionAnswer={optionAnswer}
                  setChangeQuestionNo={setChangeQuestionNo}
                  form={form}
                />
              </Form>
              {/* <Col span={24}>
              <QuestionsList
                questions={medicalHistorylist}
                prefilledData={completeUser?.past_medical_history?.detail}
                handleUpdate={handleUpdate}
              />
            </Col>
            <Col span={24}>
              <button onClick={handleSubmit}>Update</button>
            </Col> */}
            </Row>
          </Spin>
        ) : (
          <Empty />
        )}
      </Modal>
    </>
  );
};
