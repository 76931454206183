import { useNavigate } from "react-router-dom";
import classes from "./Notification.module.scss";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { chevron } from "../../assets";
const Notification = ({ arrayData }) => {
  const navigate = useNavigate();

  return (
    <div className={classes.border}>
      {arrayData?.length > 0
        ? arrayData.map((el, index) => {
            return (
              <div key={index} className={classes.notification}>
                <div className={classes.message}>
                  <p>
                    {el.message}{" "}
                    <b style={{ fontSize: "10px", color: "gray" }}>
                      {moment(el?.createdAt).format("hh:mm A")}
                    </b>
                  </p>
                  <div
                    onClick={() => {
                      if (el?.bookingId) {
                        navigate(
                          el?.message == "Your urgent care has been accepted"
                            ? `/urgent-chat/${el?.bookingId}`
                            : el?.message.includes("has been refer to")
                            ? "/consultations"
                            : `/consultation-detail/${el?.bookingId}`
                        );
                      } else if (
                        el.message?.includes("medications are accepted")
                      ) {
                        navigate(`/refills`);
                      } else if (
                        el.message?.includes(
                          "All of your medications you requested"
                        )
                      ) {
                        navigate(`/refills`);
                      } else if (el?.refillId) {
                        navigate(`/refills`);
                      }
                    }}
                    className={classes.icon}
                  >
                    <span>View</span>
                    <ReactSVG src={chevron} />
                  </div>
                </div>
                {arrayData.length - 1 === index ? null : <hr />}
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default Notification;
