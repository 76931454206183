import React, { useState } from "react";
import { Col, Row } from "antd";
import { useMediaQuery } from "react-responsive";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "./collapseDetails.scss"

const QuestionCollapse = ({ item,index }) => {
  const [isOpenWeekly, setIsOpenWeekly] = useState(false);

  const toggleCollapseWeekly = () => {
    setIsOpenWeekly(!isOpenWeekly);
  };

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  

  return (
    <div
    key={index}
    className={`Collapse-container ${isOpenWeekly ? "open" : ""}`}
    onClick={toggleCollapseWeekly}
    >
      <div className="mainCollapse">
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <h3 className="collapseTitle">{`Q${index+1} :-`} {item?.name}</h3>
        </div>
        <div className="collapseActive">
          <div style={{ cursor: "pointer" }} >
            {isOpenWeekly ? <UpOutlined /> : <DownOutlined />}
          </div>
        </div>
      </div>

      {isOpenWeekly && (
        <>
          {item?.CheckBoxAnswer?.length > 0 && (
            <Row style={{ marginTop: 15 }}>
              {item?.CheckBoxAnswer?.map((checkbox, index) => (
                <Col key={index} span={mobileResponsive ? 12 : 8}>
                  <p className="patient-detail-heading">
                    {index+1}: {checkbox?.name || ""}
                  </p>
                </Col>
              ))}
            </Row>
          )}
            <Row style={{ marginTop: 15 }}>
              <Col span={mobileResponsive ? 12 : 8}>
                <p className="patient-detail-heading">
                  {item?.option?.name || ""}
                </p>
              </Col>
            </Row>
          {item?.images?.length> 0  && (
            <Row style={{ marginTop: 15 }}>
              {item?.images?.map((image, index) => (
                <Col key={index} span={mobileResponsive ? 12 : 5}>
                  <a href={image} target="_blank">
                    <img
                      width="100px"
                      height="100px"

                      style={{ objectFit: "cover",borderRadius:"10px" }}
                      src={image}
                    />
                  </a>
                </Col>
              ))}
            </Row>
          )}

          {item?.inputData?.length > 0 && (
            <Row style={{ marginTop: 15 }}>
              <Col span={mobileResponsive ? 12 : 8}>
                <p className="patient-detail-heading">
                  {item?.inputData || ""}
                </p>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default QuestionCollapse;
