import React, { useEffect, useState } from "react";
import Header from "../../Components/Layouts/Header/Header";
import { Col, Divider, Input, Row, Spin, Table, message } from "antd";
import SingleRefillHeader from "../../Components/Refills/SingleRefill/SingleRefillHeader";
import { getRequest } from "../../service/ApisFunctions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ReactSVG } from "react-svg";
import { arrowBack, messageLines } from "../../assets";
import ConsultationProviderDetails from "../../Components/ConsultationDetail/ProviderPersonalDetails/consultationProviderDetails";
import HealthInformation from "../../Components/ConsultationDetail/EHR/healthInformation";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import OtherDetails from "../../Components/ConsultationDetail/EHR/ConsDetails/otherDetails";
const SingleRefillView = () => {
  const { id } = useParams();
  const location = useLocation();
  const { data } = location.state || {};

  const navigate = useNavigate();
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const searchFilterAllergies = (value) => {
    const filterData = allAllergies?.filter((item) =>
      item?.DisplayName?.toLowerCase()
        ?.trim()
        ?.includes(value?.toLowerCase()?.trim())
    );

    setAllergies(filterData);
  };

  const [loading, setLoading] = useState(false);
  const [allergiesLoading, setAllergiesLoading] = useState(false);
  const [allAllergies, setAllAllergies] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [refill, setRefill] = useState(null);
  const [prescriptions, setPrescriptions] = useState([]);

  const getSingleRefillRequest = async () => {
    setLoading(true);

    const onSuccess = (res) => {
      setLoading(false);
      setRefill(res?.data);
    };

    const onError = (err) => {
      message?.error(err?.message);
      setLoading(false);
    };

    await getRequest(
      "",
      `refill/get-single-refill-request/${id}/provider/${data?.provider_id}/${data?.speciality_id}`,
      true,
      onSuccess,
      onError
    );
  };
  const getAllAllergies = async () => {
    setAllergiesLoading(true);

    const onSuccess = (res) => {
      setAllAllergies(res?.data?.Items?.reverse());

      setAllergiesLoading(false);
    };
    const onError = (err) => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setAllergiesLoading(false);
    };

    const route = `allergy/retrieve-allergy-details/${completeUser?.doseSpotId}`;
    await getRequest("", route, true, onSuccess, onError);
  };
  useEffect(() => {
    getSingleRefillRequest();
  }, []);
  useEffect(() => {
    if (completeUser?.doseSpotId) {
      getAllAllergies();
    }
  }, [completeUser]);

  const gotoChat = () => {
    navigate(`/messages?messageId=${refill?.relatedSpecialities?.chat_id}`);
  };

  const getPrescriptionsList = async () => {
    setLoading(true);

    const onSuccess = (res) => {
      setLoading(false);
      const prescriptions = res?.data?.Items?.filter(
        (elem) => elem?.Encounter == refill?.relatedSpecialities?._id
      );
      setPrescriptions(prescriptions);
    };

    const onError = (err) => {
      message?.error(err?.message);
      setLoading(false);
    };

    await getRequest(
      "",
      `prescription/get-all-prescriptions?doseSpotId=${completeUser?.doseSpotId}`,
      true,
      onSuccess,
      onError
    );
  };

  const prescriptionColumns = [
    {
      key: "1",
      title: "Drug name",
      render: (_, object) => object?.DisplayName,
    },
    {
      key: "1",
      title: "Quantity",
      render: (_, object) => object?.Quantity,
    },
    {
      key: "1",
      title: "Refills",
      render: (_, object) => object?.Refills,
    },

    {
      key: "3",
      title: "Status",
      render: (_, object) => object?.Status,
    },
  ];
  useEffect(() => {
    getPrescriptionsList();
  }, [refill]);

  const medicationColumns = [
    {
      key: "1",
      title: "Medication Name",
      render: (_, object) => object?.label || "-",
      width: "80%",
    },
    // {
    //   key: "4",
    //   title: "Strength",
    //   render: (_, object) => (object?.Strength ? object?.Strength : "-"),
    // },
    // {
    //   key: "3",
    //   title: "Days Supply",
    //   render: (_, object) => object?.DaysSupply || "-",
    // },
    // {
    //   key: "5",
    //   title: "Last Fill Date",
    //   render: (_, object) =>
    //     object?.LastFillDate
    //       ? dayjs(object?.LastFillDate).format("YYYY-MM-DD")
    //       : "-",
    // },

    {
      key: "5",
      title: "Action",
      render: (_, object) => {
        // Assuming refill is defined in the parent scope or component state/props
        const isMedicationIncluded = prescriptions?.find(
          (med) => med.DispensableDrugId === object.key
        );
        return isMedicationIncluded ? (
          isMedicationIncluded?.Status
        ) : (
          <div>Incomplete</div>
        );
      },
      width: "20%",
    },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <div className="newConsultation">
          <Row gutter={20}>
            <SingleRefillHeader refill={refill} />
            <Col span={mobileResponsive ? 24 : 7}>
              <Row gutter={[0, 10]}>
                {refill?.relatedSpecialities?.chat_id && (
                  <Col onClick={() => gotoChat()} span={24}>
                    <div className="DetailsMessage">
                      <div className="DetailsMessageLeft">
                        <h2>Message Dr.</h2>
                        <ReactSVG src={arrowBack} />
                      </div>
                      <img src={messageLines} alt="$$$" />
                    </div>
                  </Col>
                )}
                <Col span={24}>
                  <ConsultationProviderDetails
                    booking={refill?.relatedSpecialities}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={mobileResponsive ? 24 : 17}>
              <div className="ehr">
                <Row gutter={[10, 10]}>
                  <Col span={mobileResponsive ? 24 : 12}>
                    <div className="healthEquity">
                      <div className="healthEquityHeader">
                        <h2>Health Equity Information</h2>
                      </div>
                      <Divider />
                      <Row gutter={[10, 10]}>
                        <Col span={12}>
                          <HealthInformation
                            question="Race"
                            answer={refill?.patient_id?.race || "--"}
                          />
                        </Col>
                        <Col span={12}>
                          <HealthInformation
                            question="Ethnicity"
                            answer={
                              refill?.patient_id?.ethnicity?.length
                                ? refill?.patient_id?.ethnicity?.map((item) =>
                                    refill?.patient_id?.ethnicity?.[
                                      refill?.patient_id?.ethnicity?.length - 1
                                    ] === item
                                      ? item
                                      : item + " | "
                                  )
                                : "-"
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <HealthInformation
                            question="Birth gender"
                            answer={refill?.patient_id?.birthGender || "--"}
                          />
                        </Col>
                        <Col span={12}>
                          <HealthInformation
                            question="Current gender"
                            answer={refill?.patient_id?.gender || "--"}
                          />
                        </Col>
                        <Col span={12}>
                          <HealthInformation
                            question="Pronoun"
                            answer={refill?.patient_id?.pronoun || "--"}
                          />
                        </Col>
                        <Col span={12}>
                          <HealthInformation
                            question="Sexual orientation"
                            answer={
                              refill?.patient_id?.sexualOrientation || "--"
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={mobileResponsive ? 24 : 12}>
                    <Spin spinning={allergiesLoading}>
                      <div className="allergies">
                        <div className="allergiesHeader">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <h2>Allergies</h2>

                            <Input
                              onChange={(e) =>
                                searchFilterAllergies(e?.target?.value)
                              }
                              suffix={
                                <SearchOutlined
                                  size={15}
                                  style={{ color: "#958070" }}
                                />
                              }
                              placeholder="Search here"
                            />
                          </div>
                        </div>
                        <Divider />
                        <Table
                          className="antd-custom-table"
                          pagination={{ pageSize: 3 }}
                          columns={columns}
                          scroll={mobileResponsive ? { x: 1300, y: 660 } : {}}
                          dataSource={allAllergies}
                          size="small"
                        />
                      </div>
                    </Spin>
                  </Col>
                  <Col span={24}>
                    <div className="allergies">
                      <div className="allergiesHeader">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <h2>Requested Medications</h2>
                        </div>
                      </div>
                      <Divider />
                      <Table
                        className="antd-custom-table"
                        pagination={{ pageSize: 3 }}
                        columns={medicationColumns}
                        scroll={mobileResponsive ? { x: 1300, y: 660 } : {}}
                        dataSource={refill?.relatedSpecialities?.medications}
                        size="small"
                      />

                      <div className="allergiesHeader">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <h2>Prescription</h2>
                        </div>
                      </div>
                      <Divider />

                      <Table
                        scroll={mobileResponsive ? { x: 1200, y: 660 } : {}}
                        className="antd-custom-table"
                        pagination={true}
                        columns={prescriptionColumns}
                        dataSource={prescriptions}
                        size="small"
                      />
                    </div>
                  </Col>
                  <Col style={{ marginTop: "10px" }} span={24}>
                    {refill?.relatedSpecialities?.medications?.map(
                      (item, index) => {
                        return (
                          <div key={index} style={{ marginTop: "10px" }}>
                            <OtherDetails
                              title={item?.label}
                              questions={item?.preQuestions}
                              check={true}
                            />
                          </div>
                        );
                      }
                    )}
                  </Col>
                  {/* <OtherDetails
                      title="Questionnaire"
                      questions={refill?.pre_questions}
                      check={true}
                    /> */}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default SingleRefillView;
const columns = [
  {
    key: "1",
    title: "Reactant",
    render: (_, object) => object?.DisplayName || "-",
    width: "16.66%",
  },
  {
    key: "3",
    title: "Status",
    width: "16.66%",
    render: (_, object) =>
      object?.StatusType === "Active" ? "Current" : "Past" || "-",
  },
  {
    key: "2",
    title: "Severity of Reaction",
    render: (_, object) => object?.Reaction || "-",
    width: "16.66%",
  },
  {
    key: "4",
    title: "Reaction Type",
    render: (_, object) => object?.ReactionType || "-",
    width: "16.66%",
  },
  {
    key: "5",
    title: "On Set Date",
    render: (_, object) => moment(object?.OnsetDate).format("DD-MM-YYYY"),
    width: "16.66%",
  },
];
