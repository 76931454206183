import { Col, DatePicker, Form, Input, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import './addOtherPatient.scss';
import {
  postRequest,
  postRequestFormData,
  putRequest
} from '../../service/ApisFunctions';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import SecondaryButton from '../../Components/UI/SecondaryButton/SecondaryButton';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import {
  AllLanguages,
  EthnicityList,
  HeightUnit,
  RaceList,
  birthGendersList,
  gendersList,
  pronounList,
  sexualOrientation
} from '../../Utils/constant';
import { calendarSimple, paperclip } from '../../assets';
import { validateHeight } from '../../Utils/helper';

const AddAnOtherpatient = ({
  setModalLoading,
  handleOk,
  getAllOtherPatients,
  singlePatient,
  setSinglePatient,
  form,
  completeUser
}) => {
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const [guardianFile, setGuardianFile] = useState(null);
  const [otherPatientHeightUnit, setOtherPatientHeightUnit] = useState(1);
  const [otherPatientWeightUnit, setOtherPatientWeightUnit] = useState(1);

  const handleInputChange = async e => {
    const file = e.target.files[0];
    UploadS3(file);
  };

  const UploadS3 = async file => {
    setModalLoading(true);
    const onSuccess = res => {
      setModalLoading(false);
      setGuardianFile(res?.data);
    };

    const onError = err => {
      setModalLoading(false);
    };

    const formData = {
      image: file
    };

    await postRequestFormData(
      formData,
      'auth/upload_image_s3',
      true,
      onSuccess,
      onError
    );
  };

  const handleSubmit = async e => {
    if (!guardianFile) {
      message.warning('Please attach file.');
      return;
    }
    setModalLoading(true);

    const onSuccess = res => {
      message.success(res?.message);
      setModalLoading(false);
      form.resetFields();
      handleOk();
      getAllOtherPatients();
      setSinglePatient({});
    };

    const onError = err => {
      message.error(err?.message);
      setModalLoading(false);
    };

    const formData = {
      patient: {
        first_name: e?.firstName,
        last_name: e?.lastname,
        middle_name: e?.middle_name,
        gender: e?.gender,
        dob: e?.date_of_birth?.$d,
        relation: e?.relation,
        birthGender: e?.birthGenderPatient,
        sexualOrientation: e?.sexualOrientationPatient,
        race: e?.racePatient,
        ethnicity: e?.ethnicityPatient,
        height: e?.heightPatient
          ? { value: e.heightPatient, unit: e.heightUnitPatient }
          : null,
        weight: e?.weightPatient
          ? { value: e.weightPatient, unit: e.weightUnitPatient }
          : null,
        language: e?.languagePatient,
        location: completeUser?.location,
        phone_number: completeUser?.phone_number,
        attachment: guardianFile,
        pronoun: e?.pronounPatient
      }
    };

    if (singlePatient?._id) {
      await putRequest(
        formData,
        `patient/update-patient/${singlePatient?._id}`,
        true,
        onSuccess,
        onError
      );
    } else {
      await postRequest(
        formData,
        `patient/add-patient`,
        true,
        onSuccess,
        onError
      );
    }
  };

  const disabledFutureDate = current => {
    // Disable dates that are after today
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    if (singlePatient?._id) {
      form.setFieldsValue({
        firstName: singlePatient?.patient?.first_name || '-',
        middle_name: singlePatient?.patient?.middle_name || '-',
        lastname: singlePatient?.patient?.last_name || '-',
        gender: singlePatient?.patient?.gender,
        date_of_birth: dayjs(singlePatient?.patient?.dob),
        relation: singlePatient?.patient?.relation,
        birthGenderPatient: singlePatient?.patient?.birthGender,
        sexualOrientationPatient: singlePatient?.patient?.sexualOrientation,
        pronounPatient: singlePatient?.patient?.pronoun,
        racePatient: singlePatient?.patient?.race,
        ethnicityPatient: singlePatient?.patient?.ethnicity,
        heightPatient: singlePatient?.patient?.height?.value,
        weightPatient: singlePatient?.patient?.weight?.value,
        languagePatient: singlePatient?.patient?.language || [],
        heightUnitPatient: parseInt(singlePatient?.patient?.height?.unit) || 1,
        weightUnitPatient: parseInt(singlePatient?.patient?.weight?.unit) || 1
      });

      setOtherPatientHeightUnit(
        parseInt(singlePatient?.patient?.height?.unit) || 1
      );
      setOtherPatientWeightUnit(
        parseInt(singlePatient?.patient?.weight?.unit) || 1
      );
      setGuardianFile(singlePatient?.patient?.attachment);
    }
  }, [singlePatient, form]);

  useEffect(() => {
    form.setFieldsValue({
      heightUnitPatient: 'ft',
      weightUnitPatient: 'lb'
    });
  }, []);

  return (
    <Row className='addOtherPatient'>
      <Col span={24}>
        <h3 style={{ textAlign: 'center' }}>
          {singlePatient?._id ? 'Edit Other Patient' : 'Add Other Patient'}
        </h3>
      </Col>
      <Form
        style={{ width: '100%', marginTop: '20px' }}
        layout='vertical'
        form={form}
        onFinish={handleSubmit}
      >
        <div className='formItem'>
          <Form.Item
            name='firstName'
            label='Patient First Name'
            rules={[
              { required: true, message: 'Please enter your first name' }
            ]}
          >
            <Input
              className='ant-input-affix-wrapper'
              placeholder='Patient First name'
            />
          </Form.Item>

          <Form.Item name='middle_name' label='Patient Middle Name'>
            <Input
              className='ant-input-affix-wrapper'
              placeholder='Patient Middle name'
            />
          </Form.Item>
          <Form.Item
            name='lastname'
            label='Patient Last Name'
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input
              className='ant-input-affix-wrapper'
              placeholder='Patient Last name'
            />
          </Form.Item>
        </div>

        <div className='formItem'>
          <Form.Item
            name='ethnicityPatient'
            label='Patient Ethnicity'
            rules={[{ required: true, message: 'Please enter your ethnicity' }]}
          >
            <Select
              mode='multiple'
              className='ant-select-selector'
              placeholder='Please select ethnicity'
            >
              {EthnicityList?.map((item, index) => (
                <Select.Option key={index} value={item?.name}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='racePatient'
            label='Race'
            rules={[{ required: true, message: 'Please enter your race' }]}
          >
            <Select
              className='ant-select-selector'
              placeholder='Please select race'
            >
              {RaceList?.map((item, index) => (
                <Select.Option key={index} value={item?.name}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className='formItem'>
          <div
            style={
              mobileResponsive
                ? { width: '100%', display: 'flex', gap: '10px' }
                : { width: '50%', display: 'flex', gap: '20px' }
            }
          >
            <Form.Item
              style={{ width: '70%' }}
              name=''
              label='Patient Height'
              rules={[
                { required: true },
                { validator: validateHeight(otherPatientHeightUnit) }
              ]}
            >
              <Input
                type='number'
                className='ant-input-affix-wrapper'
                placeholder='Patient Height'
              />
            </Form.Item>

            <Form.Item
              style={{ width: '30%' }}
              name='heightUnitPatient'
              label='Unit'
              rules={[{ required: true, message: 'Please select unit' }]}
            >
              <Select
                onChange={e => {
                  setOtherPatientHeightUnit(e);
                  form.resetFields(['heightPatient']);
                }}
                className='ant-select-selector'
                placeholder='Unit'
              >
                {HeightUnit?.map((item, index) => (
                  <Select.Option key={index} value={item?.id}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div
            style={
              mobileResponsive
                ? { width: '100%', display: 'flex', gap: '10px' }
                : { width: '50%', display: 'flex', gap: '20px' }
            }
          >
            <Form.Item
              style={{ width: '70%' }}
              name='weightPatient'
              label='Patient Weight'
              rules={[
                { required: true },
                { validator: validateHeight(otherPatientWeightUnit) }
              ]}
            >
              <Input
                min={1}
                type='number'
                className='ant-input-affix-wrapper'
                placeholder='Patient Weight'
              />
            </Form.Item>

            <Form.Item
              style={{ width: '30%' }}
              name='weightUnitPatient'
              label='Unit'
              rules={[{ required: true, message: 'Please select unit' }]}
            >
              <Select
                onChange={e => {
                  setOtherPatientWeightUnit(e);
                  form.resetFields(['weightPatient']);
                }}
                className='ant-select-selector'
                placeholder='Unit'
              >
                <Select.Option value='Kg'>Kg</Select.Option>
                <Select.Option value='Lb'>Lbs</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className='formItem'>
          <Form.Item
            name='sexualOrientationPatient'
            label='Patient Sexual orientation'
            rules={[
              {
                required: true,
                message: 'Please enter your sexual orientation'
              }
            ]}
          >
            <Select
              className='ant-select-selector'
              placeholder='Please select sexual orientation'
            >
              {sexualOrientation?.map((item, index) => (
                <Select.Option key={index} value={item?.name}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='pronounPatient'
            label='Patient pronoun'
            rules={[
              {
                required: true,
                message: 'Please enter pronoun'
              }
            ]}
          >
            <Select
              className='ant-select-selector'
              placeholder='Please select pronoun'
            >
              {pronounList?.map((item, index) => (
                <Select.Option key={index} value={item?.name}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className='formItem'>
          <Form.Item
            name='birthGenderPatient'
            label='Patient Birth Gender'
            rules={[
              {
                required: true,
                message: 'Please select your birth gender'
              }
            ]}
          >
            <Select
              className='ant-select-selector'
              placeholder='Patient BirthGender'
            >
              {birthGendersList?.map((item, index) => (
                <Select.Option key={index} value={item?.name}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='gender'
            label='Patient Current Gender'
            rules={[{ required: true, message: 'Please select your gender' }]}
          >
            <Select
              className='ant-select-selector'
              placeholder='Patient gender'
            >
              {gendersList?.map((item, index) => (
                <Select.Option key={index} value={item?.name}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className='formItem'>
          <Form.Item
            name='relation'
            label='Relationship with Patient'
            rules={[
              { required: true, message: 'Please enter your relationship' }
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className='ant-select-selector'
              placeholder='Relationship'
            >
              <Select.Option value='parent'>Parent</Select.Option>
              <Select.Option value='guardian'>Guardian</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='languagePatient'
            label='Patient Language'
            rules={[{ required: true, message: 'Please select your language' }]}
          >
            <Select
              mode='multiple'
              showSearch
              className='ant-select-selector'
              placeholder='language'
            >
              {AllLanguages?.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          name='date_of_birth'
          label='Patient Date Of Birth'
          rules={[
            { required: true, message: 'Please select your date of birth' }
          ]}
        >
          <DatePicker
            disabledDate={disabledFutureDate}
            className='ant-input-affix-wrapper'
            placeholder='Date of birth'
            suffixIcon={<ReactSVG width='20px' src={calendarSimple} />}
            format='MM-DD-YYYY'
          />
        </Form.Item>

        <Form.Item label='Attachment'>
          <div className='formInputs'>
            <div className={`${'fileField'} ${!guardianFile && 'error'}`}>
              <input
                className='field'
                id='guardianID'
                name='guardianID'
                type='file'
                placeholder='Guardian’s ID'
                // value={guardianFile}
                onChange={handleInputChange}
                style={{ boxShadow: 'none', display: 'none' }}
              />
              <label
                className='fileLabel'
                type='file'
                htmlFor='guardianID'
                // style={{
                //   minHeight: '45px',
                //   width: '100%',
                //   alignItems: 'center',
                //   display: 'flex',
                //   justifyContent: 'space-between',
                //   padding: '0px 10px'
                // }}
              >
                <p
                  style={
                    guardianFile ? { color: 'gray' } : { color: 'lightgray' }
                  }
                >
                  {guardianFile ? guardianFile : 'Attachment'}
                </p>
                <ReactSVG src={paperclip} />
              </label>
            </div>
            {!guardianFile && (
              <p className='errorMessage'>Please select file</p>
            )}
          </div>
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }}
        >
          <SecondaryButton>
            {singlePatient?._id ? 'Update' : 'Submit'}
          </SecondaryButton>
        </div>
      </Form>
    </Row>
  );
};

export default AddAnOtherpatient;
