import { useEffect, useState } from "react";
import classes from "./MyConsultations.module.scss";
import SecondaryButton from "../../Components/UI/SecondaryButton/SecondaryButton";
import { ReactSVG } from "react-svg";
import SearchField from "../../Components/UI/SearchField/SearchField";
import DashboardTable from "../../Components/DashboardTable/DashboardTable";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Select, Spin, message } from "antd";
import { getRequest, postRequest } from "../../service/ApisFunctions";
import { getViewBookings, vdieoToken } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import "./consultation.scss";
import { setVideoToken } from "../../Redux/Reducers/authSlice";
import {
  EmergencyLight,
  consultDoc,
  consultationMenu,
  magnifyingGlassSolid,
  medBottle,
  mobile,
} from "../../assets";
import NextSchedule from "./nextSchedule";
import Header from "../../Components/Layouts/Header/Header";
import SkeletonInput from "../../Components/UI/Skeleton/skeletonInput";
import BlueButton from "../../Components/UI/BlueButton/BlueButton";
const MyConsultations = () => {
  const navigate = useNavigate();
  const [showMega, setShowMega] = useState(false);
  const [allBooking, setAllBooking] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUrgentFound, setIsUrgentFound] = useState(false);
  const [isLeaveFound, setIsLeaveound] = useState(false);
  const [UrgentFilter, setUrgentFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [isSearchFilter, setIsSearchFilter] = useState(false);

  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );

  const cardsArray = [
    {
      id: 1,
      title: "Find and Book Consultation",
      description: "Expert doctors for you",
      button: "Video Consultation",
      img: consultDoc,
    },
    {
      id: 2,
      title: "Treatment Programs",
      description: "Talk to Doctor Online",
      button: "Book a Program",
      img: mobile,
    },
    {
      id: 3,
      title: "Order Labs",
      description: "Order labs online ",
      button: "Get a refil online",
      img: medBottle,
    },
  ];
  const dispatch = useDispatch();
  const _id = useParams();

  const getAllBookings = async (page, limit) => {
    setLoading(true);
    const onSuccess = (res) => {
      setTotalCount(res?.data?.totalCount);
      setBookingList(res?.data?.bookings);
      setAllBooking(res?.data?.bookings);
      const filtered = res?.data?.bookings?.filter((elem) => {
        return (
          (elem?.urgent_care && !elem?.isPreQuestionAttempted) ||
          !elem?.isMedicalHistoryAttempted ||
          (!elem?.urgent_care && !elem?.isPreQuestionAttempted) ||
          (!elem?.isMedicalHistoryAttempted &&
            new Date(elem?.schedule?.end_time) >= new Date())
        );
      });
      const isLeave = res?.data?.bookings?.some(
        (elem) => elem.booking_status === "leave"
      );
      setIsLeaveound(isLeave);
      setIsUrgentFound(filtered?.length ? true : false);
      setLoading(false);
    };

    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    const formData = {
      status: true,
    };

    await postRequest(
      formData,
      `${getViewBookings}?page=${page}&limit=${limit}`,
      true,
      onSuccess,
      onError
    );
  };

  const getOtherspatient = async (page, limit) => {
    setLoading(true);
    const onSuccess = (res) => {
      setTotalCount(res?.data?.totalCount);
      setBookingList(res?.data?.bookings);
      setAllBooking(res?.data?.bookings);
      const filtered = res?.data?.bookings?.filter((elem) => {
        return (
          (elem?.urgent_care && !elem?.pre_questions?.length) ||
          !elem?.medicalHistory?.length ||
          (!elem?.urgent_care && !elem?.pre_questions?.length) ||
          (!elem?.medicalHistory?.length &&
            new Date(elem?.schedule?.end_time) >= new Date())
        );
      });

      const isLeave = res?.data?.bookings?.some(
        (elem) => elem.booking_status === "leave"
      );
      setIsLeaveound(isLeave);
      setIsUrgentFound(filtered?.length ? true : false);
      setLoading(false);
    };

    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    const formData = {
      status: false,
      id: _id?.id,
    };

    await postRequest(
      formData,
      `${getViewBookings}?page=${page}&limit=${limit}`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (_id?.id) {
      getOtherspatient(1, 10);
    } else {
      getAllBookings(1, 10);
    }
  }, []);

  // get Video Token
  const getToken = async (object) => {
    if (object?.payment_status) {
      setLoading(true);

      const onSuccess = (res) => {
        setLoading(false);
        dispatch(setVideoToken(res?.token));
      };

      const onError = (err) => {
        message.error(err?.message);
        setLoading(false);
      };

      const formData = {
        name: object?.user_id,
        booking_id: object?._id,
      };

      await postRequest(formData, vdieoToken, true, onSuccess, onError);
    }
  };

  // useEffect(() => {
  //   let filteredArray = allBooking || [];

  //   if (statusFilter && statusFilter !== 'all') {
  //     filteredArray = filteredArray.filter(item =>
  //       item?.consultation_status
  //         ?.toLowerCase()
  //         ?.includes(statusFilter.toLowerCase())
  //     );
  //   }

  //   if (nameFilter) {
  //     const filterName = nameFilter.toLowerCase().replace(/\s/g, '');
  //     filteredArray = filteredArray.filter(item => {
  //       const fullName = `${item.provider_id?.first_name || ''}${item.provider_id?.middle_name || ''}${item.provider_id?.last_name || ''}`
  //         .toLowerCase()
  //         .replace(/\s/g, '');
  //       return fullName.includes(filterName);
  //     });
  //   }

  //   if (UrgentFilter === 'urgent') {
  //     filteredArray = filteredArray.filter(item => item?.urgent_care === true);
  //   } else if (UrgentFilter === 'consultations') {
  //     filteredArray = filteredArray.filter(item => item?.urgent_care !== true);
  //   }

  //   setBookingList(filteredArray);
  // }, [UrgentFilter, statusFilter, nameFilter, allBooking]);

  const searchBookings = async (value) => {
    if (statusFilter || UrgentFilter || nameFilter || value) {
      setLoading(true);

      const onSuccess = (res) => {
        setLoading(false);
        setAllBooking(res?.data?.bookings);
        setBookingList(res?.data?.bookings);
        setIsSearchFilter(value ? false : true);
      };

      const onError = (err) => {
        setLoading(false);
        message.error(err?.message);
      };

      const params = new URLSearchParams();
      params.append("status", "customer");
      if (UrgentFilter) {
        params.append("booking_type", UrgentFilter);
      }

      if (statusFilter) {
        params.append("consultation_status", statusFilter);
      }

      if (nameFilter) {
        params.append("providerFullName", nameFilter);
      }

      const queryString = params.toString();
      const route = `booking/search-bookings${
        queryString && !value ? `?${queryString}` : `?status=clear`
      }`;

      await getRequest("", route, true, onSuccess, onError);
    }
  };

  const handClearFilter = () => {
    setNameFilter("");
    setStatusFilter(null);
    setUrgentFilter(null);
    searchBookings("clear");
  };

  return (
    <>
      <Header showMega={showMega} setShowMega={setShowMega} />
      <div className={classes.container} onClick={() => setShowMega(false)}>
        {/* <h1>Find the best doctor near you</h1>
        <p>1 Million + patients found doctors through Ohana Doc.</p>
        <div className={classes.card}>
          {cardsArray.map((el, index) => {
            return (
              <div key={index} className={classes.cardBody}>
                <div className={classes.content}>
                  <h3>{el.title}</h3>
                  <p className={classes.description}>{el.description}</p>
                  <SecondaryButton
                    onClick={() =>
                      index === 0
                        ? navigate('/virtualConsultation?type=medical')
                        : index === 1
                        ? navigate('/virtualConsultation?type=treatment')
                        : navigate('/consultation-booking')
                    }
                    className={
                      index === 0
                        ? classes.btnOrange
                        : index === 1
                        ? classes.btnPrimary
                        : classes.btnSuccess
                    }
                  >
                    {el.button}
                  </SecondaryButton>
                </div>
                <ReactSVG src={el.img} className={classes.images} />
              </div>
            );
          })}
        </div> */}
        {completeUser?.alerts?.length > 0
          ? completeUser?.alerts?.map((item, index) => (
              <NextSchedule
                key={index}
                item={item}
                index={index}
                completeUser={completeUser}
                getAllBookings={getAllBookings}
              />
            ))
          : null}
        {isUrgentFound ? (
          <div className={classes.unfilledConsultationDetail}>
            <div className={classes.logo}>
              <img src={EmergencyLight} alt="$$$" />
            </div>
            <div className={classes.alertText}>
              <h4>Additional information is required.</h4>
              <p>
                Some consultations require more information. Please complete
                them.
              </p>
            </div>
          </div>
        ) : null}
        {isLeaveFound ? (
          <div className={classes.unfilledConsultationDetail}>
            <div className={classes.logo}>
              <img src={EmergencyLight} alt="$$$" />
            </div>
            <div className={classes.alertText}>
              <h4>Reschedule Consultations.</h4>
              <p>
                Certain consultations require rescheduling. We apologize for any
                inconvenience and appreciate your understanding.
              </p>
            </div>
          </div>
        ) : null}

        <h2>My Consultations</h2>
        <div className={classes.fields}>
          <div className={classes.dropDowns}>
            <ReactSVG src={consultationMenu} />
            {/* <SelectFields
              placeholder='All Apointments'
              border={true}
              className={classes.field1}
              options={['Video', 'Program', 'Labtest']}
              filterSelect={filterSelect}
            /> */}
            <div className="consulatation">
              <Select
                onChange={(e) => setUrgentFilter(e)}
                placeholder="All visits"
                className="ant-select-selector"
                value={UrgentFilter}
              >
                <Select.Option key="all" value="all">
                  All
                </Select.Option>
                <Select.Option key="consultations" value="consultations">
                  Scheduled Consultations
                </Select.Option>
                <Select.Option key="urgent" value="urgent">
                  Instant Consultations
                </Select.Option>
              </Select>

              <Select
                onChange={(e) => setStatusFilter(e)}
                placeholder="All status"
                className="ant-select-selector"
                value={statusFilter}
              >
                <Select.Option key="all" value="all">
                  All
                </Select.Option>
                <Select.Option key="upcoming" value="upcoming">
                  Upcoming
                </Select.Option>
                <Select.Option key="missed" value="missed">
                  Missed
                </Select.Option>
                <Select.Option key="review pending" value="review pending">
                  Review pending
                </Select.Option>
                <Select.Option key="complete" value="completed">
                  Complete
                </Select.Option>
                <Select.Option key="leave" value="leave">
                  Leave
                </Select.Option>
              </Select>

              {/* <SelectFields
                placeholder="All Status"
                border={true}
                className={classes.field2}
                options={[
                  "Upcoming",
                  "Reserved",
                  "Missed",
                  "Complete",
                  "review pending",
                ]}
                filterSelect={filterSelect}
              /> */}
            </div>
          </div>
          <SearchField
            icon={magnifyingGlassSolid}
            className={classes.search}
            placeholder="Search"
            onChange={(e) => setNameFilter(e?.target?.value)}
            value={nameFilter}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "35px",
              gap: "10px",
            }}
          >
            <BlueButton type="primary" onClick={() => searchBookings()}>
              Search
            </BlueButton>

            <Button
              style={{ padding: "0px " }}
              type="link"
              onClick={() => handClearFilter()}
            >
              {/* <img
                  src={ClearFilter}
                  alt="$$$"
                  width={"25px"}
                  height={"25px"}
                /> */}
              Clear Filters
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            paddingBottom: "20px",
            width: "95%",
            marginInline: "auto",
            minHeight: "80vh",
          }}
        >
          {/* {loading ? (
          [1, 2, 3]?.map((item) => (
            <div style={{ marginTop: "10px" }}>
              <SkeletonInput block={true} />
            </div>
          ))
        ) : ( */}
          <DashboardTable
            totalCount={totalCount}
            getAllBookings={getAllBookings}
            getToken={getToken}
            bookingList={bookingList}
            setLoading={setLoading}
            isSearchFilter={isSearchFilter}
          />
          {/* )} */}
        </div>
      </Spin>
    </>
  );
};

export default MyConsultations;
