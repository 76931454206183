import React, { useEffect, useState } from "react";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";
import { Col, Form, Input, Modal, Row, Spin, message } from "antd";
import { getRequest, postRequest } from "../../service/ApisFunctions";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../UI/PrimaryButton/PrimaryButton";
import { useMediaQuery } from "react-responsive";
import {
  resetRefillState,
  setRefillMedications,
  setRefillMedicationsPrice,
} from "../../Redux/Reducers/refillsSlice";
import { CheckCircleOutlined } from "@ant-design/icons";
import OrangeButton from "../UI/OrangeButton/OrangeButton";
import { useNavigate } from "react-router-dom";
import SingleConsentForm from "../ConsentForm/singleConsentForm";
import { consentForms } from "../../Utils/constant";

const RefillPayment = ({ setRefillBookingProgressStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const refill = useSelector((state) => state.refills);
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const [isloading, setisloading] = useState(false);
  const [cvc, setCVC] = useState("");
  const [coupon, setCoupon] = useState("");
  const [refillPrice, setRefillPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [expiryDate, setExpiryDate] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const handleCardNumberChange = (value) => {
    const input = value.replace(/\D/g, "");
    let formattedInput = "";

    for (let i = 0; i < input.length; i += 4) {
      formattedInput += input.substr(i, 4) + " ";
    }

    setCardNumber(formattedInput.trim());
  };
  const handleExpiryDateChange = (value) => {
    const input = value.replace(/\D/g, "");
    let formattedInput = "";

    if (input.length >= 2) {
      const month = input.substr(0, 2);
      if (parseInt(month, 10) > 0 && parseInt(month, 10) <= 12) {
        formattedInput += month;

        const enteredYear = parseInt(input.substr(2, 2), 10);
        if (enteredYear >= 0 && enteredYear < 100) {
          formattedInput += " / " + input.substr(2, 2);
        } else if (input.length > 2) {
          formattedInput += " / " + input.substr(2, 4);
        }
      }
    } else {
      formattedInput += input;
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;

    const enteredYear =
      parseInt(input.substr(2, 2), 10) + currentYear - (currentYear % 100);
    const enteredMonth = parseInt(input.substr(0, 2), 10);

    setIsInvalidDate(
      isNaN(enteredYear) ||
        isNaN(enteredMonth) ||
        enteredYear < currentYear ||
        (enteredYear === currentYear && enteredMonth < currentMonth)
    );

    setIsExpired(
      enteredYear < currentYear ||
        (enteredYear === currentYear && enteredMonth < currentMonth)
    );
    setExpiryDate(formattedInput.trim());
  };
  const handleSubmitPayment = async () => {
    if (!isInvalidDate) {
      if ((cardNumber, cvc)) {
        setisloading(true);
        const onSuccess = (res) => {
          setisloading(false);
          handleCreateRefillRequest();
        };
        const onError = (res) => {
          setisloading(false);
          message.error(res?.error_message);
        };
        const formData = {
          card_number: cardNumber.replace(/\s/g, ""),
          card_expiration: expiryDate.replace(/\s/g, "").replace("/", ""),
          card_code: cvc,
          amount: discountedPrice?.toString(),
        };
        await postRequest(
          formData,
          "auth/authorized_card_payment",
          true,
          onSuccess,
          onError
        );
      } else {
        message.warning("Please enter valid card details");
      }
    } else {
      message.warning("Please enter a valid expiry date");
    }
  };
  const handleCreateRefillRequest = async () => {
    setisloading(true);
    const onSuccess = (res) => {
      setisloading(false);
      setRefillBookingProgressStep("refillSuccess");
      dispatch(resetRefillState());
      navigate(`/refill-request?refill_id=${res?.data?._id}`);
      dispatch(setRefillMedications(null));
      dispatch(setRefillMedicationsPrice(0));
    };
    const onError = (err) => {
      setisloading(false);
      message.error(err?.message);
    };
    const dataToSend = {
      booking_as: "patient",
      // category: refill?.refillCondition,
      // speciality: refill?.refillSpeciality,
      medications: refill?.refillMedications,
      // medications: refill?.refillPrescriptions,
      doseSpotId: completeUser?.doseSpotId,
      couponId: appliedCoupon?._id,
      payment: refill?.refillMedicationsPrice,
      pharmacy: completeUser?.pharmacy,
    };
    await postRequest(
      dataToSend,
      "refill/create-refill-request",
      true,
      onSuccess,
      onError
    );
  };
  const applyCoupon = async () => {
    if (coupon.length >= 8) {
      setisloading(true);
      const onSuccess = (res) => {
        setisloading(false);
        if (
          res?.data?.discountType === "value" &&
          res?.data?.discountValue < refill?.refillMedicationsPrice
        ) {
          setDiscountedPrice(
            refill?.refillMedicationsPrice - res?.data?.discountValue
          );
          setAppliedCoupon(res?.data);
          setIsCouponApplied(true);
        } else if (res?.data?.discountType === "percentage") {
          const decimalPercentage = res?.data?.discountValue / 100;
          const percentageValue =
            refill?.refillMedicationsPrice * decimalPercentage;
          setDiscountedPrice(refill?.refillMedicationsPrice - percentageValue);
          setAppliedCoupon(res?.data);
          setIsCouponApplied(true);
        } else {
          message.error("Invalid Token");
        }
      };
      const onError = (res) => {
        setisloading(false);
        message.error(res?.message);
      };
      const formData = {
        code: coupon.trim(),
        isRefill: true,
      };
      await postRequest(
        formData,
        "coupon/coupon_exists",
        true,
        onSuccess,
        onError
      );
    } else if (coupon.length && coupon.length < 8) {
      message.warning("Coupon minimum 8 character");
    } else {
      message.warning("Please enter coupon code");
    }
  };
  // const getRefillPrice = async () => {
  //   setisloading(true);
  //   const onSuccess = (res) => {
  //     setisloading(false);
  //     if (res?.data?.length) {
  //       setRefillPrice(res?.data[0]?.price);
  //       setDiscountedPrice(res?.data[0]?.price);
  //     }
  //   };
  //   const onError = (err) => {
  //     setisloading(false);
  //     message.error(err?.message);
  //   };
  //   await getRequest(
  //     "",
  //     `refill_admin/get-all-admin-refills`,
  //     true,
  //     onSuccess,
  //     onError
  //   );
  // };

  const removeCoupon = () => {
    setDiscountedPrice(refill?.refillMedicationsPrice);
    setIsCouponApplied(false);
    setAppliedCoupon(false);
  };
  useEffect(() => {
    setDiscountedPrice(refill?.refillMedicationsPrice);
    // getRefillPrice();
  }, []);
  return (
    <div className="refillRequestPaymentWrapper">
      <Spin spinning={isloading}>
        <Form layout="vertical">
          <div className="payment">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>Pay With Card</h3>
            </div>
            <Form.Item
              style={{ marginTop: "10px" }}
              label="Card No"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Card no"
                maxLength={"19"}
                onChange={(e) => handleCardNumberChange(e.target.value)}
                className="ant-input-affix-wrapper"
                value={cardNumber}
              />
            </Form.Item>
            <Form.Item label="CVC" rules={[{ required: true }]}>
              <Input
                placeholder="CVC"
                type="number"
                value={cvc}
                onChange={(e) => {
                  setCVC(e.target.value.length === 4 ? cvc : e.target.value);
                }}
                className="ant-input-affix-wrapper"
              />
            </Form.Item>
            <Form.Item
              label="Expiry Date ( MM/YY )"
              rules={[
                { required: true, message: "Please enter a valid expiry date" },
              ]}
              validateStatus={isInvalidDate ? "error" : ""}
              help={isInvalidDate && "Please enter a valid expiry date"}
            >
              <Input
                placeholder="Expiry Date MM/YY"
                type="text"
                onChange={(e) => handleExpiryDateChange(e.target.value)}
                value={expiryDate}
                className={
                  isExpired
                    ? "ant-input-affix-wrapper expired-color"
                    : "ant-input-affix-wrapper"
                }
              />
            </Form.Item>
          </div>
        </Form>
        {isCouponApplied ? (
          <Row gutter={20} className="apply-coupon">
            <Col span={mobileResponsive ? 16 : 19}>
              <div className="couponDetails">
                <div className="icon">
                  <CheckCircleOutlined />
                </div>

                <div className="details">
                  <div className="details-top">
                    <p className="code">{appliedCoupon?.code}</p>
                    <span>applied</span>
                  </div>
                  <div className="details-bottom">
                    <p>
                      -{discountedPrice}${" "}
                      {`(${
                        appliedCoupon?.discountType === "value"
                          ? appliedCoupon?.discountValue + "$"
                          : appliedCoupon?.discountValue + "%"
                      } off)`}
                    </p>
                  </div>
                </div>
              </div>
            </Col>

            <Col span={mobileResponsive ? 8 : 5}>
              <OrangeButton width="100%" onClick={() => removeCoupon()}>
                Remove
              </OrangeButton>
            </Col>
          </Row>
        ) : (
          <Form layout="vertical">
            <Row gutter={20}>
              <Col span={mobileResponsive ? 16 : 19}>
                <Form.Item
                  label="Coupon Code"
                  name="code"
                  rules={[
                    { required: true, message: "Please enter coupon code" },
                  ]}
                >
                  <Input
                    disabled={isCouponApplied}
                    min={8}
                    max={8}
                    onChange={(e) => setCoupon(e.target.value)}
                    placeholder="Enter coupon code"
                    className="ant-input-affix-wrapper"
                  />
                </Form.Item>
              </Col>
              <Col
                span={mobileResponsive ? 8 : 5}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <button
                  onClick={() => applyCoupon()}
                  type="button"
                  style={{ marginTop: "22px", height: "36px", width: "100%" }}
                  className={
                    coupon?.length < 8 || isCouponApplied
                      ? "disableBtn"
                      : "secondaryBtn"
                  }
                >
                  Apply Coupon
                </button>
              </Col>
            </Row>
          </Form>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            gap: "10px",
          }}
        >
          <PrimaryButton
            onClick={() => setRefillBookingProgressStep("medications")}
            className="primaryActive"
          >
            Previous
          </PrimaryButton>
          <SecondaryButton
            className={
              cvc.length === 3 &&
              cardNumber.length === 19 &&
              expiryDate.length === 7
                ? "pay-btn"
                : "disablePayBtn"
            }
            disabled={
              cvc.length === 3 &&
              cardNumber.length === 19 &&
              expiryDate.length === 7
                ? false
                : true
            }
            onClick={() => handleSubmitPayment()}
          >
            Confirm payment ${discountedPrice}
          </SecondaryButton>
        </div>

        {/* <Modal
          open={!completeUser?.isFinancialResponsibilityConsentAllowed && true}
          closable={false}
          width={mobileResponsive ? "90%" : "70%"}
          footer={false}
          centered
        >
          <SingleConsentForm
            consent={consentForms["financialResponsibility"]}
            type="financialResponsibility"
          />
        </Modal> */}
      </Spin>
    </div>
  );
};

export default RefillPayment;
