import React from 'react';
import classes from './CheckFields.module.scss';
import RadioButton from '../RadioButton/RadioButton';

const CheckBoxFields = ({ onClick, label, placeholder, button, style }) => {
  return (
    <div
      className={style ? classes.checkField : classes.labelField}
      onClick={onClick}
    >
      <span>{placeholder}</span>
      <div className={classes.label}>
        <p style={{ fontSize: '12px' }}>{label}</p>
        <div className={classes.checkBtn}>{button}</div>
      </div>
    </div>
  );
};
export default CheckBoxFields;
