import React, { useEffect, useState } from "react";
import { getRequest, putRequest } from "../../../service/ApisFunctions";
import { Col, Empty, Form, Row, Spin } from "antd";
import MedicalHistoryQuestionnaire from "../../MedicalHistoryQuestionnaire";
import SkeletonInput from "antd/es/skeleton/Input";
import { useDispatch } from "react-redux";
import { setCompleteUser } from "../../../Redux/Reducers/gernalSlice";

const ProfileMedicalHistoryPopup = ({ completeUser, setProfileStep }) => {
  const dispatch = useDispatch();

  const [medicalHistorylist, setMedicalHistorylist] = useState([]);
  const [changeQuestionNo, setChangeQuestionNo] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingGetQuestions, setLoadingGetQuestions] = useState(false);
  const [progressLine, setProgressLine] = useState(0);
  const [answerData, setAnswerData] = useState([]);
  const [CheckBoxAnswer, setCheckBoxAnswer] = useState([]);
  const [InputAnswer, setInputAnswer] = useState("");
  const [optionAnswer, setOptionAnswer] = useState("");
  const [ImagesAnswer, setImagesAnswer] = useState([]);
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState(
    medicalHistorylist?.[changeQuestionNo]?._id
  );

  const [form] = Form.useForm();

  const resetState = () => {
    setMedicalHistorylist([]);
    setLoading(false);
    setChangeQuestionNo(0);
    setProgressLine(0);
    setCheckBoxAnswer([]);
    setInputAnswer("");
    setOptionAnswer("");
    setImagesAnswer([]);
    setPreviousQuestions([]);
    setCurrentQuestion(null);
    setCurrentQuestionId(medicalHistorylist?.[changeQuestionNo]?._id);
  };

  const getmedicalHistoryList = async () => {
    setLoadingGetQuestions(true);
    const onSuccess = (res) => {
      setLoadingGetQuestions(false);
      const filterMedicalHistory = res?.data[0]?.questions?.filter(
        (item) => !item?.isSocialHistory
      );
      setMedicalHistorylist(filterMedicalHistory);
      setCurrentQuestion(filterMedicalHistory?.[changeQuestionNo]);
    };
    const onError = () => {
      setLoadingGetQuestions(false);
    };
    await getRequest(
      "",
      `medicalHistory/get-medical-history-questions`,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getmedicalHistoryList();
  }, []);

  const handleMedicalHistory = async (array) => {
    if (changeQuestionNo + 1 === medicalHistorylist?.length) {
      setLoading(true);
      setProgressLine(100);

      const onSuccess = () => {
        setLoading(false);
        setProfileStep(3);
        dispatch(
          setCompleteUser({
            ...completeUser,
            past_medical_history: {detail:array},
            isProfileFilled:
              completeUser?.pharmacy?.StoreName &&
              completeUser?.past_social_history?.detail?.length >= 0
                ? true
                : false,
          })
        );
        resetState();
      };
      const onError = () => {
        setLoading(false);
      };

      const formData = {
        medicalHistory: array,
        isProfileFilled:
          completeUser?.pharmacy?.StoreName &&
          completeUser?.past_social_history?.detail?.length >= 0
            ? true
            : false,
      };

      const route = `customer/update_customer_medical_history/${completeUser?.user_id?._id}`;

      await putRequest(formData, route, true, onSuccess, onError);
    } else {
      setChangeQuestionNo((pre) => pre + 1);
      setCurrentQuestion(medicalHistorylist[changeQuestionNo + 1]);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="PatientBookingMedicalHistory">
        <Row justify="center">
          {/* <Col span={24}>
            <h2 style={{ textAlign: "center" }}>Medical History</h2>
          </Col> */}

          {loadingGetQuestions ? (
            <Col span={24}>
              <div style={{ marginTop: "20px" }}>
                <SkeletonInput active size="small" block={true} />
              </div>
              <div style={{ marginTop: "10px" }}>
                <SkeletonInput active block={true} />
              </div>

              <div
                style={{
                  display: "flex",
                  flexFlow: "wrap",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "45%" }}>
                  <SkeletonInput active size="small" block={false} />
                </div>
                <div style={{ width: "45%" }}>
                  <SkeletonInput active size="small" block={false} />
                </div>
                <div style={{ width: "45%" }}>
                  <SkeletonInput active size="small" block={false} />
                </div>
                <div style={{ width: "45%" }}>
                  <SkeletonInput active size="small" block={false} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexFlow: "wrap",
                  gap: "20px",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <SkeletonInput active block={false} />
                </div>
                <div>
                  <SkeletonInput active block={false} />
                </div>
              </div>
            </Col>
          ) : medicalHistorylist?.length > 0 ? (
            <Col span={24}>
              <Form form={form} style={{ width: "100%" }} layout="vertical">
                <div className="progressBox">
                  <p
                    style={{ width: progressLine + "%", transition: "all .3s" }}
                    className="innerLine"
                  >
                    <span className="percentageNumber">{progressLine}%</span>
                  </p>
                </div>
                <MedicalHistoryQuestionnaire
                  setCurrentQuestionId={setCurrentQuestionId}
                  currentQuestionId={currentQuestionId}
                  setCurrentQuestion={setCurrentQuestion}
                  currentQuestion={currentQuestion}
                  ChangeQuestion={handleMedicalHistory}
                  answerData={answerData}
                  setAnswerData={setAnswerData}
                  setPreviousQuestions={setPreviousQuestions}
                  previousQuestions={previousQuestions}
                  setProgressLine={setProgressLine}
                  progressLine={progressLine}
                  setCheckBoxAnswer={setCheckBoxAnswer}
                  CheckBoxAnswer={CheckBoxAnswer}
                  setInputAnswer={setInputAnswer}
                  setImagesAnswer={setImagesAnswer}
                  InputAnswer={InputAnswer}
                  ImagesAnswer={ImagesAnswer}
                  setOptionAnswer={setOptionAnswer}
                  optionAnswer={optionAnswer}
                  setChangeQuestionNo={setChangeQuestionNo}
                  form={form}
                />
              </Form>
            </Col>
          ) : (
            <Empty />
          )}
        </Row>
      </div>
    </Spin>
  );
};

export default ProfileMedicalHistoryPopup;
