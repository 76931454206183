import React from 'react';
import './OrangeButton.scss';

const OrangeButton = ({
  onClick,
  className,
  children,
  type,
  width,
  disabled,
  btnRef,
  style
}) => {
  return (
    <button
      style={style ? style :width ? { width: width } : {}}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`orange-button ${className} `}
      ref={btnRef}
    >
      {children}
    </button>
  );
};

export default OrangeButton;
