import React, { useEffect, useState } from 'react';
import { BookingStepsLayout } from '../../Components/Booking/BookingStepsLayout/BookingStepsLayout';
import { Steps } from 'antd';
import './ConsultationBooking.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setCompleteUser } from '../../Redux/Reducers/gernalSlice';
import { PatientBookingInfo } from '../../Components/Booking/MainBookingSteps/PatientBookingInfo';
import { PatientBookingSchedule } from '../../Components/Booking/MainBookingSteps/PatientBookingSchedule';
import { PatientBookingPayment } from '../../Components/Booking/MainBookingSteps/PatientBookingPayment';
import { getRequest } from '../../service/ApisFunctions';
export const ConsultationBooking = () => {
  const dispatch = useDispatch();
  const patientLocalStorage = useSelector(
    state => state.authReducer.paitentToken
  );
  const bookingData = useSelector(state => state.booking);
  console.log(bookingData);
  const items = [
    {
      title: 'Demographic',
      content: <PatientBookingInfo patientToken={patientLocalStorage} />,
      disabled: true
    },
    // {
    //   title: 'Medical History',
    //   content: <PatientBookingMedicalHistory />,
    //   disabled: true
    // },
    bookingData?.bookingFlowIsUrgent
      ? null
      : {
          title: 'Schedule',
          content: <PatientBookingSchedule />,
          disabled: true
        },
    {
      title: 'Pay & Book',
      content: <PatientBookingPayment />,
      disabled: true
    }
  ];

  const me = async () => {
    const onSuccess = res => {
      dispatch(setCompleteUser(res.data));
    };

    const onError = err => {};

    await getRequest(
      '',
      'auth/auth_me?role=customer',
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    me();
  }, []);

  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <BookingStepsLayout>
          <div className='mainConsultationBookingWrap'>
            <div className='consultationSteps'>
              <Steps
                width='50%'
                current={bookingData?.bookingFlowInformationSteps}
                items={items}
                labelPlacement='vertical'
              />
            </div>
            <div className='consultationStepsContent'>
              {items?.[bookingData?.bookingFlowInformationSteps]?.content}
            </div>
          </div>
        </BookingStepsLayout>
      </div>
    </>
  );
};
