import { Col, Modal, Row } from 'antd';
import { useState } from 'react';
import { generatePharmacyAndLabArray } from './customHooks';

import 'mapbox-gl/dist/mapbox-gl.css';

import BookingDetailTab from './bookingDetailTab';
import { useMediaQuery } from 'react-responsive';
import Pharmacy from '../../../Global/Pharmacy';
import { useSelector } from 'react-redux';

export const ReviewPharmacyAndLab = () => {
  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const completeUser = useSelector(state => state.generalReducer.completeUser);

  const [isModal, setIsModal] = useState(false);
  const pharmacyAndLab = generatePharmacyAndLabArray(completeUser);

  return (
    <div className='reviewAllergiesWrap'>
      <div className='customReviewComponentHeading'>
        <p>Pharmacy</p>
        <button onClick={() => setIsModal(true)} className='noBgBtn'>
          Change
        </button>
      </div>
      <Row gutter={[20, 20]}>
        {pharmacyAndLab?.map((item, index) =>
          item?.question ? (
            <Col key={index} span={mobileResponsive ? 24 : 24}>
              <BookingDetailTab
                key={index}
                question={item?.question}
                answer={item?.answer}
              />
            </Col>
          ) : (
            ''
          )
        )}
        {isModal ? (
          <Modal
            centered
            title={`Update Pharmacy ${
              completeUser?.pharmacy?.StoreName
                ? '(' + completeUser?.pharmacy?.StoreName + ')'
                : ''
            }`}
            width='90%'
            open={isModal} // Change 'open' to 'visible'
            onCancel={() => setIsModal(false)}
            footer={false}
          >
            <Pharmacy />
          </Modal>
        ) : null}
      </Row>
    </div>
  );
};
