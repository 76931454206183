import React from "react";
import { BookingStepsLayout } from "../../Components/Booking/BookingStepsLayout/BookingStepsLayout";
import { ConsultationPreQues } from "../../Components/Booking/MedicalInformationSteps/ConsultationPreQues";
import { Steps } from "antd";
import { useSelector } from "react-redux";
import PatientBookingMedicalHistory from "../../Components/Booking/MainBookingSteps/PatientBookingMedicalHistory";
import PatientBookingSocialHistory from "../../Components/Booking/MainBookingSteps/PatientBookingSocialHistory";

export const ConsultationMedicalInfo = () => {

  const bookingData = useSelector((state)=>state?.booking)

  const steps = [
    // {
    //   title: 'Medical History',
    //   content: <ConsultationMedicalHistory userBookingId={bookingId} />,
    //   disabled: true
    // },
    // {
    //   title: 'Medical History',
    //   content: <PatientBookingMedicalHistory />,
    //   disabled: true
    // },
    // {
    //   title: 'Social History',
    //   content: <PatientBookingSocialHistory />,
    //   disabled: true
    // },
    // {
    //   title: 'Questionnaire',
    //   content: <ConsultationPreQues />,
    //   disabled: true
    // },
  ];


  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <BookingStepsLayout>
          <div className="mainConsultationBookingWrap">
            <div className="consultationSteps">
              <h2 style={{ textAlign: "center" }}>Questionnaire</h2>
              <ConsultationPreQues />
              {/* <Steps
                width='50%'
                current={bookingData?.bookingFlowMedialInfoSteps}
                items={steps}
                labelPlacement='vertical'
              />
              <div className='consultationStepsContent'>
                {steps?.[bookingData?.bookingFlowMedialInfoSteps]?.content}
              </div> */}

            </div>
          </div>
        </BookingStepsLayout>
      </div>
    </>
  );
};
