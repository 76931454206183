import React from 'react';
import classes from './SearchField.module.scss';
import { ReactSVG } from 'react-svg';

const SearchField = ({ placeholder, icon, background, onChange,value }) => {
  return (
    <div className={background ? classes.bgWhite : classes.bgColor}>
      <ReactSVG src={icon} />
      <input
        placeholder={placeholder}
        className={classes.field}
        type='search'
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default SearchField;
