import React, { useEffect, useState } from 'react';
import Header from '../../Components/Layouts/Header/Header';
import classes from '../MyConsultations/MyConsultations.module.scss';
import { consultDoc, medBottle, mobile } from '../../assets';
import SecondaryButton from '../../Components/UI/SecondaryButton/SecondaryButton';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { message } from 'antd';
import SkeletonInput from '../../Components/UI/Skeleton/skeletonInput';
import AllRefillsTable from '../../Components/Refills/AllRefillsTable';
import { getRequest } from '../../service/ApisFunctions';

const AllRefillsView = () => {
  const navigate = useNavigate();
  const [showMega, setShowMega] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refills, setRefills] = useState([]);
  const [nestedRefills, setNestedRefills] = useState([]);
  const getAllRefills = async () => {
    setLoading(true);

    const onSuccess = res => {
      setLoading(false);
      setRefills(res?.data);
      const nestedData = res?.data?.map((item)=> item?.refills)
      setNestedRefills(nestedData)
    };

    const onError = err => {
      message?.error(err?.message);
      setLoading(false);
    };

    await getRequest('', 'refill/get-user-refills', true, onSuccess, onError);
  };
 
  useEffect(() => {
    getAllRefills();
  }, []);
  return (
    <>
      <Header showMega={showMega} setShowMega={setShowMega} />
      <div className={classes.container} onClick={() => setShowMega(false)}>
        {/* <h1>Find the best doctor near you</h1>
        <p>1 Million + patients found doctors through Ohana Doc.</p>
        <div className={classes.card}>
          {cardsArray.map((el, index) => {
            return (
              <div key={index} className={classes.cardBody}>
                <div className={classes.content}>
                  <h3>{el.title}</h3>
                  <p className={classes.description}>{el.description}</p>
                  <SecondaryButton
                    onClick={() =>
                      index === 0
                        ? navigate('/virtualConsultation?type=medical')
                        : index === 1
                        ? navigate('/virtualConsultation?type=treatment')
                        : navigate('/consultation-booking')
                    }
                    className={
                      index === 0
                        ? classes.btnOrange
                        : index === 1
                        ? classes.btnPrimary
                        : classes.btnSuccess
                    }
                  >
                    {el.button}
                  </SecondaryButton>
                </div>
                <ReactSVG src={el.img} className={classes.images} />
              </div>
            );
          })}
        </div> */}
        <h2>Refills</h2>
        <div
          style={{
            paddingBottom: '20px',
            // width: '95%',
            marginInline: 'auto',
            marginTop: '20px'
          }}
        >
          {loading ? (
            [1, 2, 3]?.map(item => (
              <div style={{ marginTop: '10px' }}>
                <SkeletonInput block={true} />
              </div>
            ))
          ) : (
            <AllRefillsTable refills={refills} nestedRefills={nestedRefills} />
          )}
        </div>
      </div>
    </>
  );
};

export default AllRefillsView;
