import { Form, Input, Modal, Spin, message } from "antd";
import React, { useState } from "react";
import { postRequest} from "../../service/ApisFunctions";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";
import ApplyCoupon from "../ApplyCoupon";
import CouponAppled from "../ApplyCoupon/couponAppled";
import { useDispatch, useSelector } from "react-redux";
import { setBookingFlowCouponDetail } from "../../Redux/Reducers/bookingSlice";
import SingleConsentForm from "../ConsentForm/singleConsentForm";
import { consentForms } from "../../Utils/constant";
import { useMediaQuery } from "react-responsive";

export const SinglePayment = ({
  bookingDetails,
  offer,
  setIsPaymentModalOpen,
  getBookingDetailsById,
  customSetLoading,
}) => {
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [couponDetails, setCouponDetails] = useState({});


  const dispatch = useDispatch()

  const handleOk = () => {
    setIsModalOpen(false);
  };

    
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });
  
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );


  
  const bookingFlowCouponDetail = useSelector(
    (state) => state.booking?.bookingFlowCouponDetail
  );

  

  const handleExpiryDateChange = (event) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedInput = "";

    if (input.length >= 2) {
      const month = input.substr(0, 2);
      if (parseInt(month, 10) > 0 && parseInt(month, 10) <= 12) {
        formattedInput += month;

        const enteredYear = parseInt(input.substr(2, 2), 10);
        if (enteredYear >= 0 && enteredYear < 100) {
          formattedInput += " / " + input.substr(2, 2);
        } else if (input.length > 2) {
          formattedInput += " / " + input.substr(2, 4);
        }
      }
    } else {
      formattedInput += input;
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;

    const enteredYear =
      parseInt(input.substr(2, 2), 10) + currentYear - (currentYear % 100);
    const enteredMonth = parseInt(input.substr(0, 2), 10);

    setIsInvalidDate(
      isNaN(enteredYear) ||
        isNaN(enteredMonth) ||
        enteredYear < currentYear ||
        (enteredYear === currentYear && enteredMonth < currentMonth)
    );

    setIsExpired(
      enteredYear < currentYear ||
        (enteredYear === currentYear && enteredMonth < currentMonth)
    );
    setExpiryDate(formattedInput.trim());
  };

  const handleCardNumberChange = (event) => {
    const input = event?.target?.value?.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedInput = "";

    for (let i = 0; i < input.length; i += 4) {
      formattedInput += input.substr(i, 4) + " ";
    }

    setCardNumber(formattedInput.trim());
  };

  const applyDiscount = (price, discountType, discountValue) => {
    if (discountType === "value") {
      return price - discountValue;
    } else {
      const discountAmount = (discountValue / 100) * price;
      return price - discountAmount.toFixed(2);
    }
  };
  
  const finalPrice = bookingFlowCouponDetail?._id ? applyDiscount(offer?.payment, bookingFlowCouponDetail?.discountType, bookingFlowCouponDetail?.discountValue) : offer?.payment;

  
  const PayPayment = async (values) => {
    if ((cardNumber, cvc, finalPrice, expiryDate, !isExpired)) {
      setLoading(true);
      const onSuccess = (res) => {
        changePaymentStatus();
        setLoading(false);
        setIsPaymentModalOpen(false);
      };

      const onError = (res) => {
        setLoading(false);
        message.error(res?.error_message);
        setIsPaymentModalOpen(false);
      };

      const formData = {
        card_number: cardNumber?.replace(/\s/g, ""),
        card_expiration: expiryDate?.replace(/\s/g, "").replace("/", ""),
        card_code: cvc,
        amount: parseInt(finalPrice)?.toFixed()?.toString(2),
      };
      await postRequest(
        formData,
        "auth/authorized_card_payment",
        true,
        onSuccess,
        onError
      );
    } else {
      message.warning("Please enter valid card details");
    }
  };
  const changePaymentStatus = async () => {
    customSetLoading(true);
    const onSuccess = (res) => {
      customSetLoading(false);
      getBookingDetailsById();
      dispatch(setBookingFlowCouponDetail(null))
      setCouponDetails({})

    };
    const onError = (res) => {
      customSetLoading(false);
      message.error(res?.message);
    };
    const formData = {
      payment_status: true,
      payment: offer?.payment,
      booking_id: bookingDetails?._id,
      customerId: bookingDetails?.user_id,
      provider_id: bookingDetails?.provider_id?._id,
      total_sessions: offer?.total_sessions.toString(),
      totalDays: offer?.totalDays.toString(),
      description: offer?.description,
      pharmacy_id: bookingDetails?.pharmacy_id?._id,
      program: bookingDetails?.program?._id?._id,
      offerId: offer?._id,
      couponId: couponDetails?._id ? couponDetails?._id : "",

    };
    await postRequest(
      formData,
      `program_booking/add_booking`,
      true,
      onSuccess,
      onError
    );
  };

  const bookingType = bookingDetails?.booking_type == "video"
  ? "Medical consultation"
  : "Treatment programs";



  return (
    <div>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={PayPayment}>
          <div className="payment">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>Pay With Card</h3>
          </div>
            <Form.Item
              style={{ marginTop: "10px" }}
              label="Card No"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Card no"
                maxLength={"19"}
                onChange={handleCardNumberChange}
                className="ant-input-affix-wrapper"
                value={cardNumber}
              />
            </Form.Item>
            <Form.Item label="CVC" rules={[{ required: true }]}>
              <Input
                placeholder="CVC"
                type="number"
                value={cvc}
                onChange={(e) => {
                  setCVC((prev) => {
                    if (e.target.value.length === 4) {
                      return prev;
                    }
                    return e.target.value;
                  });
                }}
                className="ant-input-affix-wrapper"
              />
            </Form.Item>

            <Form.Item
              label="Expiry Date ( MM/YY )"
              rules={[
                { required: true, message: "Please enter a valid expiry date" },
              ]}
              validateStatus={isInvalidDate ? "error" : ""}
              help={isInvalidDate && "Please enter a valid expiry date"}
            >
              <Input
                placeholder="Expiry Date MM/YY"
                type="text"
                onChange={handleExpiryDateChange}
                value={expiryDate}
                className={
                  isExpired
                    ? "ant-input-affix-wrapper expired-color"
                    : "ant-input-affix-wrapper"
                }
              />
            </Form.Item>

            {bookingFlowCouponDetail?._id ? (
              <CouponAppled
                bookingPrice={offer?.payment}
                finalPrice={finalPrice}
              />
            ) : (

            
            <ApplyCoupon
            bookingType={bookingType}
            setLoading={setLoading}
            handleOk={handleOk}
            setCouponDetails={setCouponDetails}
            couponDetails={couponDetails}
            category={
              bookingDetails?.booking_type == "video"
                ? bookingDetails?.category?._id
                : bookingDetails?.program?._id
            }
            speciality={bookingDetails?.speciality?._id}
          />
            )}

         

            <div style={{ display: "flex", justifyContent: "center" ,marginTop:"20px"}}>
              <SecondaryButton
                className={
                  cvc.length === 3 &&
                  cardNumber.length == 19 &&
                  expiryDate.length === 7
                    ? "pay-btn"
                    : "disablePayBtn"
                }
                disabled={
                  cvc.length === 3 &&
                  cardNumber.length == 19 &&
                  expiryDate.length === 7
                    ? false
                    : true
                }
              >
                Confirm payment $
              {finalPrice}
              </SecondaryButton>
            </div>
          </div>
        </Form>

        {/* <Modal
        open={!completeUser?.isFinancialResponsibilityConsentAllowed && true}
        closable={false}
        width={mobileResponsive ? "90%" : "70%"}
        footer={false}
        centered
      >
        <SingleConsentForm consent={consentForms["financialResponsibility"]} type="financialResponsibility" />
      </Modal> */}
      </Spin>
    </div>
  );
};
