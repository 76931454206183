import "./joinOhana.scss";
import JoinAsProvider from "../JoinAsAProvider";
import Layouts from "../Layouts/Layout/Layouts";
import { Col, Row } from "antd";
import { LoginVector } from "../../assets";
import { useMediaQuery } from "react-responsive";

export const JoinOhana = () => {
  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  return (
    <Layouts>
      <Row className="joinOhanaWrapper">
        {!mobileResponsive && (
          <Col span={12}>
            <div className="left-side">
              <img src={LoginVector} alt="" />
            </div>
          </Col>
        )}
        <Col span={ mobileResponsive ? 24 : 12}>
          <div className="right-side">
            <div className="joinOhanaWrapper">
              <h2>
                Join as a <span>Provider</span>
              </h2>
            </div>
            <div className="formArea">
              <JoinAsProvider />
            </div>
          </div>
        </Col>
      </Row>
    </Layouts>
  );
};
