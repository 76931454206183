import React, { useState } from 'react';
import classes from '../../TestingsTreatments.module.scss';
import SecondaryButton from '../../../UI/SecondaryButton/SecondaryButton';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const TreatmentPlans = ({
  data,
  dite,
  description1,
  description2,
  background
}) => {
  const [active, setActive] = useState(0);
  const navigate = useNavigate();

  return (
    <>
      {data
        ? data.map((el, index) => {
            return (
              <div
                className={`${classes.container} 
                      ${background ? classes.bgWhite : classes.bgBlue}`}
              >
                <div
                  className={classes.title}
                  onClick={() => setActive(index)}
                ></div>
                {active === index ? (
                  <div className={classes.description}>
                    <div
                      className={`${classes.header} 
                      ${background ? classes.bgBlue : classes.bgWhite}`}
                    >
                      <div>
                        <h3>{el.title}</h3>
                        <p>{el.description}</p>
                        <SecondaryButton
                          className={classes.button}
                          children='Get Started'
                          onClick={() => navigate('/consultation-booking')}
                        />
                      </div>
                      <ReactSVG src={el.img} />
                    </div>
                    <div
                      className={`${classes.bodyContent} 
                      ${background ? classes.bgBlue : classes.bgWhite}`}
                    >
                      <h5>{el.title1}</h5>
                      {description1.map(des => {
                        return (
                          <>
                            <p>{des.description}</p>
                          </>
                        );
                      })}
                    </div>
                    <div
                      className={`${classes.bodyContent} 
                      ${background ? classes.bgBlue : classes.bgWhite}`}
                    >
                      <h5>{el.title2}</h5>
                      {description2.map(des => {
                        return (
                          <>
                            <p>{des.description}</p>
                          </>
                        );
                      })}
                    </div>
                    <div
                      className={`${classes.bodyContent} 
                      ${background ? classes.bgBlue : classes.bgWhite}`}
                    >
                      <h5>{el.title3}</h5>
                      {dite.map(obj => {
                        return (
                          <>
                            <p>
                              <span>{obj.heading}</span> {obj.description}
                            </p>
                          </>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })
        : ''}
    </>
  );
};

export default TreatmentPlans;
