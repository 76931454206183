import { useState } from "react";

import "./landingPage.scss";
import { HeroSection } from "../../Components/LandingPage2/heroSection";
import HowItWorks from "../../Components/LandingPage2/howItWorks";
import Header from "../../Components/Layouts/Header/Header";
import Footer from "../../Components/Layouts/Footer/Footer";
import { Faqs } from "../../Components/LandingPage2/Faqs";
import { AboutOhana } from "../../Components/LandingPage2/AboutOhana";
import { PatientReviews } from "../../Components/LandingPage2/PatientReviews";

export const LandingPage = () => {
  const [showMega, setShowMega] = useState(false);



  return (
    <div className="landingPageWrap">
      <Header showMega={showMega} setShowMega={setShowMega} />
      <HeroSection />
      <HowItWorks />
      <AboutOhana />
      <Faqs />
      <PatientReviews/>
      <Footer />

      {/* Update Profile */}
    </div>
  );
};
