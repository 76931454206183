import { useState } from "react";
import classes from "./ContactUs.module.scss";
import { Col, Form, Input, Row, Spin, message } from "antd";
import { postRequest } from "../../service/ApisFunctions";
import SecondaryButton from "../../Components/UI/SecondaryButton/SecondaryButton";
import Header from "../../Components/Layouts/Header/Header";

const ContactUs = () => {
  const [form] = Form.useForm();

  const [showMega, setShowMega] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentYear = new Date().getFullYear();

  const handleSubmit = async (e) => {
    setLoading(true);
    const onSuccess = (res) => {
      setLoading(false);
      message.success(res?.message);
      form.resetFields();
    };

    const onError = (err) => {
      setLoading(false);
      message.error(err?.message);
    };

    const formdata = {
      email: e?.email,
      subject: e?.subject,
      description: e?.message,
    };

    await postRequest(formdata, `contact/contact_us`, true, onSuccess, onError);
  };
  return (
    <Spin spinning={loading}>
      <Header showMega={showMega} setShowMega={setShowMega} />
      <div className={classes.container} onClick={() => setShowMega(false)}>
        <h1>Contact us</h1>
        <p className={classes.description}>
          Send us your questions & concerns. We'll give you the help you need.
        </p>
        <Form
          style={{ marginTop: "50px" }}
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Email Address",
                    type: "email",
                  },
                ]}
              >
                <Input
                  placeholder="Email Address"
                  type="email"
                  className="ant-input-affix-wrapper"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  { required: true, message: "Please Enter Your Subject" },
                ]}
              >
                <Input
                  placeholder="Subject"
                  type="text"
                  className="ant-input-affix-wrapper"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="message"
                label="Message"
                rules={[
                  { required: true, message: "Please Enter Your Message" },
                ]}
              >
                <Input.TextArea
                  placeholder="Message"
                  type="text"
                  className="ant-input-affix-wrapper"
                />
              </Form.Item>
            </Col>
            <Col style={{ textAlign: "center" }} span={24}>
              <SecondaryButton className={classes.msgButton} type="submit">
                Send Message
              </SecondaryButton>
            </Col>
          </Row>
        </Form>
        <p className={classes.rights}>
          © {currentYear} OhanaDoc. All rights reserved.
        </p>
      </div>
    </Spin>
  );
};

export default ContactUs;
