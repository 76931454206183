import React, { useState, useEffect } from "react";
import "./CustomDesignedDatePicker.scss";
import { Col, Input, Row, Select, Space } from "antd";
import dayjs from "dayjs";

const CustomDesignedDatePicker = ({ form, field, defaultValue }) => {
  const currentYear = new Date().getFullYear();
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [error, setError] = useState("");

  const daysInMonth = (month, year) => {
    return new Date(year, parseInt(month) + 1, 0).getDate();
  };

  const validateDate = (month, day, year) => {
    if (!month || !day || !year) {
      return {
        date: null,
        error: "",
      };
    }

    // Check if the year is in the valid range
    if (parseInt(year) < 1900 || parseInt(year) > currentYear) {
      return {
        date: null,
        error: `Year must be between 1900 and ${currentYear}.`,
      };
    }

    // Check if the day is valid for the selected month and year
    const maxDays = daysInMonth(month, year);
    if (parseInt(day) < 1 || parseInt(day) > maxDays) {
      return {
        date: null,
        error: `Invalid day. ${maxDays} days in the selected month.`,
      };
    }

    const date = dayjs(new Date(year, month, day));
    if (!date.isValid()) {
      return {
        date: null,
        error: "Invalid date. Please enter a valid date.",
      };
    }

    return {
      date: date,
      error: "",
    };
  };

  useEffect(() => {
    if (month && day && year) {
      const { date, error } = validateDate(month, day, year);
      if (error) {
        setError(error);
        form.setFieldsValue({
          [field]: null,
        });
      } else {
        form.setFieldsValue({
          [field]: date,
        });
        setError("");
      }
    }
  }, [month, day, year]);

  useEffect(() => {
    if (defaultValue) {
      const date = dayjs(defaultValue);
      setMonth(date.month().toString());
      setDay(date.date().toString());
      setYear(date.year().toString());
    }
  }, [defaultValue]);

  const restrictToNumbers = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const handleYearChange = (e) => {
    const value = e.target.value;
    if (value === "" || (value.length <= 4 && parseInt(value) <= 2100)) {
      setYear(value);
    }
  };

  return (
    <div className="customDesignedDatePickerWrapper">
      <Row gutter={16}>
        <Col span={24}>
          <Space.Compact size="small" style={{ width: "100%" }}>
            <Select
              style={{ width: "50%" }}
              placeholder="Month"
              value={month}
              onChange={(value) => setMonth(value)}
              options={[
                { value: "0", label: "January" },
                { value: "1", label: "February" },
                { value: "2", label: "March" },
                { value: "3", label: "April" },
                { value: "4", label: "May" },
                { value: "5", label: "June" },
                { value: "6", label: "July" },
                { value: "7", label: "August" },
                { value: "8", label: "September" },
                { value: "9", label: "October" },
                { value: "10", label: "November" },
                { value: "11", label: "December" },
              ]}
            />
            <Input
              style={{ width: "25%" }}
              placeholder="Day"
              value={day}
              onKeyPress={restrictToNumbers}
              onChange={(e) => setDay(e.target.value)}
              type="number"
            />
            <Input
              style={{ width: "25%" }}
              placeholder="Year"
              value={year}
              onKeyPress={restrictToNumbers}
              onChange={handleYearChange}
              type="number"
              maxLength={4}
            />
          </Space.Compact>
        </Col>
      </Row>
      {error && (
        <Row>
          <Col span={24}>
            <span className="error-message">{error}</span>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CustomDesignedDatePicker;
