import { Col, DatePicker, Form, Input, Row, Select, Spin, message } from "antd";
import { useEffect, useState } from "react";
import "./personalInfo.scss";
import SecondaryButton from "../../UI/SecondaryButton/SecondaryButton";
import { ReactSVG } from "react-svg";
import {
  disabledFutureDate,
  formatHeight,
  formatPhoneNumber,
  validateHeight,
  ValidateMaxLength,
  ValidateMaxLengthAndContent,
  validatePhoneNumber,
  validateWeight,
} from "../../../Utils/helper";
import { getRequest, putRequest } from "../../../service/ApisFunctions";
import dayjs from "dayjs";
import { useGeolocated } from "react-geolocated";
import { useDispatch } from "react-redux";
import {
  AllLanguages,
  EthnicityList,
  HeightUnit,
  PhoneTypes,
  RaceList,
  WeightUnit,
  birthGendersList,
  gendersList,
  pronounList,
  sexualOrientation,
} from "../../../Utils/constant";
import { calendarSimple } from "../../../assets";
import { useMediaQuery } from "react-responsive";
import { setCompleteUser } from "../../../Redux/Reducers/gernalSlice";
import CustomDesignedDatePicker from "../../UI/CustomDesignedDatePicker/CustomDesignedDatePicker";

const PersonalInfo = ({ completeUser }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const [loading, setLoading] = useState(false);
  const [allState, setAllState] = useState([]);
  const [profileHeightUnit, setProfileHeightUnit] = useState(1);
  const [profileWeightUnit, setProfileWeightUnit] = useState(1);
  const [profileLocation, setProfileLocation] = useState({
    address: "",
    coordinates: [],
  });

  const handleSubmit = async (e) => {
    const currentYear = new Date().getFullYear();
    const patientAge = new Date(e?.date_of_birth?.$d).getFullYear();
    if (18 <= currentYear - patientAge) {
      setLoading(true);
      const onSuccess = (res) => {
        setLoading(false);
        message.success(res?.message);
        dispatch(setCompleteUser(res?.data));
      };
      const onError = (err) => {
        if (err?.message?.Message) {
          const modelState = err.message.ModelState;
          for (const key in modelState) {
            message.error(`${key}: ${modelState[key]}`);
          }
        } else {
          message.error(err.message);
        }
        setLoading(false);
      };
      const filterPhoneTypeByLable = PhoneTypes.find(
        (item) => item?.id === e?.PrimaryPhoneType
      );
      const formdata = {
        first_name: e?.first_name,
        last_name: e?.last_name,
        middle_name: e?.middle_name,
        prefix: e?.prefix,
        suffix: e?.suffix,
        location: profileLocation,
        language: e?.language,
        dob: e?.date_of_birth,
        height: { value: e?.height, unit: 3 },
        weight: { value: e?.weight, unit: 1 },
        state: e?.state,
        ZipCode: e?.ZipCode,
        City: e?.City,
        PrimaryPhoneType: filterPhoneTypeByLable?.id,
        sexualOrientation: e?.sexualOrientation,
        pronoun: e?.pronoun,
        birthGender: e?.birthGender,
        gender: e?.gender,
        ethnicity: e?.ethnicity,
        race: e?.race,
        isProfileFilled: completeUser?.pharmacy?.StoreName ? true : false,
        phone_number: e?.phone_number,
      };
      await putRequest(
        formdata,
        "customer/update_customer",
        true,
        onSuccess,
        onError
      );
    } else {
      message.warning("Your age must be 18+ to join Ohana Doc");
    }
  };
  const getAllSatates = async () => {
    const onSuccess = (res) => {
      setAllState(res?.data);
    };

    const onError = (err) => {
      message.error(err?.message);
    };

    await getRequest(
      "",
      "state/active_state_listing",
      true,
      onSuccess,
      onError
    );
  };
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  useEffect(() => {
    if (completeUser?._id) {
      const filterPhoneTypeById = PhoneTypes.find(
        (item) => item?.id == completeUser?.PrimaryPhoneType
      );
      form.setFieldsValue({
        first_name: completeUser?.first_name || "",
        last_name: completeUser?.last_name || "",
        middle_name: completeUser?.middle_name || "",
        prefix: completeUser?.prefix || "",
        suffix: completeUser?.suffix || "",
        address: completeUser?.location?.address || "",
        language: completeUser?.language || [],
        date_of_birth: completeUser?.dob ? dayjs(completeUser?.dob) : null,
        height: completeUser?.height?.value || "",
        heightUnit: 3,
        weight: completeUser?.weight?.value || "",
        weightUnit: 1,
        state: completeUser?.state || null,
        sexualOrientation: completeUser?.sexualOrientation || null,
        pronoun: completeUser?.pronoun || null,
        birthGender: completeUser?.birthGender || null,
        gender: completeUser?.gender || null,
        ethnicity: completeUser?.ethnicity || null,
        race: completeUser?.race || null,
        phone_number: completeUser?.phone_number || null,
        ZipCode: completeUser?.ZipCode || "",
        City: completeUser?.City || "",
        PrimaryPhoneType: filterPhoneTypeById?.id || null,
      });
      setProfileHeightUnit(parseInt(completeUser?.height?.unit) || 1);
      setProfileWeightUnit(parseInt(completeUser?.weight?.unit) || 1);
      setProfileLocation(completeUser?.location);
      getAllSatates();
    } else {
      form.setFieldsValue({
        weightUnit: 1,
        heightUnit: 3,
      });
    }
  }, [completeUser]);
  const handleChangePhoneNumberPattren = (e) => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);

    form.setFieldsValue({
      phone_number: formatted,
    });
  };
  const handleChangeHeight = (e) => {
    const input = e.target.value;
    const formatted = formatHeight(input);
    form.setFieldsValue({
      height: formatted,
    });
  };
  const height = Form.useWatch("height", form);
  const weight = Form.useWatch("weight", form);

  const calculateBMI = (height, weight) => {
    if (!height || !weight || height.length < 3) {
      form.setFieldsValue({
        bmi: "",
      });
      return;
    }
    const split_height = height.split("'");
    const feet = parseInt(split_height[0], 10);
    const inches = parseInt(split_height[1], 10);
    const heightInInches = feet * 12 + inches;
    const heightInMeters = heightInInches * 0.0254;
    const weightInKg = weight * 0.453592;
    const bmi = weightInKg / (heightInMeters * heightInMeters);
    form.setFieldsValue({
      bmi: bmi.toFixed(1),
    });
  };
  useEffect(() => {
    calculateBMI(height, weight);
  }, [height, weight]);
  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        className="personalInfo"
      >
        <Row gutter={[10, 0]}>
          <Col className="personalInfoHeader" span={24}>
            <h2>Personal info</h2>
          </Col>
          <Col span={3}>
            <Form.Item
              name="prefix"
              label="Prefix"
              rules={[{ required: false }, ValidateMaxLength(10, "Prefix")]}
            >
              <Input
                className="ant-input-affix-wrapper"
                placeholder="Enter your prefix"
              />
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 6}>
            <Form.Item
              name="first_name"
              label="First name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
                ValidateMaxLengthAndContent(35, "First Name"),
              ]}
            >
              <Input
                className="ant-input-affix-wrapper"
                placeholder="Enter your First Name"
              />
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 6}>
            <Form.Item
              name="middle_name"
              label="Middle name"
              rules={[
                { required: false },
                ValidateMaxLengthAndContent(35, "Middle Name"),
              ]}
            >
              <Input
                className="ant-input-affix-wrapper"
                placeholder="Enter your Middle Name"
              />
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 6}>
            <Form.Item
              name="last_name"
              label="Last name"
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                },
                ValidateMaxLengthAndContent(35, "Last name")
              ]}
            >
              <Input
                className="ant-input-affix-wrapper"
                placeholder="Enter your Last Name"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
          <Form.Item
            name="suffix"
            label="Suffix"
            rules={[{ required: false }, ValidateMaxLength(10, "Suffix")]}
          >
            <Input
              className="ant-input-affix-wrapper"
              placeholder="Enter your suffix"
            />
          </Form.Item>
        </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="address"
              label="Street"
              rules={[
                {
                  required: true,
                  message: "Please enter street",
                },
                ValidateMaxLength(35, "Street")
              ]}
            >
              <Input
                className="ant-input-affix-wrapper"
                placeholder="Enter street"
                onChange={(e) =>
                  setProfileLocation({
                    address: e.target.value,
                    coordinates: [
                      coords?.longitude ? coords?.longitude : 0,
                      coords?.latitude ? coords?.latitude : 0,
                    ],
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="City"
              label="City"
              rules={[
                { required: true, message: "Please enter city" },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "City must contain only alphabets",
                },
                ValidateMaxLength(35, "City")
              ]}
            >
              <Input
                autoComplete="off"
                className="ant-input-affix-wrapper"
                placeholder="Please enter city"
              />
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: "Please enter state",
                },
                ValidateMaxLength(10, "State")
              ]}
            >
              <Select
                maxLength={2}
                className="ant-select-selector"
                placeholder="State"
              >
                {allState?.map((item, index) => (
                  <Select.Option key={index} value={item?.state}>
                    {item?.state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="ZipCode"
              label="Zip Code"
              rules={[
                { required: true, message: "Please enter only digits" },
                {
                  pattern: /^[0-9]{5}$/,
                  message: "Zip Code must be exactly 5 digits",
                },
              ]}
            >
              <Input
                type="number"
                maxLength={5}
                autoComplete="off"
                className="ant-input-affix-wrapper"
                placeholder="Please enter zip code"
              />
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="language"
              label="Language"
              rules={[
                { required: true, message: "Please select your language" },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                className="ant-select-selector"
                placeholder="language"
              >
                {AllLanguages?.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="date_of_birth"
              label="Date Of Birth"
              rules={[
                {
                  required: true,
                  message: "Please select your date of birth",
                },
              ]}
            >
              <CustomDesignedDatePicker
                field={"date_of_birth"}
                form={form}
                defaultValue={completeUser?.dob}
              />
              {/* <DatePicker
                disabledDate={disabledFutureDate}
                className='ant-input-affix-wrapper'
                placeholder='Date of birth'
                suffixIcon={<ReactSVG width='20px' src={calendarSimple} />}
                format='MM-DD-YYYY'
              /> */}
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 12 : 6}>
            <div style={{ display: "flex", gap: "10px" }}>
              <Form.Item
                name="height"
                label="Height"
                rules={[
                  { required: true },
                  { validator: validateHeight(profileHeightUnit) },
                ]}
              >
                <Input
                  addonBefore="Ft'In"
                  placeholder="Height"
                  onChange={handleChangeHeight}
                />
              </Form.Item>

              {/* <Form.Item
                style={{ width: '30%' }}
                name='heightUnit'
                label='Unit'
                rules={[{ required: true, message: 'Please select unit' }]}
              >
                <Select
                  onChange={e => {
                    setProfileHeightUnit(e);
                  }}
                  className='ant-select-selector'
                  placeholder='Unit'
                >
                  {HeightUnit?.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      {item?.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
            </div>
          </Col>
          <Col span={mobileResponsive ? 12 : 6}>
            <div style={{ display: "flex", gap: "10px" }}>
              <Form.Item
                name="weight"
                label="Weight"
                rules={[
                  { required: true },
                  { validator: validateWeight(profileWeightUnit) },
                ]}
              >
                <Input
                  addonBefore="Lbs"
                  type="number"
                  placeholder="Enter your weight"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="bmi"
              label="BMI"
              rules={[
                {
                  required: false,
                  message: "Please select phone type",
                },
              ]}
            >
              <Input placeholder="BMI" disabled />
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="PrimaryPhoneType"
              label="Phone type"
              rules={[
                {
                  required: true,
                  message: "Please select phone type",
                },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Select phone type"
              >
                {PhoneTypes?.map((item, index) => (
                  <Select.Option key={index} value={item?.id}>
                    {item?.label || "-"}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[
                {
                  validator: validatePhoneNumber,
                },
              ]}
            >
              <Input
                min={0}
                // type='number'
                onChange={handleChangePhoneNumberPattren}
                className="ant-input-affix-wrapper"
                placeholder="Enter your phone number"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col
            style={{ marginTop: "10px" }}
            className="healthEquityInfoHeader"
            span={24}
          >
            <h2>Health Equity info</h2>
          </Col>

          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="sexualOrientation"
              label="Sexual orientation"
              rules={[
                {
                  required: true,
                  message: "Please enter sexual orientation",
                },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Please select sexual orientation"
              >
                {sexualOrientation.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="pronoun"
              label="Pronouns"
              rules={[
                {
                  required: true,
                  message: "Please enter pronouns",
                },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Please select pronouns"
              >
                {pronounList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="birthGender"
              label="Birth gender"
              rules={[
                {
                  required: true,
                  message: "Please select your birth gender",
                },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Birth Gender"
              >
                {birthGendersList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="gender"
              label="Current gender"
              rules={[
                {
                  required: true,
                  message: "Please select your gender",
                },
              ]}
            >
              <Select className="ant-select-selector" placeholder="Gender">
                {gendersList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="ethnicity"
              label="Ethnicity"
              rules={[
                {
                  required: true,
                  message: "Please select your ethnicity",
                },
              ]}
            >
              <Select
                mode="multiple"
                className="ant-select-selector"
                placeholder="ethnicity"
              >
                {EthnicityList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="race"
              label="Race"
              rules={[
                {
                  required: true,
                  message: "Please select your race",
                },
              ]}
            >
              <Select className="ant-select-selector" placeholder="Race">
                {RaceList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
            className="personalInfoHeader"
            span={24}
          >
            <SecondaryButton disabled={loading}>Update</SecondaryButton>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default PersonalInfo;
