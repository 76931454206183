import { Empty, Select, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

export const DebounceSelect = ({
  fetchOptions,
  debounceTimeout = 800,
  selectedMedication,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      setOptions([]);
      if (value?.length >= 3) {
        setFetching(true);

        fetchOptions(value)
          .then((newOptions) => {
            setOptions(newOptions);
            setFetching(false);
          })
          .catch((err) => {  // Corrected here
            setFetching(false);
            console.log(err);
          });
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  
  return (
    <Select
      style={{ width: '100%' }}
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size='small' /> : <Empty />}
      {...props}
      options={options}
      value={selectedMedication}
    />
  );
}