import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  refillSpeciality: '',
  refillCondition: '',
  refillPrescriptions: [],
  refillServiceMedications: [],
  refillMedications: [],
  refillMedicationsPrice : ""
};

const refillSlice = createSlice({
  name: 'refills',
  initialState: initialState,
  reducers: {
    resetRefillState: () => initialState,
    setRefillSpeciality: (state, action) => {
      state.refillSpeciality = action.payload;
    },
    setRefillCondition: (state, action) => {
      state.refillCondition = action.payload;
    },
    setRefillPrescriptions: (state, action) => {
      state.refillPrescriptions = action.payload;
    },
    setServiceMedications: (state, action) => {
      state.refillServiceMedications = action.payload;
    },
    setRefillMedications :(state,action)=>{
      state.refillMedications = action.payload
    },
    setRefillMedicationsPrice :(state,action)=>{
      state.refillMedicationsPrice = action.payload
    }
  }
});

export const {
  setRefillSpeciality,
  setRefillCondition,
  setRefillPrescriptions,
  resetRefillState,
  setServiceMedications,
  setRefillMedications,
  setRefillMedicationsPrice
} = refillSlice.actions;
export const refillReducer = refillSlice.reducer;
