import { Col, Form, Row, Select, Spin, message } from "antd";
import { useState } from "react";
import "../MyAccount/personalInfo.scss";
import SecondaryButton from "../../UI/SecondaryButton/SecondaryButton";
import { putRequest } from "../../../service/ApisFunctions";
import { useDispatch } from "react-redux";
import {
  EthnicityList,
  RaceList,
  birthGendersList,
  pronounList,
  sexualOrientation,
} from "../../../Utils/constant";
import { useMediaQuery } from "react-responsive";
import { setCompleteUser } from "../../../Redux/Reducers/gernalSlice";

const HealthEquityInfoPopup = ({completeUser,setProfileStep}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const mobileResponsive = useMediaQuery({
    query: "(max-width: 800px)",
  });


  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    const onSuccess = (res) => {
      setLoading(false);
      message.success(res?.message);
      dispatch(setCompleteUser(res?.data));
      setProfileStep(2)

    };
    const onError = (err) => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setLoading(false);
    };

    const formdata = {
      sexualOrientation: e?.sexualOrientation,
      pronoun: e?.pronoun,
      birthGender: e?.birthGender,
      ethnicity: e?.ethnicity,
      race: e?.race,
      language: completeUser?.language,
      height: completeUser?.height,
      weight: completeUser?.weight,
      first_name: completeUser?.first_name,
      last_name: completeUser?.last_name,
      middle_name: completeUser?.middle_name,
      location: completeUser?.location,
      dob: completeUser?.dob,
      state: completeUser?.state,
      ZipCode: completeUser?.ZipCode,
      City: completeUser?.City,
      PrimaryPhoneType: completeUser?.PrimaryPhoneType,
      gender: completeUser?.gender,
      isProfileFilled: completeUser?.pharmacy?.StoreName && completeUser?.past_medical_history?.date ? true : false,
      phone_number: completeUser?.phone_number,
    };
    await putRequest(
      formdata,
      "customer/update_customer",
      true,
      onSuccess,
      onError
    );
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        className="personalInfo"
      >

        <Row gutter={10}>
          <Col
            style={{ marginTop: "10px" }}
            className="healthEquityInfoHeader"
            span={24}
          >
            <h2>Health Equity info</h2>
          </Col>

          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="sexualOrientation"
              label="Sexual orientation"
              rules={[
                {
                  required: true,
                  message: "Please enter sexual orientation",
                },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Please select sexual orientation"
              >
                {sexualOrientation.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="pronoun"
              label="Pronouns"
              rules={[
                {
                  required: true,
                  message: "Please enter pronouns",
                },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Please select pronouns"
              >
                {pronounList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="birthGender"
              label="Birth gender"
              rules={[
                {
                  required: true,
                  message: "Please select your birth gender",
                },
              ]}
            >
              <Select
                className="ant-select-selector"
                placeholder="Birth Gender"
              >
                {birthGendersList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name="ethnicity"
              label="Ethnicity"
              rules={[
                {
                  required: true,
                  message: "Please select your ethnicity",
                },
              ]}
            >
              <Select
                mode="multiple"
                className="ant-select-selector"
                placeholder="ethnicity"
              >
                {EthnicityList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={mobileResponsive ? 24 : 24}>
            <Form.Item
              name="race"
              label="Race"
              rules={[
                {
                  required: true,
                  message: "Please select your race",
                },
              ]}
            >
              <Select className="ant-select-selector" placeholder="Race">
                {RaceList.map((item, index) => (
                  <Select.Option key={index} value={item?.name}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col style={{display:"flex",justifyContent:"center"}} span={24}>
            <SecondaryButton disabled={loading}>Next</SecondaryButton>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default HealthEquityInfoPopup;
