import React from "react";
import { DomyD, ExprienceBagIcon } from "../../../../assets";
import moment from "moment";
import "./singleDoctorCard.scss";
import BlueButton from "../../../UI/BlueButton/BlueButton";
import { useDispatch } from "react-redux";
import { setBookingFlowDoctor } from "../../../../Redux/Reducers/bookingSlice";

const SingleDoctorCard = ({ setSelectedDoctor, doctor }) => {
  const dispatch = useDispatch();


  return (
    <div className="singleDoctorCard">
      <div className="singleDoctorCardImage">
        <img
          src={doctor?.profile_image ? doctor?.profile_image : DomyD}
          alt="$$$"
        />
      </div>
      <h2>
        {`${doctor?.first_name || "-"} ${doctor?.middle_name || ""} ${
          doctor?.last_name || "-"
        }`}
        <span>
          (
          {doctor?.ratings?.averageRating
            ? (doctor?.ratings?.averageRating).toFixed(2)
            : 0}
          )
        </span>
      </h2>

      <div className="exprienceSeccion">
        <img src={ExprienceBagIcon} alt="$$$" />
        <p>{doctor?.specialities?.[0]?.speciality_name || ""}</p>
      </div>
      <div className="exprienceSeccion">
        <img src={ExprienceBagIcon} alt="$$$" />
        <p>
          {moment(new Date()).format("YYYY") -
            moment(doctor?.experience?.[0]?.start_date).format("YYYY") ||
            "--"}{" "}
          years
        </p>
      </div>
      <BlueButton
        onClick={() => {
          dispatch(setBookingFlowDoctor(doctor));
          setSelectedDoctor(doctor);
        }}
        width="100%"
      >
        Book Now
      </BlueButton>
    </div>
  );
};

export default SingleDoctorCard;
