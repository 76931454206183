import React from 'react';
import './BackButton.scss';
import { arrowBack } from '../../../assets';

const BackButton = ({
  onClick,
  className,
  children,
  type,
  width,
  disabled,
  btnRef
}) => {
  return (
    <button
      style={width ? { width: width } : {}}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`back-button ${className} `}
      ref={btnRef}
    >
      <img src={arrowBack} alt="" />
      {children}
    </button>
  );
};

export default BackButton;
