// local Urls
// export const BASE_URL = 'http://192.168.18.22:4000/api/';
// export const IMAGE_URL = 'http://192.168.18.22:4000';
// export const S3_IMAGE_URL = 'https://dubaidesertsafarigo.com';
// export const LOGIN_AS_A_PROVIDER = 'https://dubaidesertsafarigo.com/provider/login';
// export const DEPLOYMENT_MODE = 'dev';

// development
// export const IMAGE_URL = 'https://dubaidesertsafarigo.com';
// export const BASE_URL = 'https://dubaidesertsafarigo.com/api/';
// export const S3_IMAGE_URL = 'https://dubaidesertsafarigo.com';
// export const LOGIN_AS_A_PROVIDER = 'https://dubaidesertsafarigo.com/provider/login';
// export const DEPLOYMENT_MODE = 'dev';   

// production
export const IMAGE_URL = 'https://ohanadoc.com';
export const BASE_URL = 'https://ohanadoc.com/api/';
export const S3_IMAGE_URL = 'https://ohanadoc.s3.amazonaws.com';
export const LOGIN_AS_A_PROVIDER = "https://ohanadoc.com/provider/login"
export const DEPLOYMENT_MODE = "prod"
