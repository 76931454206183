import React, { useEffect, useRef } from "react";
import { Input } from "antd";

const OTPInput = ({ value = "", onChange, className }) => {
  const inputs = Array(6).fill(0);
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    if (newValue.length <= 1) {
      const newValueArray = value.split("");
      newValueArray[index] = newValue;
      onChange(newValueArray.join(""));

      if (newValue && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !value[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text").replace(/[^0-9]/g, '');
    if (paste.length === 6) {
      onChange(paste);
      inputRefs.current[5].focus();
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {inputs.map((_, index) => (
        <Input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          maxLength={1}
          value={value[index] || ""}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          className={className}
          style={{ width: "3rem", textAlign: "center", marginInline: "0px" }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
