import React from "react";
import "./bio.scss";

const Bio = ({title,text}) => {
  return (
    <div className="bio">
      <h2>{title}</h2>
      <div className="bioOptions">
        <p>{text}</p>
      </div>
      
    </div>
  );
};

export default Bio;
