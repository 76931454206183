import Pharmacy from '../../Global/Pharmacy';

const PharmacyPopup = ({ completeUser }) => {
  return (
    <>
      <Pharmacy />
    </>
  );
};

export default PharmacyPopup;
