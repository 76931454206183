import { Col, Form, Input, Row, Select, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import '../MyAccount/personalInfo.scss';
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton';
import {
  formatHeight,
  validateHeight,
  validateWeight
} from '../../../Utils/helper';
import { putRequest } from '../../../service/ApisFunctions';
import { useDispatch } from 'react-redux';
import { AllLanguages, HeightUnit, WeightUnit } from '../../../Utils/constant';
import { useMediaQuery } from 'react-responsive';
import { setCompleteUser } from '../../../Redux/Reducers/gernalSlice';

const PersonalInfoPopup = ({ completeUser, setProfileStep }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const mobileResponsive = useMediaQuery({
    query: '(max-width: 800px)'
  });

  const [loading, setLoading] = useState(false);
  const [profileHeightUnit, setProfileHeightUnit] = useState(3);
  const [profileWeightUnit, setProfileWeightUnit] = useState(1);

  const handleSubmit = async e => {
    setLoading(true);
    const onSuccess = res => {
      setLoading(false);
      message.success(res?.message);
      dispatch(setCompleteUser(res?.data));
      setProfileStep(1);
    };
    const onError = err => {
      if (err?.message?.Message) {
        const modelState = err.message.ModelState;
        for (const key in modelState) {
          message.error(`${key}: ${modelState[key]}`);
        }
      } else {
        message.error(err.message);
      }
      setLoading(false);
    };

    const formdata = {
      language: e?.language,
      height: { value: e?.height, unit: 3 },
      weight: { value: e?.weight, unit: 1 },
      first_name: completeUser?.first_name,
      last_name: completeUser?.last_name,
      middle_name: completeUser?.middle_name,
      location: completeUser?.location,
      dob: completeUser?.dob,
      state: completeUser?.state,
      ZipCode: completeUser?.ZipCode,
      City: completeUser?.City,
      PrimaryPhoneType: completeUser?.PrimaryPhoneType,
      sexualOrientation: completeUser?.sexualOrientation,
      pronoun: completeUser?.pronoun,
      birthGender: completeUser?.birthGender,
      gender: completeUser?.gender,
      ethnicity: completeUser?.ethnicity,
      race: completeUser?.race,
      phone_number: completeUser?.phone_number
    };
    await putRequest(
      formdata,
      'customer/update_customer',
      true,
      onSuccess,
      onError
    );
  };
  const handleChangeHeight = e => {
    const input = e.target.value;
    const formatted = formatHeight(input);
    form.setFieldsValue({
      height: formatted
    });
  };
  const height = Form.useWatch('height', form);
  const weight = Form.useWatch('weight', form);

  const calculateBMI = (height, weight) => {
    if (!height || !weight || height.length < 3) {
      form.setFieldsValue({
        bmi: ''
      });
      return;
    }
    const split_height = height.split("'");
    const feet = parseInt(split_height[0], 10);
    const inches = parseInt(split_height[1], 10);
    const heightInInches = feet * 12 + inches;
    const heightInMeters = heightInInches * 0.0254;
    const weightInKg = weight * 0.453592;
    const bmi = weightInKg / (heightInMeters * heightInMeters);
    form.setFieldsValue({
      bmi: bmi.toFixed(1)
    });
  };
  useEffect(() => {
    calculateBMI(height, weight);
  }, [height, weight]);
  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout='vertical'
        className='personalInfo'
      >
        <Row gutter={[10, 0]}>
          <Col className='personalInfoHeader' span={24}>
            <h2>Personal info</h2>
          </Col>
          {/* <Col span={mobileResponsive ? 24 : 12}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item
                style={{ width: '70%' }}
                name='height'
                label='Height'
                rules={[
                  { required: true },
                  { validator: validateHeight(profileHeightUnit) }
                ]}
              >
                <Input
                  type='number'
                  className='ant-input-affix-wrapper'
                  placeholder='Height'
                />
              </Form.Item>
              <Form.Item
                style={{ width: '30%' }}
                name='heightUnit'
                label='Unit'
                rules={[{ required: true, message: 'Please select unit' }]}
              >
                <Select
                  onChange={e => {
                    setProfileHeightUnit(e);
                  }}
                  className='ant-select-selector'
                  placeholder='Unit'
                >
                  {HeightUnit?.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      {item?.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item
                style={{ width: '70%' }}
                name='weight'
                label='Weight'
                rules={[
                  { required: true },
                  { validator: validateWeight(profileWeightUnit) }
                ]}
              >
                <Input
                  type='number'
                  className='ant-input-affix-wrapper'
                  placeholder='Enter your weight'
                />
              </Form.Item>

              <Form.Item
                style={{ width: '30%' }}
                name='weightUnit'
                label='Unit'
                rules={[{ required: true, message: 'Please select unit' }]}
              >
                <Select
                  onChange={e => {
                    setProfileWeightUnit(e);
                  }}
                  className='ant-select-selector'
                  placeholder='Unit'
                  disabled

                >
                  {WeightUnit?.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      {item?.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col> */}
          <Col span={mobileResponsive ? 12 : 6}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item
                name='height'
                label='Height'
                rules={[
                  { required: true },
                  { validator: validateHeight(profileHeightUnit) }
                ]}
              >
                <Input
                  addonBefore="Ft'In"
                  placeholder='Height'
                  onChange={handleChangeHeight}
                />
              </Form.Item>

              {/* <Form.Item
                style={{ width: '30%' }}
                name='heightUnit'
                label='Unit'
                rules={[{ required: true, message: 'Please select unit' }]}
              >
                <Select
                  onChange={e => {
                    setProfileHeightUnit(e);
                  }}
                  className='ant-select-selector'
                  placeholder='Unit'
                >
                  {HeightUnit?.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      {item?.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
            </div>
          </Col>
          <Col span={mobileResponsive ? 12 : 6}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item
                name='weight'
                label='Weight'
                rules={[
                  { required: true },
                  { validator: validateWeight(profileWeightUnit) }
                ]}
              >
                <Input
                  addonBefore='Lbs'
                  type='number'
                  placeholder='Enter your weight'
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={mobileResponsive ? 24 : 12}>
            <Form.Item
              name='bmi'
              label='BMI'
              rules={[
                {
                  required: false,
                  message: 'Please select phone type'
                }
              ]}
            >
              <Input placeholder='BMI' disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='language'
              label='Language'
              rules={[
                { required: true, message: 'Please select your language' }
              ]}
            >
              <Select
                mode='multiple'
                showSearch
                className='ant-select-selector'
                placeholder='language'
              >
                {AllLanguages?.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }} span={24}>
            <SecondaryButton disabled={loading}>Next</SecondaryButton>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default PersonalInfoPopup;
