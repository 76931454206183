import { Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import CheckBoxFields from '../../UI/CheckBoxFields/CheckFields';
import './PatientBookingInfo.scss';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../../service/ApisFunctions';
import { InformationSelfForm } from './BookingInfoForms/InformationSelfForm';
import { InformationChildForm } from './BookingInfoForms/InformationChildForm';
import {
  setBookingFlowInformationSteps,
  setBookingFlowLocation,
  setBookingFlowLogin,
  setBookingFlowOtherPatient,
  setBookingFlowOtherPatientIsExisting,
  setBookingFlowPatientData
} from '../../../Redux/Reducers/bookingSlice';
import dayjs from 'dayjs';
import SkeletonInput from '../../UI/Skeleton/skeletonInput';
import { DEPLOYMENT_MODE } from '../../../Utils/baseUrl';
import { useNavigate } from 'react-router-dom';
export const PatientBookingInfo = ({ patientToken, isModal, setModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookingFor = useSelector(state => state.booking.bookingFlowAs);

  const completeUser = useSelector(state => state.generalReducer.completeUser);

  // Define states
  const [loading, setLoading] = useState(false);
  const [otherPatientLoading, seOtherPatientLoading] = useState(false);
  const [allOthersPatient, setAllOthersPatient] = useState([]);
  const [fpHash, setFpHash] = useState("");
  const [allState, setAllState] = useState([]);
  const [informationStep, setInformationStep] = useState("general");
  const [guestSignup, setGuestSignup] = useState(false);
  const [addPatient, setAddPatient] = useState(false);

  // Custom Functions
  const getAllOtherPatients = async () => {
    if (patientToken) {
      seOtherPatientLoading(true);
      const onSuccess = (res) => {
        setAllOthersPatient(res?.data);
        seOtherPatientLoading(false);
      };

      const onError = (err) => {
        message.error(err?.message);
        seOtherPatientLoading(false);
      };

      await getRequest("", `patient/get-others`, true, onSuccess, onError);
    }
  };
  const getAllSatates = async () => {
    setLoading(true);
    const onSuccess = (res) => {
      setAllState(res?.data);
      setLoading(false);
    };

    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    await getRequest(
      "",
      "state/active_state_listing",
      true,
      onSuccess,
      onError
    );
  };

  const setChildPersonalInfo = item => {
    dispatch(
      setBookingFlowPatientData({
        first_name: item?.patient?.first_name,
        last_name: item?.patient?.last_name,
        middle_name: item?.patient?.middle_name,

        email: completeUser?.user_id?.email,
        address: completeUser?.location?.address,
        dob: item?.patient?.dob,
        height: {
          value: item?.patient?.height?.value,
          unit: item?.patient?.height?.unit,
        },
        weight: {
          value: item?.patient?.weight?.value,
          unit: item?.patient?.weight?.unit,
        },
        guardianFirstName: completeUser?.first_name,
        guardianLastName: completeUser?.last_name,
        guardian_middle_name: completeUser?.middle_name,
        guardianDob: completeUser?.dob ? dayjs(completeUser?.dob) : null,
        phone_number: completeUser?.phone_number,
        file: item?.patient?.attachment || "",
        relation: item?.patient?.relation,
        language: item?.patient?.language,
        state: completeUser?.state,
        gender: item?.patient?.gender,
        birthGender: item?.patient?.birthGender,
        sexualOrientation: item?.patient?.sexualOrientation,
        race: item?.patient?.race,
        ethnicity: item?.patient?.ethnicity,
        pronoun: item?.patient?.pronoun || "",
      })
    );
    dispatch(
      setBookingFlowLocation({
        address: item?.patient?.location?.address,
        coordinates: [
          item?.patient?.location?.coordinates[0],
          item?.patient?.location?.coordinates[1],
        ],
      })
    );
    dispatch(setBookingFlowOtherPatient(item));
    dispatch(setBookingFlowOtherPatientIsExisting(true));
    dispatch(setBookingFlowInformationSteps(1));
  };
  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFpHash(visitorId);
    };
    setFp();
    getAllSatates();
    if (bookingFor === "guardian") {
      getAllOtherPatients();
    }
  }, []);

  return (
    <div className="patientInformationWrap">
      {informationStep === "general" ? (
        <div>
          {!patientToken ? (
            <>
             <CheckBoxFields
                label=''
                placeholder='Already have an account?'
                button={
                  <button
                    className="customLoginButton"
                    onClick={() => {
                      dispatch(setBookingFlowLogin("booking"))
                      navigate("/login")
                    }}
                  >
                    Log in
                  </button>
                }
              />
              {guestSignup ? (
                <InformationSelfForm
                  allState={allState}
                  patientToken={patientToken}
                  fpHash={fpHash}
                  isModal={isModal}
                  setModal={setModal}
                />
              ) : DEPLOYMENT_MODE === "dev" ? (
                <CheckBoxFields
                  label=""
                  placeholder="Don't have an account?"
                  button={
                    <button
                      className="customLoginButton"
                      onClick={() => setGuestSignup(true)}
                    >
                      Continue As Guest
                    </button>
                  }
                />
              ) : null}
            </>
          ) : (
            <>
              {completeUser?._id && (
                <InformationSelfForm
                  allState={allState}
                  patientToken={patientToken}
                  fpHash={fpHash}
                  isModal={isModal}
                  setModal={setModal}
                />
              )}

              {bookingFor === "guardian" && (
                <>
                  {otherPatientLoading ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                      {[1, 2]?.map((item, index) => (
                        <div key={index} style={{ width: "20%" }}>
                          <SkeletonInput block={true} />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="addOther">
                      {allOthersPatient?.map((item, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            setChildPersonalInfo(item);
                          }}
                          className={"patientBtn"}
                        >
                          {`${item?.patient?.first_name || "-"} ${
                            item?.patient?.middle_name || ""
                          } ${item?.patient?.last_name || "-"}`}
                        </button>
                      ))}
                      <button
                        onClick={() => {
                          setAddPatient(true);
                          dispatch(setBookingFlowOtherPatient(null));
                          dispatch(setBookingFlowOtherPatientIsExisting(false));
                        }}
                        className={"activePatient"}
                      >
                        + Add Patient
                      </button>
                    </div>
                  )}
                  {addPatient ? (
                    <Spin spinning={loading}>
                      <InformationChildForm
                        informationStep={informationStep}
                        allState={allState}
                        setInformationStep={setInformationStep}
                        patientToken={patientToken}
                        fpHash={fpHash}
                        isModal={isModal}
                        setModal={setModal}
                      />
                    </Spin>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};
