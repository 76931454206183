import { useState, useEffect, useRef } from "react";
import classes from "./MessagesData.module.scss";
import Doctors from "../../Components/MessagesData/Doctors/Doctors";
import Messages from "../../Components/MessagesData/Messages/Messages";
import { useMediaQuery } from "react-responsive";
import { GetAllDoctors } from "./constants";
import { getRequest, postRequestFormData } from "../../service/ApisFunctions";
import { Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../Utils/helper";
import { useSearchParams } from "react-router-dom";
import { setMessagesCount } from "../../Redux/Reducers/gernalSlice";
import Header from "../../Components/Layouts/Header/Header";

const MessagesData = () => {
  const [activeDoctor, setActiveDoctor] = useState("");
  const [showMega, setShowMega] = useState(false);
  const [allDoctor, setAllDoctors] = useState([]);
  const [patientDetial, setPatientDetial] = useState({});
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [allFilterDoctors, setAllFilterDoctors] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [singleMessage, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [fileToSend, setFileToSend] = useState(null);
  const [mobileSelectedDoctor, setMobileSelectedDoctor] = useState({});

  const [searchParams] = useSearchParams();
  const messageId = searchParams.get("messageId");
  const completeUser = useSelector(
    (state) => state.generalReducer.completeUser
  );
  const dispatch = useDispatch();
  const isLaptop = useMediaQuery({ query: "(max-width: 768px)" });

  const socketRef = useRef(socket); // Store socket instance in ref
  const inputRef = useRef(null); // Ref for the input field

  const getALLDoctors = async () => {
    setLoading(true);
    const onSuccess = (res) => {
      setAllDoctors(res?.data);
      setAllFilterDoctors(res?.data);
      getDoctorDtails(messageId ? messageId : res?.data?.[0]?._id);
      setActiveDoctor(messageId ? messageId : res?.data?.[0]?._id);
      setLoading(false);
    };

    const onError = () => {
      setLoading(false);
    };

    await getRequest("", GetAllDoctors, true, onSuccess, onError);
  };

  useEffect(() => {
    getALLDoctors();
    dispatch(setMessagesCount(0));
  }, []);

  const getDoctorDtails = async (id) => {
    setChatLoading(true);
    const onSuccess = (res) => {
      setPatientDetial(res?.data);
      setMessages(res?.data?.messages);
      setFilteredMessages(res?.data?.messages);
      setChatLoading(false);
    };

    const onError = () => {
      setChatLoading(false);
    };

    const route = `chat/chat_detail/${id}`;

    await getRequest("", route, true, onSuccess, onError);
  };

  const sendMessage = () => {
    if (!fileToSend) {
      if (singleMessage.trim() !== "") {
        const messageData = {
          senderId: completeUser?.user_id?._id,
          text: singleMessage,
          conversationId: patientDetial?._id,
        };
        socketRef.current.emit("chat", messageData, (acknowledgment) => {
          if (acknowledgment === "sent") {
            setMessage("");
            inputRef.current.focus(); // Focus the input field after sending the message
          }
        });
      }
    } else {
      sendFile();
      setFileToSend(null);
      setMessage("");
      inputRef.current.focus(); // Focus the input field after sending the message
    }
  };

  useEffect(() => {
    if (allDoctor.length) {
      socketRef.current.on("onlineUsersUpdate", (users) => {
        setOnlineUsers(users);
      });
    }
    return () => {
      socketRef.current.off("onlineUsersUpdate"); // Clean up on unmount
    };
  }, [allDoctor.length]);

  useEffect(() => {
    if (patientDetial._id) {
      socketRef.current.emit("join", {
        conversationId: patientDetial._id,
        userId: completeUser?.user_id?._id,
      });

      const handleChat = (chat) => {
        if (chat?.conversationId) {
          const newMessage = {
            date: chat?.date,
            sender: chat?.senderId,
            _id: chat?.conversationId,
            status: "sent",
          };

          if (chat?.file) {
            newMessage.file = chat?.file;
          } else {
            newMessage.message = chat?.text;
          }

          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setFilteredMessages((prevMessages) => [...prevMessages, newMessage]);

          if (chat?.senderId !== completeUser?.user_id?._id) {
            socketRef.current.emit("messageSeen", {
              chatId: chat?.conversationId,
              messageId: chat?.messageId,
              text: chat?.text,
              conversationId: chat?.conversationId,
            });
          }
        }
      };

      socketRef.current.on("chat", handleChat);

      return () => {
        socketRef.current.off("chat", handleChat); // Clean up on unmount
      };
    }
  }, [patientDetial?._id, completeUser?.user_id?._id]);

  useEffect(() => {
    socketRef.current.on("setMessageSeen", (payload) => {
      const updatedMessages = messages.map((message) => {
        if (message._id === payload?.conversationId) {
          return { ...message, status: payload?.status };
        }
        return message;
      });
      setMessages(updatedMessages);
      setFilteredMessages(updatedMessages);
    });

    return () => {
      socketRef.current.off("setMessageSeen"); // Clean up on unmount
    };
  }, [messages]);

  const onUserSearch = (e) => {
    const value = e.target.value;
    const filtered = allDoctor.filter((item) => {
      return (item?.provider?.first_name + item?.provider?.last_name)
        ?.toLowerCase()
        .includes(value.toLowerCase().replace(/\s/g, ""));
    });

    setAllFilterDoctors(filtered);
  };

  const onMessageSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const filtered = messages.filter((message) => {
        return message?.message
          ?.toLowerCase()
          .includes(value.toLowerCase().replace(/\s/g, ""));
      });
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages(messages);
    }
  };

  const sendFile = () => {
    socketRef.current.emit(
      "upload",
      fileToSend,
      completeUser?.user_id?._id,
      patientDetial?._id
    );
  };

  const beforeUpload = async (file) => {
    const isPdfOrImage =
    (file.type.startsWith("image/") && file.type !== "image/svg+xml") || file.type === "application/pdf";

    if (!isPdfOrImage) {
      message.warning("You can only upload PDF and image files!");
      return false;
    }

    setImageLoading(true);

    const onSuccess = (res) => {
      setImageLoading(false);
      setFileToSend(res?.data);
    };

    const onError = () => {
      setImageLoading(false);
    };

    const formData = {
      image: file,
    };

    await postRequestFormData(
      formData,
      "auth/upload_image_s3",
      true,
      onSuccess,
      onError
    );

    return false;
  };

  const props = {
    beforeUpload,
    fileList: null,
  };

  return (
    <Spin spinning={loading}>
      <Header showMega={showMega} setShowMega={setShowMega} />
      <div className={classes.messagesRow} onClick={() => setShowMega(false)}>
        {!isLaptop ? (
          <>
            <Doctors
              onUserSearch={onUserSearch}
              getDoctorDtails={getDoctorDtails}
              allFilterDoctors={allFilterDoctors}
              setActiveDoctor={setActiveDoctor}
              activeDoctor={activeDoctor}
              onlineUsers={onlineUsers}
              setMobileSelectedDoctor={setMobileSelectedDoctor}
            />
            <Messages
              props={props}
              completeUser={completeUser}
              setMessage={setMessage}
              message={singleMessage}
              filteredMessages={filteredMessages}
              sendMessage={sendMessage}
              patientDetial={patientDetial}
              onMessageSearch={onMessageSearch}
              fileToSend={fileToSend}
              setFileToSend={setFileToSend}
              setMobileSelectedDoctor={setMobileSelectedDoctor}
              chatLoading={chatLoading}
              imageLoading={imageLoading}
              inputRef={inputRef} // Pass the ref to the Messages component
            />
          </>
        ) : (
          <>
            {activeDoctor && mobileSelectedDoctor?._id ? (
              <Messages
                props={props}
                filteredMessages={filteredMessages}
                setActiveDoctor={setActiveDoctor}
                sendMessage={sendMessage}
                setMessage={setMessage}
                message={singleMessage}
                completeUser={completeUser}
                onMessageSearch={onMessageSearch}
                patientDetial={patientDetial}
                fileToSend={fileToSend}
                setFileToSend={setFileToSend}
                setMobileSelectedDoctor={setMobileSelectedDoctor}
                chatLoading={chatLoading}
                imageLoading={imageLoading}
                inputRef={inputRef} // Pass the ref to the Messages component
              />
            ) : (
              <Doctors
                onUserSearch={onUserSearch}
                getDoctorDtails={getDoctorDtails}
                allFilterDoctors={allFilterDoctors}
                setActiveDoctor={setActiveDoctor}
                activeDoctor={activeDoctor}
                onlineUsers={onlineUsers}
                setMobileSelectedDoctor={setMobileSelectedDoctor}
              />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default MessagesData;
