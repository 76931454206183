import { Col, Form, Input, Row, Spin, message } from "antd";
import React, { useState } from "react";
import SecondaryButton from "../UI/SecondaryButton/SecondaryButton";
import { putRequest } from "../../service/ApisFunctions";
import { UpdatePassword } from "../../Pages/Profile/constant";
import { strongPasswordValidator } from "../../Utils/helper";
import "./changePassword.scss"

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [old, setOld] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const [form] = Form.useForm();

  const formHandler = async (e) => {
    setLoading(true);

    const onSuccess = (res) => {
      message.success(res?.message);
      setLoading(false);
      form.resetFields();
    };
    const onError = (err) => {
      message.error(err?.message);
      setLoading(false);
    };

    const formdata = {
      old_password: e?.old_password,
      new_password: e?.new_password,
      confirm_password: e?.confirm_password,
    };

    await putRequest(formdata, UpdatePassword, true, onSuccess, onError);
  };

  return (
    <Spin spinning={loading}>
      <Form
        className="changePassword"
        form={form}
        layout="vertical"
        onFinish={formHandler}
      >
        <Row>
          <Col className="changePasswordHeader" span={24}>
            <h2>Change Password</h2>
            <SecondaryButton>Update</SecondaryButton>
          </Col>
          <Col span={24}>
            <Form.Item
              name="old_password"
              label="Old Password"
              rules={[
                { required: true, message: "Please enter a old password" },
                { validator: old && strongPasswordValidator },
              ]}
            >
              <Input.Password
                onChange={(e) => setOld(e?.target?.value)}
                placeholder="Enter Old Password"
                className="ant-input-affix-wrapper"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="new_password"
              label="New Password"
              rules={[
                { required: true, message: "Please enter a new password" },
                { validator: newPassword && strongPasswordValidator },
              ]}
            >
              <Input.Password
                onChange={(e) => setNewPassword(e?.target?.value)}
                placeholder="Enter New Password"
                className="ant-input-affix-wrapper"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              rules={[
                { required: true, message: "Please enter a confirm password" },
                { validator: confirm && strongPasswordValidator },
              ]}
            >
              <Input.Password
                onChange={(e) => setConfirm(e?.target?.value)}
                placeholder="Enter Confirm Password"
                className="ant-input-affix-wrapper"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ChangePassword;
