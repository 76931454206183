import React from 'react';
import './gradientBackground.scss';
export const GradientBackground = ({ children, componentType }) => {
  return (
    <div className='customLayerdBackground'>
      {' '}
      <div className='layer1'></div>
      <div className='layer2'></div>
      <div className='layer3'></div>
      {/* {componentType === 'banner' ? (
        <>
          <img className='right' src={BannerTree1} alt='' />
          <img className='bottom' src={BannerTree2} alt='' />
        </>
      ) : componentType === 'services' ? (
        <>
          <img className='right' src={ServiceTree1} alt='' />
          <img className='left' src={ServiceTree2} alt='' />
        </>
      ) : null} */}
      {children}
    </div>
  );
};
